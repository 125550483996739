export function getBackendHost(): string {
    const fromEnv: string = process.env.BACKEND_HOST
    let backendHost: string

    if (fromEnv) {
        backendHost = fromEnv
    } else {
        const { protocol, hostname } = window.location
        backendHost = protocol + '//' + hostname
    }

    console.log('Backend host: ' + backendHost)

    return backendHost
}
