import * as Yup from 'yup'
import * as styles from './teamForm.scss'
import { Field, Form, Formik } from 'formik'
import { BasicPopup } from '../../../portal/components/basicPopup/basicPopup'
import { TextInput } from '../../../portal/components/textInput/textInput'
import { SubmitButton } from '../../../portal/components/submitButton/submitButton'
import { TeamEditable } from '../../interfaces/team'
import { PopupFooter } from '../../../portal/components/popupFooter/popupFooter'
import Select, { MultiValue, ActionMeta, Theme } from 'react-select'
import classNames from 'classnames'
import { CustomAppFlags } from '~/app/portal/interfaces/apps'
import { PermissionComponent } from '~/app/components/permission/permission'

interface Props {
	initial: TeamEditable
	onSubmit: (team: TeamEditable) => void
}

const themeConfig = (theme: Theme) => {
	return {
		...theme,
		colors: {
			...theme.colors,
			primary25: 'rgba(255,109,51,.20)',
			primary: 'rgba(255,109,51,1)',
		},
	}
}

export function TeamForm(props: Props): JSX.Element {
	const { initial, onSubmit } = props

	type Option = { label: string; value: string }

	const appOptions = [
		{ value: CustomAppFlags.CodeTesting, label: 'Code testing' },
		{
			value: CustomAppFlags.GenerateCertificate,
			label: 'Generate Certificates',
		},
		{ value: CustomAppFlags.SampleApprovals, label: 'Sample Approvals' },
		{ value: CustomAppFlags.RapidAntigen, label: 'Rapid Antigen' },
		{ value: CustomAppFlags.NoCard, label: 'Experimental No Card' },
		{ value: CustomAppFlags.CreateTeam, label: 'Create new team' },
		{ value: CustomAppFlags.UploadLabExport, label: 'Upload results' },
	]

	return (
		<Formik
			initialValues={initial}
			onSubmit={onSubmit}
			validationSchema={Yup.object().shape({
				name: Yup.string().required(),
				apps: Yup.array().of(Yup.string()),
			})}
		>
			{({
				values,
				errors,
				touched,
				handleChange,
				handleBlur,
				isSubmitting,
				isValid,
				setFieldTouched,
			}) => (
				<BasicPopup title="Edit team" size="m">
					<Form>
						<div className="container">
							<fieldset>
								<div className="row mb-4">
									<div className="col-12">
										<label className="form-label">
											Team name
										</label>
										<TextInput
											name="name"
											title="Name"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.name}
											error={errors.name}
											touched={touched.name}
											autoFocus
										/>
									</div>
								</div>
								<PermissionComponent
									service="portal"
									action="team:AssignApps"
								>
									<div className="row mb-4">
										<div className="col-12">
											<label className="form-label">
												Apps
											</label>
											<div className="input-group">
												<Field
													name="apps"
													component={({
														field,
														form,
													}) => (
														<Select
															defaultValue={
																field.value
															}
															isMulti
															name="apps"
															placeholder="Select apps for the new team"
															options={appOptions}
															theme={(theme) =>
																themeConfig(
																	theme
																)
															}
															menuPosition="fixed"
															className={classNames(
																'basic-multi-select',
																styles.select
															)}
															classNamePrefix="select"
															value={field.value.map(
																(template) =>
																	appOptions.find(
																		(
																			option
																		) =>
																			option.value ===
																			template
																	)
															)}
															onChange={(
																option: MultiValue<Option>,
																{
																	action,
																}: ActionMeta<unknown>
															) => {
																form.setFieldValue(
																	field.name,
																	[
																		...option.map(
																			(
																				option
																			) =>
																				option.value
																		),
																	]
																)
															}}
															onBlur={() =>
																setFieldTouched(
																	field.name,
																	true
																)
															}
														/>
													)}
												/>
											</div>
										</div>
									</div>
								</PermissionComponent>
							</fieldset>
						</div>

						<PopupFooter>
							<SubmitButton
								title="Save"
								disabled={!isValid || isSubmitting}
								saving={isSubmitting}
							/>
						</PopupFooter>
					</Form>
				</BasicPopup>
			)}
		</Formik>
	)
}
