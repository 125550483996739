import * as React from 'react'
import * as styles from './portalImages.scss'
import { AppScreen } from '../appScreen/appScreen'
import { AppLinks } from '../../config'
import { SetPageTitle } from '../../helpers/setPageTitle'
import { ImagesList } from '../imagesList/imagesList'
import { Role } from '@graphql-enums@'
import { PermissionComponent } from '~/app/components/permission/permission'

export function PortalImages(): JSX.Element {
	return (
		<AppScreen activeAppLink={AppLinks.images}>
			<PermissionComponent
				service={'sample-approvals'}
				action={'images:ViewData'}
			>
				<>
					<SetPageTitle title="Images" />
						<div className={styles.container}>
							<ImagesList />
						</div>
				</>
			</PermissionComponent>
		</AppScreen>
	)
}
