import classNames from 'classnames'
import { Field, Form, Formik } from 'formik'
import moment from 'moment-timezone'
import { useSnackbar } from 'notistack'
import * as React from 'react'
import { useCallback, useState } from 'react'
import * as Yup from 'yup'
import { usePopup } from '~/app/providers/popup'
import { Button, ButtonTypes } from '../../portal/components/button/button'
import { LabeledInput } from '../../portal/components/labeledInput/labeledInput'
import { PrimaryContent } from '../../portal/components/primaryContent/primaryContent'
import { ReactSelect } from '../../portal/components/reactSelect/reactSelect'
import { SubmitButton } from '../../portal/components/submitButton/submitButton'
import { useFormatMessage } from '../../portal/helpers/intlContext'
import { validateForm } from '../../portal/helpers/validateForm'
import { useTimezone } from '../../providers/store'
import { SettingsAppScreen } from '../../settings/components/settingsAppScreen'
import { DisplayRole } from '../helpers/displayRole'
import { passwordValidate } from '../helpers/passwordValidate'
import { useUser } from '../hooks/useUser'
import { useUpdateUserMutation } from '../mutations/updateUser.graphql'
import { useVerifyEmailMutation } from '../mutations/verifyEmail.graphql'
import { Avatar } from './avatar/avatar'
import { DisableOtpForm } from './disableOtpForm'
import { EnableOtpForm } from './enableOtpForm'
import { UpdatePassword } from './updatePassword/updatePassword'

const SelectTimezone = (): JSX.Element => {
	const names = moment.tz.names()

	type Option = { label: string; value: string }

	const options: Option[] = React.useMemo(
		() =>
			names.map((tz) => ({
				label: tz,
				value: tz,
			})),
		[names]
	)

	const [tz, setTz] = useTimezone()

	const handleSubmit = React.useCallback((values, actions) => {
		setTz(values.tz)

		actions.setSubmitting(false)
	}, [])

	return (
		<div>
			<Formik
				initialValues={{
					tz: tz,
				}}
				enableReinitialize={true}
				onSubmit={handleSubmit}
			>
				{({
					values,
					handleBlur,
					setFieldValue,
					setFieldTouched,
					dirty,
					isSubmitting,
					isValid,
				}) => (
					<Form>
						<fieldset className="mb-2">
							<Field
								name="tz"
								component={({ field, form }) => (
									<ReactSelect
										name="tz"
										options={options}
										onChange={(option: Option) =>
											form.setFieldValue(
												field.name,
												option.value
											)
										}
										onBlur={handleBlur}
										value={options.find(
											(option) =>
												option.value === values.tz
										)}
									/>
								)}
							/>
							<Field
								name="Current"
								component={() => (
									<>
										<b>Current: </b>{' '}
										{moment().tz(values.tz).toString()}
									</>
								)}
							/>
						</fieldset>
						<SubmitButton
							title="Save"
							saving={isSubmitting}
							disabled={!isValid || !dirty}
						/>
					</Form>
				)}
			</Formik>
		</div>
	)
}

export function Account(): JSX.Element {
	const t = useFormatMessage()

	const user = useUser()
	const [incorrectPasswords] = useState<string[]>([])

	const getValidationSchema = useCallback((values) => {
		const isSettingPassword =
			!!values.currentPassword ||
			!!values.newPassword ||
			!!values.repeatPassword
		const passwordType = isSettingPassword
			? Yup.string().required(t('form.required'))
			: Yup.string()

		return Yup.object().shape({
			fullName: Yup.string().required(t('form.required')),
			email: Yup.string()
				.required(t('form.required'))
				.email(t('form.emailInvalid')),
			currentPassword: passwordType.notOneOf(
				incorrectPasswords,
				'Incorrect password'
			),
			newPassword: passwordType && passwordValidate,
			repeatPassword: passwordType.oneOf(
				[values.newPassword],
				t('form.passwordsMustMatch')
			),
		})
	}, [])

	const [, updateUser] = useUpdateUserMutation()

	const { enqueueSnackbar } = useSnackbar()

	const onSubmit = useCallback(
		(data, { setSubmitting }) => {
			const input = {
				fullName: data.fullName,
				userId: user.userId,
				currentPassword: data.currentPassword,
				newPassword: data.newPassword,
				role: user.role,
			}

			updateUser({ input }).then((response) => {
				setSubmitting(false)

				if (response.error) {
					enqueueSnackbar(
						<span>
							Something went wrong, please try again later.
						</span>,
						{
							variant: 'error',
						}
					)
				} else {
					enqueueSnackbar(
						<span>Your account has been updated.</span>,
						{
							variant: 'success',
						}
					)
				}
			})
		},
		[user]
	)

	const { setPopup } = usePopup()

	const enableOtp = useCallback(() => {
		setPopup(<EnableOtpForm />)
	}, [])

	const disableOtp = useCallback(() => {
		setPopup(<DisableOtpForm />)
	}, [])

	const [emailSent, setEmailSent] = useState<boolean>(false)

	const [, verifyEmail] = useVerifyEmailMutation()

	const sendNewConfirmation = useCallback(() => {
		verifyEmail().then(() => setEmailSent(true))
	}, [])

	if (!user) {
		return null
	}

	return (
		<SettingsAppScreen>
			<PrimaryContent>
				<h3>Your account</h3>
				<div className="d-flex align-items-center mb-4">
					<Avatar url={user.avatarUrl} />
					<p className="ms-2 mb-0">
						Avatars are set via{' '}
						<a
							href="https://gravatar.com"
							target="_blank"
							rel="noreferrer"
						>
							gravatar.com
						</a>
					</p>
				</div>

				<div className="mb-4">
					<Formik
						initialValues={{
							fullName: user.fullName,
							email: user.email,
						}}
						enableReinitialize={true}
						validate={(values) =>
							validateForm(getValidationSchema, values)
						}
						onSubmit={onSubmit}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							isSubmitting,
							isValid,
							dirty,
						}) => (
							<Form>
								<fieldset className="mb-2">
									<LabeledInput
										title="Full name"
										name="fullName"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.fullName}
										error={errors.fullName}
										touched={touched.fullName}
									/>
									<LabeledInput
										title="Email"
										name="email"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.email}
										error={errors.email}
										touched={touched.email}
										readOnly={true}
									/>

									<div className={classNames('form-group')}>
										<label>
											Role in team{' '}
											<b>{user.currentTeam.name}</b>
										</label>
										<div className="input-group">
											<div className="form-control">
												<DisplayRole
													roleId={user.role}
												/>
											</div>
										</div>
									</div>

									{!user.emailConfirmed && (
										<div className="alert alert-warning">
											<p>
												Your email address has not been
												confirmed yet. Go to your inbox
												to confirm your email address.
											</p>
											{emailSent ? (
												<p>
													Confirmation email has been
													sent.
												</p>
											) : (
												<Button
													onClick={
														sendNewConfirmation
													}
													type={ButtonTypes.primary}
												>
													Resend confirmation email
												</Button>
											)}
										</div>
									)}
								</fieldset>
								<SubmitButton
									title="Save"
									saving={isSubmitting}
									disabled={!isValid || !dirty}
								/>
							</Form>
						)}
					</Formik>
				</div>

				<h3>Timezone</h3>
				<div className="mb-4">
					<SelectTimezone />
				</div>

				<h3>Update password</h3>
				<div className="mb-4">
					<UpdatePassword />
				</div>

				<h3>Two-factor authentication</h3>
				{!user.otpEnabled && (
					<div className="alert alert-primary mb-3">
						We highly recommend you enable 2FA for increased
						security.
					</div>
				)}
				<div className="mb-4">
					{user.otpEnabled && (
						<React.Fragment>
							<fieldset className="mb-2">
								<p>
									2FA is <strong>enabled</strong>
								</p>
							</fieldset>
							<Button
								type={ButtonTypes.danger}
								onClick={disableOtp}
								icon="AlertTriangle"
								iconOnRight
							>
								Disable 2FA
							</Button>
						</React.Fragment>
					)}
					{!user.otpEnabled && (
						<React.Fragment>
							<fieldset className="mb-2">
								<p className="">
									2FA is <strong>disabled</strong>
								</p>
							</fieldset>
							<Button
								type={ButtonTypes.primary}
								onClick={enableOtp}
								icon="Link2"
								iconOnRight
							>
								Enable 2FA
							</Button>
						</React.Fragment>
					)}
				</div>
			</PrimaryContent>
		</SettingsAppScreen>
	)
}
