import qs from "qs"
import { useState } from "react"
import { useHistory, useLocation } from "react-router-dom"

export function useHashState<T>(key: string, initialValue?: any) {
	const location = useLocation()
	const history = useHistory<{ hash: string }>()

	// State to store our value
	// Pass initial state function to useState so logic is only executed once
	const [storedValue, setStoredValue] = useState<any>(() => {
		const hash = qs.parse(location.hash.replace(/^\#/, ''))
		return hash[key] ?? initialValue
	})

	// Return a wrapped version of useState's setter function that ...
	// ... persists the new value to localStorage.
	const setValue = (value: any) => {
		const hash = qs.parse(location.hash.replace(/^\#/, ''))
		hash[key] = value

		const s = qs.stringify(hash)

		history.replace({
			hash: s,
		})

		setStoredValue(value)
	}

	return [storedValue, setValue]
}
