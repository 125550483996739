import * as React from 'react'
import { useCallback } from 'react'
import { Paths } from '../../../portal/helpers/routePaths'
import { PrimaryContent } from '../../../portal/components/primaryContent/primaryContent'
import { DisplayString } from '../../../portal/components/displayString'
import { DisplayDate } from '../../../portal/components/displayDate/displayDate'
import { DisplayBoolean } from '../../../portal/components/displayBoolean'
import { Avatar } from '../avatar/avatar'
import { Button, ButtonTypes } from '../../../portal/components/button/button'
import { useGoTo } from '../../../portal/hooks/useGoTo'
import { SettingsAppScreen } from '../../../settings/components/settingsAppScreen'
import { Link, RouteComponentProps } from 'react-router-dom'
import { UserEdit } from '../userEdit/userEdit'
import { DisplayRole } from '../../helpers/displayRole'
import { setNotAllowedRoles } from '../selectRole/selectRole'
import { useUser } from '../../hooks/useUser'
import { ConfirmAction } from '../../../portal/components/confirmAction/confirmAction'
import { User } from '@graphql-types@'
import { Role } from '@graphql-enums@'
import { useUserQuery } from '../../queries/userQuery.graphql'
import { useRolesQuery } from '../selectRole/roles.graphql'
import { usePopup } from '~/app/providers/popup'
import { useRemoveUserFromTeamMutation } from '../../mutations/removeUserFromTeam.graphql'
import { useSnackbar } from 'notistack'
import { PermissionComponent } from '~/app/components/permission/permission'

type Props = RouteComponentProps<{ userId: string }>

export function UserDetail(props: Props): JSX.Element {
	const currentUser = useUser()
	const { userId } = props.match.params
	const [{ data, fetching }] = useUserQuery({ variables: { userId } })

	const user: User = data ? data.user : null

	const [, removeUserFromTeam] = useRemoveUserFromTeamMutation()
	const goBack = useGoTo(Paths.settingsTeam)

	const { enqueueSnackbar } = useSnackbar()

	const onRemoveFromTeam = useCallback(() => {
		var promise = new Promise<void>(function (resolve, reject) {
			removeUserFromTeam({
				userId: user.userId,
				teamId: currentUser.currentTeam.teamId,
			}).then((response) => {
				if (response.error) {
					enqueueSnackbar(
						<span>
							Could not remove <b>{user.fullName}</b> from team.
						</span>,
						{
							variant: 'error',
						}
					)
					reject()
				} else {
					enqueueSnackbar(
						<span>
							User <b>{user.fullName}</b> removed from team.
						</span>,
						{
							variant: 'success',
						}
					)
					goBack()
					resolve()
				}
			})
		})
		return promise
	}, [user, removeUserFromTeam, currentUser])

	const { setPopup } = usePopup()

	const onEdit = useCallback(() => {
		setPopup(<UserEdit user={user} />)
	}, [user])

	const notMySelf = user?.userId !== currentUser.userId

	const [urq] = useRolesQuery()

   	const currentUserRole:string = urq?.data?.roles.find(
	    (role) => role.roleId === currentUser?.role
   		)?.name ?? 'unknown role'
	const userRole:string = urq?.data?.roles.find(
		(role) => role.roleId === user?.role
		)?.name ?? 'unknown role'

	const loadRanking:boolean = !urq.fetching
   	const notAllowedList = setNotAllowedRoles(currentUserRole)
   	const higherRankingUser:boolean = !loadRanking ? null :
		    !(notAllowedList.indexOf(userRole) > -1)
	   
	const header = (
		<>
			<Button
				onClick={goBack}
				type={ButtonTypes.transparent}
				icon="ChevronLeft"
			>
				Team
			</Button>
				{ ( loadRanking && higherRankingUser)&& (
					<PermissionComponent service="portal" action="team:EditUser">
						<Button
							icon="Edit"
							onClick={onEdit}
							type={ButtonTypes.transparent}
						>
							Edit user
						</Button>
					</PermissionComponent>
				)}
			{( loadRanking && higherRankingUser && notMySelf) && (
				<PermissionComponent service="portal" action="team:RemoveUser">
					<ConfirmAction
						buttonType={ButtonTypes.transparentDanger}
						icon="Trash2"
						dialogueTitle={
							<span>
								Remove <b>{user?.fullName}</b> from team?
							</span>
						}
						onAction={onRemoveFromTeam}
					>
						Remove from team
					</ConfirmAction>
				</PermissionComponent>
			)}
		</>
	)

	return (
		<SettingsAppScreen>
			<PrimaryContent header={header} loading={fetching}>
				{user && (
					<>
						<h2 className="mb-3">
							<Avatar
								url={user.avatarUrl.slice(0, -2) + 'identicon'}
							/>
							<span className="ps-2">{user.fullName}</span>
						</h2>
						<dl>
							<dt>Username</dt>
							<dd>
								<DisplayString value={user.userName} />
							</dd>
							<dt>Email</dt>
							<dd>
								<DisplayString value={user.email} />
							</dd>
							<dt>Full name</dt>
							<dd>
								<DisplayString value={user.fullName} />
							</dd>
							<dt>
								Role in <strong>{currentUser.currentTeam.name}</strong> &mdash;{' '}
							</dt>
							<dd>
								<DisplayRole roleId={user?.role} />
							</dd>
							<dt>Multi-factor authentication enabled</dt>
							<dd>
								<DisplayBoolean value={user.otpEnabled} />
							</dd>
							<dt>Email confirmed</dt>
							<dd>
								<DisplayBoolean value={user.emailConfirmed} />
							</dd>
							<dt>Last login date</dt>
							<dd>
								<DisplayDate value={user.lastLoginDate} />
							</dd>
							<dt>Created at</dt>
							<dd>
								<DisplayDate value={user.createdAt} />
							</dd>
							<dt>Updated at</dt>
							<dd>
								<DisplayDate value={user.updatedAt} />
							</dd>
						</dl>
					</>
				)}
			</PrimaryContent>
		</SettingsAppScreen>
	)
}
