/* fe62187c5782ff1dbce05c48b6334db1c3821fcc
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../__types__';

import gql from 'graphql-tag';
import * as React from 'react';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type LoginMutationVariables = Types.Exact<{
  username: Types.Scalars['String'];
  password: Types.Scalars['String'];
  code?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type LoginMutation = { __typename?: 'Mutation', login?: string | null };


export const LoginDocument = gql`
    mutation login($username: String!, $password: String!, $code: String) {
  login(username: $username, password: $password, code: $code)
}
    `;

export const LoginComponent = (props: Omit<Urql.MutationProps<LoginMutation, LoginMutationVariables>, 'query'> & { variables?: LoginMutationVariables }) => (
  <Urql.Mutation {...props} query={LoginDocument} />
);


export function useLoginMutation() {
  return Urql.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument);
};