/* 29c18e824b0446219bf894753bf7815139c0f6d5
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../__types__';

import gql from 'graphql-tag';
import * as React from 'react';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RemoveUserFromTeamMutationVariables = Types.Exact<{
  teamId: Types.Scalars['ID'];
  userId: Types.Scalars['ID'];
}>;


export type RemoveUserFromTeamMutation = { __typename?: 'Mutation', removeUserFromTeam: { __typename?: 'removeUserFromTeam', user: { __typename?: 'User', userId: string } } };


export const RemoveUserFromTeamDocument = gql`
    mutation removeUserFromTeam($teamId: ID!, $userId: ID!) {
  removeUserFromTeam(input: {teamId: $teamId, userId: $userId}) {
    user {
      userId
    }
  }
}
    `;

export const RemoveUserFromTeamComponent = (props: Omit<Urql.MutationProps<RemoveUserFromTeamMutation, RemoveUserFromTeamMutationVariables>, 'query'> & { variables?: RemoveUserFromTeamMutationVariables }) => (
  <Urql.Mutation {...props} query={RemoveUserFromTeamDocument} />
);


export function useRemoveUserFromTeamMutation() {
  return Urql.useMutation<RemoveUserFromTeamMutation, RemoveUserFromTeamMutationVariables>(RemoveUserFromTeamDocument);
};