import { Field } from 'formik'
import * as React from 'react'
import { LoaderMessage } from '../../../portal/components/loaderMessage/loaderMessage'
import { ReactSelect } from '../../../portal/components/reactSelect/reactSelect'
import { FacilityEdge } from '../../interfaces/facility'
import { useFacilitiesQuery } from '../samplesList/queries/useFacilitiesQuery'

interface Props {
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
    setFieldTouched: (
        field: string,
        isTouched?: boolean,
        shouldValidate?: boolean
    ) => void
    className?: string
}

export function FacilitySelector(props: Props): JSX.Element {
    // Get available facilities
    const { setFieldValue, setFieldTouched, className } = props
    const [{ data, fetching, error }] = useFacilitiesQuery()

    type Option = { label: string; value: string }

    const facilityOptions: Option[] = data
        ? data.facilities.edges.map((edge: FacilityEdge) => {
              return { value: edge.node.teamId, label: edge.node.name }
          })
        : []

    return (
        <Field
            name="facilityId"
            component={({ field, form }) => (
                <ReactSelect
                    name="facilityId"
                    label="Choose facility"
                    isMulti={false}
                    isLoading={fetching}
                    options={facilityOptions.sort((a, b) =>
                        a.label > b.label ? 1 : -1
                    )}
                    value={facilityOptions.find(
                        (option) => option.value === field.value
                    )}
                    onChange={(option: Option) =>
                        option
                            ? form.setFieldValue(field.name, option.value)
                            : form.setFieldValue(field.name, null)
                    }
                    onBlur={field.onBlur}
                    className={className}
                    isClearable
                />
            )}
        />
    )
}
