import * as React from 'react'
import { useCallback } from 'react'
import * as styles from './appScreen.scss'
import { AppLinks } from '../../config'
import { AppState } from '../store'
import { Breadcrumb } from '../../interfaces/breadcrumb'
import { AccountHeader } from '../accountHeader/accountHeader'
import { useSelect } from '../../hooks/useSelect'
import { SidebarMenu } from '../sidebarMenu/sidebarMenu'
import { SetPageTitle } from '../../helpers/setPageTitle'

interface Props {
    children: React.ReactNode
    activeAppLink: AppLinks
    breadcrumbs?: Breadcrumb[]
    title?: string
}

export const ActiveAppLink = React.createContext<AppLinks>(null)
export const Breadcrumbs = React.createContext<Breadcrumb[]>(undefined)

export function AppScreen({
    title,
    children,
    activeAppLink,
    breadcrumbs,
}: Props): JSX.Element {
    return (
        <ActiveAppLink.Provider value={activeAppLink}>
            <SetPageTitle title={title} />
            <Breadcrumbs.Provider value={breadcrumbs}>
                <div className={styles.appScreen}>
                    <div className={styles.left}>
                        <SidebarMenu />
                    </div>

                    <div className={styles.right}>
                        <div className={styles.headerBar}>
                            <div className={styles.headerRight}>
                                <AccountHeader />
                            </div>
                        </div>
                        <div className={styles.content}>{children}</div>
                    </div>
                </div>
            </Breadcrumbs.Provider>
        </ActiveAppLink.Provider>
    )
}
