import { Helmet } from 'react-helmet'

interface Props {
    title: string
}

export const SetPageTitle = (props: Props): JSX.Element => (
    <Helmet
        defer={false}
        titleTemplate="%s | DTACT Raven"
        defaultTitle="DTACT Raven"
    >
        <title>{props.title}</title>
    </Helmet>
)
