import * as React from 'react'
import { IntlProvider } from 'react-intl'
const messagesEn = require('../../../translations/en.json')
import moment from 'moment-timezone'
import 'moment/locale/en-gb'
import { useTimezone } from '../../hooks/useTimezone'

interface Props {
    children: React.ReactNode
}

export function Translate(props: Props) {
    let locale: string
    const defaultLocale = 'en'
    let messages

    switch (location.host) {
        default: {
            locale = defaultLocale
            messages = messagesEn
            moment.locale('en-gb')
            break
        }
    }

    const [tz] = useTimezone()

    return (
        <IntlProvider
            locale={locale}
            key={locale}
            timeZone={tz}
            defaultLocale={defaultLocale}
            messages={messages}
        >
            {props.children}
        </IntlProvider>
    )
}
