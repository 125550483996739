import * as React from 'react'
import { useCallback, useContext } from 'react'
import { useIntl } from 'react-intl'

// the format message hook
export function useFormatMessage() {
    const intl = useIntl()

    return useCallback((id: string, values?: any) => {
        return intl.formatMessage({ id }, values)
    }, [])
}

export function useFormatNumber() {
    const intl = useIntl()

    return useCallback((value: number) => {
        return intl.formatNumber(value)
    }, [])
}
