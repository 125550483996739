import { Role } from '@graphql-types@'
import { Role as RoleEnum } from '@graphql-enums@'
import { RolesComponent } from '../components/selectRole/roles.graphql'
import { DisplayString } from '../../portal/components/displayString'

export const DisplayRole = ({ roleId }: { roleId: string }): JSX.Element => {
    return (
        <RolesComponent>
            {({ data, fetching }) => (
                <>
                    {!fetching && (
                        <DisplayString
                            value={
                                data?.roles.find(
                                    (role) => role.roleId === roleId
                                )?.name ?? 'unknown role'
                            }
                        />
                    )}
                </>
            )}
        </RolesComponent>
    )
}
