import classNames from 'classnames'
import { Formik, useFormikContext } from 'formik'
import { useSnackbar } from 'notistack'
import qs from 'qs'
import * as React from 'react'
import { useCallback, useState } from 'react'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import { gql, useQuery } from 'urql'
import * as Yup from 'yup'
import { FullScreenLoading } from '~/app/portal/components/fullScreenLoading/fullScreenLoading'
import { Button, ButtonTypes } from '../../../portal/components/button/button'
import { IconTextInput } from '../../../portal/components/iconTextInput/iconTextInput'
import { SubmitButton } from '../../../portal/components/submitButton/submitButton'
import { TextInput } from '../../../portal/components/textInput/textInput'
import { useFormatMessage } from '../../../portal/helpers/intlContext'
import { Paths } from '../../../portal/helpers/routePaths'
import { useTeamInviteToken, useToken } from '../../../providers/store'
import { useLoginMutation } from '../../mutations/login.graphql'
import { useSignupMutation } from '../../mutations/signup.graphql'
import { useCheckUsernameMutation } from './checkUsername.graphql'
import * as styles from './loginForm.scss'
import { PreLogin } from './preLogin/preLogin'
import { qsOptions } from '~/app/portal/components/qsOptions'
import { passwordValidate } from '../../helpers/passwordValidate'

const AutoSubmitToken = () => {
	const {
		touched,
		values,
		errors,
		isSubmitting,
		submitForm,
		setFieldTouched,
	} = useFormikContext<{
		code?: string
	}>()

	React.useEffect(() => {
		if (isSubmitting) return

		if (errors.code) return
		if (values.code.length !== 6) return

		submitForm()
	}, [values.code])

	return null
}

export const SSOLogInForm = (props) => {
	const { slug }: { slug?: String } = useParams()

	const location = useLocation()

	const search = qs.parse(location.search.replace(/^\#/, ''), {
		ignoreQueryPrefix: true,
	})

	// check for existing auth
	// retrieve authentication url
	const [{ data, fetching }] = useQuery({
		query: gql`
            query provider($slug: String!) {
                provider(slug: $slug) {
                    redirectUrl
                }
            }
        `,
		variables: {
			slug: slug,
		},
		pause: search?.code !== undefined,
	})

	console.log('provider', slug, data, fetching, search)

	React.useEffect(() => {
		const redirectUrl = data?.provider?.redirectUrl
		if (!redirectUrl) return

		window.location.replace(redirectUrl)
	}, [data])

	if (search?.code) {
		console.log('authenticating', 'loginform')
		return <FullScreenLoading message={'Authenticating...'} />
	}

	return !search?.code ? (
		<PreLogin>
			<div className={styles.container}>
				<div className="d-flex justify-content-center">
					<span>
						Redirecting to <b>{slug}</b> login
					</span>
				</div>
			</div>
		</PreLogin>
	) : null
}

export const LogInForm = (props) => {
	const t = useFormatMessage()
	const [invalidCodes, setInvalidCodes] = useState<string[]>([])

	const [state, setState] = useState<
		'username' | 'password' | 'otp' | 'sso' | 'signup'
	>('username')

	const [, login] = useLoginMutation()
	const [, signup] = useSignupMutation()
	const [, checkUsername] = useCheckUsernameMutation()

	const [teamInviteToken, setTeamInviteToken] = useTeamInviteToken()

	const [, setToken] = useToken()

	const { enqueueSnackbar } = useSnackbar()

	const history = useHistory<{ hash: string }>()

	const onSubmit = useCallback(
		(
			{ username, password, token, code, fullName },
			{ setFieldError, setFieldValue, setSubmitting }
		) => {
			if (state === 'username') {
				checkUsername({ username, token }).then((response) => {
					setSubmitting(false)

					if (response.error) {
						return
					}

					console.log(response)
					if (response.data.checkUsername.action === 'sso') {
						setState('sso')
						window.location.replace(
							response.data.checkUsername.redirectTo
						)
						return
					} else if (
						response.data.checkUsername.action === 'sign-up'
					) {
						setState('signup')
						return
					}

					setState('password')
				})
				return
			} else if (state === 'password' || state === 'otp') {
				login({ username, password, code }).then(({ data, error }) => {
					setSubmitting(false)

					if (error) {
						if (
							error.graphQLErrors?.some(
								(e) => e.message === 'otp_invalid_code'
							)
						) {
							if (state === 'password') {
								setState('otp')
							} else {
								setInvalidCodes([code])
								setFieldError('code', 'Code is invalid')
							}
						} else {
							setFieldError(
								'password',
								'Password or email is incorrect'
							)
						}
					} else {
						setToken(data.login)

						history.push(Paths.activity)
					}
				})
			} else if (state === 'signup') {
				signup({
					input: {
						email: username,
						password,
						fullName,
						token,
					},
				}).then(({ data, error }) => {
					setSubmitting(false)

					if (error) {
						if (
							error.graphQLErrors?.some(
								(e) => e.message === 'user_exists'
							)
						) {
							enqueueSnackbar(
								<span>
									The email address has been registered
									already.
								</span>,
								{
									variant: 'error',
								}
							)

							return
						}

						enqueueSnackbar(
							<span>{error.graphQLErrors[0].message}</span>,
							{
								variant: 'error',
							}
						)
					} else {
						setSubmitting(false)

						setTeamInviteToken(null)

						setToken(data.signup)

						history.push(Paths.activity)
					}
				})
			}
		},
		[state]
	)

	const formikRef = React.useRef(null)

	// changing state forces validation (and updates submit button)
	React.useEffect(() => {
		if (state !== 'username') return

		formikRef.current.validateForm()
	}, [state])

	const getValidationSchema = useCallback(() => {
		const shapes = {
			username: {
				username: Yup.string()
					.required(t('form.required'))
					.email(t('form.emailInvalid')),
			},
			password: {
				username: Yup.string()
					.required(t('form.required'))
					.email(t('form.emailInvalid')),
				password: Yup.string().required(t('form.required')),
			},
			otp: {
				username: Yup.string()
					.required(t('form.required'))
					.email(t('form.emailInvalid')),
				password: Yup.string().required(t('form.required')),
				code: Yup.string()
					.required(t('form.required'))
					.matches(/[0-9]+/)
					.notOneOf(invalidCodes, 'Code is invalid'),
			},
			sso: {},
			signup: {
				username: Yup.string()
					.required(t('form.required'))
					.email(t('form.emailInvalid')),
				password: passwordValidate.required(t('Password is required.')),
				fullName: Yup.string().required(t('form.required')),
				repeatPassword: Yup.string()
					.required(t('form.required'))
					.oneOf([Yup.ref('password')], t('form.passwordsMustMatch')),
			},
		}

		return Yup.object().shape(shapes[state])
	}, [invalidCodes, state])

	const onCancel = useCallback(() => {
		setState('username')
	}, [])

	return (
		<PreLogin>
			<div className={styles.container}>
				<Formik
					innerRef={formikRef}
					initialValues={{
						username: '',
						password: '',
						code: '',
						token: teamInviteToken,
					}}
					enableReinitialize={false}
					validationSchema={getValidationSchema}
					onSubmit={onSubmit}
				>
					{({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						handleSubmit,
						dirty,
						isSubmitting,
						isValid,
					}) => (
						<form onSubmit={handleSubmit} className={styles.form}>
							{state == 'username' && (
								<>
									<IconTextInput
										icon="Mail"
										placeholder="Email address"
										name="username"
										readOnly={isSubmitting}
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.username}
										error={errors.username}
										touched={touched.username}
										autoFocus
									/>

									<div className={styles.btnWrap}>
										<div
											className={classNames(
												styles.buttons,
												'btn-group'
											)}
											role="group"
										>
											<SubmitButton
												disabled={
													!dirty ||
													!isValid ||
													isSubmitting
												}
												submitting={isSubmitting}
												type={ButtonTypes.primary}
												iconOnRight={true}
											>
												Continue
											</SubmitButton>
										</div>
									</div>
								</>
							)}

							{(state === 'password' || state === 'otp') && (
								<>
									<IconTextInput
										icon="Mail"
										placeholder="Email address"
										name="username"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.username}
										error={errors.username}
										touched={touched.username}
										readOnly
										autoFocus
										autocomplete="email"
									/>

									<IconTextInput
										icon="Key"
										placeholder="Password"
										name="password"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.password}
										error={errors.password}
										touched={touched.password}
										autoFocus
										type="password"
									/>

									{state !== 'otp' && (
										<p className={styles.text}>
											<Link
												className={styles.textLink}
												to={'/forgot-password'}
											>
												Forgot password?
											</Link>
										</p>
									)}

									{state === 'otp' && (
										<React.Fragment>
											<strong>
												2FA is required &mdash; enter
												the code
											</strong>
											<TextInput
												placeholder="Code"
												name="code"
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.code}
												error={errors.code}
												touched={touched.code}
												autoFocus
												autocomplete="one-time-code"
											/>
											<AutoSubmitToken />
										</React.Fragment>
									)}

									<div className={styles.btnWrap}>
										<div
											className={classNames(
												styles.buttons,
												'btn-group'
											)}
											role="group"
										>
											<Button
												type={ButtonTypes.secondary}
												onClick={onCancel}
											>
												Cancel
											</Button>
											<SubmitButton
												disabled={
													!dirty ||
													!isValid ||
													isSubmitting
												}
												iconOnRight={true}
												submitting={isSubmitting}
												title="Log in"
											/>
										</div>
									</div>
								</>
							)}

							{state === 'signup' && (
								<>
									<IconTextInput
										icon="Mail"
										placeholder="Email address"
										name="username"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.username}
										error={errors.username}
										touched={touched.username}
										readOnly
										autoFocus
										autocomplete="email"
									/>
									<div className="mb-3">
										<TextInput
											placeholder="Full name"
											className="form-control"
											name="fullName"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.fullName}
											error={errors.fullName}
											touched={touched.fullName}
											autoFocus
										/>
									</div>

									<div className="mb-3">
										<TextInput
											placeholder="Password"
											className="form-control"
											name="password"
											passwordStrength
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.password}
											error={errors.password}
											touched={touched.password}
											type="password"
										/>
									</div>
									<div className="mb-3">
										<TextInput
											placeholder="Repeat password"
											className="form-control"
											name="repeatPassword"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.repeatPassword}
											error={errors.repeatPassword}
											touched={touched.repeatPassword}
											type="password"
										/>
									</div>

									<div className={styles.btnWrap}>
										<div
											className={classNames(
												styles.buttons,
												'btn-group'
											)}
											role="group"
										>
											<Button
												type={ButtonTypes.secondary}
												onClick={onCancel}
											>
												Cancel
											</Button>
											<SubmitButton
												disabled={
													!dirty ||
													!isValid ||
													isSubmitting
												}
												iconOnRight={true}
												submitting={isSubmitting}
												title="Sign up"
											/>
										</div>
									</div>
								</>
							)}

							{state === 'sso' && (
								<div>Redirecting to login provider</div>
							)}
						</form>
					)}
				</Formik>
			</div>
		</PreLogin>
	)
}
