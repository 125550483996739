// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".labeledInput__container--BT3C_{margin-bottom:1rem;display:flex;flex-direction:column}.labeledInput__label--k53TP{margin-bottom:.25rem}.labeledInput__tab--fsE0z{padding:1rem;height:60vh;overflow-y:auto}.labeledInput__lastEdited--iuGWN{background:var(--background-lighter);border-bottom:1px solid var(--border-color);padding:.5rem 1rem}", "",{"version":3,"sources":["webpack://./src/app/portal/components/labeledInput/labeledInput.scss"],"names":[],"mappings":"AAAA,gCACI,kBAAA,CACA,YAAA,CACA,qBAAA,CAGJ,4BACI,oBAAA,CAGJ,0BACI,YAAA,CACA,WAAA,CACA,eAAA,CAGJ,iCACI,oCAAA,CACA,2CAAA,CACA,kBAAA","sourcesContent":[".container {\n    margin-bottom: 1rem;\n    display: flex;\n    flex-direction: column;\n}\n\n.label {\n    margin-bottom: 0.25rem;\n}\n\n.tab {\n    padding: 1rem;\n    height: 60vh;\n    overflow-y: auto;\n}\n\n.lastEdited {\n    background: var(--background-lighter);\n    border-bottom: 1px solid var(--border-color);\n    padding: 0.5rem 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "labeledInput__container--BT3C_",
	"label": "labeledInput__label--k53TP",
	"tab": "labeledInput__tab--fsE0z",
	"lastEdited": "labeledInput__lastEdited--iuGWN"
};
export default ___CSS_LOADER_EXPORT___;
