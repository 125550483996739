/* 3b294d06739423a26a703f2982bb33fa783cfef4
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../__types__';

import gql from 'graphql-tag';
import * as React from 'react';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UsersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UsersQuery = { __typename?: 'Query', users?: { __typename?: 'UsersConnection', edges: Array<{ __typename?: 'UserEdge', node: { __typename?: 'User', userId: string, userName: string, email: string, fullName: string, role: string, avatarUrl: string, lastLoginDate: any, createdAt: any, emailConfirmed: boolean, otpEnabled: boolean, updatedAt: any } } | null> } | null };


export const UsersDocument = gql`
    query users {
  users(first: 99999, sort: {order: ASCENDING}) {
    edges {
      node {
        userId
        userName
        email
        fullName
        role
        avatarUrl
        lastLoginDate
        createdAt
        emailConfirmed
        otpEnabled
        updatedAt
      }
    }
  }
}
    `;

export const UsersComponent = (props: Omit<Urql.QueryProps<UsersQuery, UsersQueryVariables>, 'query'> & { variables?: UsersQueryVariables }) => (
  <Urql.Query {...props} query={UsersDocument} />
);


export function useUsersQuery(options?: Omit<Urql.UseQueryArgs<UsersQueryVariables>, 'query'>) {
  return Urql.useQuery<UsersQuery>({ query: UsersDocument, ...options });
};