import { QRCodeRenderersOptions, toCanvas } from 'qrcode'
import { SampleResult, SampleStatus } from '../samplesList/samples'

// QR Codes:
const qrCodeOptions: QRCodeRenderersOptions = {
    margin: 0,
    width: 300,
    color: {
        dark: '#323142',
    },
}

export function InsertAndGenerateQR(content: string): void {
    const qr = document.querySelector('#canvasQR') as HTMLElement
    toCanvas(qr, content, qrCodeOptions).catch((err) => {
        console.error(err)
    })
}

// Types:
export interface GenerateInput {
    testType: string
    facilityId: string
    requiresApproval: boolean
}
export interface UpdateInput {
    outcome: { status: SampleStatus; result: SampleResult }
    message?: string
}

// External API functions:
export const getValidatedId = async (code: string): Promise<string> => {
    let newCode = ''
    await fetch(
        `${process.env.BRIDGE_API}/testkit/validate?testKitId=${code}`,
        {
            headers: {
                'Accept-Version': '2',
            },
        }
    )
        .then((res) => handleErrors(res))
        .then((res) => res.json())
        .then((res) => {
            console.log(res)
            if (res && res.valid) {
                newCode = res.code
            }
        })
        .catch((err) => console.log('Fetch error:', err))
    return newCode
}

function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText)
    }
    return response
}
