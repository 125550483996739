/* 6f296fd720a2c3d74cd3f869de2e932e2d73434d
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../__types__';

import gql from 'graphql-tag';
import * as React from 'react';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateTeamMutationVariables = Types.Exact<{
  input: Types.CreateTeamInput;
}>;


export type CreateTeamMutation = { __typename?: 'Mutation', createTeam: { __typename?: 'Team', teamId: string, name: string } };


export const CreateTeamDocument = gql`
    mutation createTeam($input: createTeamInput!) {
  createTeam(input: $input) {
    teamId
    name
  }
}
    `;

export const CreateTeamComponent = (props: Omit<Urql.MutationProps<CreateTeamMutation, CreateTeamMutationVariables>, 'query'> & { variables?: CreateTeamMutationVariables }) => (
  <Urql.Mutation {...props} query={CreateTeamDocument} />
);


export function useCreateTeamMutation() {
  return Urql.useMutation<CreateTeamMutation, CreateTeamMutationVariables>(CreateTeamDocument);
};