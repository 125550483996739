/* 893aa05788250346b091842999b05c334dd9f73b
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../__types__';

import gql from 'graphql-tag';
import * as React from 'react';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TeamInvitesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type TeamInvitesQuery = { __typename?: 'Query', teamInvites: { __typename?: 'TeamInvitesConnection', edges: Array<{ __typename?: 'TeamInviteEdge', node: { __typename?: 'TeamInvite', teamInviteId: string, email: string, role: string, expireDate: any, createdAt: any, updatedAt: any } } | null> } };


export const TeamInvitesDocument = gql`
    query teamInvites {
  teamInvites {
    edges {
      node {
        teamInviteId
        email
        role
        expireDate
        createdAt
        updatedAt
      }
    }
  }
}
    `;

export const TeamInvitesComponent = (props: Omit<Urql.QueryProps<TeamInvitesQuery, TeamInvitesQueryVariables>, 'query'> & { variables?: TeamInvitesQueryVariables }) => (
  <Urql.Query {...props} query={TeamInvitesDocument} />
);


export function useTeamInvitesQuery(options?: Omit<Urql.UseQueryArgs<TeamInvitesQueryVariables>, 'query'>) {
  return Urql.useQuery<TeamInvitesQuery>({ query: TeamInvitesDocument, ...options });
};