import Magnifier from 'react-magnifier'
import { BasicPopup } from '../basicPopup/basicPopup'
import { SampleImage } from './samples'
import * as styles from './samplesList.scss'
import Pagination from '@material-ui/lab/Pagination'
import React from 'react'
import classNames from 'classnames'
import { useTimezone } from '~/app/providers/store'
import moment from 'moment'
import useKeyPress from '../../helpers/useKeyPress'
import { useSamplesQuery } from './queries/useSamplesQuery'
import { LoadingSkeleton } from '../approvalEdit/approvalEdit'
import { Icon } from '../icon/ficon'

interface Props {
    code: string
    image: SampleImage
}

export function SamplesImagePopup(props: Props): JSX.Element {
    const { code } = props

    const [{ data, fetching }] = useSamplesQuery({
        limit: 1,
        offset: 0,
        where: {
            field: 'code',
            eq: code,
        },
    })

    const [sample] = React.useMemo(() => {
        return [data?.samples?.edges[0]?.node]
    }, [data])

    const images = sample?.images?.sort((a, b) => {
        return moment(b.createdAt).unix() - moment(a.createdAt).unix() // Sort by createdAt
    })

    const [currentPage, setCurrentPage] = React.useState<number>(
        images?.findIndex((i) => i.imageId === props.image.imageId)
    )

    const onPaginationChange = React.useCallback(
        (event: object, page: number) => {
            setCurrentPage(page - 1)
        },
        []
    )

    React.useEffect(() => {
        setCurrentPage(0)
    }, [sample])

    const image = images?.[currentPage]

    const [tz] = useTimezone()

    useKeyPress(
        'ArrowRight',
        () => {
            if (currentPage + 1 >= sample.images.length) return

            setCurrentPage(currentPage + 1)
        },
        [currentPage]
    )

    useKeyPress(
        'ArrowLeft',
        () => {
            if (currentPage == 0) return

            setCurrentPage(currentPage - 1)
        },
        [currentPage]
    )

    return (
        <BasicPopup
            title={`[${image?.template || image?.operation + '-error'}] Code: ${
                sample?.code
            }`}
            size="xl"
            className={styles.imagePopup}
        >
            <div className="container">
                <div className="row">
                    <div className="col-10">
                        <LoadingSkeleton loading={fetching}>
                            <Magnifier
                                src={image?.imageURL}
                                width={'100%'}
                                mgShape="square"
                                className={styles.magnifierWrap}
                                mgShowOverflow={false}
                            />
                            {image?.validationMessage && (
                                <p>
                                    [{image?.statusCode}]:{' '}
                                    {image?.validationMessage}
                                </p>
                            )}
                        </LoadingSkeleton>
                    </div>
                    <div className={classNames('col col-2', styles.box)}>
                        <LoadingSkeleton loading={fetching}>
                            <h3 className={styles.box}>Picture history</h3>
                            {sample && (
                                <Pagination
                                    count={sample?.images.length}
                                    onChange={onPaginationChange}
                                    page={currentPage + 1}
                                    size="small"
                                    boundaryCount={1}
                                    className={classNames(
                                        'center',
                                        styles.boxContent,
                                        styles.pagination
                                    )}
                                />
                            )}
                        </LoadingSkeleton>
                        <h3 className={classNames(styles.box)}>Operation</h3>
                        <span className={classNames(styles.boxContent)}>
                            <LoadingSkeleton loading={fetching}>
                                <code>{image?.operation}</code>
                            </LoadingSkeleton>
                        </span>
                        <h3 className={classNames(styles.box)}>Date/time</h3>
                        <span className={classNames(styles.boxContent)}>
                            <LoadingSkeleton loading={fetching}>
                                <code>
                                    {moment(image?.createdAt)
                                        .tz(tz)
                                        .format('DD-MM-YYYY HH:mm')}
                                </code>
                            </LoadingSkeleton>
                        </span>
                        <h3 className={classNames(styles.box)}>Template</h3>
                        <span className={classNames(styles.boxContent)}>
                            <LoadingSkeleton loading={fetching}>
                                <code>
                                    {image?.template ? image?.template : 'N/A'}
                                </code>
                            </LoadingSkeleton>
                        </span>

                        {image?.validationMessage && (
                            <>
                                <h3 className={classNames(styles.box)}>
                                    Validation message
                                </h3>
                                <div className={classNames(styles.boxContent)}>
                                    <LoadingSkeleton loading={fetching}>
                                        <code>{image?.validationMessage}</code>
                                    </LoadingSkeleton>
                                </div>
                            </>
                        )}
                        {image?.imageURL && (
                            <>
                                <h3 className={classNames(styles.box)}>
                                    Download picture
                                </h3>
                                <span className={classNames(styles.boxContent)}>
                                    <LoadingSkeleton loading={fetching}>
                                        <a href={image?.imageURL}>
                                            <Icon name={'Download'} />
                                        </a>
                                    </LoadingSkeleton>
                                </span>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </BasicPopup>
    )
}
