import * as React from 'react'
import * as styles from './list.scss'
import { LoaderMessage } from '../loaderMessage/loaderMessage'
import classNames from 'classnames'
import { Loader } from '../loader/loader'
import { Keys, useKey } from '../../hooks/useKey'
import { useRef } from 'react'

interface Props {
    children: React.ReactNode
    empty?: boolean
    loading?: boolean
    emptyMessage?: string
    loadingMessage?: string
    className?: string
    enableKeyboard?: boolean
}

export function List(props: Props) {
    const ul = useRef<HTMLUListElement>(null)

    useKey(Keys.arrowDown, () => {
        if (!props.enableKeyboard || !ul.current) {
            return
        }

        const active = ul.current.querySelector('.active')

        if (active) {
            const next = active.nextElementSibling

            if (next) {
                const clickable = next.querySelector(
                    'a, button'
                ) as HTMLButtonElement

                if (clickable) {
                    clickable.click()
                }
            }
        }
    })

    useKey(Keys.arrowUp, () => {
        if (!props.enableKeyboard || !ul.current) {
            return
        }

        const active = ul.current.querySelector('.active')

        if (active) {
            const previous = active.previousElementSibling

            if (previous) {
                const clickable = previous.querySelector(
                    'a, button'
                ) as HTMLButtonElement

                if (clickable) {
                    clickable.click()
                }
            }
        }
    })

    if (props.empty) {
        return <p className="p-3">{props.emptyMessage}</p>
    }

    return (
        <div className={classNames(styles.container, props.className)}>
            {props.loading && <Loader center large />}

            <ul className={styles.list} ref={(ref) => (ul.current = ref)}>
                {props.children}
            </ul>
        </div>
    )
}
