import * as React from 'react'
import moment from 'moment-timezone'
import { UnknownValue } from '../unknownValue'
import * as styles from './displayDate.scss'
import { useTimezone } from '../../../hooks/useTimezone'

interface Props {
    value: string
    diff?: string
    small?: boolean
    onlyExact?: boolean
}

export function DisplayDate(props: Props) {
    const [tz] = useTimezone()

    if (!props.value) {
        return <UnknownValue />
    }

    const parsed = moment(props.value).tz(tz)
    const exactFormat = 'YYYY-M-D HH:mm:ss'

    if (props.onlyExact) {
        return (
            <React.Fragment>
                <span>{parsed.format(exactFormat)}</span>
                <DisplayDiff parsedValue={parsed} diff={props.diff} />
            </React.Fragment>
        )
    }

    if (props.small) {
        const tmpParsed = parsed.clone()
        const now = moment()
        let format: string

        if (tmpParsed.startOf('day').unix() >= now.startOf('day').unix()) {
            format = 'HH:mm'
        } else if (
            tmpParsed.startOf('year').unix() >= now.startOf('year').unix()
        ) {
            format = 'D MMM - HH:mm'
        } else {
            format = 'D/M/YY HH:mm'
        }

        return <span>{parsed.format(format)}</span>
    }

    return (
        <React.Fragment>
            {parsed.fromNow()}
            <span className="text-muted">
                &nbsp;&mdash;&nbsp;
                {parsed.format(exactFormat)}
            </span>
        </React.Fragment>
    )
}

interface DisplayDiffProps {
    parsedValue: moment.Moment
    diff: string
}

function DisplayDiff(props: DisplayDiffProps) {
    const { parsedValue, diff } = props

    if (!diff) {
        return null
    }

    const parsedDiff = parsedValue.diff(moment(diff))
    const duration = moment.duration(parsedDiff)

    return (
        <span className={styles.diff}>
            {parsedDiff > 0 ? '+' : '-'} {duration.humanize()}
        </span>
    )
}
