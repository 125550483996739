/* a7e578cfa65cca563e4166a840848f063c128214
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../__types__';

import gql from 'graphql-tag';
import * as React from 'react';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RolesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type RolesQuery = { __typename?: 'Query', roles: Array<{ __typename?: 'Role', roleId: string, name: string, description: string, createdAt: any, updatedAt: any }> };


export const RolesDocument = gql`
    query roles {
  roles {
    roleId
    name
    description
    createdAt
    updatedAt
  }
}
    `;

export const RolesComponent = (props: Omit<Urql.QueryProps<RolesQuery, RolesQueryVariables>, 'query'> & { variables?: RolesQueryVariables }) => (
  <Urql.Query {...props} query={RolesDocument} />
);


export function useRolesQuery(options?: Omit<Urql.UseQueryArgs<RolesQueryVariables>, 'query'>) {
  return Urql.useQuery<RolesQuery>({ query: RolesDocument, ...options });
};