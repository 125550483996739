import classNames from 'classnames'
import * as React from 'react'
import * as styles from './preLogin.scss'
const logo = require('./dtactLogoWhite.svg')

interface Props {
    children: React.ReactNode
}

export function PreLogin(props: Props) {
    return (
        <div className={styles.pageWrap}>
            <div className={styles.container}>
                <div className={styles.header}>
                    <img className={styles.logo} src={logo} />
                </div>

                <div className={styles.content}>
                    <div className={styles.mainContent}>{props.children}</div>
                </div>

                <div className={styles.footer}>Purpose. Mission. Impact.</div>
            </div>
            <div className={classNames(styles.triangle, styles.tLeft)}></div>
            <div className={classNames(styles.triangle, styles.tRight)}></div>
        </div>
    )
}
