import { Icon, IconName } from '../icon/ficon'
import { TextInput, TextInputProps } from '../textInput/textInput'
import * as styles from './iconTextInput.scss'

export interface Props extends TextInputProps {
    icon: IconName
}

export function IconTextInput(props: Props) {
    return (
        <div className="input-group mb-4">
            <span className="input-group-text">
                <Icon name={props.icon} small />
            </span>
            <TextInput className={styles.borderRoundRight} {...props} />
        </div>
    )
}
