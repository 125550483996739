// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mobileBreadcrumbs__nav--lflfu{border-bottom:1px solid var(--border-color)}.mobileBreadcrumbs__list--CloQ7{display:flex;flex-wrap:wrap;margin:0;padding:.5rem;background:var(--background-light);border-radius:var(--border-radius);list-style:none}.mobileBreadcrumbs__item--U6IM1{margin-right:.5rem}.mobileBreadcrumbs__icon--NgEHV{font-size:12px;margin-right:.5rem}", "",{"version":3,"sources":["webpack://./src/app/portal/components/mobileBreadcrumbs/mobileBreadcrumbs.scss"],"names":[],"mappings":"AAAA,+BACI,2CAAA,CAGJ,gCACI,YAAA,CACA,cAAA,CACA,QAAA,CACA,aAAA,CACA,kCAAA,CACA,kCAAA,CACA,eAAA,CAGJ,gCACI,kBAAA,CAGJ,gCACI,cAAA,CACA,kBAAA","sourcesContent":[".nav {\n    border-bottom: 1px solid var(--border-color);\n}\n\n.list {\n    display: flex;\n    flex-wrap: wrap;\n    margin: 0;\n    padding: 0.5rem;\n    background: var(--background-light);\n    border-radius: var(--border-radius);\n    list-style: none;\n}\n\n.item {\n    margin-right: 0.5rem;\n}\n\n.icon {\n    font-size: 12px;\n    margin-right: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nav": "mobileBreadcrumbs__nav--lflfu",
	"list": "mobileBreadcrumbs__list--CloQ7",
	"item": "mobileBreadcrumbs__item--U6IM1",
	"icon": "mobileBreadcrumbs__icon--NgEHV"
};
export default ___CSS_LOADER_EXPORT___;
