import * as React from 'react'
import * as styles from './fullScreenCard.scss'

interface Props {
    children: React.ReactNode
}

export function FullScreenCard(props: Props) {
    return (
        <div className={styles.container}>
            <div className={styles.card}>{props.children}</div>
        </div>
    )
}
