// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".index__info--okny2{background-color:#ff6d33 !important}", "",{"version":3,"sources":["webpack://./src/app/index.scss"],"names":[],"mappings":"AAGA,oBACI,mCAAA","sourcesContent":["@import '../scss/variables';\n@import '../scss/mixins/responsive';\n\n.info {\n    background-color: $brandOrange !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info": "index__info--okny2"
};
export default ___CSS_LOADER_EXPORT___;
