/* eslint-disable react/display-name */
import {
    createStyles,
    createTheme,
    makeStyles,
    MuiThemeProvider,
    TableBody as MuiTableBody,
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import classNames from 'classnames'
import MUIDataTable, {
    MUIDataTableBody,
    MUIDataTableColumn,
    MUIDataTableColumnDef,
    MUIDataTableMeta,
    MUIDataTableOptions,
    TableBody,
    TableBodyCell,
    TableBodyRow,
} from 'mui-datatables'
import * as React from 'react'
import { useCallback, useEffect, useMemo } from 'react'
import { useHashState } from '~/app/hooks/useHashState'
import { usePopup } from '~/app/providers/popup'
import { CustomAppFlags } from '../../interfaces/apps'
import { useApps, useCurrentTeam, usePermissions } from '../../../providers/user'
import { OperatorInput } from '../../interfaces/operatorInput'
import { Button, ButtonTypes } from '../button/button'
import { Button as MBButton } from '@material-ui/core'
import { Icon } from '../icon/ficon'
import { Loader } from '../loader/loader'
import { useBatchesQuery } from './queries/useBatchesQuery'
import { BatchEdge } from './batches'
import { TimestampPrint } from '../../components/samplesList/samplesHelpers'
import * as styles from '../samplesList/samplesList.scss'
import { BatchesListRowDetail } from './batchesListRowDetail'
import { useLocalStorage } from '~/app//hooks/useLocalStorage'
import { Role } from '@graphql-enums@'
import { BasicPopup } from '../basicPopup/basicPopup'
import { CSVLink } from 'react-csv'
import { useSnackbar } from 'notistack'
import { slug } from '../portalGenerate/portalGenerate'
import moment from 'moment'

export function BatchesExport(props): JSX.Element {
    const { where } = props

    const [cursor, setCursor] = React.useState<any>('')
    const [rows, setRows] = React.useState<any[]>([])
    const [ready, setReady] = React.useState<any>(false)

    const { clearPopup, setPopup } = usePopup()

    const [result] = useBatchesQuery(
        {
            limit: 500,
            offset: 0,
            after: cursor,
            where,
        },
        false
    )


    const { closeSnackbar, enqueueSnackbar } = useSnackbar()

    const [team] = useCurrentTeam()

    const csvLink = React.useRef<
        CSVLink & HTMLAnchorElement & { link?: HTMLAnchorElement }
    >()

    React.useEffect(() => {
        const { error, data, fetching } = result
        if (error) {
            console.error(error)
            return
        }

        if (fetching) return
        if (!data) return

        if (data.batches?.edges.length == 0) {
            setReady(true)

            setCSV({
                ...csv,
                ready: true,
                filename: `export-${slug(team.name)}-${moment().format(
                    'YYYYMMDDTHHmmss'
                )}.csv`,
                data: rows,
            })

            const action = (key) => (
                <>
                    <MBButton
                        onClick={() => {
                            csvLink.current.link.click()
                        }}
                    >
                        Download
                    </MBButton>
                    <MBButton
                        onClick={() => {
                            closeSnackbar(key)
                        }}
                    >
                        Close
                    </MBButton>
                </>
            )

            clearPopup()

            enqueueSnackbar(
                <span>
                    The export file is <b>ready</b>, please download.
                    <CSVLink
                        className={'invisible'}
                        data={rows}
                        filename={`export-${slug(team.name)}-${moment().format(
                            'YYYYMMDDTHHmmss'
                        )}.csv`}
                        ref={csvLink}
                        target="_blank"
                    >
                        download {csv.filename}
                    </CSVLink>
                </span>,
                {
                    persist: true,
                    variant: 'success',
                    action,
                }
            )
            return
        }

        const cursor = data.batches?.edges.reduce((cursor, edge) => {
            return edge.cursor
        }, "")

        setRows([
            ...rows,
            ...data.batches?.edges.map((edge) => ({
                batchId: edge.node.batchId,
                team: edge.node.team.name,
                teamId: edge.node.team.teamId,
                active: edge.node.active,
                approvalThreshold: edge.node.approvalThreshold,
                mode: edge.node.mode,
                templates: edge.node.templates,
                noCard: edge.node.noCard,
            })),
        ])

        setCursor(cursor)
    }, [result])

    const [csv, setCSV] = React.useState({
        ready: false,
        data: [],
        filename: '',
    })

    return (
        <>
            <BasicPopup title="Export" size="s" className={styles.modalHeight}>
                {!ready && (
                    <div>
                        <Loader 
                            large 
                            center 
                            message="Preparing export file... "
                        />     
                    </div>     
                )} 
                { ready && (
                    <>Collected ({rows.length}) rows.</>
                )}
            </BasicPopup>
        </>
    )
}

export function BatchesList(): JSX.Element {
    // Filter states
    const [queryF, setQueryF] = useHashState<any>('q')
    const [ teamF, setTeamF ] = useHashState<any>('team.name')
    const [ templatesF, setTemplatesF ] = useHashState<any>('templates')

    const [rowsExpanded, setRowsExpanded] = React.useState<any[]>([])
    const [rowsPerPage, setRowsPerPage] = useHashState<any>('rows', 10)
    const [currentPage, setCurrentPage] = useHashState<any>('page', 1)

    const { clearPopup, setPopup } = usePopup()

    // List batches
    const where: OperatorInput = React.useMemo(() => {
        setCurrentPage(1) // Reset pagination if filters change
        const out: OperatorInput = {
            and: [],
        }
        if ( teamF ) out.and.push({ field: 'team.name', eq: teamF})

        if ( templatesF ) out.and.push({ field: 'templates', eq: templatesF})
        
        return out
    }, [teamF, templatesF])

    const [result, refresh] = useBatchesQuery(
        {
            limit: rowsPerPage,
            offset: (currentPage - 1) * rowsPerPage,
            where,
        },
        false
    )

    const batches: BatchEdge[] = result.data ? result.data?.batches?.edges : []
    const batchesCount: number = result.data
        ? result.data.batches?.totalCount
        : 0
    const batchesRef = React.useRef<BatchEdge[]>()
    useEffect(() => {
        batchesRef.current = batches
    }, [batches])

    // Refresh results every hour
    useEffect(() => {
        const interval = setInterval(() => {
            refresh({ requestPolicy: 'network-only' })
        }, 3600 * 1000)

        return () => clearInterval(interval)
    }, [refresh])

    const allColumns: MUIDataTableColumn[] = [
        {
            name: 'batchId',
            label: 'Batch ID',
            options: {
                hint: 'Batch ID',
                filter: false,
                display: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const node = batchesRef.current[dataIndex]?.node
                    return (
                        <div className={styles.nowrap}>
                            <span>{node.batchId}</span>
                        </div>
                    )
                },
            },
        },
        {
            name: 'team.name',
            label: 'Team',
            options: {
                display: true,
                filter: true,
                sort: true,
                searchable: false,
            },
        },
        {
            name: 'count',
            label: 'Count',
            options: {
                display: true,
                filter: false,
                sort: true,
                searchable: false,
                customBodyRender: (value) => (
                    <span className="badge bg-secondary">
                        {value}
                    </span>
                ),
            },
        },
        {
            name: 'active',
            label: 'Active',
            options: {
                display: true,
                filter: false,
                sort: true,
                searchable: false,
            },
        },
        {
            name: 'approvalThreshold',
            label: 'Approval threshold',
            options: {
                display: true,
                hint: 'Approvals threshold',
                filter: false,
                sort: true,
                searchable: false,
                customBodyRender: (value, tableMeta: MUIDataTableMeta) => {
                    return (
                        <>{value}</>
                    )
                },
            },
        },
        {
            name: 'mode',
            label: 'Mode',
            options: {
                display: true,
                hint: 'Batch mode',
                filter: false,
                sort: true,
                searchable: false,
                customBodyRender: (value) => (
                    <span className="badge bg-secondary">
                        {value}
                    </span>
                ),
            },
        },
        {
            name: 'templates',
            label: 'Templates',
            options: {
                display: true,
                filter: true,
                sort: true,
                searchable: false,
            },
        },
        {
            name: 'noCard',
            label: 'Uses Card',
            options: {
                display: true,
                filter: false,
                sort: true,
                searchable: false,
            },
        },
        {
            name: 'createdAt',
            label: 'Created At',
            options: {
                display: false,
                filter: false,
                sort: false,
                searchable: false,
                filterType: 'dropdown',
                customBodyRender: (value) =>
                    value ? (
                        <TimestampPrint>{value}</TimestampPrint>
                    ) : (
                        'N/A'
                    ),
            },
        },
        {
            name: 'updatedeAt',
            label: 'Updated At',
            options: {
                display: false,
                filter: false,
                sort: false,
                searchable: false,
                customBodyRender: (value) =>
                    value ? (
                        <TimestampPrint>{value}</TimestampPrint>
                    ) : (
                        'N/A'
                    ),
            },
        },
    ]

    const [selectedColumns, setSelectedColumns] = useLocalStorage<string[]>(
        'batchesColumns',
        allColumns.filter((a) => a.options.display).map((a) => a.name)
    )

    // update display state for selected columns
    const columns = allColumns.reduce((cols, col) => {
        const display =
            selectedColumns.findIndex((a: string) => a === col.name) >= 0

        return [
            ...cols,
            {
                ...col,
                options: {
                    ...col.options,
                    display: display,
                },
            },
        ]
    }, [])

    const [ , hasPermission ] = usePermissions()

    // Set options
    const options: MUIDataTableOptions = {
        enableNestedDataAccess: '.',
        serverSide: true,
        filterType: 'checkbox',
        searchOpen: true,
        searchPlaceholder: 'Search for a code...',
        download: hasPermission({ service: "batches", action: "batches:ActionDownloadData"}),
        rowsPerPageOptions: [10, 25, 50, 100],
        count: batchesCount,
        onChangeRowsPerPage: (numberOfRows) => setRowsPerPage(numberOfRows),
        onChangePage: (currentPage) => {
            setCurrentPage(currentPage + 1)
            setRowsExpanded([])
        },
        // Update column state on change
        onViewColumnsChange: (changedColumn, action) => {
            if (columns.some((n) => n.name === changedColumn)) {
                columns.find((n) => n.name === changedColumn).options.display =
                    action === 'add'
                setSelectedColumns(
                    columns.filter((a) => a.options.display).map((a) => a.name)
                )
            }
        },
        // Flatten the facility and organisation objects in the downloaded file
        onDownload: (buildHead, buildBody, columns: any[], data) => {
            setPopup(<BatchesExport where={where} />)

            return false
        },
        tableBodyMaxHeight: '70vh',
        print: false,
        selectableRows: 'none',
        sortOrder: { name: 'submitted', direction: 'desc' },
        searchText: queryF,
        textLabels: {
            body: {
                noMatch: result.fetching ? (
                    <div>Loading data...</div>
                ) : (
                    'Sorry, there is no matching data to display'
                ),
            },
        },
        onSearchChange: (searchText) => {
            setRowsExpanded([])
            setQueryF(searchText?.trim())
        },
        onFilterChange: (
            changedColumn,
            filterList,
            filterType,
            changedColumnIndex
        ) => {
            setRowsExpanded([])
            switch (changedColumn) {
            }
        },
        // Expandable rows
        expandableRows: true,
        expandableRowsHeader: false,
        expandableRowsOnClick: true,
        rowsExpanded: rowsExpanded,
        isRowExpandable: () => true,
        renderExpandableRow: (rowData, rowMeta) => (
            <BatchesListRowDetail
                batch={batchesRef.current[rowMeta.dataIndex]?.node}
                colSpan={rowData.length + 1}
            />
        ),
        onRowExpansionChange: (_, allExpanded) =>
            setRowsExpanded(allExpanded.map((a) => a.dataIndex)),
    }

    const theme = createTheme({
        overrides: {
            MUIDataTableSelectCell: {
                root: {
                    display: 'none',
                },
            },
            MuiTableCell: {
                head: {},
            },
        },
    })

    const useStyles = makeStyles(() =>
        createStyles({
            loading: {
                textAlign: 'center',
            },
        })
    )

    const LoadingTableBody = ({
        loading,
        options,
        columns,
        rowsPerPage,
        ...others
    }: { loading: boolean } & MUIDataTableBody) => {
        // @ts-ignore
        const classes = useStyles()
        const rows = [...Array(rowsPerPage)]

        return loading ? (
            <MuiTableBody>
                {rows.map((row, rowIndex) => {
                    return (
                        <TableBodyRow options={options} key={rowIndex}>
                            {columns
                                .filter(
                                    (
                                        column: any
                                    ): column is MUIDataTableColumn =>
                                        column?.display === 'true'
                                )
                                .map(
                                    (
                                        column: MUIDataTableColumn,
                                        columnIndex
                                    ) => (
                                        <TableBodyCell
                                            key={column?.name}
                                            // @ts-ignore
                                            options={options}
                                            colIndex={columnIndex}
                                            rowIndex={rowIndex}
                                        >
                                            <Skeleton variant="text" />
                                        </TableBodyCell>
                                    )
                                )}
                        </TableBodyRow>
                    )
                })}
            </MuiTableBody>
        ) : (
            <TableBody options={options} columns={columns} {...others} />
        )
    }

    const { fetching } = result

    const BodyComponent = useMemo(
        () => (props: MUIDataTableBody) => (
            <LoadingTableBody loading={fetching} {...props} />
        ),
        [fetching]
    )

    const dataTable = useMemo(
        () => (
            <MuiThemeProvider theme={theme}>
                <MUIDataTable
                    title={''}
                    data={batches?.map((edge) => edge.node)}
                    columns={columns}
                    options={options}
                    components={{ TableBody: BodyComponent }}
                />
            </MuiThemeProvider>
        ),
        [batches, fetching]
    )

    return (
        <div>
            {result.fetching && !batches && (
                <div className={styles.stat}>
                    <Loader message={'Loading batches'} large />
                </div>
            )}

            {!!batches && (
                <div className={styles.tableWrap}>
                    <Button
                        className={classNames(
                            { [styles.loading]: result.fetching },
                            styles.refreshBtn
                        )}
                        type={ButtonTypes.nothing}
                        title="Refresh"
                        onClick={(e) =>
                            refresh({ requestPolicy: 'network-only' })
                        }
                        icon="RefreshCw"
                    ></Button>
                    {dataTable}
                </div>
            )}
        </div>
    )
}
