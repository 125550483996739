import * as React from 'react'
import { Fragment, useCallback, useMemo } from 'react'
import { SettingsAppScreen } from '../../../settings/components/settingsAppScreen'
import { PrimaryContent } from '../../../portal/components/primaryContent/primaryContent'
import { Button, ButtonTypes } from '../../../portal/components/button/button'
import { TeamEditable } from '../../interfaces/team'
import { LoadableTable } from '../../../portal/components/loadableTable/loadableTable'
import { UserRow } from '../userRow/userRow'
import { UserInvite } from '../userInvite/userInvite'
import { TeamForm } from '../teamForm/teamForm'
import { useUser } from '../../hooks/useUser'
import { SwitchTeams } from '../switchTeams/switchTeams'
import { useDoChangeTeam } from '../../hooks/useDoChangeTeam'
import { DisplayDate } from '../../../portal/components/displayDate/displayDate'
import { TeamInvite } from '@graphql-types@'
import { DisplayRole } from '../../helpers/displayRole'
import { newTeamsEnabled } from '../../../portal/helpers/featureFlags'
import { useUsersQuery } from '../../queries/users.graphql'
import { User } from '@graphql-types@'
import { Role } from '@graphql-enums@'
import { usePopup } from '~/app/providers/popup'
import { useSnackbar } from 'notistack'
import { useApps, useCurrentTeam } from '~/app/providers/user'
import { useUpdateTeamMutation } from '../../mutations/updateTeam.graphql'
import { useCreateTeamMutation } from '../../mutations/createTeam.graphql'
import { Button as MaterialButton } from '@material-ui/core'
import { useCancelTeamInviteMutation } from '../../mutations/cancelTeamInvite.graphql'
import { useTeamInvitesQuery } from '../../queries/teamInvites.graphql'
import { LoadingSkeleton } from '~/app/components/loadingSkeleton/loadingSkeleton'
import { CustomAppFlags } from '~/app/portal/interfaces/apps'
import { PermissionComponent } from '~/app/components/permission/permission'

export function TeamDetail(): JSX.Element {
	const { clearPopup, setPopup } = usePopup()

	const onInvite = useCallback(() => {
		setPopup(<UserInvite />)
	}, [])

	const [, updateTeam] = useUpdateTeamMutation()
	const [, createTeam] = useCreateTeamMutation()

	const [currentTeam] = useCurrentTeam()

	const user = useUser()

	const [{ data, fetching, error }] = useUsersQuery()

	const users: User[] =
		data?.users?.edges.filter((edge) => edge).map((edge) => edge.node) ?? []

	const hasMultipleTeams = user ? user.teams.edges.length > 1 : false
	const currentTeamHasUsers = users.length > 1

	const onSubmitEdit = useCallback(
		(team: TeamEditable) => {
			updateTeam({
				input: {
					teamId: currentTeam.teamId,
					name: team.name,
					apps: team.apps,
				},
			}).then(() => {
				clearPopup()

				enqueueSnackbar(
					<span>
						Changes to team <b>{team.name}</b> saved.
					</span>,
					{
						variant: 'success',
					}
				)
			})
		},
		[user]
	)

	const onOpenEdit = useCallback(() => {
		setPopup(
			<TeamForm
				initial={{
					name: currentTeam.name,
					apps: currentTeam.apps.map((ta) => ta.app.slug),
				}}
				onSubmit={onSubmitEdit}
			/>
		)
	}, [user, currentTeam, onSubmitEdit])

	const doChangeTeam = useDoChangeTeam()

	const { enqueueSnackbar, closeSnackbar } = useSnackbar()

	const onOpenTeam = React.useCallback((key, createTeam) => {
		closeSnackbar(key)

		const action = (key) => (
			<Fragment>
				<MaterialButton
					onClick={() => {
						closeSnackbar(key)
					}}
				>
					Dismiss
				</MaterialButton>
			</Fragment>
		)

		doChangeTeam(createTeam.teamId).then(() => {
			enqueueSnackbar(`Changed to team '${createTeam.name}'.`, {
				variant: 'info',
				action,
			})
		})
	}, [])

	const onSubmitCreate = useCallback(
		(team: TeamEditable) => {
			createTeam({
				input: {
					name: team.name,
					apps: team.apps,
				},
			}).then(({ data, error }) => {
				const { createTeam } = data

				if (!createTeam) {
					return
				}

				const action = (key) => (
					<Fragment>
						<MaterialButton
							onClick={(key) => onOpenTeam(key, createTeam)}
						>
							Open
						</MaterialButton>
						<MaterialButton
							onClick={() => {
								closeSnackbar(key)
							}}
						>
							Dismiss
						</MaterialButton>
					</Fragment>
				)

				enqueueSnackbar(
					`You've created the '${createTeam.name} team.`,
					{
						variant: 'info',
						action,
					}
				)

				clearPopup()
			})
		},
		[user, doChangeTeam]
	)

	const onOpenCreate = useCallback(() => {
		setPopup(
			<TeamForm
				initial={{ name: '', apps: [] }}
				onSubmit={onSubmitCreate}
			/>
		)
	}, [])

	const onSwitch = useCallback(() => {
		setPopup(<SwitchTeams />)
	}, [])

	const [, hasApp] = useApps()

	const header = (
		<React.Fragment>
			<PermissionComponent service="portal" action="team:InviteUser">
				<Button
					type={ButtonTypes.transparent}
					onClick={onInvite}
					icon="UserPlus"
				>
					Invite user
				</Button>
			</PermissionComponent>
			<PermissionComponent service="portal" action="team:EditTeam">
				<Button
					type={ButtonTypes.transparent}
					onClick={onOpenEdit}
					icon="Edit"
				>
					Edit team
				</Button>
			</PermissionComponent>
			{hasMultipleTeams && (
				<Button
					type={ButtonTypes.transparent}
					onClick={onSwitch}
					icon="Repeat"
				>
					Switch teams
				</Button>
			)}
			<PermissionComponent service="portal" action="team:Create">
				<Button
					type={ButtonTypes.transparent}
					onClick={onOpenCreate}
					icon="PlusSquare"
				>
					Create additional team
				</Button>
			</PermissionComponent>
		</React.Fragment>
	)

	const [invitesResult] = useTeamInvitesQuery()
	const invites: TeamInvite[] =
		invitesResult?.data?.teamInvites?.edges
			.filter((edge) => edge)
			.map((edge) => edge.node) ?? []

	const pendingInvites: TeamInvite[] = useMemo(() => {
		if (fetching) {
			return []
		}

		const emails = users.map((user) => user.email)

		return invites.filter((invite) => !emails.includes(invite.email))
	}, [fetching, invites, users])

	const [, cancelTeamInvite] = useCancelTeamInviteMutation()

	const onClickCancel = useCallback((teamInvite: TeamInvite) => {
		const { teamInviteId } = teamInvite

		cancelTeamInvite({ teamInviteId }).then(() => {
			enqueueSnackbar(
				<span>
					Invite for <b>{teamInvite.email}</b> cancelled.
				</span>,
				{ variant: 'success' }
			)
		})
	}, [])

	return (
		<SettingsAppScreen>
			<PrimaryContent header={header}>
				<React.Fragment>
					<h2>Members</h2>

					<p>View and manage your team.</p>

					<LoadableTable
						className={'w-auto'}
						couldNotLoad={!!error}
						noResults={users.length === 0}
						noResultsMessage="You are the only user in this team."
						isLoading={fetching}
					>
						<thead>
							<tr>
								<th className="col-1"></th>
								<th>Name</th>
								<th>Role</th>
								<th>Email</th>
								<th>Last login</th>
							</tr>
						</thead>
						<tbody>
							{fetching &&
								[0, 1, 2, 3].map((i) => (
									<UserRow fetching={true} key={i} />
								))}
							{users.map((listUser) => (
								<UserRow
									fetching={false}
									key={listUser.userId}
									user={listUser}
									currentUserId={user?.userId}
								/>
							))}
						</tbody>
					</LoadableTable>
				</React.Fragment>

				<h2>Invites</h2>
				<LoadableTable
					couldNotLoad={!!error}
					noResults={pendingInvites.length === 0}
					noResultsMessage="There are no outstanding invites."
					isLoading={fetching}
				>
					<thead>
						<tr>
							<th className="col-1"></th>
							<th>Pending invitations</th>
							<th>Role</th>
							<th>Sent</th>
							<th>Expire</th>
							<th />
						</tr>
					</thead>
					<tbody>
						{fetching &&
							[0, 1, 2, 3].map((i) => (
								<tr key={i}>
									<td>
										<LoadingSkeleton loading={fetching} />
									</td>
									<td>
										<LoadingSkeleton loading={fetching} />
									</td>
									<td>
										<LoadingSkeleton loading={fetching} />
									</td>
									<td>
										<LoadingSkeleton loading={fetching} />
									</td>
									<td>
										<LoadingSkeleton loading={fetching} />
									</td>
									<td>
										<LoadingSkeleton loading={fetching} />
									</td>
								</tr>
							))}
						{pendingInvites.map((invite) => (
							<tr key={invite.teamInviteId}>
								<td></td>
								<td>{invite.email}</td>
								<td><DisplayRole roleId={invite.role} /></td>
								<td>
									<DisplayDate
										value={invite.createdAt}
										small
									/>
								</td>
								<td>
									<DisplayDate value={invite.expireDate} />
								</td>
								<td>
									<PermissionComponent service="portal" action="team:CancelInvite">
										<Button
											onClick={() => onClickCancel(invite)}
											icon="UserX"
											type={ButtonTypes.transparentDanger}
										>
											Cancel
										</Button>
									</PermissionComponent>
								</td>
							</tr>
						))}
					</tbody>
				</LoadableTable>
			</PrimaryContent>
		</SettingsAppScreen>
	)
}
