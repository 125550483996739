import * as React from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import { history } from './store'

export const RouterContext = React.createContext({})

interface Props {
    children: React.ReactNode
}
export function CustomBrowserRouter(props: Props) {
    return (
        <BrowserRouter>
            <Route>
                {(routeProps) => (
                    <RouterContext.Provider value={routeProps}>
                        {props.children}
                    </RouterContext.Provider>
                )}
            </Route>
        </BrowserRouter>
    )
}
