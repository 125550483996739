import { useEffect, useMemo } from 'react'
import { OperatorInput } from '../../interfaces/operatorInput'
import { useSamplesAggregateQuery } from '../samplesList/queries/useSamplesAggregateQuery'
import { CountBadge } from './countBadge'
import * as styles from './consentryDashboard.scss'
import { SampleAgg } from '../samplesList/samples'
import moment from 'moment-timezone'
import { time } from './momentHelpers'
import { Loader } from '../loader/loader'

type ListProps = {
    field: 'CREATED_AT' | 'TESTED_AT' | 'LAB_RECEIVED_AT' // NOTE LAB_RECEIVED_AT represents the manually completed samples.
    where?: OperatorInput | OperatorInput[]
    type?: 'PCR' | 'RA'
}

type Counts = {
    today: number
    thisWeek: number
    lastWeek: number
    thisMonth: number
    lastMonth: number
}

export function CountList(props: ListProps): JSX.Element {
    const { field, type, where } = props

    const conditions: OperatorInput[] = []

    if (where) {
        Array.isArray(where)
            ? where.forEach((w) => conditions.push(w))
            : conditions.push(where)
    }
    if (type && type === 'RA') {
        conditions.push({ field: 'testType', eq: 'ra' })
    }
    if (type && type !== 'RA') {
        conditions.push({ not: { field: 'testType', eq: 'ra' } })
    }

    const [{ data, fetching }, refresh] = useSamplesAggregateQuery(
        {
            aggs: [
                {
                    field: field,
                    type: 'COUNT',
                    interval: 'DAY',
                },
            ],
            where: {
                and: conditions,
            },
        },
        false
    )

    useEffect(() => {
        const interval = setInterval(() => {
            refresh({ requestPolicy: 'network-only' })
        }, 60 * 10000)
        return () => clearInterval(interval)
    }, [refresh])

    const counts: Counts = useMemo(() => {
        const kits: SampleAgg[] = data?.samplesAggregate?.buckets
        return {
            today: kits?.reduce((agg, k) => {
                const created = moment.utc(k.value)
                return created >= time.today ? agg + k.count : agg
            }, 0),
            thisWeek: kits?.reduce((agg, k) => {
                const created = moment.utc(k.value)
                return created >= time.thisWeek ? agg + k.count : agg
            }, 0),
            lastWeek: kits?.reduce((agg, k) => {
                const created = moment.utc(k.value)
                return created >= time.lastWeekMin &&
                    created <= time.lastWeekMax
                    ? agg + k.count
                    : agg
            }, 0),
            thisMonth: kits?.reduce((agg, k) => {
                const created = moment.utc(k.value)
                return created >= time.thisMonth ? agg + k.count : agg
            }, 0),
            lastMonth: kits?.reduce((agg, k) => {
                const created = moment.utc(k.value)
                return created >= time.lastMonthMin &&
                    created <= time.lastMonthMax
                    ? agg + k.count
                    : agg
            }, 0),
        }
    }, [data])

    let label:string
    switch(field) {
        case 'CREATED_AT':
            label = 'Submitted'
            break
        case 'TESTED_AT':
            label = 'Tested'
            break
        case 'LAB_RECEIVED_AT':
            label = 'Manual approvals'
            break
    }

    return (
        <div className={styles.countWrap}>
            {fetching && (
                <div className={styles.overlay}>
                    <Loader center inline />
                </div>
            )}
            <CountBadge value={counts.today ?? 0} title={`${label} today`} />
            <CountBadge
                value={counts.thisWeek ?? 0}
                title={`${label} this week`}
                info={'The week starts on Monday.'}
            />
            <CountBadge
                value={counts.lastWeek ?? 0}
                title={`${label} last week`}
                info={'The week starts on Monday.'}
            />
            <CountBadge
                value={counts.thisMonth ?? 0}
                title={`${label} this month`}
            />
            <CountBadge
                value={counts.lastMonth ?? 0}
                title={`${label} last month`}
            />
        </div>
    )
}
