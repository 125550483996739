import * as React from 'react'
import { useCallback, useContext } from 'react'
import * as styles from './sidebarMenu.scss'
import { Icon } from '../icon/ficon'
import { Link } from 'react-router-dom'
import { ActiveAppLink } from '../appScreen/appScreen'
import classNames from 'classnames'
import { Button, ButtonTypes } from '../button/button'
import { AppLinks, getConfig } from '../../config'
import { CompanyLogo } from '../companyLogo/companyLogo'
import { useUser } from '../../../auth/hooks/useUser'
import Badge from '@material-ui/core/Badge'
import { useApprovalCount } from '../../hooks/useApprovalCount'
import { useSidebarExpanded } from '../../../providers/store'
import { useApps } from '../../../providers/user'
import { PermissionComponent } from '~/app/components/permission/permission'

export function SidebarMenu(): JSX.Element {
	const activeAppLink = useContext(ActiveAppLink)

	const [apps, hasApp] = useApps()

	const [sidebarExpanded, setSidebarExpanded] = useSidebarExpanded()

	const toggleExpanded = useCallback(() => {
		setSidebarExpanded(!sidebarExpanded)
	}, [sidebarExpanded])

	const user = useUser()
	const config = getConfig()

	const [count] = useApprovalCount()

	return (
		<div
			className={classNames(styles.container, {
				[styles.expanded]: sidebarExpanded,
			})}
		>
			<CompanyLogo small={!sidebarExpanded} />
			<ul className={styles.nav}>
				{config
					.filter((item) => hasApp(item.app))
					.map((item) => (
						<PermissionComponent
							service={item.service}
							action={item.action}
						>
							<li key={item.appLink}>
								<Link
									to={item.path}
									className={classNames(styles.item, {
										[styles.active]:
											item.appLink === activeAppLink,
									})}
								>
									{item.appLink === AppLinks.approvals && (
										<>
											<Badge
												badgeContent={count}
												color="primary"
												anchorOrigin={{
													vertical: 'top',
													horizontal: 'left',
												}}
											>
												<Icon name={item.icon} />
											</Badge>

											<span
												className={
													sidebarExpanded
														? styles.label
														: styles.tooltip
												}
											>
												{item.label}
											</span>
										</>
									)}
									{item.appLink !== AppLinks.approvals && (
										<>
											<Icon name={item.icon} />
											<span
												className={
													sidebarExpanded
														? styles.label
														: styles.tooltip
												}
											>
												{item.label}
											</span>
										</>
									)}
								</Link>
							</li>
						</PermissionComponent>
					))}
			</ul>

			<Button
				type={ButtonTypes.nothing}
				onClick={toggleExpanded}
				className={classNames(styles.toggle, 'justify-content-center')}
			>
				<Icon name="AlignJustify" />
			</Button>
		</div>
	)
}
