import moment from 'moment'
import * as React from 'react'
import * as styles from './consentryDashboard.scss'
import { ResponsiveLine } from '@nivo/line'
import { Loader } from '../loader/loader'
import { useSamplesAggregateQuery } from '../samplesList/queries/useSamplesAggregateQuery'
import { useEffect, useState } from 'react'
import { SampleAgg } from '../samplesList/samples'
import { OperatorInput } from '../../interfaces/operatorInput'
import classNames from 'classnames'
import { time } from './momentHelpers'
import { InfoTooltip } from '../InfoTooltip/infoTooltip'

type ChartProps = {
    title: string
    info?: string
    field: 'CREATED_AT' | 'TESTED_AT' | 'LAB_RECEIVED_AT'
    where?: OperatorInput | OperatorInput[]
    type?: 'PCR' | 'RA'
    disableTimeFilter?: boolean
}

export enum TimeFilter {
    Week = 'Week',
    Month = 'Month',
    Quarter = 'Quarter',
}

const createQueryConditions = (
    props: ChartProps,
    timeFilter: TimeFilter
): OperatorInput[] => {
    const { field, type, where } = props
    const conditions: OperatorInput[] = []
    if (where) {
        Array.isArray(where)
            ? where.forEach((w) => conditions.push(w))
            : conditions.push(where)
    }
    if (type && type === 'RA') {
        conditions.push({ field: 'testType', eq: 'ra' })
    }
    if (type && type !== 'RA') {
        conditions.push({ not: { field: 'testType', eq: 'ra' } })
    }
    if (timeFilter && !props.disableTimeFilter) {
        let wField:string
        switch(field) {
            case 'CREATED_AT':
                wField = 'createdAt'
                break
            case 'TESTED_AT':
                wField = 'testedAt'
                break
            case 'LAB_RECEIVED_AT':
                wField = 'labReceivedAt'
                break
        }
        
        switch (timeFilter) {
            case TimeFilter.Week: {
                conditions.push({
                    field: wField,
                    gt: time.thisWeek.toISOString(),
                })
                break
            }
            case TimeFilter.Month: {
                conditions.push({
                    field: wField,
                    gt: time.last30Days.toISOString(),
                })
                break
            }
            case TimeFilter.Quarter: {
                conditions.push({
                    field: wField,
                    gt: time.lastQuarter.toISOString(),
                })
                break
            }
        }
    }
    return conditions
}

export const SamplesAggLineChart = (props: ChartProps): JSX.Element => {
    const { title, info, field, disableTimeFilter } = props
    const [timeFilter, setTimeFilter] = useState<TimeFilter>(TimeFilter.Month)
    const [conditions, setConditions] = useState<OperatorInput[]>(
        createQueryConditions(props, timeFilter)
    )

    const [{ data, fetching }, refresh] = useSamplesAggregateQuery(
        {
            aggs: [
                {
                    field: field,
                    type: 'COUNT',
                    interval: 'DAY',
                },
            ],
            where: {
                and: conditions,
            },
        },
        false
    )

    useEffect(() => {
        const interval = setInterval(() => {
            refresh({ requestPolicy: 'network-only' })
        }, 60 * 10000)
        return () => clearInterval(interval)
    }, [refresh])

    const chartData = React.useMemo(() => {
        const kits: SampleAgg[] = data?.samplesAggregate?.buckets
        return [
            {
                id: 'Samples',
                color: 'hsl(194, 70%, 50%)',
                data:
                    kits
                        ?.sort((a, b) => (a.value > b.value ? 1 : -1))
                        .filter((k) => moment(k.value).isValid())
                        .map((k) => ({
                            x: moment(k.value).format('YYYY-MM-DD'),
                            y: k.count,
                        })) || [],
            },
        ]
    }, [data])

    const onChangeTimeFilter = (filter: TimeFilter) => {
        setTimeFilter(filter)
        setConditions(createQueryConditions(props, filter))
        refresh()
    }

    return (
        <div className={styles.chartbox}>
            {fetching && (
                <div className={styles.overlay}>
                    <Loader center large />
                </div>
            )}

            <div className="d-flex justify-content-between ps-3 pe-3 pt-3">
                <h3>
                    {title}
                    {info != null ? InfoTooltip(info) : null}
                </h3>
                {!disableTimeFilter && (
                    <div className="btn-group d-block btn-group-toggle">
                        <label
                            className={classNames(
                                'btn btn-sm btn-secondary',
                                timeFilter == TimeFilter.Week ? 'active' : null
                            )}
                            onClick={() => onChangeTimeFilter(TimeFilter.Week)}
                        >
                            Week
                        </label>
                        <label
                            className={classNames(
                                'btn btn-sm btn-secondary',
                                timeFilter == TimeFilter.Month ? 'active' : null
                            )}
                            onClick={() => onChangeTimeFilter(TimeFilter.Month)}
                        >
                            Month
                        </label>
                        <label
                            className={classNames(
                                'btn btn-sm btn-secondary',
                                timeFilter == TimeFilter.Quarter
                                    ? 'active'
                                    : null
                            )}
                            onClick={() =>
                                onChangeTimeFilter(TimeFilter.Quarter)
                            }
                        >
                            Quarter
                        </label>
                    </div>
                )}
            </div>

            <div className={styles.chart}>
                <ResponsiveLine
                    data={chartData}
                    margin={{ top: 16, right: 32, bottom: 26, left: 66 }}
                    xScale={{
                        type: 'time',
                        format: '%Y-%m-%d',
                        useUTC: true,
                        precision: 'day',
                    }}
                    xFormat="time:%Y-%m-%d"
                    yScale={{
                        type: 'linear',
                        min: 'auto',
                        max: 'auto',
                        stacked: true,
                        reverse: false,
                    }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        format: '%b %d',
                        tickValues: 7,
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'count',
                        legendOffset: -40,
                        legendPosition: 'middle',
                    }}
                    pointSize={10}
                    pointColor={{ theme: 'background' }}
                    pointBorderWidth={2}
                    pointBorderColor={{ from: 'serieColor' }}
                    pointLabelYOffset={-12}
                    useMesh={true}
                />
            </div>
        </div>
    )
}
