/* c7bb58021821b493ed84bbf466347b1d449b995a
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../__types__';

import gql from 'graphql-tag';
import * as React from 'react';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SystemQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SystemQuery = { __typename?: 'Query', system: { __typename?: 'System', info: { __typename?: 'SystemInfo', version: string, buildDate: string, tag: string, commitId: string, shortCommitId: string } } };


export const SystemDocument = gql`
    query system {
  system {
    info {
      version
      buildDate
      tag
      commitId
      shortCommitId
    }
  }
}
    `;

export const SystemComponent = (props: Omit<Urql.QueryProps<SystemQuery, SystemQueryVariables>, 'query'> & { variables?: SystemQueryVariables }) => (
  <Urql.Query {...props} query={SystemDocument} />
);


export function useSystemQuery(options?: Omit<Urql.UseQueryArgs<SystemQueryVariables>, 'query'>) {
  return Urql.useQuery<SystemQuery>({ query: SystemDocument, ...options });
};