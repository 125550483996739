import * as styles from './consentryDashboard.scss'
import { InfoTooltip } from '../InfoTooltip/infoTooltip'
interface Props {
    title: string
    value: number
    info?: string
    className?: string
    breakBefore?: boolean
}

export function CountBadge(props: Props): JSX.Element {
    const { title, value, breakBefore } = props

    return (
        <>
            {breakBefore && <div className={styles.flexBreak}></div>}
            <div className={styles.statWrap}>
                <div className={styles.stat}>
                    <span className={styles.statTitle}>
                        {title}
                        {props?.info != null ? InfoTooltip(props.info) : null}
                    </span>
                    <span className={styles.statCount}>
                        {value.toLocaleString('en')}
                    </span>
                </div>
            </div>
        </>
    )
}
