import { SchemaOf } from 'yup'
import { yupToFormErrors } from 'formik'

export function validateForm(
    getValidationSchema: (values) => SchemaOf<any>,
    values: any
) {
    const schema = getValidationSchema(values)

    try {
        schema.validateSync(values, { abortEarly: false })
        return {}
    } catch (error) {
        return yupToFormErrors(error)
    }
}
