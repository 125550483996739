// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".companyLogo__container--KUbpA{margin:0;width:55px;display:flex;align-items:center;padding:0 1rem}.companyLogo__container--KUbpA:hover{text-decoration:none}.companyLogo__container--KUbpA.companyLogo__expanded--mAlaV{width:var(--sidebar-width)}.companyLogo__logoWrap--F9XHO{height:72px;display:flex}.companyLogo__logo--JDlRU{height:28px}", "",{"version":3,"sources":["webpack://./src/app/portal/components/companyLogo/companyLogo.scss"],"names":[],"mappings":"AAAA,+BACI,QAAA,CACA,UAAA,CACA,YAAA,CACA,kBAAA,CACA,cAAA,CAEA,qCACI,oBAAA,CAGJ,4DACI,0BAAA,CAIR,8BACI,WAAA,CACA,YAAA,CAGJ,0BACI,WAAA","sourcesContent":[".container {\n    margin: 0;\n    width: 55px;\n    display: flex;\n    align-items: center;\n    padding: 0 1rem;\n\n    &:hover {\n        text-decoration: none;\n    }\n\n    &.expanded {\n        width: var(--sidebar-width);\n    }\n}\n\n.logoWrap {\n    height: 72px;\n    display: flex;\n}\n\n.logo {\n    height: 28px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "companyLogo__container--KUbpA",
	"expanded": "companyLogo__expanded--mAlaV",
	"logoWrap": "companyLogo__logoWrap--F9XHO",
	"logo": "companyLogo__logo--JDlRU"
};
export default ___CSS_LOADER_EXPORT___;
