// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tabs__nav--HpVJj{background:var(--background-lighter);border-bottom:1px solid var(--border-color);display:flex;padding:0 .5rem}.tabs__nav--HpVJj.tabs__centeredNav--ptdaS{justify-content:center}.tabs__tabLink--KAyKV{display:inline-flex;border-bottom:2px solid transparent;padding:.75rem 1.25rem;border-radius:0;margin:0 0 -1px}.tabs__tabLink--KAyKV.tabs__active--WKRYj{font-weight:bold;border-color:var(--primary-color);color:var(--primary-color)}.tabs__dot--oDrI0{display:inline-flex;align-items:center;height:8px;width:8px;background-color:var(--primary-color);border-radius:50%;margin:.25rem}.tabs__hidden--hCzTS{display:none}", "",{"version":3,"sources":["webpack://./src/app/portal/components/tabs/tabs.scss"],"names":[],"mappings":"AAAA,kBACI,oCAAA,CACA,2CAAA,CACA,YAAA,CACA,eAAA,CAEA,2CACI,sBAAA,CAIR,sBACI,mBAAA,CACA,mCAAA,CACA,sBAAA,CACA,eAAA,CACA,eAAA,CAEA,0CACI,gBAAA,CACA,iCAAA,CACA,0BAAA,CAIR,kBACI,mBAAA,CACA,kBAAA,CACA,UAAA,CACA,SAAA,CACA,qCAAA,CACA,iBAAA,CACA,aAAA,CAGJ,qBACI,YAAA","sourcesContent":[".nav {\n    background: var(--background-lighter);\n    border-bottom: 1px solid var(--border-color);\n    display: flex;\n    padding: 0 0.5rem;\n\n    &.centeredNav {\n        justify-content: center;\n    }\n}\n\n.tabLink {\n    display: inline-flex;\n    border-bottom: 2px solid transparent;\n    padding: 0.75rem 1.25rem;\n    border-radius: 0;\n    margin: 0 0 -1px;\n\n    &.active {\n        font-weight: bold;\n        border-color: var(--primary-color);\n        color: var(--primary-color);\n    }\n}\n\n.dot {\n    display: inline-flex;\n    align-items: center;\n    height: 8px;\n    width: 8px;\n    background-color: var(--primary-color);\n    border-radius: 50%;\n    margin: 0.25rem;\n}\n\n.hidden {\n    display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nav": "tabs__nav--HpVJj",
	"centeredNav": "tabs__centeredNav--ptdaS",
	"tabLink": "tabs__tabLink--KAyKV",
	"active": "tabs__active--WKRYj",
	"dot": "tabs__dot--oDrI0",
	"hidden": "tabs__hidden--hCzTS"
};
export default ___CSS_LOADER_EXPORT___;
