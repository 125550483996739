/* 6ea33340676fc81823817231eead1e17878ac2cf
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../__types__';

import gql from 'graphql-tag';
import * as React from 'react';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateUserMutationVariables = Types.Exact<{
  input: Types.UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', userId: string } };


export const UpdateUserDocument = gql`
    mutation updateUser($input: updateUserInput!) {
  updateUser(input: $input) {
    userId
  }
}
    `;

export const UpdateUserComponent = (props: Omit<Urql.MutationProps<UpdateUserMutation, UpdateUserMutationVariables>, 'query'> & { variables?: UpdateUserMutationVariables }) => (
  <Urql.Mutation {...props} query={UpdateUserDocument} />
);


export function useUpdateUserMutation() {
  return Urql.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument);
};