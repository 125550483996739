import moment from 'moment-timezone'
import * as React from 'react'
import { TimestampPrint } from './samplesHelpers'

const redCellStyle = { color: '#f44336', fontWeight: 800 }
const yellowCellStyle = { color: '#ffac33', fontWeight: 800 }

type Props = {
    /** Cell value */
    value: any
    /** Previous cell value */
    prevTimestamp?: any
    /** Alert if time difference higher than number of hours */
    firstWarning?: number
    /** Alert if time difference higher than number of hours */
    secondWarning?: number
}

export default function SamplesWarningCell(props: Props): JSX.Element {
    const { value, prevTimestamp, firstWarning, secondWarning } = props

    // If no value, and timestamp of previous status available,
    // we can decide the correct style:
    let style
    if (prevTimestamp && !value) {
        const timeDiff: number = moment().diff(
            moment.unix(prevTimestamp),
            'hours'
        )

        if (secondWarning && secondWarning < timeDiff) {
            style = redCellStyle
        } else if (firstWarning && firstWarning < timeDiff) {
            style = !secondWarning ? redCellStyle : yellowCellStyle
        }
    }

    return (
        <div style={style} className="badge bg-secondary">
            <TimestampPrint value={value} />
        </div>
    )
}
