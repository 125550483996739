import * as React from 'react'
import * as styles from './loader.scss'
import classNames from 'classnames'

export interface Props {
    className?: string
    large?: boolean
    center?: boolean
    message?: string
    inline?: boolean
}

export const Loader = (props: Props): JSX.Element => {
    return (
        <div
            className={classNames(props.className, styles.loaderWrap, {
                [styles.large]: props.large,
                [styles.center]: props.center,
                [styles.inline]: props.inline,
            })}
        >
            <div className={styles.loader} />
            {props.message && <p className={styles.message}>{props.message}</p>}
        </div>
    )
}
