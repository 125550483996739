/* a4314d59961bf1715f29074f1cac0ab4444bd125
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../__types__';

import gql from 'graphql-tag';
import * as React from 'react';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UserQueryVariables = Types.Exact<{
  userId: Types.Scalars['ID'];
}>;


export type UserQuery = { __typename?: 'Query', user: { __typename?: 'User', userId: string, userName: string, email: string, fullName: string, role: string, lastLoginDate: any, otpEnabled: boolean, emailConfirmed: boolean, avatarUrl: string, createdAt: any, updatedAt: any } };


export const UserDocument = gql`
    query user($userId: ID!) {
  user(userId: $userId) {
    userId
    userName
    email
    fullName
    role
    lastLoginDate
    otpEnabled
    emailConfirmed
    avatarUrl
    createdAt
    updatedAt
  }
}
    `;

export const UserComponent = (props: Omit<Urql.QueryProps<UserQuery, UserQueryVariables>, 'query'> & { variables: UserQueryVariables }) => (
  <Urql.Query {...props} query={UserDocument} />
);


export function useUserQuery(options: Omit<Urql.UseQueryArgs<UserQueryVariables>, 'query'>) {
  return Urql.useQuery<UserQuery>({ query: UserDocument, ...options });
};