import * as React from 'react'
import { useCallback } from 'react'
import { usePopup } from '~/app/providers/popup'
import { AppLinks } from '../../config'
import { AppScreen } from '../appScreen/appScreen'
import { SetPageTitle } from '../../helpers/setPageTitle'
import { PrimaryContent } from '../primaryContent/primaryContent'
import { ButtonLink, ButtonTypes } from '../button/button'
import { Paths } from '../../helpers/routePaths'
import { generatePath } from 'react-router-dom'
import * as styles from './approvalSection.scss'
import Magnifier from 'react-magnifier'
import classNames from 'classnames'
import { useSamplesQuery } from '../samplesList/queries/useSamplesQuery'
import { SampleEdge, SampleStatus } from '../samplesList/samples'
import { Loader } from '../loader/loader'
import { Icon } from '../icon/ficon'
import { ScreenCalibrationHelper } from './screenCalibrateHelper'
import { Button } from '../button/button'
import { PermissionComponent } from '~/app/components/permission/permission'

const cardExample = require('./images/alltest_big2.jpg')

export function ApprovalSection(): JSX.Element {
    // Create request to check on status
    const [{ data, fetching }] = useSamplesQuery(
        {
            limit: 1000,
            offset: 0,
            where: {
                and: [
                    {
                        field: 'status',
                        eq: SampleStatus.REQUIRES_APPROVAL,
                    },
                ],
            },
            after: '',
        },
        false
    )

    const approvals: SampleEdge[] = React.useMemo(
        () => (data ? data?.samples?.edges : undefined),
        [data, fetching]
    )

    const { setPopup } = usePopup()

    const onOpenCalibration = useCallback(() => {
        setPopup(<ScreenCalibrationHelper />)
    }, [])

    return (
        <AppScreen activeAppLink={AppLinks.approvals}>
            <SetPageTitle title="Approvals" />
            <PrimaryContent>
                <div className={styles.container}>
                    <div className={styles.imgCol}>
                        <span
                            className={classNames(
                                styles.label,
                                'badge bg-secondary'
                            )}
                        >
                            example image
                        </span>
                        <Magnifier
                            src={cardExample}
                            width={'100%'}
                            mgShape="square"
                            className={styles.magnifierWrap}
                        />
                    </div>

                    <div
                        className={classNames(
                            'container-fluid',
                            styles.boxContainer
                        )}
                    >
                        <div className={styles.content}>
                            <div
                                className={classNames(
                                    'mt-4 mb-4',
                                    styles.boxContent
                                )}
                            >
                                <section>
                                    <h2>Rapid Antigen Approvals</h2>
                                    {fetching && !approvals && (
                                        <Loader message={'Loading...'} large />
                                    )}
                                    {!fetching && approvals?.length < 1 && (
                                        <p>
                                            There are no pending approvals to be
                                            done.
                                        </p>
                                    )}
                                    {approvals && approvals.length > 0 && (
                                        <div className="container">
                                            <div className="col row  d-flex align-items-center mt-4">
                                                <div className='col-sm-8 g-0'>
                                                    There are <span className="badge bg-secondary">
                                                    {approvals.length}{' '}pending approvals</span>{' '}
                                                    to be done.
                                                </div>
                                                <div className='me-8 col-sm-4'>
                                                    <PermissionComponent
                                                        service={'sample-approvals'}
                                                        action={'approvals:ActionStartReview'}
                                                        >
                                                        <ButtonLink
                                                            to={generatePath(
                                                                Paths.approvalEdit,
                                                                {
                                                                    code:
                                                                        approvals[0]
                                                                            .node.code,
                                                                }
                                                            )}
                                                            type={
                                                                ButtonTypes.superPrimary
                                                            }
                                                            icon="Image"
                                                            iconOnRight
                                                        >
                                                            Start reviewing
                                                        </ButtonLink>
                                                    </PermissionComponent>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </section>
                            </div>
                            <div className="mt-4 mb-4">
                                <hr className='mb-4'></hr>

                                <section>
                                    <h3>Instruction</h3>
                                    <p>
                                        Rapid Antigen tests that did not match
                                        the users guess will end up here.
                                        <br />
                                        Please use below guide to review a
                                        submission appropiately.
                                    </p>
                                    <p className="me-2">
                                        <Icon name="ZoomIn" small />
                                        &nbsp; Hover your{' '}
                                        <Icon name="MousePointer" small /> over
                                        the image to inspect an image further.
                                    </p>
                                    <hr className='mb-4 mt-4'></hr>
                                    <PermissionComponent
                                        service={'sample-approvals'}
                                        action={'approvals:ViewCalibrationHelper'}
                                    >
                                        <>
                                            <h3>Screen Calibration</h3>
                                            <div className='col row  d-flex align-items-center'>
                                                <div className='col-sm-8'>
                                                        Before starting run the screen calibration test.
                                                </div>
                                                <div className='col-sm-4 me-8'>
                                                    <Button
                                                        onClick={() =>
                                                            onOpenCalibration()
                                                        }
                                                        type={ButtonTypes.primary}
                                                        icon="Monitor"
                                                        iconOnRight
                                                        className=""
                                                    >
                                                    Calibrate my screen
                                                    </Button>
                                                </div>
                                            </div>
                                        </>
                                    </PermissionComponent>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </PrimaryContent>
        </AppScreen>
    )
}
