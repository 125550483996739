import { TableCell, TableRow } from '@material-ui/core'
import moment from 'moment-timezone'
import { useCallback } from 'react'
import { usePopup } from '~/app/providers/popup'
import { Button, ButtonTypes } from '../button/button'
import { Icon } from '../icon/ficon'
import { Batch } from './batches'
import { TimestampPrint } from '../samplesList/samplesHelpers'

interface Props {
    batch: Batch
    colSpan: number
}

export function BatchesListRowDetail(props: Props): JSX.Element {
    const { batch, colSpan } = props

    const { setPopup } = usePopup()

    return (
        <TableRow>
            <TableCell colSpan={colSpan} className="text-center">
                TODO
            </TableCell>
        </TableRow>
    )
}
