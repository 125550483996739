import { Link } from 'react-router-dom'
import { Paths } from '../../../portal/helpers/routePaths'
import * as styles from './joinTeamInfo.scss'
import {
    InvitationError,
    useTeamInviteStatus,
} from '../../hooks/useTeamInviteStatus'
import { Loader } from '../../../portal/components/loader/loader'

interface Props {
    isSignUp: boolean
}

export function JoinTeamInfo(props: Props) {
    const { isSignUp } = props
    const { team, error, loading } = useTeamInviteStatus()

    if (loading) {
        return (
            <p className={styles.container}>
                <Loader />
            </p>
        )
    }

    if (isSignUp && error === InvitationError.noToken) {
        return (
            <p className={styles.container}>
                You can only sign up if your team admin sends you an invitation
                first. Use the link from your invitation email.
            </p>
        )
    }

    if (error === InvitationError.invalidToken) {
        return (
            <p className={styles.container}>
                The invitation does not work anymore. Maybe it was already used?
                Request a new invitation from your team admin.
            </p>
        )
    }

    if (isSignUp) {
        return (
            <p className={styles.container}>
                To accept the invitation and join the team{' '}
                <strong>{team.name}</strong>, please sign up or{' '}
                <Link to={Paths.logIn} className={styles.link}>
                    log in
                </Link>
                .
            </p>
        )
    }

    if (team) {
        return (
            <p className={styles.container}>
                To accept the invitation and join the team{' '}
                <strong>{team.name}</strong>, please log in or{' '}
                <Link to={Paths.signUp} className={styles.link}>
                    sign up
                </Link>
                .
            </p>
        )
    }

    return null
}
