import moment from 'moment-timezone'
import { useTimezone } from '../../../hooks/useTimezone'

export const TimestampPrint = (props: any): JSX.Element => {
    const { value } = props
    const [tz] = useTimezone()

    return (
        <>
            <>
                {value
                    ? moment(value).tz(tz).format('DD-MM-YYYY HH:mm:ss')
                    : 'N/A'}{' '}
            </>
        </>
    )
}
