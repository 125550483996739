import {
    LogInForm,
    SSOLogInForm,
} from '../../auth/components/loginScreens/logInForm'
import { SignUpForm } from '../../auth/components/loginScreens/signUpForm/signUpForm'
import { ForgotPasswordForm } from '../../auth/components/loginScreens/forgotPasswordForm'
import { ResetPasswordForm } from '../../auth/components/loginScreens/resetPasswordForm'
import { Account } from '../../auth/components/account'
import { RouteNotFound } from '../components/routeNotFound/routeNotFound'
import { RouteNotAllowed } from '../components/routeNotAllowed/routeNotAllowed'
import { PortalImages } from '../components/portalImages/portalImages'
import { PortalGenerate } from '../components/portalGenerate/portalGenerate'
import { EmptySettingsAppScreen } from '../../auth/components/emptySettingsAppScreen'
import { Paths } from './routePaths'
import { UserDetail } from '../../auth/components/userDetail/userDetail'
import { TeamDetail } from '../../auth/components/teamDetail/teamDetail'
import { SystemManagement } from '../../settings/components/systemManagement/systemManagement'
import { ConsentryUpload } from '../components/consentryUpload/consentryUpload'
import { ConsentryDashboard } from '../components/consentryDashboard/consentryDashboard'
import { ApprovalSection } from '../components/approvalSection/approvalSection'
import { ApprovalEdit } from '../components/approvalEdit/approvalEdit'
import { CodeTesting } from '../components/codeTesting/codeTesting'
import { AuditLogList } from '../../settings/components/auditLogList/auditLogList'
import { CustomAppFlags, CustomApps } from '../interfaces/apps'
import { PortalSamples } from '../components/portalSamples/portalSamples'
import { PortalBatches } from '../components/portalBatches/portalBatches'

export interface RouteConfig {
    paths: Paths[]
    exact: boolean
    component?: any
    children?: any
    authRequired: boolean
    notAuthRequired: boolean
    // When it's not specified, all roles have access
    service?: string
    action?: string
    apps?: CustomAppFlags[]
}

const routes: RouteConfig[] = [
    // Consentry
    Route([Paths.activity], ConsentryDashboard, true),
    Route(
        [Paths.samples], 
        PortalSamples, 
        true,
        'portal',
        'samples:ViewData',
        ),
    Route(
        [Paths.images], 
        PortalImages, 
        true,
        'sample-approvals',
        'images:ViewData',
        ),
    Route([Paths.batches], 
        PortalBatches, 
        true,
        'batches',
        'batches:ViewData',
        [CustomAppFlags.Batches,]
        ),
    Route([Paths.generate], 
        PortalGenerate, 
        true,
        'portal',
        'generator:ViewGenerator',
        ),
    Route([Paths.upload], 
        ConsentryUpload, 
        true, 
        'upload-lab-export', 
        'labupload:View', 
        [CustomAppFlags.UploadLabExport,]
        ),

    // Settings
    Route([Paths.settings], EmptySettingsAppScreen, true),
    Route([Paths.settingsAccount], Account, true),
    Route(
        [Paths.settingsTeam], 
        TeamDetail, 
        true,
        'portal',
        'team:ViewTeam',
        ),
    Route([Paths.settingsTeamUser], UserDetail, true),
    {
        paths: [Paths.ssoPage],
        exact: true,
        component: SSOLogInForm,
        notAuthRequired: false,
        authRequired: false,
    },
    // Auth
    Route([Paths.logIn], LogInForm, false),
    Route([Paths.signUp], SignUpForm, false),
    Route([Paths.forgotPassword], ForgotPasswordForm, false),
    Route([Paths.resetPassword], ResetPasswordForm, false),
    // Misc.
    Route([Paths.auditLogs], AuditLogList, true),
    {
        paths: [Paths.systemManagement],
        exact: false,
        component: SystemManagement,
        notAuthRequired: false,
        authRequired: true,
    },
    // Approvals UI
    Route(
        [Paths.approvals],
        ApprovalSection,
        true,
        'sample-approvals',
        'approvals:ViewEntry',
        [CustomAppFlags.SampleApprovals]
    ),

    Route(
        [Paths.approvalEdit],
        ApprovalEdit,
        true,
        'sample-approvals',
        'approvals:ViewReview',
        [CustomAppFlags.SampleApprovals]
    ),
    // Testing UI
    Route([Paths.testing], 
        CodeTesting, 
        true, 
        'code-testing', 
        'testing:View', 
        [CustomAppFlags.CodeTesting,]
        ),
]

/**
 * Creates a new Route
 * @param paths Path to the view.
 * @param component Component of the view.
 * @param auth Auth required?
 * @param roles Permissions required?
 */
function Route(
    paths: Paths[],
    component: any,
    auth: boolean,
    service = '*',
    action = '*',
    apps?: CustomAppFlags[]
): RouteConfig {
    return {
        paths: paths,
        exact: true,
        component: component,
        notAuthRequired: !auth,
        authRequired: auth,
        service: service,
        action: action,
        apps: apps,
    }
}

/** Returns routes for the portal, including optional routes for feature flagged views. */
export const getRoutes = (apps?: CustomApps): RouteConfig[] => {
    return routes
}
