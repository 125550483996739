import { useEffect } from 'react'

export enum Keys {
    escape = 'Escape',
    arrowDown = 'ArrowDown',
    arrowUp = 'ArrowUp',
    arrowRight = 'ArrowRight',
    arrowLeft = 'ArrowLeft',
    backspace = 'Backspace',
    slash = '/',
    g = 'g',
    c = 'c',
    d = 'd',
    a = 'a',
    f = 'f',
    e = 'e',
    h = 'h',
    b = 'b',
    o = 'o',
    q = 'q',
    r = 'r',
    s = 's',
    u = 'u',
    i = 'i',
    questionMark = '?',
}

export function useKey(
    key: Keys,
    callback: () => void,
    enabled: boolean = true
) {
    useEffect(() => {
        const keyboardListener = (event: KeyboardEvent) => {
            if (
                (enabled && event.key === key) ||
                event.key === key.toUpperCase()
            ) {
                const activeNodeName = document.activeElement.nodeName

                if (
                    !['INPUT', 'TEXTAREA'].includes(activeNodeName) ||
                    key == Keys.escape
                ) {
                    event.preventDefault()
                    callback()
                }
            }
        }

        window.addEventListener('keydown', keyboardListener)

        return () => window.removeEventListener('keydown', keyboardListener)
    })
}
