// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iconTextInput__borderRoundRight--KdudK{border-top-right-radius:var(--border-radius) !important;border-bottom-right-radius:var(--border-radius) !important}", "",{"version":3,"sources":["webpack://./src/app/portal/components/iconTextInput/iconTextInput.scss"],"names":[],"mappings":"AAAA,wCACI,uDAAA,CACA,0DAAA","sourcesContent":[".borderRoundRight {\n    border-top-right-radius: var(--border-radius) !important;\n    border-bottom-right-radius: var(--border-radius) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"borderRoundRight": "iconTextInput__borderRoundRight--KdudK"
};
export default ___CSS_LOADER_EXPORT___;
