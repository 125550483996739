/* 6e73c5a58dd5d3dde92b66e731f53864185e13c0
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../__types__';

import gql from 'graphql-tag';
import * as React from 'react';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CancelTeamInviteMutationVariables = Types.Exact<{
  teamInviteId: Types.Scalars['ID'];
}>;


export type CancelTeamInviteMutation = { __typename?: 'Mutation', cancelTeamInvite: boolean };


export const CancelTeamInviteDocument = gql`
    mutation cancelTeamInvite($teamInviteId: ID!) {
  cancelTeamInvite(teamInviteId: $teamInviteId)
}
    `;

export const CancelTeamInviteComponent = (props: Omit<Urql.MutationProps<CancelTeamInviteMutation, CancelTeamInviteMutationVariables>, 'query'> & { variables?: CancelTeamInviteMutationVariables }) => (
  <Urql.Mutation {...props} query={CancelTeamInviteDocument} />
);


export function useCancelTeamInviteMutation() {
  return Urql.useMutation<CancelTeamInviteMutation, CancelTeamInviteMutationVariables>(CancelTeamInviteDocument);
};