/* 14f276df951bfd2ad0e235916dd9a1bb11fd50b6
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../__types__';

import gql from 'graphql-tag';
import * as React from 'react';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type InviteUserMutationVariables = Types.Exact<{
  input: Types.InviteUserInput;
}>;


export type InviteUserMutation = { __typename?: 'Mutation', inviteUser: { __typename?: 'inviteUser', email: string, role: string } };


export const InviteUserDocument = gql`
    mutation inviteUser($input: inviteUserInput!) {
  inviteUser(input: $input) {
    email
    role
  }
}
    `;

export const InviteUserComponent = (props: Omit<Urql.MutationProps<InviteUserMutation, InviteUserMutationVariables>, 'query'> & { variables?: InviteUserMutationVariables }) => (
  <Urql.Mutation {...props} query={InviteUserDocument} />
);


export function useInviteUserMutation() {
  return Urql.useMutation<InviteUserMutation, InviteUserMutationVariables>(InviteUserDocument);
};