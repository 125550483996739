import * as React from 'react'
import { useCallback, useState } from 'react'
import { useFormatMessage } from '../../../portal/helpers/intlContext'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { validateForm } from '../../../portal/helpers/validateForm'
import { passwordValidate } from '../../helpers/passwordValidate'
import { SubmitButton } from '../../../portal/components/submitButton/submitButton'
import {
	AvailableParams,
	useSearchParam,
} from '../../../portal/hooks/useSearchParam'
import { PreLogin } from './preLogin/preLogin'
import * as styles from './loginForm.scss'
import { LabeledInput } from '../../../portal/components/labeledInput/labeledInput'
import {
	ButtonLink,
	ButtonTypes,
} from '../../../portal/components/button/button'
import classNames from 'classnames'
import { useConfirmResetPasswordMutation } from '../../mutations/confirmResetPassword.graphql'
import { useSnackbar } from 'notistack'
import { useLocation } from 'react-router-dom'
import qs from 'qs'
import { IconTextInput } from '~/app/portal/components/iconTextInput/iconTextInput'
import { qsOptions } from '~/app/portal/components/qsOptions'

export function ResetPasswordForm() {
	const t = useFormatMessage()
	const [isChanged, setIsChanged] = useState<boolean>(false)

	const location = useLocation()

	const params = qs.parse(location.hash.replace(/^\#/, ''), qsOptions)
	const token = params?.token

	const { enqueueSnackbar } = useSnackbar()

	const [, confirmResetPassword] = useConfirmResetPasswordMutation()

	const onSubmit = useCallback((data, { setFieldError, setSubmitting }) => {
		confirmResetPassword({ token, password: data.password })
			.then(() => {
				setSubmitting(false)
				setIsChanged(true)
			})
			.catch((action) => {
				setSubmitting(false)
				enqueueSnackbar(<span>Something went wrong.</span>, {
					variant: 'error',
				})
			})
	}, [])

	const getValidationSchema = useCallback((values) => {
		return Yup.object().shape({
			password: passwordValidate.required(t('Password is required.')),
			repeatPassword: Yup.string()
				.required(t('form.required'))
				.oneOf([values.password], t('form.passwordsMustMatch')),
		})
	}, [])

	return (
		<PreLogin>
			<div className={styles.container}>
				<h3>Change your password</h3>
				{!token && (
					<div className="text-center">
						<p className="alert alert-danger">
							Could not find token.
						</p>
						<ButtonLink to="/login" type={ButtonTypes.link}>
							Back to login
						</ButtonLink>
					</div>
				)}
				{isChanged && (
					<div className="text-center">
						<p>
							Your password has been changed. You can now use your
							new password to log in.
						</p>
						<ButtonLink to="/login" type={ButtonTypes.link}>
							Back to login
						</ButtonLink>
					</div>
				)}

				{token && !isChanged && (
					<Formik
						initialValues={{
							password: '',
							repeatPassword: '',
						}}
						validate={(values) =>
							validateForm(getValidationSchema, values)
						}
						onSubmit={onSubmit}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							dirty,
							isSubmitting,
							isValid,
						}) => (
							<form
								className={styles.form}
								onSubmit={handleSubmit}
							>
								<IconTextInput
									icon="Key"
									placeholder="new password"
									autocomplete="new-password"
									name="password"
									passwordStrength
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.password}
									error={errors.password}
									touched={touched.password}
									autoFocus
									type="password"
								/>

								<IconTextInput
									icon="Key"
									placeholder="repeat password"
									name="repeatPassword"
									autocomplete="new-password"
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.repeatPassword}
									error={errors.repeatPassword}
									touched={touched.repeatPassword}
									type="password"
								/>

								<div className={styles.btnWrap}>
									<div
										className={classNames(
											styles.buttons,
											'btn-group'
										)}
										role="group"
									>
										<ButtonLink
											to="/login"
											type={ButtonTypes.secondary}
										>
											Cancel
										</ButtonLink>
										<SubmitButton
											title="Change password"
											submitting={isSubmitting}
											iconOnRight={true}
											disabled={
												!isValid ||
												!dirty ||
												isSubmitting
											}
										/>
									</div>
								</div>
							</form>
						)}
					</Formik>
				)}
			</div>
		</PreLogin>
	)
}
