/* 0737e8d93cf802bf38ea050dc28c03d994cbc2e6
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../__types__';

import gql from 'graphql-tag';
import * as React from 'react';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RefreshMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type RefreshMutation = { __typename?: 'Mutation', refresh?: string | null };


export const RefreshDocument = gql`
    mutation refresh {
  refresh
}
    `;

export const RefreshComponent = (props: Omit<Urql.MutationProps<RefreshMutation, RefreshMutationVariables>, 'query'> & { variables?: RefreshMutationVariables }) => (
  <Urql.Mutation {...props} query={RefreshDocument} />
);


export function useRefreshMutation() {
  return Urql.useMutation<RefreshMutation, RefreshMutationVariables>(RefreshDocument);
};