import * as React from 'react'
import * as styles from './portalGenerate.scss'
import { AppScreen } from '../appScreen/appScreen'
import { AppLinks } from '../../config'
import * as Yup from 'yup'
import { Field, Form, Formik } from 'formik'
import { LabeledInput } from '../labeledInput/labeledInput'
import { useCallback } from 'react'
import { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'
import moment from 'moment-timezone'
import { useUser } from '../../../auth/hooks/useUser'
import { ReactSelect } from '../reactSelect/reactSelect'
import { SetPageTitle } from '../../helpers/setPageTitle'
import { FacilitySelector } from '../codeTesting/facilitySelector'
import { SampleType } from '../samplesList/samples'
import { Checkbox } from '../checkbox/checkbox'
import { PrimaryContent } from '../primaryContent/primaryContent'
import { useApps } from '../../../providers/user'
import { CustomAppFlags } from '../../interfaces/apps'
import { useSnackbar } from 'notistack'
import classNames from 'classnames'
import Select, { MultiValue, ActionMeta } from 'react-select'
import { useGenerateMutation } from '../../mutations/generate.graphql'
import { GenerateInput, Team } from '@graphql-types@'
import { SubmitButton } from '../submitButton/submitButton'

export const slug = (text: string): string => {
    const from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;'
    const to = 'aaaaaeeeeeiiiiooooouuuunc------'

    const newText = text
        .split('')
        .map((letter, i) =>
            letter.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
        )

    return (
        newText
            .toString() // Cast to string
            .toLowerCase() // Convert the string to lowercase letters
            .trim() // Remove whitespace from both sides of a string
            .replace(/\s+/g, '-') // Replace spaces with -
            .replace(/&/g, '-y-') // Replace & with 'and'
            // eslint-disable-next-line no-useless-escape
            .replace(/[^\w\-]+/g, '') // Remove all non-word chars
            // eslint-disable-next-line no-useless-escape
            .replace(/\-\-+/g, '-')
    ) // Replace multiple - with single -
}

export function PortalGenerate(): JSX.Element {
    const [, hasApp] = useApps()
    const [, generate] = useGenerateMutation()
    const [csv, setCSV] = useState({ ready: false, data: [], filename: '' })

    const csvLink = React.useRef<
        CSVLink & HTMLAnchorElement & { link?: HTMLAnchorElement }
    >()

    useEffect(() => {
        if (csvLink && csvLink.current && csv.ready) {
            window.setTimeout(function () {
                csvLink.current.link.click()
            }, 0)
        }
    }, [csv])

    const user = useUser()
    const teams: Team[] = user.teams.edges.map((edge) => edge.node)

    const { enqueueSnackbar } = useSnackbar()

    const onSave = useCallback(
        (values: Partial<GenerateInput>, { setSubmitting }) => {
            const {
                count,
                teamId,
                testType,
                mode,
                facilityId,
                requiresApproval,
                noCard,
                approvalThreshold,
                templates,
            } = values
            const team = teams.find((team) => team.teamId === teamId)

            setSubmitting(true)

            generate({
                count: count,
                teamId: teamId,
                testType: testType,
                mode: mode,
                facilityId: facilityId,
                requiresApproval: requiresApproval,
                noCard: noCard,
                approvalThreshold: approvalThreshold,
                templates: templates,
            }).then((response) => {
                setSubmitting(false)

                if (response.error) {
                    enqueueSnackbar(
                        <span>
                            Could not generate codes, please try again later.
                        </span>,
                        {
                            variant: 'error',
                        }
                    )
                } else {
                    const data = [
                        ['code39', 'text', 'link'],
                        ...response.data.generate.map((c) => [
                            c.code.split('-')[0],
                            c.code,
                            `https://testkit.consentry.com/?kitId=${c.code}`,
                        ]),
                    ]
                    setCSV({
                        ...csv,
                        ready: true,
                        filename: `codes-${slug(team.name)}-${moment().format(
                            'YYYYMMDDTHHmmss'
                        )}-${response.data.generate.length}.csv`,
                        data: data,
                    })

                    enqueueSnackbar(
                        <span>
                            Codes generated for <b>{testType}</b>.
                        </span>,
                        {
                            variant: 'success',
                        }
                    )
                }
            })
        },
        [generate, teams]
    )

    type Option = { label: string; value: string }

    const teamOptions: Option[] = teams.map((team) => ({
        label: team.name,
        value: team.teamId,
    }))

    const typeOptions = [
        { value: SampleType.saliva, label: 'Saliva' },
        { value: SampleType.swab, label: 'Swab' },
        ...(hasApp(CustomAppFlags.RapidAntigen)
            ? [
                  { value: SampleType.ra, label: 'Rapid Antigen' },
                  {
                      value: SampleType.ra_batch,
                      label: 'Rapid Antigen Package',
                  },
              ]
            : []),
    ]

    const modeOptions = [
        { value: 'DEFAULT', label: 'Default' },
        { value: 'SUPERVISOR', label: 'Supervisor' },
        { value: 'USER_ALWAYS_RIGHT', label: 'User is always Right' },
    ]

    const templateOptions = [
        { value: 'atomo', label: 'Atomo' },
        { value: 'roche', label: 'Roche' },
        { value: 'alltest_nasal', label: 'Alltest (nasal)' },
        { value: 'alltest_saliva', label: 'Alltest (saliva)' },
        { value: 'alltestnasalsmall', label: 'Alltest (nasal) small' },
        { value: 'alltestsalivasmall', label: 'Alltest (saliva) small' },
        { value: 'alltest', label: 'Alltest1' },
        { value: 'alltest2', label: 'Alltest2' },
        { value: 'alltest3', label: 'Alltest3' },
        { value: 'immuno', label: 'Immuno' },
        { value: 'bd', label: 'BD' },
        { value: 'abbott', label: 'Abott' },
        { value: 'nhs', label: 'NHS' },
        { value: 'acon', label: 'acon' },
        { value: 'aconnhs', label: 'aconnhs' },
        { value: 'nadal', label: 'nadal' },
        { value: 'siemens_video', label: 'siemens_video' },
        { value: 'lyher', label: 'lyher'},
    ]

    return (
        <AppScreen activeAppLink={AppLinks.generate}>
            <PrimaryContent>
                <SetPageTitle title="Generator" />
                <div className="container">
                    <div>
                        <h2>Code generator</h2>
                        <p>Generate codes for use with Consentry.</p>
                        <div className="row">
                            <div className="col-lg-4 col-12">
                                <Formik
                                    initialValues={{
                                        count: 100,
                                        teamId: user.currentTeam.teamId,
                                        requiresApproval: true,
                                        noCard: false,
                                        approvalThreshold: 2,
                                        mode: 'DEFAULT',
                                        templates: [],
                                    }}
                                    validateOnMount={true}
                                    enableReinitialize
                                    onSubmit={onSave}
                                    validationSchema={Yup.object().shape({
                                        count: Yup.number()
                                            .required()
                                            .positive()
                                            .integer(),
                                        teamId: Yup.string().required(),
                                        facilityId: Yup.string().nullable(),
                                        testType: Yup.string().required(),
                                        requiresApproval: Yup.boolean(),
                                        mode: Yup.string(),
                                        noCard: Yup.boolean(),
                                        approvalThreshold: Yup.number().nullable(),
                                        templates: Yup.array().of(Yup.string()),
                                    })}
                                >
                                    {({
                                        values,
                                        errors,
                                        isSubmitting,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        isValid,
                                        setFieldValue,
                                        setFieldTouched,
                                    }) => (
                                        <Form>
                                            <fieldset className="mb-4">
                                                <LabeledInput
                                                    name="count"
                                                    title="Count"
                                                    helpText="Number of codes to generate"
                                                    value={values.count}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={errors.count}
                                                    touched={touched.count}
                                                />
                                                <Field
                                                    name="teamId"
                                                    component={({
                                                        field,
                                                        form,
                                                    }) => (
                                                        <ReactSelect
                                                            name="teamId"
                                                            label="Codes generated for.."
                                                            isMulti={false}
                                                            className="mb-2"
                                                            options={teamOptions.sort(
                                                                (a, b) =>
                                                                    a.label >
                                                                    b.label
                                                                        ? 1
                                                                        : -1
                                                            )}
                                                            value={teamOptions.find(
                                                                (option) =>
                                                                    option.value ===
                                                                    field.value
                                                            )}
                                                            onChange={(
                                                                option: Option
                                                            ) =>
                                                                form.setFieldValue(
                                                                    field.name,
                                                                    option.value
                                                                )
                                                            }
                                                            onBlur={
                                                                field.onBlur
                                                            }
                                                        />
                                                    )}
                                                />

                                                <Field
                                                    name="testType"
                                                    component={({
                                                        field,
                                                        form,
                                                    }) => (
                                                        <ReactSelect
                                                            name="testType"
                                                            label="Choose test type"
                                                            className="mb-2"
                                                            isMulti={false}
                                                            options={
                                                                typeOptions
                                                            }
                                                            value={typeOptions.find(
                                                                (option) =>
                                                                    option.value ===
                                                                    field.value
                                                            )}
                                                            onChange={(
                                                                option: Option
                                                            ) =>
                                                                form.setFieldValue(
                                                                    field.name,
                                                                    option.value
                                                                )
                                                            }
                                                            onBlur={
                                                                field.onBlur
                                                            }
                                                        />
                                                    )}
                                                />

                                                {(values.testType ===
                                                    SampleType.saliva ||
                                                    values.testType ===
                                                        SampleType.swab) && (
                                                    <FacilitySelector
                                                        setFieldValue={
                                                            setFieldValue
                                                        }
                                                        setFieldTouched={
                                                            setFieldTouched
                                                        }
                                                        className={classNames(
                                                            styles.selectWrap,
                                                            'mb-2'
                                                        )}
                                                    />
                                                )}

                                                {(values.testType ===
                                                    SampleType.ra ||
                                                    values.testType ===
                                                        SampleType.ra_batch) && (
                                                    <>
                                                        <div className="row mt-4 mb-4">
                                                            <Checkbox
                                                                label="Requires approval on wrong guess?"
                                                                name="requiresApproval"
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                checked={
                                                                    values.requiresApproval
                                                                }
                                                                toggle
                                                                className=""
                                                            />
                                                        </div>
                                                        {hasApp(
                                                            CustomAppFlags.NoCard
                                                        ) && (
                                                            <div className="row mb-4">
                                                                <Checkbox
                                                                    label={
                                                                        <>
                                                                            Use
                                                                            experimental{' '}
                                                                            <b>
                                                                                no
                                                                                card
                                                                            </b>{' '}
                                                                            detection
                                                                        </>
                                                                    }
                                                                    name="noCard"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    checked={
                                                                        values.noCard
                                                                    }
                                                                    toggle
                                                                    className=""
                                                                />
                                                            </div>
                                                        )}
                                                        <LabeledInput
                                                            name="approvalThreshold"
                                                            title="Approval threshold"
                                                            helpText="Threshold to require manual approval"
                                                            value={
                                                                values.approvalThreshold
                                                            }
                                                            onChange={
                                                                handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            error={
                                                                errors.approvalThreshold
                                                            }
                                                            touched={
                                                                touched.approvalThreshold
                                                            }
                                                        />

                                                        <Field
                                                            name="mode"
                                                            component={({
                                                                field,
                                                                form,
                                                            }) => (
                                                                <ReactSelect
                                                                    name="mode"
                                                                    label="Choose mode"
                                                                    className="mb-2"
                                                                    isMulti={
                                                                        false
                                                                    }
                                                                    options={
                                                                        modeOptions
                                                                    }
                                                                    value={modeOptions.find(
                                                                        (
                                                                            option
                                                                        ) =>
                                                                            option.value ===
                                                                            field.value
                                                                    )}
                                                                    onChange={(
                                                                        option: Option
                                                                    ) =>
                                                                        form.setFieldValue(
                                                                            field.name,
                                                                            option.value
                                                                        )
                                                                    }
                                                                    onBlur={
                                                                        field.onBlur
                                                                    }
                                                                />
                                                            )}
                                                        />
                                                        <div
                                                            className={classNames(
                                                                'row',
                                                                'mb-2'
                                                            )}
                                                        >
                                                            <label className="form-label">
                                                                Template(s)
                                                            </label>
                                                            <Field
                                                                name="templates"
                                                                component={({
                                                                    field,
                                                                    form,
                                                                }) => (
                                                                    <Select
                                                                        defaultValue={
                                                                            field.value
                                                                        }
                                                                        isMulti
                                                                        name="templates"
                                                                        options={
                                                                            templateOptions
                                                                        }
                                                                        className="basic-multi-select"
                                                                        classNamePrefix="select"
                                                                        value={field.value.map(
                                                                            (
                                                                                template
                                                                            ) =>
                                                                                templateOptions.find(
                                                                                    (
                                                                                        option
                                                                                    ) =>
                                                                                        option.value ===
                                                                                        template
                                                                                )
                                                                        )}
                                                                        onChange={(
                                                                            option: MultiValue<Option>,
                                                                            {
                                                                                action,
                                                                            }: ActionMeta<unknown>
                                                                        ) => {
                                                                            form.setFieldValue(
                                                                                field.name,
                                                                                [
                                                                                    ...option.map(
                                                                                        (
                                                                                            option
                                                                                        ) =>
                                                                                            option.value
                                                                                    ),
                                                                                ]
                                                                            )
                                                                        }}
                                                                        onBlur={() =>
                                                                            setFieldTouched(
                                                                                field.name,
                                                                                true
                                                                            )
                                                                        }
                                                                    />
                                                                )}
                                                            />
                                                            <small className="form-text text-muted">
                                                                {
                                                                    'The templates to use for this batch, if none selected, the template will be detected.'
                                                                }
                                                            </small>
                                                        </div>
                                                    </>
                                                )}

                                                {csv.ready}
                                                {csv.ready && (
                                                    <CSVLink
                                                        className={
                                                            styles.hidden
                                                        }
                                                        data={csv.data}
                                                        filename={csv.filename}
                                                        ref={csvLink}
                                                        target="_blank"
                                                    >
                                                        download {csv.filename}
                                                    </CSVLink>
                                                )}
                                            </fieldset>
                                            <SubmitButton
                                                title="Generate"
                                                saving={isSubmitting}
                                                disabled={
                                                    !isValid || isSubmitting
                                                }
                                                icon="Coffee"
                                                iconOnRight
                                            />
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </PrimaryContent>
        </AppScreen>
    )
}
