// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".codeTesting__container--b9WqA{display:flex}.codeTesting__imgCol--UvJLB{width:380px;height:380px;padding:2rem;border:2px dashed var(--border-color);margin-right:1.5rem;position:relative;display:flex;justify-content:center;align-items:center;border-radius:var(--border-radius)}.codeTesting__label--cmaV0{position:absolute;top:1rem;left:1rem;z-index:3;font-size:1.25rem}.codeTesting__selectWrap--geLjh{max-width:320px}", "",{"version":3,"sources":["webpack://./src/app/portal/components/codeTesting/codeTesting.scss"],"names":[],"mappings":"AAAA,+BACI,YAAA,CAGJ,4BACI,WAAA,CACA,YAAA,CACA,YAAA,CACA,qCAAA,CACA,mBAAA,CACA,iBAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,kCAAA,CAEJ,2BACI,iBAAA,CACA,QAAA,CACA,SAAA,CACA,SAAA,CACA,iBAAA,CAGJ,gCACI,eAAA","sourcesContent":[".container {\n    display: flex;\n}\n\n.imgCol {\n    width: 380px;\n    height: 380px;\n    padding: 2rem;\n    border: 2px dashed var(--border-color);\n    margin-right: 1.5rem;\n    position: relative;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border-radius: var(--border-radius);\n}\n.label {\n    position: absolute;\n    top: 1rem;\n    left: 1rem;\n    z-index: 3;\n    font-size: 1.25rem;\n}\n\n.selectWrap {\n    max-width: 320px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "codeTesting__container--b9WqA",
	"imgCol": "codeTesting__imgCol--UvJLB",
	"label": "codeTesting__label--cmaV0",
	"selectWrap": "codeTesting__selectWrap--geLjh"
};
export default ___CSS_LOADER_EXPORT___;
