import * as styles from './consentryDashboard.scss'
import { useApps } from '../../../providers/user'
import { CustomAppFlags } from '../../interfaces/apps'
import { PermissionComponent } from '~/app/components/permission/permission'
import { Paths } from './../../helpers/routePaths'
import { SupportTile } from '~/app/support/components/supportTile/supportTile'

export function Welcome(): JSX.Element {
    const [, hasApp] = useApps()

    return (
        <div className={styles.container}>
            <div className="mt-4 row">
                <div className="d-flex">

                    <PermissionComponent
                        service= {'portal'}
                        action= {'samples:ViewData'}
                    >  
                        <SupportTile
                            title={'Samples'}
                            description={
                                ''
                            }
                            icon={'Activity'}
                            to={Paths.samples}
                        />
                    </PermissionComponent>

                    <PermissionComponent
                        service= {'sample-approvals'}
                        action= {'images:ViewData'}
                    > 
                    {hasApp(CustomAppFlags.SampleApprovals) && (
                        <SupportTile
                            title={'Images'}
                            description={
                                ''
                            }
                            icon={'Image'}
                            to={Paths.images}
                        />
                     )}
                    </PermissionComponent>
                    
                    <PermissionComponent
                        service= {'batches'}
                        action= {'batches:ViewData'}
                    >  
                    {hasApp(CustomAppFlags.Batches) && (
                        <SupportTile
                            title={'Batches'}
                            description={
                                ''
                            }
                            icon={'Grid'}
                            to={Paths.batches}
                        />
                    )}
                    </PermissionComponent>

                    <PermissionComponent
                        service= {'sample-approvals'}
                        action= {'approvals:ViewEntry'}
                    >  
                    {hasApp(CustomAppFlags.SampleApprovals) && (
                        <SupportTile
                            title={'Approvals'}
                            description={
                                ''
                            }
                            icon={'CheckCircle'}
                            to={Paths.approvals}
                        />
                        )}
                    </PermissionComponent>

                    </div>
            </div>

            <div className="row">
                <div className="d-flex">

                <PermissionComponent
                        service= {'portal'}
                        action= {'generator:ViewGenerator'}
                    >  
                        <SupportTile
                            title={'Generator'}
                            description={
                                ''
                            }
                            icon={'Anchor'}
                            to={Paths.generate}
                        />
                    </PermissionComponent>

                    <PermissionComponent
                        service= {'upload-lab-export'}
                        action= {'labupload:View'}
                    >  
                    {hasApp(CustomAppFlags.UploadLabExport) && (
                        <SupportTile
                            title={'Upload'}
                            description={
                                ''
                            }
                            icon={'UploadCloud'}
                            to={Paths.upload}
                        />
                    )}
                    </PermissionComponent>

                    <PermissionComponent
                        service= {'code-testing'}
                        action= {'testing:View'}
                    >  
                    {hasApp(CustomAppFlags.CodeTesting) && (
                        <SupportTile
                            title={'Testing'}
                            description={
                                ''
                            }
                            icon={'Terminal'}
                            to={Paths.testing}
                        />
                    )}
                    </PermissionComponent>

                    <SupportTile
                        title={'Settings'}
                        description={
                            ''
                        }
                        icon={'Settings'}
                        to={Paths.settingsAccount}
                    />

                </div>
            </div>

        </div>


    )
}