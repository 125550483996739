import * as React from 'react'
import * as styles from './mobileBreadcrumbs.scss'
import { useContext } from 'react'
import { Breadcrumbs } from '../appScreen/appScreen'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { Icon } from '../icon/ficon'

export function MobileBreadcrumbs() {
    const breadcrumbs = useContext(Breadcrumbs)

    if (!breadcrumbs) {
        return null
    }

    return (
        <nav className={styles.nav}>
            <ol className={styles.list}>
                {breadcrumbs.map((breadcrumb, i) => {
                    const isLast: boolean = i === breadcrumbs.length - 1

                    return (
                        <li
                            className={classNames(styles.item, {
                                [styles.active]: isLast,
                            })}
                            key={breadcrumb.link}
                        >
                            <Icon
                                name="ArrowRight"
                                small={true}
                                className={styles.icon}
                            />

                            {isLast && breadcrumb.label}
                            {!isLast && (
                                <Link to={breadcrumb.link}>
                                    {breadcrumb.label}
                                </Link>
                            )}
                        </li>
                    )
                })}
            </ol>
        </nav>
    )
}
