/* df39f4e6d46a08c5f560ac8026c5136eaa4afb32
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../__types__';

import gql from 'graphql-tag';
import * as React from 'react';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type VerifyEmailMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type VerifyEmailMutation = { __typename?: 'Mutation', verifyEmail: boolean };


export const VerifyEmailDocument = gql`
    mutation verifyEmail {
  verifyEmail
}
    `;

export const VerifyEmailComponent = (props: Omit<Urql.MutationProps<VerifyEmailMutation, VerifyEmailMutationVariables>, 'query'> & { variables?: VerifyEmailMutationVariables }) => (
  <Urql.Mutation {...props} query={VerifyEmailDocument} />
);


export function useVerifyEmailMutation() {
  return Urql.useMutation<VerifyEmailMutation, VerifyEmailMutationVariables>(VerifyEmailDocument);
};