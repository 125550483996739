import * as React from 'react'
import { useFormatMessage } from '../../../portal/helpers/intlContext'
import { Formik } from 'formik'
import { useCallback, useState } from 'react'
import * as Yup from 'yup'
import { validateForm } from '../../../portal/helpers/validateForm'
import { SubmitButton } from '../../../portal/components/submitButton/submitButton'
import { PreLogin } from './preLogin/preLogin'
import * as styles from './loginForm.scss'
import {
    ButtonLink,
    ButtonTypes,
} from '../../../portal/components/button/button'
import classNames from 'classnames'
import { IconTextInput } from '../../../portal/components/iconTextInput/iconTextInput'
import { useResetPasswordMutation } from '../../mutations/resetPassword.graphql'

export function ForgotPasswordForm() {
    const t = useFormatMessage()

    const [isSent, setIsSent] = useState<boolean>(false)
    const [notFoundEmails, setNotFoundEmails] = useState<string[]>([])

    const [, resetPassword] = useResetPasswordMutation()

    const onSubmit = useCallback((data, { setFieldError, setSubmitting }) => {
        const { email } = data

        resetPassword({ email }).then((response) => {
            if (response.error) {
                setNotFoundEmails([email])
                setFieldError('email', 'Email not found')
            } else {
                setIsSent(true)
            }

            setSubmitting(false)
        })
    }, [])

    const getValidationSchema = useCallback((values) => {
        return Yup.object().shape({
            email: Yup.string()
                .email(t('form.emailInvalid'))
                .required(t('form.required'))
                .notOneOf(notFoundEmails, 'Email not found'),
        })
    }, [])

    return (
        <PreLogin>
            <div className={styles.container}>
                <h3>Forgot your password?</h3>
                {isSent && (
                    <p>
                        A link to reset your password has been sent to your
                        email. Please check your inbox.
                    </p>
                )}

                {!isSent && (
                    <Formik
                        initialValues={{
                            email: '',
                        }}
                        validate={(values) =>
                            validateForm(getValidationSchema, values)
                        }
                        onSubmit={onSubmit}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            dirty,
                            isSubmitting,
                            isValid,
                        }) => (
                            <form
                                className={styles.form}
                                onSubmit={handleSubmit}
                            >
                                <IconTextInput
                                    icon="Mail"
                                    name="email"
                                    placeholder="Insert your email address"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    error={errors.email}
                                    touched={touched.email}
                                />

                                <div className={styles.btnWrap}>
                                    <div
                                        className={classNames(
                                            styles.buttons,
                                            'btn-group'
                                        )}
                                        role="group"
                                    >
                                        <ButtonLink
                                            to="/login"
                                            type={ButtonTypes.secondary}
                                        >
                                            Cancel
                                        </ButtonLink>
                                        <SubmitButton
                                            title="Reset password"
                                            submitting={isSubmitting}
                                            iconOnRight={true}
                                            disabled={
                                                !isValid ||
                                                !dirty ||
                                                isSubmitting
                                            }
                                        />
                                    </div>
                                </div>
                            </form>
                        )}
                    </Formik>
                )}
                {/* <p className={styles.text}>
					<Link to="/login">Back to login</Link>
				</p> */}
            </div>
        </PreLogin>
    )
}
