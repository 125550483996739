import { useMutation } from 'urql'

interface Variables {
    input: {
        testKitId: string
        passportName: string
        passportNumber: number
    }
}

const query = `
mutation Certificate(
  $input: CertificateInput!
) {
  certificate(
      input: $input
  ) {
      blob
  }
}
`

export function useGenerateCertificate() {
    return useMutation<any, Variables>(query)
}
