import * as styles from './consentryDashboard.scss'
import { AppLinks } from '../../config'
import { SamplesAggLineChart } from './samplesAggLineChart'
import { AppScreen } from '../appScreen/appScreen'
import { SetPageTitle } from '../../helpers/setPageTitle'
import { time } from './momentHelpers'
import { Tabs } from '../tabs/tabs'
import { Tab } from '../tab/tab'
import { CountList } from './countList'
import { CountBadge } from './countBadge'
import {
    useSamplesPending,
    useSamplesPendingLab,
    useSamplesReceivedLabToday,
    useSamplesResultAgg,
    useSamplesStatusAgg,
    useRaSamplesResultAgg,
    useRaSamplesStatusAgg,
} from '../samplesList/queries/useSampleCounts'
import { SamplesPieChart } from './samplesPieChart'
import { useApps, usePermissions } from '../../../providers/user'
import { CustomAppFlags } from '../../interfaces/apps'
import { PermissionComponent } from '~/app/components/permission/permission'
import { Welcome } from './welcome'

export function ConsentryDashboard(): JSX.Element {
    const [, hasApp] = useApps()

    const [, hasPermission] = usePermissions()

    // TODO-TG: Could be refactored into the CountBadge itself (?)
    // TODO-TG: Possibly also polling for this? Might need to be inside the hook itself.
    const samplesPending: number = useSamplesPending() ?? 0
    const receivedLabToday: number = useSamplesReceivedLabToday() ?? 0
    const samplesPendingLab: number = useSamplesPendingLab() ?? 0
    const resultPieData = useSamplesResultAgg()
    const statusPieData = useSamplesStatusAgg()
    const resultRaPieData = useRaSamplesResultAgg()
    const statusRaPieData = useRaSamplesStatusAgg()

    return (
        <AppScreen activeAppLink={AppLinks.activity}>
            <SetPageTitle title="Dashboard" />
            <div className={styles.container}>
                <Tabs centerNav className={styles.tabs}>
                    <Tab title="Welcome" className="p-3 overflow-visible">
                        <Welcome />
                    </Tab>

                { hasPermission({ service: "portal", action: "dashboard:ViewOverview" }) && (
                    <Tab title="Overview" className="p-3 overflow-visible">
                        <CountList field="CREATED_AT" key="overview-created" />

                        <div className="row">
                            {statusPieData && (
                                <div className="col-6">
                                    <div style={{ height: '30vh' }}>
                                        <SamplesPieChart
                                            title="Status (uncompleted)"
                                            data={statusPieData}
                                            disableTimeFilter
                                        />
                                    </div>
                                </div>
                            )}
                            {resultPieData && (
                                <div className="col-6">
                                    <div style={{ height: '30vh' }}>
                                        <SamplesPieChart
                                            title="Results (completed)"
                                            data={resultPieData}
                                            disableTimeFilter
                                        />
                                    </div>
                                </div>
                            )}
                        </div>

                        <SamplesAggLineChart
                            title="Submitted (all)"
                            field="CREATED_AT"
                            key="all-submitted"
                        />
                    </Tab>
                )}
                {hasPermission({ service: "portal", action: "dashboard:ViewPCR" }) && (
                    <Tab title="PCR" className="p-3 overflow-visible">
                        <>
                            <div className={styles.countWrap}>
                                <CountBadge
                                    title="Total pending"
                                    value={samplesPending}
                                    key="samples-pending"
                                />
                                <CountBadge
                                    title="Received today in Lab"
                                    value={receivedLabToday}
                                    key="received-lab-today"
                                />
                                <CountBadge
                                    title="Pending in Lab"
                                    value={samplesPendingLab}
                                    key="samples-pending-lab"
                                />
                            </div>
                            <CountList
                                field="CREATED_AT"
                                type="PCR"
                                key="pcr-created"
                            />
                            <CountList
                                field="TESTED_AT"
                                type="PCR"
                                key="pcr-tested"
                            />
                        </>
                        <SamplesAggLineChart
                            title="Submitted (pcr)"
                            field="CREATED_AT"
                            type="PCR"
                            key="pcr-submitted"
                        />
                        <SamplesAggLineChart
                            title="Tested (pcr)"
                            field="TESTED_AT"
                            type="PCR"
                            key="pcr-tested"
                        />
                    </Tab>
                    )}
                    {hasPermission( { service: "rapid-antigen", action: "dashboard:ViewRA"}) &&
                        hasApp(CustomAppFlags.RapidAntigen) && 
                         (
                            <Tab
                                title="Rapid Antigen"
                                className="p-3 overflow-visible"
                            >
                                <CountList
                                    field="CREATED_AT"
                                    type="RA"
                                    key="counter-ra-created"
                                />

                                <div className="row">
                                    {statusRaPieData && (
                                        <div className="col-6">
                                            <div style={{ height: '30vh' }}>
                                                <SamplesPieChart
                                                    title="Status (uncompleted)"
                                                    info="The status over the total of uncompleted rapid-antigen tests."
                                                    data={statusRaPieData}
                                                    disableTimeFilter
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {resultRaPieData && (
                                        <div className="col-6">
                                            <div style={{ height: '30vh' }}>
                                                <SamplesPieChart
                                                    title="Results (completed)"
                                                    info="The results over the total of completed rapid-antigen tests."
                                                    data={resultRaPieData}
                                                    disableTimeFilter
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className="row">
                                    <div className="col-6">
                                        <SamplesAggLineChart
                                            title="Submitted (this week)"
                                            info={'The week starts on Monday.'}
                                            field="CREATED_AT"
                                            type="RA"
                                            where={{
                                                field: 'createdAt',
                                                gt: time.thisWeek.toISOString(),
                                            }}
                                            disableTimeFilter
                                        />
                                    </div>
                                    <div className="col-6">
                                        <SamplesAggLineChart
                                            title="Submitted (last week)"
                                            info={'The week starts on Monday.'}
                                            field="CREATED_AT"
                                            type="RA"
                                            disableTimeFilter
                                            where={[
                                                {
                                                    field: 'createdAt',
                                                    gt: time.lastWeekMin.toISOString(),
                                                },
                                                {
                                                    field: 'createdAt',
                                                    lt: time.lastWeekMax.toISOString(),
                                                },
                                            ]}
                                        />
                                    </div>
                                </div>
                                {/*<div className="row">
                                    <div className="col-6">
                                        <SamplesAggLineChart
                                            title="Tested (this week)"
                                            info={'The week starts on Monday.'}
                                            field="TESTED_AT"
                                            type="RA"
                                            where={{
                                                field: 'testedAt',
                                                gt: time.thisWeek.toISOString(),
                                            }}
                                            disableTimeFilter
                                        />
                                    </div>
                                    <div className="col-6">
                                        <SamplesAggLineChart
                                            title="Tested (last week)"
                                            info={'The week starts on Monday.'}
                                            field="TESTED_AT"
                                            type="RA"
                                            disableTimeFilter
                                            where={[
                                                {
                                                    field: 'testedAt',
                                                    gt: time.lastWeekMin.toISOString(),
                                                },
                                                {
                                                    field: 'testedAt',
                                                    lt: time.lastWeekMax.toISOString(),
                                                },
                                            ]}
                                        />
                                    </div>
                                </div>
                                */}

                                <SamplesAggLineChart
                                    title="Submitted (ra)"
                                    field="CREATED_AT"
                                    type="RA"
                                    key="linechart-ra-submitted"
                                />
                                {/*}
                                <SamplesAggLineChart
                                    title="Tested (ra)"
                                    field="TESTED_AT"
                                    type="RA"
                                    key="linechart-ra-tested"
                                />
                                */}
                            </Tab>
                    )}
                </Tabs>
            </div>
        </AppScreen>
    )
}
