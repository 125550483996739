import { createContext, ReactNode, useContext, useState } from 'react'

// https://dev.to/finiam/predictable-react-authentication-with-the-context-api-g10
const PopupContext = createContext(
    {} as {
        value: ReactNode
        clearPopup: () => void
        setPopup: (popup: ReactNode) => void
    }
)

// Export the provider as we need to wrap the entire app with it
export function PopupProvider({
    children,
}: {
    children: ReactNode
}): JSX.Element {
    const [value, setValue] = useState<ReactNode>()
    const setPopup = (popup: ReactNode) => setValue(popup)
    const clearPopup = () => setValue(null)

    return (
        <PopupContext.Provider value={{ value, setPopup, clearPopup }}>
            {children}
        </PopupContext.Provider>
    )
}

export const usePopup = () => useContext(PopupContext)
