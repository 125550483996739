import * as React from 'react'
import { TextInput, TextInputProps } from '../textInput/textInput'
import * as styles from './labeledInput.scss'

interface Props extends TextInputProps {
    disabled?: boolean
    helpText?: string | JSX.Element
    appendText?: string | JSX.Element
}

export function LabeledInput(props: Props): JSX.Element {
    return (
        <div className={styles.container}>
            <label className={styles.label}>{props.title}</label>
            <TextInput {...props} />
            {props.helpText && (
                <small className="form-text text-muted">{props.helpText}</small>
            )}
        </div>
    )
}
