import { Link } from 'react-router-dom'
import * as styles from './supportTile.scss'
import { IconName } from '../../../portal/config'
import { Icon } from '../../../portal/components/icon/ficon'
import classNames from 'classnames'

export interface SupportTileProps {
    title: string
    description?: string
    to: any
    icon?: IconName
    className?: string
}

/**
  Supporttile creates a tile with a big icons in the welcome page.
**/

export function SupportTile(props: SupportTileProps): JSX.Element {
    // TODO NTH show description on hover when available
    const { title, description, to, className } = props
    
    const icon =
        typeof props.icon !== 'undefined' && props.icon != null
            ? props.icon
            : 'BookOpen'

    return (
        <div className={classNames(className, 'flex-grow-1 m-2', styles.section)}>
            <Link 
                to={to} 
                className={classNames('d-flex flex-column align-items-center justify-content-center',styles.box)}
            >
                <div className={classNames("center mt-4", styles.top)}>
                    <Icon name={icon} xl />
                </div>
                <div className={classNames("center", styles.bottom)}>
                    <h1>{title}</h1>
                </div>
            </Link>
        </div>
    )
}
