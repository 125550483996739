import { BasicPopup } from '../../portal/components/basicPopup/basicPopup'
import { useCallback, useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useFormatMessage } from '../../portal/helpers/intlContext'
import { TextInput } from '../../portal/components/textInput/textInput'
import { Button, ButtonTypes } from '../../portal/components/button/button'
import { PopupFooter } from '../../portal/components/popupFooter/popupFooter'
import { usePopup } from '~/app/providers/popup'
import { useRemoveOtpMutation } from '../mutations/removeOTP.graphql'
import { useSnackbar } from 'notistack'

export function DisableOtpForm(): JSX.Element {
    const t = useFormatMessage()
    const [invalidCodes, setInvalidCodes] = useState<string[]>([])

    const validationSchema = Yup.object().shape({
        code: Yup.string()
            .required(t('form.required'))
            .notOneOf(invalidCodes, 'Code is invalid'),
    })

    const [, removeOTP] = useRemoveOtpMutation()

    const { clearPopup } = usePopup()

    const { enqueueSnackbar } = useSnackbar()

    const onSubmit = useCallback((data, { setFieldError }) => {
        removeOTP({ code: data.code }).then((response) => {
            if (response.data) {
                enqueueSnackbar(<span>2FA is now disabled.</span>, {
                    variant: 'success',
                })

                clearPopup()
            } else {
                setInvalidCodes([data.code])
                setFieldError('code', 'Code is invalid')
            }
        })
    }, [])

    return (
        <Formik
            initialValues={{ code: '' }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isValid,
            }) => (
                <BasicPopup title="Disable 2FA" size="s">
                    <p>Are you sure you want to disable 2FA authentication?</p>
                    <form onSubmit={handleSubmit}>
                        <label className="form-label">Token</label>
                        <TextInput
                            title="Token"
                            name="code"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.code}
                            error={errors.code}
                            touched={touched.code}
                            autocomplete="one-time-code"
                            autoFocus
                        />

                        <PopupFooter>
                            <Button
                                disabled={!isValid}
                                type={ButtonTypes.primary}
                                onClick={handleSubmit}
                                submit={true}
                            >
                                Disable
                            </Button>
                        </PopupFooter>
                    </form>
                </BasicPopup>
            )}
        </Formik>
    )
}
