import { useMutation } from 'urql'
import { SampleStatus, SampleResult } from '../../samplesList/samples'

interface Variables {
    input: {
        code: string
        status: SampleStatus
        result: SampleResult
        facilityId?: string
        message?: string
    }
}

const query = `
mutation updateSample(
  $input: UpdateSampleInput!
) {
  updateSample(
  	input: $input
  ) {
    code
	status
	result
  }
}
`

export function useUpdateSample() {
    return useMutation<any, Variables>(query)
}
