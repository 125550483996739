import * as React from 'react'
import * as styles from './popupFooter.scss'
import classNames from 'classnames'

interface Props {
    children: React.ReactNode
    noNegativeMargin?: boolean
}

export function PopupFooter(props: Props) {
    return (
        <div
            className={classNames(styles.footer, {
                [styles.noNegativeMargin]: props.noNegativeMargin,
            })}
        >
            {props.children}
        </div>
    )
}
