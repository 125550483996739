import React from 'react'
import classNames from 'classnames'
import Magnifier from 'react-magnifier'
import Pagination from '@material-ui/lab/Pagination'
import * as styles from './approvalSection.scss'
import { BasicPopup } from '../basicPopup/basicPopup'
import { LoadingSkeleton } from '../approvalEdit/approvalEdit'
import { Checkbox } from '../checkbox/checkbox'

export function ScreenCalibrationHelper() : JSX.Element {
   
    const images = [
       require("./images/229.jpg"),
       require("./images/243.jpg"),
       require("./images/247.jpg"),
       require("./images/255.jpg"),
       require("./images/259.jpg"),
       require("./images/263.jpg"),
   ];

    const [currentPage, setCurrentPage] = React.useState<number>(
        images?.findIndex((i) => images[i] === images[i])
    )

    const onPaginationChange = React.useCallback(
        (event: object, page: number) => {
            setCurrentPage(page - 1)
        },
        []
    )

    const [ checked, setChecked ] = React.useState(false)
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked)
    }

    const filter = (checked ? styles.raFilters : null) 

    return (
        <BasicPopup title="Screen Calibration Helper" size="xl">
            <div className="container">
                <div className='row'>
                    <div className="col-md-8">
                            <LoadingSkeleton loading={(!images)}>
                                <Magnifier
                                    src={images[currentPage]}
                                    width={'100%'}
                                    mgShape="square"
                                    className={classNames(filter, styles.magnifierWrap)}
                                    mgShowOverflow={false}
                                />
                            </LoadingSkeleton>
                        </div>
                    <div className="col-md-4">
                        <div className='mb-4 ms-1'>
                            <Pagination
                                count={(images.length)}
                                onChange={onPaginationChange}
                                page={currentPage + 1}
                                size="small"
                                boundaryCount={1}
                                className={classNames(
                                    'center',
                                    styles.boxContent,
                                    styles.pagination
                                )}
                            />
                        </div>
                        <div className={classNames(styles.checkbox, 'mb-4')}>
                            <Checkbox
                                label={"Apply image filter"}
                                name={'Toggle filter'}
                                checked={checked}
                                onChange={handleChange}
                                toggle
                            />
                        </div>
                        <hr className='mt-4 mb-4'></hr>
                        <div className='my-6'>
                            <p>
                                In some cases the result of a rapid antigen test
                                is positive while the indicator is not or barely 
                                visible to the eye.
                                <br className='mb-4'></br>
                                The <span className='fw-bold'>image filter </span> 
                                helps to detect the test result in the case of a 
                                very faint line.
                                The filter will alter the colors of the image making 
                                the faint line more visible.
                                <br className='mb-4'></br>
                                The images available in this calibration tool all 
                                have a positive result but the test line is barely visible.
                                Adjust your screen <span className='fw-bold'> brightness </span>
                                and <span className='fw-bold'>contrast </span> 
                                in the settings menu of the screen so you can detect 
                                the faint line while the image filter is active.
                                <br className='mb-4'></br>
                                Hover over the image to see a more detailed view 
                                in the magnifying glass. Sometimes the faint line 
                                is only visible with the filter on as a distorted 
                                line in the pixels of the image.
                                <br className='mb-4'></br>
                                When adjusting the screen will not make the faint line
                                visible with the image filter turned on the screen does 
                                not meet the requirements to do rapid antigen approvals.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </BasicPopup>
    )
}