import * as React from 'react'
import { AppLinks } from '../../config'
import { AppScreen } from '../appScreen/appScreen'
import { SetPageTitle } from '../../helpers/setPageTitle'
import { PrimaryContent } from '../primaryContent/primaryContent'
import * as styles from './codeTesting.scss'
import { Field, Form, Formik } from 'formik'
import { ReactSelect } from '../reactSelect/reactSelect'
import * as Yup from 'yup'
import { useCallback } from 'react'
import { useUser } from '../../../auth/hooks/useUser'
import { Loader } from '../loader/loader'
import {
    SampleEdge,
    SampleResult,
    SampleStatus,
    SampleType,
} from '../samplesList/samples'
import { useUpdateSample } from './mutations/useUpdateSample'
import { useSamplesQuery } from '../samplesList/queries/useSamplesQuery'
import { SubmitButton } from '../submitButton/submitButton'
import { Icon } from '../icon/ficon'
import { LabeledInput } from '../labeledInput/labeledInput'
import { Button, ButtonTypes } from '../button/button'
import {
    getValidatedId,
    InsertAndGenerateQR,
    UpdateInput,
} from './codeTestingHelpers'
import { FacilitySelector } from './facilitySelector'
import moment from 'moment-timezone'
import { generatePath } from 'react-router'
import { Paths } from '../../helpers/routePaths'
import { Checkbox } from '../checkbox/checkbox'
import { useSnackbar } from 'notistack'
import { useGenerateMutation } from '../../mutations/generate.graphql'
import Select, { MultiValue, ActionMeta } from 'react-select'
import { GenerateInput, Team } from '@graphql-types@'
import { CustomAppFlags } from '../../interfaces/apps'
import { useApps } from '~/app/providers/user'
import classNames from 'classnames'

const typeOptions = [
    { value: SampleType.saliva, label: 'Saliva' },
    { value: SampleType.swab, label: 'Swab' },
    { value: SampleType.ra, label: 'Rapid Antigen' },
    { value: SampleType.ra_batch, label: 'Rapid Antigen Package' },
]

const templateOptions = [
    { value: 'atomo', label: 'Atomo' },
    { value: 'roche', label: 'Roche' },
    { value: 'alltest_nasal', label: 'Alltest (nasal)' },
    { value: 'alltest_saliva', label: 'Alltest (saliva)' },
    { value: 'alltestnasalsmall', label: 'Alltest (nasal) small' },
    { value: 'alltestsalivasmall', label: 'Alltest (saliva) small' },
    { value: 'alltest', label: 'Alltest1' },
    { value: 'alltest2', label: 'Alltest2' },
    { value: 'alltest3', label: 'Alltest3' },
    { value: 'immuno', label: 'Immuno' },
    { value: 'bd', label: 'BD' },
    { value: 'abbott', label: 'Abott' },
    { value: 'nhs', label: 'NHS' },
    { value: 'acon', label: 'acon' },
    { value: 'aconnhs', label: 'aconnhs' },
    { value: 'nadal', label: 'nadal' },
    { value: 'siemens_video', label: 'siemens_video' },
    { value: 'lyher', label: 'lyher'},
]

const modeOptions = [
    { value: 'DEFAULT', label: 'Default' },
    { value: 'SUPERVISOR', label: 'Supervisor' },
    { value: 'USER_ALWAYS_RIGHT', label: 'User is always Right' },
]

type Option = {
    label: string
    value: { status: SampleStatus; result: SampleResult }
}

const outcomeOptions: Option[] = [
    // { value: { status: SampleStatus.REQUIRES_APPROVAL }, label: "Requires approval" },
    {
        value: { status: SampleStatus.LAB_RECEIVED, result: null },
        label: 'Manual report',
    },
    {
        value: {
            status: SampleStatus.COMPLETED,
            result: SampleResult.POSITIVE,
        },
        label: 'Positive',
    },
    {
        value: {
            status: SampleStatus.COMPLETED,
            result: SampleResult.NEGATIVE,
        },
        label: 'Negative',
    },
    {
        value: {
            status: SampleStatus.COMPLETED,
            result: SampleResult.INCONCLUSIVE,
        },
        label: 'Inconclusive',
    },
    {
        value: {
            status: SampleStatus.COMPLETED,
            result: SampleResult.REJECTED,
        },
        label: 'Rejected',
    },
    {
        value: {
            status: SampleStatus.RETESTING,
            result: SampleResult.INCONCLUSIVE,
        },
        label: 'Retesting',
    },
]

export function CodeTesting(): JSX.Element {
    const [testKitId, setTestKitId] = React.useState<string>() // Used for QR display
    const [sampleId, setSampleId] = React.useState<string>() // Querying for result
    const [facilityId, setFacilityId] = React.useState<string>()
    const [isSubmitted, setIsSubmitted] = React.useState<boolean>(false)
    const [sampleData, setSampleData] = React.useState<SampleEdge>(null)
    const [testType, setTestType] = React.useState<string>()
    const [resultUpdated, setResultUpdated] = React.useState<boolean>(false)

    const { enqueueSnackbar } = useSnackbar()

    const [, generate] = useGenerateMutation()
    const [, updateSample] = useUpdateSample()

    const user = useUser()
    const team: Team = user.currentTeam

    const onGenerate = useCallback(
        async (input: Partial<GenerateInput>, { setSubmitting }) => {
            setSubmitting(true)

            generate({
                count: 1,
                teamId: team.teamId,
                testType: input.testType,
                mode: input.mode,
                facilityId: input.facilityId,
                requiresApproval: input.requiresApproval,
                noCard: input.noCard,
                approvalThreshold: input.approvalThreshold,
                templates: input.templates,
            }).then(async (response) => {
                setSubmitting(false)

                if (response.error || !response.data) {
                    enqueueSnackbar(
                        <span>
                            Could not generate code, please try again later.
                        </span>,
                        {
                            variant: 'error',
                        }
                    )
                } else {
                    const code = response.data.generate[0].code
                    const qrText = `https://testkit.consentry.com/?kitId=${code}`
                    setFacilityId(input.facilityId)
                    setTestKitId(code)
                    setSampleId(code)
                    setTestType(
                        typeOptions.find(
                            (option) => option.value === input.testType
                        ).label
                    )

                    // If this is a batch, find the first ID and start polling
                    if (
                        typeOptions.find(
                            (option) => option.value === input.testType
                        ).value === SampleType.ra_batch
                    ) {
                        const test = await getValidatedId(code)
                        console.log('Next code in package:', code, test)
                        setSampleId(test)
                    }

                    InsertAndGenerateQR(qrText)

                    enqueueSnackbar(<span>Code is ready.</span>, {
                        variant: 'success',
                    })
                }
            })
        },
        [generate, team]
    )

    // Create request to check on status
    const [{ data }, executeQuery] = useSamplesQuery(
        {
            limit: 1,
            offset: 0,
            where: {
                field: 'code',
                eq: sampleId?.split('-')[0],
            },
        },
        !sampleId || !isSubmitted
    )

    // Poll the API for status on the generated code
    React.useEffect(() => {
        if (sampleId && !isSubmitted) {
            const interval = setInterval(() => {
                executeQuery({ requestPolicy: 'network-only' })
                if (
                    data?.samples?.edges.find(
                        (n) => n.node.code === sampleId.split('-')[0]
                    )
                ) {
                    setIsSubmitted(true)
                    setSampleData(
                        data?.samples?.edges.find(
                            (n) => n.node.code === sampleId.split('-')[0]
                        )
                    )
                }
            }, 5 * 1000)
            return () => clearInterval(interval)
        }
    }, [executeQuery, data, isSubmitted, sampleId])

    // Update the sample to new chosen result/status
    const onUpdateSample = useCallback(
        (input: UpdateInput, { setSubmitting }) => {
            setSubmitting(true)
            updateSample({
                input: {
                    code: sampleId.split('-')[0],
                    status: input.outcome.status,
                    result: input.outcome.result,
                    facilityId: facilityId,
                    message: input.message ?? null,
                },
            }).then((response) => {
                console.log('Response-Update', response)
                if (response.error || !response.data) {
                    enqueueSnackbar(
                        <span>
                            Could not update result, please try again later.
                        </span>,
                        {
                            variant: 'error',
                        }
                    )
                } else {
                    enqueueSnackbar(<span>Result has been updated.</span>, {
                        variant: 'success',
                    })

                    setResultUpdated(true)
                }
                setSubmitting(false)
            })
        },
        [updateSample, sampleId, team, facilityId]
    )

    // For rapid antigen tests
    const onRequiresApproval = () => {
        updateSample({
            input: {
                code: sampleId.split('-')[0],
                status: SampleStatus.REQUIRES_APPROVAL,
                result: sampleData.node.result,
                facilityId: facilityId,
            },
        }).then((response) => {
            console.log('Response-Requires-Approval', response)
            if (response.error || !response.data) {
                enqueueSnackbar(
                    <span>
                        Could not update result, please try again later.
                    </span>,
                    {
                        variant: 'error',
                    }
                )
            } else {
                enqueueSnackbar(<span>Result requires approval now.</span>, {
                    variant: 'success',
                })
            }
        })
    }

    // For batches; gives you the next ID for the batch.
    const onValidateNew = async () => {
        setIsSubmitted(false)
        setSampleId(null)

        const test = await getValidatedId(testKitId)
        console.log('New-Validated as:', testKitId, test)
        setSampleId(test)
    }

    const [, hasApp] = useApps()

    return (
        <AppScreen activeAppLink={AppLinks.testing}>
            <SetPageTitle title="Consentry QA" />
            <PrimaryContent>
                <div className={styles.container}>
                    <div>
                        <div className={styles.imgCol}>
                            {!testKitId && <p>Generate a code to view</p>}
                            <canvas id="canvasQR" hidden={!testKitId}></canvas>
                        </div>

                        <div className="text-center mt-2" hidden={!testKitId}>
                            <p>
                                <strong>
                                    {testKitId}
                                    <br />
                                    {testType}
                                </strong>
                            </p>
                        </div>
                    </div>

                    <div>
                        <section className="mt-4 mb-4">
                            <h2>Testing Consentry</h2>
                            <p>
                                This page allows you to generate a new testkit
                                ID, so that you can scan and test it via the
                                app.
                            </p>
                            <p>
                                Once generated, you can set the result for
                                easing testing (PCR only). This feature should
                                only be used for testing.
                            </p>
                        </section>
                        {!testKitId && (
                            <section>
                                <h4>Generate a new code</h4>
                                <Formik
                                    initialValues={{
                                        testType: null,
                                        facilityId: null,
                                        requiresApproval: true,
                                        noCard: false,
                                        approvalThreshold: 2,
                                        mode: 'DEFAULT',
                                        templates: [],
                                    }}
                                    validateOnMount={true}
                                    enableReinitialize
                                    onSubmit={onGenerate}
                                    validationSchema={Yup.object().shape({
                                        testType: Yup.string().required(),
                                        facilityId: Yup.string().nullable(),
                                        requiresApproval: Yup.boolean(),
                                        mode: Yup.string(),
                                        noCard: Yup.boolean(),
                                        approvalThreshold: Yup.number().nullable(),
                                        templates: Yup.array().of(Yup.string()),
                                    })}
                                >
                                    {({
                                        handleChange,
                                        handleBlur,
                                        errors,
                                        touched,
                                        isValid,
                                        setFieldValue,
                                        setFieldTouched,
                                        isSubmitting,
                                        values,
                                    }) => (
                                        <Form>
                                            <fieldset className="mb-2">
                                                <Field
                                                    name="testType"
                                                    component={({
                                                        field,
                                                        form,
                                                    }) => (
                                                        <ReactSelect
                                                            name="testType"
                                                            label="Choose test type"
                                                            isMulti={false}
                                                            options={
                                                                typeOptions
                                                            }
                                                            value={typeOptions.find(
                                                                (option) =>
                                                                    option.value ===
                                                                    field.value
                                                            )}
                                                            onChange={(
                                                                option: Option
                                                            ) =>
                                                                form.setFieldValue(
                                                                    field.name,
                                                                    option.value
                                                                )
                                                            }
                                                            onBlur={
                                                                field.onBlur
                                                            }
                                                            className={
                                                                styles.selectWrap
                                                            }
                                                        />
                                                    )}
                                                />

                                                {(values.testType ===
                                                    SampleType.saliva ||
                                                    values.testType ===
                                                        SampleType.swab) && (
                                                    <FacilitySelector
                                                        setFieldValue={
                                                            setFieldValue
                                                        }
                                                        setFieldTouched={
                                                            setFieldTouched
                                                        }
                                                        className={classNames(
                                                            styles.selectWrap,
                                                            'mb-2'
                                                        )}
                                                    />
                                                )}

                                                {(values.testType ===
                                                    SampleType.ra ||
                                                    values.testType ===
                                                        SampleType.ra_batch) && (
                                                    <>
                                                        <div className="row mt-4 mb-4">
                                                            <Checkbox
                                                                label="Requires approval on wrong guess?"
                                                                name="requiresApproval"
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                checked={
                                                                    values.requiresApproval
                                                                }
                                                                toggle
                                                                className=""
                                                            />
                                                        </div>
                                                        {hasApp(
                                                            CustomAppFlags.NoCard
                                                        ) && (
                                                            <div className="row mb-4">
                                                                <Checkbox
                                                                    label={
                                                                        <>
                                                                            Use
                                                                            experimental{' '}
                                                                            <b>
                                                                                no
                                                                                card
                                                                            </b>{' '}
                                                                            detection
                                                                        </>
                                                                    }
                                                                    name="noCard"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    checked={
                                                                        values.noCard
                                                                    }
                                                                    toggle
                                                                    className=""
                                                                />
                                                            </div>
                                                        )}
                                                        <LabeledInput
                                                            name="approvalThreshold"
                                                            title="Approval threshold"
                                                            helpText="Threshold to require manual approval"
                                                            value={
                                                                values.approvalThreshold
                                                            }
                                                            onChange={
                                                                handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            error={
                                                                errors.approvalThreshold
                                                            }
                                                            touched={
                                                                touched.approvalThreshold
                                                            }
                                                        />

                                                        <Field
                                                            name="mode"
                                                            component={({
                                                                field,
                                                                form,
                                                            }) => (
                                                                <ReactSelect
                                                                    name="mode"
                                                                    label="Choose mode"
                                                                    className="mb-2"
                                                                    isMulti={
                                                                        false
                                                                    }
                                                                    options={
                                                                        modeOptions
                                                                    }
                                                                    value={modeOptions.find(
                                                                        (
                                                                            option
                                                                        ) =>
                                                                            option.value ===
                                                                            field.value
                                                                    )}
                                                                    onChange={(
                                                                        option: Option
                                                                    ) =>
                                                                        form.setFieldValue(
                                                                            field.name,
                                                                            option.value
                                                                        )
                                                                    }
                                                                    onBlur={
                                                                        field.onBlur
                                                                    }
                                                                />
                                                            )}
                                                        />
                                                        <div
                                                            className={classNames(
                                                                'row',
                                                                'mb-2'
                                                            )}
                                                        >
                                                            <label className="form-label">
                                                                Template(s)
                                                            </label>

                                                            <Field
                                                                name="templates"
                                                                component={({
                                                                    field,
                                                                    form,
                                                                }) => (
                                                                    <Select
                                                                        defaultValue={
                                                                            field.value
                                                                        }
                                                                        isMulti
                                                                        name="templates"
                                                                        options={
                                                                            templateOptions
                                                                        }
                                                                        className="basic-multi-select"
                                                                        classNamePrefix="select"
                                                                        value={field.value.map(
                                                                            (
                                                                                template
                                                                            ) =>
                                                                                templateOptions.find(
                                                                                    (
                                                                                        option
                                                                                    ) =>
                                                                                        option.value ===
                                                                                        template
                                                                                )
                                                                        )}
                                                                        onChange={(
                                                                            option: MultiValue<Option>,
                                                                            {
                                                                                action,
                                                                            }: ActionMeta<unknown>
                                                                        ) => {
                                                                            form.setFieldValue(
                                                                                field.name,
                                                                                [
                                                                                    ...option.map(
                                                                                        (
                                                                                            option
                                                                                        ) =>
                                                                                            option.value
                                                                                    ),
                                                                                ]
                                                                            )
                                                                        }}
                                                                        onBlur={() =>
                                                                            setFieldTouched(
                                                                                field.name,
                                                                                true
                                                                            )
                                                                        }
                                                                    />
                                                                )}
                                                            />
                                                            <small className="form-text text-muted">
                                                                {
                                                                    'The templates to use for this batch, if none selected, the template will be detected.'
                                                                }
                                                            </small>
                                                        </div>
                                                    </>
                                                )}
                                            </fieldset>

                                            <SubmitButton
                                                title="Generate"
                                                saving={isSubmitting}
                                                disabled={
                                                    !isValid || isSubmitting
                                                }
                                                icon="Coffee"
                                                iconOnRight
                                            />
                                        </Form>
                                    )}
                                </Formik>
                            </section>
                        )}

                        {sampleId && !isSubmitted && (
                            <Loader message="Awaiting submit" inline />
                        )}

                        {sampleId && isSubmitted && sampleData && (
                            <section>
                                <h4 className="text-success mb-4">
                                    Submission received via{' '}
                                    {sampleData.node.submittedBy}{' '}
                                    <Icon name="CheckCircle" small />
                                </h4>
                                {(testType === 'Rapid Antigen' ||
                                    testType === 'Rapid Antigen Package') && (
                                    <>
                                        <p>
                                            For Rapid Antigen, your result will
                                            be calculated with image
                                            recognition. Please check samples
                                            page, or request{' '}
                                            <code>/result</code> to view.
                                            <br />
                                            If a <code>guess</code> is
                                            submitted, that does not match below{' '}
                                            <code>result</code>,{' '}
                                            <strong>REQUIRES_APPROVAL</strong>{' '}
                                            status will be applied (or click
                                            below button).
                                            <br />
                                            Result can{' '}
                                            <a
                                                href={generatePath(
                                                    Paths.approvalEdit,
                                                    {
                                                        code: sampleId.split(
                                                            '-'
                                                        )[0],
                                                    }
                                                )}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                be approved here
                                            </a>
                                            , once needed.
                                        </p>
                                        <p>
                                            <Icon
                                                name="Hash"
                                                small
                                                className="me-2"
                                            />
                                            <span className="badge bg-secondary">
                                                {sampleId}
                                            </span>
                                        </p>
                                        <p>
                                            <Icon
                                                name="Calendar"
                                                small
                                                className="me-2"
                                            />
                                            {moment(
                                                sampleData.node.submitted
                                            ).format('DD-MM-YYYY HH:mm')}
                                        </p>
                                        <p>
                                            <Icon
                                                name="Cpu"
                                                small
                                                className="me-2"
                                            />
                                            {sampleData.node.result} |{' '}
                                            {sampleData.node?.score}
                                        </p>
                                        {sampleData.node.image?.template && (
                                            <p>
                                                <Icon
                                                    name="Package"
                                                    small
                                                    className="me-2"
                                                />
                                                {sampleData.node.image.template}
                                            </p>
                                        )}
                                        <Button
                                            type={ButtonTypes.primary}
                                            onClick={onRequiresApproval}
                                            className="mt-3"
                                        >
                                            Set requires approval
                                        </Button>
                                        {/* {testType === "Rapid Antigen Package" && (<Button type={ButtonTypes.primary} onClick={onValidateNew}>Start next ID in package</Button>)} */}
                                    </>
                                )}
                                {testType !== 'Rapid Antigen' &&
                                    testType !== 'Rapid Antigen Package' &&
                                    resultUpdated && (
                                        <h4 className="text-success mb-4">
                                            Result updated{' '}
                                            <Icon name="CheckCircle" small />
                                        </h4>
                                    )}
                                {testType !== 'Rapid Antigen' &&
                                    testType !== 'Rapid Antigen Package' && (
                                        <>
                                            <h4>
                                                Change the result of the sample
                                            </h4>
                                            <Formik
                                                initialValues={{
                                                    outcome: null,
                                                    message: '',
                                                }}
                                                validateOnMount={true}
                                                enableReinitialize
                                                onSubmit={onUpdateSample}
                                                validationSchema={Yup.object().shape(
                                                    {
                                                        outcome: Yup.object().required(),
                                                    }
                                                )}
                                            >
                                                {({
                                                    isSubmitting,
                                                    isValid,
                                                    setFieldValue,
                                                    setFieldTouched,
                                                    handleChange,
                                                    handleBlur,
                                                    values,
                                                    touched,
                                                    errors,
                                                }) => (
                                                    <Form>
                                                        <Field
                                                            name="outcome"
                                                            component={({
                                                                field,
                                                                form,
                                                            }) => (
                                                                <ReactSelect
                                                                    name="outcome"
                                                                    label="Choose outcome"
                                                                    isMulti={
                                                                        false
                                                                    }
                                                                    options={
                                                                        outcomeOptions
                                                                    }
                                                                    value={outcomeOptions.find(
                                                                        (
                                                                            option: Option
                                                                        ) =>
                                                                            option.value ===
                                                                            field.value
                                                                    )}
                                                                    onChange={(
                                                                        option: Option
                                                                    ) =>
                                                                        form.setFieldValue(
                                                                            field.name,
                                                                            option.value
                                                                        )
                                                                    }
                                                                    onBlur={
                                                                        field.onBlur
                                                                    }
                                                                    className={
                                                                        styles.selectWrap
                                                                    }
                                                                />
                                                            )}
                                                        />

                                                        <LabeledInput
                                                            name="message"
                                                            title="Custom message (optional)"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            value={
                                                                values.message
                                                            }
                                                            error={
                                                                errors.message
                                                            }
                                                            touched={
                                                                touched.message
                                                            }
                                                            className={
                                                                styles.selectWrap
                                                            }
                                                        />
                                                        <SubmitButton
                                                            title="Send result"
                                                            saving={
                                                                isSubmitting
                                                            }
                                                            disabled={
                                                                !isValid ||
                                                                isSubmitting
                                                            }
                                                            icon="Send"
                                                            iconOnRight
                                                        />
                                                    </Form>
                                                )}
                                            </Formik>
                                        </>
                                    )}
                            </section>
                        )}
                    </div>
                </div>
            </PrimaryContent>
        </AppScreen>
    )
}
