import * as React from 'react'
import { DisplayDate } from '../../../portal/components/displayDate/displayDate'
import { useGoTo } from '../../../portal/hooks/useGoTo'
import { generatePath } from 'react-router-dom'
import { Paths } from '../../../portal/helpers/routePaths'
import * as styles from './userRow.scss'
import { DisplayRole } from '../../helpers/displayRole'
import { Avatar } from '../avatar/avatar'
import { User } from '@graphql-types@'
import { LoadingSkeleton } from '~/app/components/loadingSkeleton/loadingSkeleton'
import { useUser } from '../../hooks/useUser'
import { usePermissions } from '../../../providers/user'

interface Props {
    user?: User
    currentUserId?: string
    fetching: boolean
}

export function UserRow(props: Props) {
    const { user, currentUserId, fetching } = props

    const currentUser = useUser()
	const notMySelf = user?.userId !== currentUser.userId
    const [, hasPermission] = usePermissions()

    const allowed:boolean = (!notMySelf || hasPermission({ service: 'portal', action: 'team:ViewUserDetail'}))

    const goTo = useGoTo(
        generatePath(Paths.settingsTeamUser, { userId: user?.userId ?? 'none' })
    )

    return (
        <tr onClick={(allowed ? goTo : null)}>
            <td>
                <LoadingSkeleton
                    variant="rect"
                    width={40}
                    height={40}
                    loading={fetching}
                >
                    <Avatar
                        url={user?.avatarUrl.slice(0, -2) + 'identicon'}
                        small
                        className="me-2"
                    />
                </LoadingSkeleton>
            </td>
            <td>
                <LoadingSkeleton variant="text" loading={fetching}>
                    {user?.fullName}
                    {user?.userId === currentUserId && (
                        <span className="ms-1 badge bg-secondary">
                            {"It's"} you
                        </span>
                    )}
                    {user?.otpEnabled && (
                        <span className="ms-1 badge bg-info">2fa</span>
                    )}
                </LoadingSkeleton>
            </td>
            <td>
                <LoadingSkeleton variant="text" loading={fetching}>
                    <DisplayRole roleId={user?.role} />
                </LoadingSkeleton>
            </td>
            <td>
                <LoadingSkeleton variant="text" loading={fetching}>
                    {user?.email}
                </LoadingSkeleton>
            </td>
            <td>
                <LoadingSkeleton variant="text" loading={fetching}>
                    <DisplayDate value={user?.lastLoginDate} small />
                </LoadingSkeleton>
            </td>
        </tr>
    )
}
