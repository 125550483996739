import classNames from 'classnames'
import * as React from 'react'
import * as styles from './tab.scss'

interface Props {
    title: string
    children: React.ReactNode
    className?: string
    displayActive?: boolean
    disabled?: boolean
}

/** Tab content. Used together with `<Tabs>`-component */
export function Tab(props: Props): JSX.Element {
    return (
        <div className={classNames(props.className, styles.content)}>
            {props.children}
        </div>
    )
}
