// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".loginForm__container--giCis{width:100%}.loginForm__container--giCis h3,.loginForm__container--giCis h3+p{text-align:center}.loginForm__btnWrap--shVew{margin:1rem 0 0}.loginForm__btnWrap--shVew .loginForm__buttons--Ti_Ln{display:flex;justify-content:space-around;margin:0 -0.5rem}.loginForm__btnWrap--shVew .loginForm__buttons--Ti_Ln>button,.loginForm__btnWrap--shVew .loginForm__buttons--Ti_Ln>a{width:50%;margin:0 .5rem;display:inline-flex;justify-content:center;align-items:center}.loginForm__form--tvDV3+p{margin:1.5rem 0 0}.loginForm__text--boQML{text-align:left;margin-bottom:0}", "",{"version":3,"sources":["webpack://./src/app/auth/components/loginScreens/loginForm.scss"],"names":[],"mappings":"AAGA,6BACI,UAAA,CACA,kEAEI,iBAAA,CAIR,2BACI,eAAA,CACA,sDACI,YAAA,CACA,4BAAA,CACA,gBAAA,CACA,qHAEI,SAAA,CACA,cAAA,CACA,mBAAA,CACA,sBAAA,CACA,kBAAA,CAKZ,0BACI,iBAAA,CAGJ,wBACI,eAAA,CACA,eAAA","sourcesContent":["@import '../../../../scss/mixins/responsive';\n@import '../../../../scss/_variables';\n\n.container {\n    width: 100%;\n    h3,\n    h3 + p {\n        text-align: center;\n    }\n}\n\n.btnWrap {\n    margin: 1rem 0 0;\n    .buttons {\n        display: flex;\n        justify-content: space-around;\n        margin: 0 -0.5rem;\n        > button,\n        > a {\n            width: 50%;\n            margin: 0 0.5rem;\n            display: inline-flex;\n            justify-content: center;\n            align-items: center;\n        }\n    }\n}\n\n.form + p {\n    margin: 1.5rem 0 0;\n}\n\n.text {\n    text-align: left;\n    margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "loginForm__container--giCis",
	"btnWrap": "loginForm__btnWrap--shVew",
	"buttons": "loginForm__buttons--Ti_Ln",
	"form": "loginForm__form--tvDV3",
	"text": "loginForm__text--boQML"
};
export default ___CSS_LOADER_EXPORT___;
