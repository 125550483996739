// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".popupFooter__footer--fhMpr{display:flex;justify-content:center;flex-shrink:0;padding:.75rem;background:var(--background-lighter);border-top:1px solid var(--border-color);border-bottom-left-radius:var(--border-radius);border-bottom-right-radius:var(--border-radius);margin:1.5rem -1rem -1rem -1rem}.popupFooter__footer--fhMpr>*{margin:0 .25rem}.popupFooter__noNegativeMargin--QdDkX{margin:0 0 -1rem}", "",{"version":3,"sources":["webpack://./src/app/portal/components/popupFooter/popupFooter.scss"],"names":[],"mappings":"AAAA,4BACI,YAAA,CACA,sBAAA,CACA,aAAA,CACA,cAAA,CACA,oCAAA,CACA,wCAAA,CACA,8CAAA,CACA,+CAAA,CACA,+BAAA,CAEA,8BACI,eAAA,CAIR,sCACI,gBAAA","sourcesContent":[".footer {\n    display: flex;\n    justify-content: center;\n    flex-shrink: 0;\n    padding: 0.75rem;\n    background: var(--background-lighter);\n    border-top: 1px solid var(--border-color);\n    border-bottom-left-radius: var(--border-radius);\n    border-bottom-right-radius: var(--border-radius);\n    margin: 1.5rem -1rem -1rem -1rem;\n\n    & > * {\n        margin: 0 0.25rem;\n    }\n}\n\n.noNegativeMargin {\n    margin: 0 0 -1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "popupFooter__footer--fhMpr",
	"noNegativeMargin": "popupFooter__noNegativeMargin--QdDkX"
};
export default ___CSS_LOADER_EXPORT___;
