import { useFormatMessage } from '../../../../portal/helpers/intlContext'
import { Formik } from 'formik'
import { useCallback, useState } from 'react'
import * as Yup from 'yup'
import { validateForm } from '../../../../portal/helpers/validateForm'
import { passwordValidate } from '../../../helpers/passwordValidate'
import { useRouter } from '../../../../portal/hooks/useRouter'
import { SubmitButton } from '../../../../portal/components/submitButton/submitButton'
import { Link } from 'react-router-dom'
import { PreLogin } from '../preLogin/preLogin'
import * as styles from './signUpForm.scss'
import { LabeledInput } from '../../../../portal/components/labeledInput/labeledInput'
import { Paths } from '../../../../portal/helpers/routePaths'
import { JoinTeamInfo } from '../../joinTeamInfo/joinTeamInfo'
import { useTeamInviteStatus } from '../../../hooks/useTeamInviteStatus'
import { useTeamInviteToken, useToken } from '../../../../providers/store'
import { useSignupMutation } from '~/app/auth/mutations/signup.graphql'
import { useSnackbar } from 'notistack'

export function SignUpForm() {
    const t = useFormatMessage()
    const { history } = useRouter()
    const [invalidEmails, setInvalidEmails] = useState<string[]>([])
    const [, signup] = useSignupMutation()

    const { token, error } = useTeamInviteStatus()

    const [, setToken] = useToken()

    const [, setTeamInviteToken] = useTeamInviteToken()

    const { enqueueSnackbar } = useSnackbar()

    const onSubmit = useCallback(
        (data, { setFieldError, setSubmitting }) => {
            data = { ...data }
            delete data.repeatPassword
            const { email } = data
            data.teamName = 'Team ' + data.fullName
            data.token = token

            signup({ input: data }).then((response) => {
                if (response.error) {
                    if (
                        response.error.graphQLErrors &&
                        response.error.graphQLErrors[0] &&
                        response.error.graphQLErrors[0].message ===
                            'user_exists'
                    ) {
                        setFieldError(
                            'email',
                            'This email address is already in use'
                        )
                        setInvalidEmails([email])
                    } else {
                        enqueueSnackbar(
                            <span>
                                Something went wrong, please try again later.
                            </span>,
                            {
                                variant: 'error',
                            }
                        )
                    }

                    setSubmitting(false)
                } else {
                    setTeamInviteToken(null)

                    setToken(response.data.signup)

                    history.push(Paths.activity)
                }
            })
        },
        [token]
    )

    const getValidationSchema = useCallback(
        (values) => {
            return Yup.object().shape({
                fullName: Yup.string().required(t('form.required')),
                email: Yup.string()
                    .required(t('form.required'))
                    .email(t('form.emailInvalid'))
                    .notOneOf(
                        invalidEmails,
                        'This email address is already in use'
                    ),
                password: passwordValidate.required(t('Password is required.')),
                repeatPassword: Yup.string()
                    .required(t('form.required'))
                    .oneOf([values.password], t('form.passwordsMustMatch')),
            })
        },
        [invalidEmails]
    )

    return (
        <PreLogin>
            <h1 className="text-center mb-3">New account</h1>
            <JoinTeamInfo isSignUp={true} />

            {!error && (
                <Formik
                    initialValues={{
                        fullName: '',
                        email: '',
                        password: '',
                        repeatPassword: '',
                    }}
                    validate={(values) =>
                        validateForm(getValidationSchema, values)
                    }
                    onSubmit={onSubmit}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isValid,
                    }) => (
                        <form
                            className={styles.form}
                            action="javascript:;"
                            onSubmit={handleSubmit}
                        >
                            <LabeledInput
                                title="Full name"
                                name="fullName"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.fullName}
                                error={errors.fullName}
                                touched={touched.fullName}
                                autoFocus
                            />

                            <LabeledInput
                                title="Email"
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                error={errors.email}
                                touched={touched.email}
                            />

                            <LabeledInput
                                title="Password"
                                name="password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                                error={errors.password}
                                touched={touched.password}
                                type="password"
                            />

                            <LabeledInput
                                title="Repeat password"
                                name="repeatPassword"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.repeatPassword}
                                error={errors.repeatPassword}
                                touched={touched.repeatPassword}
                                type="password"
                            />

                            <SubmitButton
                                title="Sign up"
                                disabled={!isValid}
                                className={styles.submit}
                            />
                        </form>
                    )}
                </Formik>
            )}

            <p className={styles.text}>
                Already have an account?&nbsp;
                <Link className={styles.textLink} to={Paths.logIn}>
                    Login
                </Link>
            </p>
        </PreLogin>
    )
}
