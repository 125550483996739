import * as Yup from 'yup'

export const regex = RegExp(
    /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/
)

/**
Checks if password is complex enough, a password must contain
- a small character
- a capital character
- a digit
- a special character
- a minimum of 8 characters

Regex test: https://regex101.com/r/yaITfk/1
*/

export const passwordValidate = Yup.string().matches(
    regex,
    'A password should be atleast 8 characters long and contain atleast a number, a letter, a capital letter, and a special character.'
)
