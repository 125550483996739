import { ResponsivePie } from '@nivo/pie'
import classNames from 'classnames'
import numeral from 'numeral'
import { useState } from 'react'
import { useTheme } from '../theme/theme'
import * as styles from './consentryDashboard.scss'
import { TimeFilter } from './samplesAggLineChart'
import { InfoTooltip } from '../InfoTooltip/infoTooltip'

export interface SamplesPieChartProps {
    data: SamplesPieChartData[]
    title: string
    info?: string
    disableTimeFilter?: boolean
}

export interface SamplesPieChartData {
    id: string
    label: string
    value: number
}

export function SamplesPieChart(props: SamplesPieChartProps): JSX.Element {
    const { title, info, data, disableTimeFilter } = props
    const [timeFilter, setTimeFilter] = useState<TimeFilter>(TimeFilter.Month)

    // TODO: Implement timefilter
    const onChangeTimeFilter = (filter: TimeFilter) => {
        setTimeFilter(filter)
        // setConditions(createQueryConditions(props, filter));
        // refresh();
    }

    const theme = useTheme()
    return (
        <div className={styles.chartbox}>
            <div className="d-flex justify-content-between ps-3 pe-3 pt-3">
                <h3>
                    {title}
                    {info != null ? InfoTooltip(info) : null}
                </h3>
                {!disableTimeFilter && (
                    <div className="btn-group d-block btn-group-toggle">
                        <label
                            className={classNames(
                                'btn btn-sm btn-secondary',
                                timeFilter == TimeFilter.Week ? 'active' : null
                            )}
                            onClick={() => onChangeTimeFilter(TimeFilter.Week)}
                        >
                            Week
                        </label>
                        <label
                            className={classNames(
                                'btn btn-sm btn-secondary',
                                timeFilter == TimeFilter.Month ? 'active' : null
                            )}
                            onClick={() => onChangeTimeFilter(TimeFilter.Month)}
                        >
                            Month
                        </label>
                        <label
                            className={classNames(
                                'btn btn-sm btn-secondary',
                                timeFilter == TimeFilter.Quarter
                                    ? 'active'
                                    : null
                            )}
                            onClick={() =>
                                onChangeTimeFilter(TimeFilter.Quarter)
                            }
                        >
                            Quarter
                        </label>
                    </div>
                )}
            </div>

            <div className={styles.chart}>
                <ResponsivePie
                    data={data}
                    margin={{ top: 20, right: 80, bottom: 20, left: 80 }}
                    innerRadius={0.5}
                    padAngle={2}
                    cornerRadius={3}
                    colors={theme.chartColors}
                    borderWidth={1}
                    borderColor={{
                        from: 'color',
                        modifiers: [['darker', 0.2]],
                    }}
                    arcLabelsSkipAngle={10}
                    arcLabelsTextColor="#333333"
                    arcLabel={(e) => numeral(e.value).format('0a')}
                    arcLinkLabelsSkipAngle={10}
                    arcLinkLabelsTextOffset={2}
                    arcLinkLabelsOffset={2}
                    arcLinkLabelsDiagonalLength={10}
                    arcLinkLabelsStraightLength={15}
                    arcLinkLabelsThickness={1}
                    arcLinkLabelsColor={{ from: 'color' }}
                    defs={[
                        {
                            id: 'dots',
                            type: 'patternDots',
                            background: 'inherit',
                            color: 'rgba(255, 255, 255, 0.3)',
                            size: 4,
                            padding: 1,
                            stagger: true,
                        },
                        {
                            id: 'lines',
                            type: 'patternLines',
                            background: 'inherit',
                            color: 'rgba(255, 255, 255, 0.3)',
                            rotation: -45,
                            lineWidth: 6,
                            spacing: 10,
                        },
                    ]}
                />
            </div>
        </div>
    )
}
