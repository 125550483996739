/* df373a1435fef2575413b63216fa003c872caf4d
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../__types__';

import gql from 'graphql-tag';
import * as React from 'react';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ChangeTeamMutationVariables = Types.Exact<{
  teamId: Types.Scalars['ID'];
}>;


export type ChangeTeamMutation = { __typename?: 'Mutation', changeTeam: { __typename?: 'changeTeam', token: string } };


export const ChangeTeamDocument = gql`
    mutation changeTeam($teamId: ID!) {
  changeTeam(teamId: $teamId) {
    token
  }
}
    `;

export const ChangeTeamComponent = (props: Omit<Urql.MutationProps<ChangeTeamMutation, ChangeTeamMutationVariables>, 'query'> & { variables?: ChangeTeamMutationVariables }) => (
  <Urql.Mutation {...props} query={ChangeTeamDocument} />
);


export function useChangeTeamMutation() {
  return Urql.useMutation<ChangeTeamMutation, ChangeTeamMutationVariables>(ChangeTeamDocument);
};