/* e3e5396888797e5139acc40105251fb1fbc2509b
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../__types__';

import gql from 'graphql-tag';
import * as React from 'react';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AuditLogQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int'];
  where?: Types.InputMaybe<Types.OperatorInput>;
}>;


export type AuditLogQuery = { __typename?: 'Query', me: { __typename?: 'User', currentTeam?: { __typename?: 'Team', teamId: string, name: string, auditLog?: { __typename?: 'TeamAuditEntryConnection', edges: Array<{ __typename?: 'TeamAuditEntryEdge', cursor?: string | null, node: { __typename?: 'AuditEntry', action: string, ip: string, createdAt: any, user?: { __typename?: 'User', fullName: string, avatarUrl: string, createdAt: any, email: string, emailConfirmed: boolean, lastLoginDate: any, otpEnabled: boolean, role: string, updatedAt: any, userName: string, userId: string } | null } } | null> } | null } | null } };


export const AuditLogDocument = gql`
    query AuditLog($limit: Int!, $where: OperatorInput) {
  me {
    currentTeam {
      teamId
      name
      auditLog(first: $limit, where: $where, sort: {order: DESCENDING}) {
        edges {
          node {
            ... on AuditEntry {
              action
              ip
              createdAt
              user {
                fullName
                avatarUrl
                createdAt
                email
                emailConfirmed
                lastLoginDate
                otpEnabled
                role
                updatedAt
                userName
                userId
              }
            }
          }
          cursor
        }
      }
    }
  }
}
    `;

export const AuditLogComponent = (props: Omit<Urql.QueryProps<AuditLogQuery, AuditLogQueryVariables>, 'query'> & { variables: AuditLogQueryVariables }) => (
  <Urql.Query {...props} query={AuditLogDocument} />
);


export function useAuditLogQuery(options: Omit<Urql.UseQueryArgs<AuditLogQueryVariables>, 'query'>) {
  return Urql.useQuery<AuditLogQuery>({ query: AuditLogDocument, ...options });
};