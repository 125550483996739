import * as React from 'react'
const logo = require('./dtactLogo.svg')

interface Props {
    className?: string
}

export function Logo(props: Props) {
    return <img src={logo} className={props.className} alt="DTACT logo" />
}
