import * as React from 'react'
import { BasicPopup } from './../basicPopup/basicPopup'
import { Button, ButtonTypes } from './../button/button'
import { Icon } from './../icon/ficon'
import { IconName } from './../../config'
import { useCallback } from 'react'
import { PopupFooter } from './../popupFooter/popupFooter'
import { usePopup } from '~/app/providers/popup'
import { SaveButton } from '../submitButton/submitButton'

interface Props {
    children?: string | JSX.Element
    icon?: IconName
    iconOnRight?: boolean

    onAction?: () => Promise<void>

    buttonType?: ButtonTypes
    className?: string
    disabled?: boolean

    // Popup:
    dialogueTitle: string | JSX.Element
    dialogueText?: string | JSX.Element
    dialogueType?: ButtonTypes
    dialogueIcon?: IconName
}

export function ConfirmAction(props: Props): JSX.Element {
    const { clearPopup, setPopup } = usePopup()

    const onConfirm = useCallback(
        ({ setSubmitting }) => {
            props
                .onAction()
                .then(() => {
                    clearPopup()
                })
                .finally(() => {
                    setSubmitting(false)
                })
        },
        [props.onAction]
    )

    const dialog = (
        <BasicPopup title="Confirm action" size="s">
            <div className="">{props.dialogueTitle}</div>
            <PopupFooter>
                <SaveButton
                    type={
                        props.dialogueType
                            ? props.dialogueType
                            : ButtonTypes.danger
                    }
                    onClick={onConfirm}
                    icon={props.dialogueIcon ? props.dialogueIcon : 'Trash2'}
                    iconOnRight
                >
                    {props.dialogueText || 'Delete'}
                </SaveButton>
            </PopupFooter>
        </BasicPopup>
    )

    const content = props.children ? props.children : <Icon name="Trash2" />

    return (
        <Button
            disabled={props.disabled}
            onClick={() => setPopup(dialog)}
            icon={props.icon}
            iconOnRight={props.iconOnRight}
            type={props.buttonType ? props.buttonType : ButtonTypes.secondary}
            className={props.className}
        >
            {content}
        </Button>
    )
}
