import { RequestPolicy, useQuery } from 'urql'
import { OperatorInput } from '../../../interfaces/operatorInput'

const query = `
query images($limit: Int!, $offset: Int!, $after: String!, $where: OperatorInput) {
    images(first: $limit, offset: $offset, after: $after, where: $where) {
        totalCount
        edges {
            node {
              code
              imageURL
              userAgent
              template
              statusCode
              operation
              score
              qr
              apiVersion
              validationMessage
              expectedConditions
              createdAt
              updatedAt
            }
            cursor
          }
        pageInfo {
        hasNextPage
        }
    }
}`

interface Variables {
    limit: number
    offset?: number
    after?: string
    where?: OperatorInput
}

export function useImagesQuery(
    variables: Variables,
    pause: boolean,
    requestPolicy: RequestPolicy = 'network-only'
) {
    return useQuery({
        pause,
        query,
        variables,
        requestPolicy,
    })
}
