// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".displayDate__diff--leBGF{white-space:nowrap;padding:0 .5rem;background:rgba(0,0,0,.1);border-radius:var(--border-radius);display:inline-block;margin-left:.5rem}", "",{"version":3,"sources":["webpack://./src/app/portal/components/displayDate/displayDate.scss"],"names":[],"mappings":"AAAA,0BACI,kBAAA,CACA,eAAA,CACA,yBAAA,CACA,kCAAA,CACA,oBAAA,CACA,iBAAA","sourcesContent":[".diff {\n    white-space: nowrap;\n    padding: 0 0.5rem;\n    background: rgba(0, 0, 0, 0.1);\n    border-radius: var(--border-radius);\n    display: inline-block;\n    margin-left: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"diff": "displayDate__diff--leBGF"
};
export default ___CSS_LOADER_EXPORT___;
