import * as React from 'react'
import * as styles from '../portalSamples/portalSamples.scss'
import { AppScreen } from '../appScreen/appScreen'
import { AppLinks } from '../../config'
import { SetPageTitle } from '../../helpers/setPageTitle'
import { BatchesList } from './portalBatchesList'
import { PermissionComponent } from '~/app/components/permission/permission'

export function PortalBatches(): JSX.Element {
    return (
        <AppScreen activeAppLink={AppLinks.batches}>
            <PermissionComponent
                service={'batches'}
                action={'batches:ViewData'}
                >
                    <>
                        <SetPageTitle title="Batches" />
                        <div className={styles.container}>
                            <BatchesList />
                        </div>
                    </>
                </PermissionComponent>
        </AppScreen>
    )
}
