import { Button, ButtonTypes } from '../../../portal/components/button/button'
import { BasicPopup } from '../../../portal/components/basicPopup/basicPopup'
import { PopupFooter } from '../../../portal/components/popupFooter/popupFooter'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { LabeledInput } from '../../../portal/components/labeledInput/labeledInput'
import { useCallback } from 'react'

import { useUser } from '../../../auth/hooks/useUser'
import { useGenerateCertificate } from '../../mutations/useGenerateCertificate'
import fileDownload from 'js-file-download'
import { useFormatMessage } from '../../helpers/intlContext'
import { useSnackbar } from 'notistack'
import { Team } from '@graphql-types@'

interface Props {
    testKitId: string
}

export function CertificatePopup(props: Props): JSX.Element {
    const t = useFormatMessage()
    const { testKitId } = props

    const [, generateCertificate] = useGenerateCertificate()

    const { enqueueSnackbar } = useSnackbar()

    const user = useUser()

    const teams: Team[] = user.teams.edges.map((edge) => edge.node)

    const onSave = useCallback(
        (values) => {
            generateCertificate({ input: values }).then((response) => {
                if (response.error) {
                    enqueueSnackbar(
                        <span>
                            Could not generate certificate, please try again
                            later.
                        </span>,
                        {
                            variant: 'error',
                        }
                    )
                } else {
                    const b64toBlob = (
                        b64Data,
                        contentType,
                        sliceSize = 512
                    ) => {
                        contentType = contentType || ''
                        sliceSize = sliceSize || 512

                        const byteCharacters = atob(b64Data)
                        const byteArrays = []

                        for (
                            let offset = 0;
                            offset < byteCharacters.length;
                            offset += sliceSize
                        ) {
                            const slice = byteCharacters.slice(
                                offset,
                                offset + sliceSize
                            )

                            const byteNumbers = new Array(slice.length)
                            for (let i = 0; i < slice.length; i++) {
                                byteNumbers[i] = slice.charCodeAt(i)
                            }

                            const byteArray = new Uint8Array(byteNumbers)
                            byteArrays.push(byteArray)
                        }

                        const blob = new Blob(byteArrays, { type: contentType })
                        return blob
                    }

                    fileDownload(
                        b64toBlob(
                            response.data.certificate.blob,
                            'application/pdf'
                        ),
                        `certificate-${values.testKitId}.pdf`
                    )

                    enqueueSnackbar(
                        <span>Certificate has been generated.</span>,
                        {
                            variant: 'error',
                        }
                    )
                }
            })
        },
        [teams]
    )

    return (
        <Formik
            initialValues={{
                testKitId: testKitId,
                passportFirstName: '',
                passportLastName: '',
                passportNumber: '',
            }}
            validateOnMount={true}
            enableReinitialize
            onSubmit={onSave}
            validationSchema={Yup.object().shape({
                testKitId: Yup.string().required(t('form.required')),
                passportFirstName: Yup.string().required(t('form.required')),
                passportLastName: Yup.string().required(t('form.required')),
                passportNumber: Yup.string().required(t('form.required')),
                dob: Yup.string().required(t('form.required')),
            })}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                isValid,
            }) => (
                <BasicPopup title="Generate certificate">
                    <Form>
                        <LabeledInput
                            name="testKitId"
                            title="TestKit ID"
                            placeholder="TestKit ID"
                            disabled={true}
                            value={values.testKitId}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.testKitId}
                            touched={touched.testKitId}
                        />
                        <LabeledInput
                            name="passportFirstName"
                            title="Passport FirstName"
                            placeholder="Passport FirstName"
                            value={values.passportFirstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.passportFirstName}
                            touched={touched.passportFirstName}
                        />
                        <LabeledInput
                            name="passportLastName"
                            title="Passport LastName"
                            placeholder="Passport LastName"
                            value={values.passportLastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.passportLastName}
                            touched={touched.passportLastName}
                        />
                        <LabeledInput
                            name="passportNumber"
                            title="Passport Number"
                            placeholder="Passport Number"
                            value={values.passportNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.passportNumber}
                            touched={touched.passportNumber}
                        />
                        <LabeledInput
                            name="dob"
                            title="Day of Birth"
                            placeholder="Day of Birth"
                            value={values.dob}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.dob}
                            touched={touched.dob}
                        />

                        <PopupFooter>
                            <Button
                                disabled={!isValid}
                                type={ButtonTypes.primary}
                                submit={true}
                            >
                                Save
                            </Button>
                        </PopupFooter>
                    </Form>
                </BasicPopup>
            )}
        </Formik>
    )
}
