import { Token } from 'graphql'
import moment from 'moment'
import { createContext, ReactNode, useContext, useMemo, useState } from 'react'

import { useLocalStorage } from '../hooks/useLocalStorage'
import { useSessionStorage } from '../hooks/useSessionStorage'

// https://dev.to/finiam/predictable-react-authentication-with-the-context-api-g10
interface AuthContextType {
    teamId: string
    setTeamId: (teamId: string) => void

    timeZone: string
    setTimeZone: (tz: string) => void

    token: string
    setToken: (token?: string) => void

    teamInviteToken: string
    setTeamInviteToken: (token?: string) => void

    emailVerifyToken: string
    setEmailVerifyToken: (token?: string) => void
    // team invite status
    // email confirmation token
    // set in session storage instead of localstorage?
    // useEffect, als in url. Dan in session storage plaatsen?
    // als hij valide is?
    // of moet dat gewoon in een eigen context?

    sidebarExpanded: boolean
    setSidebarExpanded: (expanded: boolean) => void
}

const StoreContext = createContext<AuthContextType>({} as AuthContextType)

// Hook
export function useBothStorage(key, initialValue): any {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [localStoredValue, setLocalStoredValue] = useState(() => {
        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(key)

            // Parse stored json or if none return initialValue
            return item ? JSON.parse(item) : initialValue
        } catch (error) {
            // If error also return initialValue
            console.error('Could not get ${key} from local storage', error)
            return initialValue
        }
    })

    const [sessionStoredValue, setSessionStoredValue] = useState(() => {
        try {
            // Get from local storage by key
            const item = window.sessionStorage.getItem(key)

            // Parse stored json or if none return initialValue
            return item ? JSON.parse(item) : initialValue
        } catch (error) {
            // If error also return initialValue
            console.error('Could not get ${key} from local storage', error)
            return initialValue
        }
    })

    const [bothStoredValue] = useMemo(() => {
        if (sessionStoredValue) return [sessionStoredValue]

        return [localStoredValue]
    }, [sessionStoredValue, localStoredValue])

    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = (value) => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore =
                value instanceof Function ? value(bothStoredValue) : value

            // Save state
            setSessionStoredValue(valueToStore)
            setLocalStoredValue(valueToStore)
            // Save to local storage
            window.sessionStorage.setItem(key, JSON.stringify(valueToStore))
            window.localStorage.setItem(key, JSON.stringify(valueToStore))
        } catch (error) {
            // A more advanced implementation would handle the error case
            console.error('Could not store ${key} in local storage', error)
        }
    }

    return [bothStoredValue, setValue]
}

// Export the provider as we need to wrap the entire app with it
export function StoreProvider({
    children,
}: {
    children: ReactNode
}): JSX.Element {
    const [timeZone, setTimeZone] = useLocalStorage('tz', moment.tz.guess())

    const [teamId, setTeamId] = useBothStorage('teamId', null)
    const [token, setToken] = useLocalStorage('jwtToken', null)

    const [sidebarExpanded, setSidebarExpanded] = useLocalStorage(
        'sidebarExpanded',
        false
    )

    const [teamInviteToken, setTeamInviteToken] = useSessionStorage(
        'teamInviteToken',
        null
    )

    const [emailVerifyToken, setEmailVerifyToken] = useSessionStorage(
        'emailVerifyToken',
        null
    )

    const memoedValue = useMemo(
        () => ({
            timeZone,
            setTimeZone,
            teamId,
            setTeamId,
            token,
            setToken,
            sidebarExpanded,
            setSidebarExpanded,
            teamInviteToken,
            setTeamInviteToken,
            emailVerifyToken,
            setEmailVerifyToken,
        }),
        [
            timeZone,
            teamId,
            token,
            sidebarExpanded,
            teamInviteToken,
            emailVerifyToken,
        ]
    )

    // We only want to render the underlying app after we
    // assert for the presence of a current user.
    return (
        <StoreContext.Provider value={memoedValue}>
            {children}
        </StoreContext.Provider>
    )
}

// Let's only export the `useAuth` hook instead of the context.
// We only want to use the hook directly and never the context component.
export default function useStore(): AuthContextType {
    return useContext(StoreContext)
}

export function useTeamInviteToken(): readonly [
    string,
    (token?: string) => void
] {
    const { teamInviteToken, setTeamInviteToken } = useStore()
    return [teamInviteToken, setTeamInviteToken] as const
}

export function useEmailVerifyToken(): readonly [
    string,
    (token?: string) => void
] {
    const { emailVerifyToken, setEmailVerifyToken } = useStore()
    return [emailVerifyToken, setEmailVerifyToken] as const
}

export function useToken(): readonly [string, (token?: string) => void] {
    const { token, setToken } = useStore()
    return [token, setToken] as const
}

// Hook
export function useTeamId(): readonly [string, (teamId: string) => void] {
    const { teamId, setTeamId } = useStore()
    return [teamId, setTeamId] as const
}

// Hook
export function useTimezone(): readonly [string, (tz: string) => void] {
    const { timeZone, setTimeZone } = useStore()
    return [timeZone, setTimeZone] as const
}

export function useSidebarExpanded(): readonly [
    boolean,
    (expanded: boolean) => void
] {
    const { sidebarExpanded, setSidebarExpanded } = useStore()
    return [sidebarExpanded, setSidebarExpanded] as const
}
