import * as React from 'react'
import { Route, RouteProps, Redirect } from 'react-router-dom'
import { useSelect } from '../../portal/hooks/useSelect'
import { AppState } from '../../portal/components/store'

interface Props extends RouteProps {
    redirectTo: string
    roles?: string[]
    rolesRedirectTo?: string
}

export function UnauthRoute(props: Props): JSX.Element {
    return <Route {...props} />
}
