import * as React from 'react'
import { useLogout } from '../hooks/useLogOut'

interface Props {
    className?: string
    children: React.ReactNode
}

export function LogOutButton(props: Props) {
    const logout = useLogout()

    return (
        <button className={props.className} onClick={logout}>
            {props.children}
        </button>
    )
}
