/* af0af81573433ac76d4bba937a906c417b21ca7a
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../__types__';

import gql from 'graphql-tag';
import * as React from 'react';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GenerateMutationVariables = Types.Exact<{
  count: Types.Scalars['Int'];
  teamId: Types.Scalars['String'];
  testType: Types.Scalars['String'];
  facilityId?: Types.InputMaybe<Types.Scalars['String']>;
  requiresApproval?: Types.InputMaybe<Types.Scalars['Boolean']>;
  noCard?: Types.InputMaybe<Types.Scalars['Boolean']>;
  approvalThreshold?: Types.InputMaybe<Types.Scalars['Int']>;
  templates?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
  mode: Types.BatchModeEnum;
}>;


export type GenerateMutation = { __typename?: 'Mutation', generate?: Array<{ __typename?: 'Code', code: string }> | null };


export const GenerateDocument = gql`
    mutation generate($count: Int!, $teamId: String!, $testType: String!, $facilityId: String, $requiresApproval: Boolean, $noCard: Boolean, $approvalThreshold: Int, $templates: [String!], $mode: BatchModeEnum!) {
  generate(
    input: {count: $count, teamId: $teamId, testType: $testType, facilityId: $facilityId, requiresApproval: $requiresApproval, noCard: $noCard, approvalThreshold: $approvalThreshold, templates: $templates, mode: $mode}
  ) {
    code
  }
}
    `;

export const GenerateComponent = (props: Omit<Urql.MutationProps<GenerateMutation, GenerateMutationVariables>, 'query'> & { variables?: GenerateMutationVariables }) => (
  <Urql.Mutation {...props} query={GenerateDocument} />
);


export function useGenerateMutation() {
  return Urql.useMutation<GenerateMutation, GenerateMutationVariables>(GenerateDocument);
};