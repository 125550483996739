import { useEffect } from 'react'
import { useQuery } from 'urql'
import { useSamplesQuery } from '../components/samplesList/queries/useSamplesQuery'
import { SampleStatus } from '../components/samplesList/samples'

const query = `
query samples($limit: Int!, $offset: Int!, $after: String!, $where: OperatorInput) {
    samples(first: $limit, offset: $offset, after: $after, where: $where) {
        totalCount
    }
}`

export function useApprovalCount(): [number] {
    const [{ data, fetching }, reexecuteQuery] = useQuery({
        query,
        variables: {
            limit: 1000,
            offset: 0,
            where: {
                and: [
                    {
                        field: 'status',
                        eq: SampleStatus.REQUIRES_APPROVAL,
                    },
                ],
            },
            after: '',
        },
    })

    useEffect(() => {
        if (fetching) return

        // Set up to refetch in one second, if the query is idle
        const timerId = setTimeout(() => {
            reexecuteQuery({ requestPolicy: 'network-only' })
        }, 5000)

        return () => clearTimeout(timerId)
    }, [fetching, reexecuteQuery])

    return [data?.samples?.totalCount]
}
