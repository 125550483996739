import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { useCallback } from 'react'
import { BasicPopup } from '../../../portal/components/basicPopup/basicPopup'
import { TextInput } from '../../../portal/components/textInput/textInput'
import { SubmitButton } from '../../../portal/components/submitButton/submitButton'
import { useUser } from '../../hooks/useUser'
import { PopupFooter } from '../../../portal/components/popupFooter/popupFooter'
import { SelectRole } from '../selectRole/selectRole'
import { useFormatMessage } from '../../../portal/helpers/intlContext'
import { Role } from '@graphql-enums@'
import { usePopup } from '~/app/providers/popup'
import { useSnackbar } from 'notistack'
import { useInviteUserMutation } from '../../mutations/inviteUser.graphql'

export function UserInvite() {
    const currentUser = useUser()
    const [, inviteUser] = useInviteUserMutation()
    const { clearPopup } = usePopup()

    const { enqueueSnackbar } = useSnackbar()

    const onSubmit = useCallback((data, { setSubmitting, resetForm }) => {
        inviteUser({
            input: {
                ...data,
            },
        }).then(() => {
            clearPopup()

            enqueueSnackbar(
                <span>
                    Invitation sent to <b>{data.email}</b>.
                </span>,
                { variant: 'success' }
            )

            setSubmitting(true)
            resetForm()
        })
    }, [])

    const t = useFormatMessage()

    return (
        <Formik
            initialValues={{
                email: '',
                role: Role.SecurityAnalyst,
            }}
            onSubmit={onSubmit}
            validationSchema={Yup.object().shape({
                email: Yup.string().email(t('form.emailInvalid')).required(),
            })}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                isSubmitting,
                isValid,
                setFieldValue,
                setFieldTouched,
            }) => (
                <BasicPopup
                    size="s"
                    title={
                        'Invite user to team ' + currentUser.currentTeam.name
                    }
                >
                    <Form>
                        <label className="form-label">Email address</label>
                        <TextInput
                            name="email"
                            title="Email address"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            error={errors.email}
                            touched={touched.email}
                            autoFocus
                        />

                        <SelectRole
                            value={values.role as Role}
                            label="Role"
                            onChange={(value) => setFieldValue('role', value)}
                            onBlur={(isTouched) =>
                                setFieldTouched('role', isTouched)
                            }
                        />

                        <PopupFooter>
                            <SubmitButton
                                title="Invite"
                                disabled={!isValid}
                                saving={isSubmitting}
                            />
                        </PopupFooter>
                    </Form>
                </BasicPopup>
            )}
        </Formik>
    )
}
