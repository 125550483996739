import { useEffect } from 'react'
import { usePopup } from '~/app/providers/popup'

export function PopupManager(): JSX.Element {
    const { value, clearPopup } = usePopup()

    useEffect(() => {
        clearPopup()
    }, [])

    return value ? <div>{value}</div> : null
}
