import * as React from 'react'
import * as styles from './basicPopup.scss'
import { Popup } from '../popup/popup'
import classNames from 'classnames'
import { Icon } from '../icon/ficon'
import { Loader } from '../loader/loader'
import { usePopup } from '~/app/providers/popup'

export interface Props {
    children: React.ReactNode
    title?: string
    noPadding?: boolean
    header?: React.ReactNode
    loading?: boolean
    className?: string
    size?: 's' | 'm' | 'l' | 'xl'
    ribbonTitle?: string
}

export function BasicPopup(props: Props): JSX.Element {
    const { clearPopup } = usePopup()

    const {
        title,
        noPadding,
        header,
        loading,
        className,
        size,
        children,
        ribbonTitle,
    } = props

    return (
        <Popup
            className={classNames(
                className,
                {
                    'modal-sm': size === 's', // 300px
                    'modal-lg': size === 'l', // 800px
                    'modal-xl': size === 'xl', // 1200px
                },
                !size || size === 'm' ? styles.modalDefault : '' // 500px
            )}
        >
            <header className={styles.header}>
                <h3 className={styles.title}>{title}</h3>
                <div className={styles.headerRight}>
                    {header}
                    <button
                        className={styles.close}
                        onClick={() => clearPopup()}
                    >
                        <Icon name="X" small className={styles.closeIcon} />
                    </button>
                </div>
                {ribbonTitle && (
                    <span
                        className={classNames(
                            'badge bg-primary',
                            styles.ribbon
                        )}
                    >
                        {ribbonTitle}
                    </span>
                )}
            </header>
            <div
                className={classNames(styles.content, {
                    [styles.noPadding]: noPadding,
                })}
            >
                {children}
            </div>
            {loading && (
                <div className={styles.loading}>
                    <Loader large />
                </div>
            )}
        </Popup>
    )
}
