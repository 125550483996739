import * as React from 'react'
import * as styles from './listLink.scss'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

export interface Props {
    children: React.ReactNode
    active: boolean
    className?: string
    to?: string
    onClick?: () => void
}

export function ListLink(props: Props) {
    const { className, onClick } = props
    return (
        <li className={classNames({ active: props.active })}>
            {props.to && (
                <Link
                    {...{ onClick }}
                    to={props.to}
                    className={classNames(styles.link, className, {
                        [styles.active]: props.active,
                    })}
                >
                    {props.children}
                </Link>
            )}

            {!props.to && (
                <button
                    onClick={onClick}
                    className={classNames(styles.link, {
                        [styles.active]: props.active,
                    })}
                >
                    {props.children}
                </button>
            )}
        </li>
    )
}
