/* 83ca6f86113b3f5ccff34c2c2e564cff203ba45b
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../__types__';

import gql from 'graphql-tag';
import * as React from 'react';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type EnrollOtpMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type EnrollOtpMutation = { __typename?: 'Mutation', enrollOTP: { __typename?: 'enrollOtp', image: string, string: string } };


export const EnrollOtpDocument = gql`
    mutation enrollOTP {
  enrollOTP {
    image
    string
  }
}
    `;

export const EnrollOtpComponent = (props: Omit<Urql.MutationProps<EnrollOtpMutation, EnrollOtpMutationVariables>, 'query'> & { variables?: EnrollOtpMutationVariables }) => (
  <Urql.Mutation {...props} query={EnrollOtpDocument} />
);


export function useEnrollOtpMutation() {
  return Urql.useMutation<EnrollOtpMutation, EnrollOtpMutationVariables>(EnrollOtpDocument);
};