import * as React from 'react'
import { Button, ButtonTypes } from '../button/button'
import { IconName } from '../icon/ficon'

interface Props {
    title?: JSX.Element | string
    children?: JSX.Element | string
    disabled?: boolean
    className?: string
    loading?: boolean
    submitting?: boolean
    saving?: boolean
    icon?: IconName
    iconOnRight?: boolean
    type?: ButtonTypes

    onClick?: (event) => void
}

export function SubmitButton(
    props: Props = { type: ButtonTypes.primary }
): JSX.Element {
    return (
        <Button
            submit={true}
            disabled={props.disabled}
            className={props.className}
            type={props.type ?? ButtonTypes.primary}
            onClick={props.onClick}
            icon={props.icon}
            iconOnRight={props.iconOnRight}
            spinning={props.loading || props.saving || props.submitting}
        >
            {props.title || props.children}
        </Button>
    )
}

interface SaveProps {
    title?: JSX.Element | string
    children?: JSX.Element | string
    disabled?: boolean
    className?: string
    loading?: boolean
    saving?: boolean
    icon?: IconName
    iconOnRight?: boolean
    type?: ButtonTypes

    onClick?: ({
        setSubmitting,
    }: {
        setSubmitting: (submitting: boolean) => void
    }) => void
}

export function SaveButton(
    props: SaveProps = { type: ButtonTypes.primary }
): JSX.Element {
    const [submitting, setSubmitting] = React.useState(false)

    return (
        <Button
            submit={true}
            disabled={props.disabled && !submitting}
            className={props.className}
            spinning={submitting}
            type={props.type}
            onClick={(evt) => {
                setSubmitting(true)
                props.onClick({ setSubmitting })
            }}
            icon={props.icon}
            iconOnRight={props.iconOnRight}
        >
            {props.title || props.children}
        </Button>
    )
}
