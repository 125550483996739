import * as React from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'
import * as styles from './expandableNav.scss'
import classNames from 'classnames'
import { useSelect } from '../../hooks/useSelect'
import { AppState } from '../store'
import { Keys, useKey } from '../../hooks/useKey'

interface Props {
    children: React.ReactNode
    button: React.ReactNode
    className?: string
    navClassName?: string
    buttonClassName?: string
}

export function ExpandableNav(props: Props) {
    const [expanded, setExpanded] = useState<boolean>(false)
    const container = useRef<HTMLDivElement>(null)

    const onClickOutside = useCallback((event: MouseEvent) => {
        const element: any = event.target

        if (!container.current) {
            window.removeEventListener('click', onClickOutside)
        } else if (!container.current.contains(element)) {
            setExpanded(false)
            window.removeEventListener('click', onClickOutside)
        }
    }, [])

    const toggleExpanded = useCallback(() => {
        if (expanded) {
            setExpanded(false)
            window.removeEventListener('click', onClickOutside)
        } else {
            setExpanded(true)
            window.addEventListener('click', onClickOutside)
        }
    }, [expanded, setExpanded, window, onClickOutside])

    // todo(remco): fix state.portal.popup
    const popup = null
    /*
  const { popup } = useSelect((state: AppState) => ({
    popup: state.portal.popup,
  }));*/

    useEffect(() => {
        setExpanded(false)
        window.removeEventListener('click', onClickOutside)
    }, [popup])

    useKey(Keys.escape, () => {
        setExpanded(false)
        window.removeEventListener('click', onClickOutside)
    })

    return (
        <div
            className={classNames(props.className, styles.expandableNav)}
            ref={(ref) => (container.current = ref)}
        >
            <button
                onClick={toggleExpanded}
                className={classNames(styles.button, props.buttonClassName)}
            >
                {props.button}
            </button>

            {expanded && (
                <div className={classNames(styles.nav, props.navClassName)}>
                    {props.children}
                </div>
            )}
        </div>
    )
}
