export interface Theme {
    colors: string[]
    chartColors: string[]
    heatmapColors: string[]
    lineChartColors: string[]
}

export const useTheme = (): Theme => {
    return {
        colors: [
            '#f49b86',
            '#f3927a',
            '#f2886c',
            '#f27e5d',
            '#f1734a',
            '#f06630',
            '#ea632f',
            '#e4612e',
            '#dd5e2c',
            '#d75b2b',
        ],
        chartColors: [
            '#ff6d33',
            '#ff8a5a',
            '#ffa680',
            '#ffc1a6',
            '#ffdcce',
            '#e5e5e5',
            '#cbcbcf',
            '#a2a1aa',
            '#7b7985',
            '#555463',
            '#323142',
        ],
        heatmapColors: [
            '#f2f2f2',
            '#ffdbca',
            '#ffdbca',
            '#ffcab0',
            '#ffb897',
            '#ffa67e',
            '#ff9365',
            '#ff814d',
            '#ff6d33',
        ],
        lineChartColors: [
            '#ff6d33', // (our) orange
            '#a4a1ae', // light grey
            '#559354', // dark green
            '#b30003', // dark red
            '#ffcab0', // lighter orange
            '#323142', // dark grey
            '#94d37c', // light green
            '#e48383', // light red
        ],
    }
}
