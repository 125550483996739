import { useSnackbar } from 'notistack'
import { useCallback } from 'react'
import { useTeamId, useToken } from '../../providers/store'
import { useChangeTeamMutation } from '../mutations/changeTeam.graphql'

export function useDoChangeTeam(): (teamId: string) => Promise<void> {
    const [, setTeamId] = useTeamId()
    const [, setToken] = useToken()

    const { enqueueSnackbar } = useSnackbar()

    const [, changeTeam] = useChangeTeamMutation()

    return useCallback(async (teamId: string) => {
        const { data, error } = await changeTeam({ teamId })
        if (!error && data && data.changeTeam) {
            setTeamId(teamId)
            setToken(data.changeTeam.token)
        } else {
            enqueueSnackbar(
                <span>Could not change team, please try again later.</span>,
                {
                    variant: 'error',
                }
            )
        }
    }, [])
}
