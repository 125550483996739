// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fullScreenLoading__fullScreenLoading--l3fjb{min-width:100%;min-height:100%;position:fixed;justify-content:center;align-items:center;display:flex;flex-direction:column;opacity:0;animation:fullScreenLoading__fadein--U85Bs ease 1s;animation-delay:.5s;animation-fill-mode:forwards}.fullScreenLoading__message--FpwJs{margin-top:2rem;font-size:1.2rem}@keyframes fullScreenLoading__fadein--U85Bs{from{opacity:0}to{opacity:1}}", "",{"version":3,"sources":["webpack://./src/app/portal/components/fullScreenLoading/fullScreenLoading.scss"],"names":[],"mappings":"AAAA,6CACI,cAAA,CACA,eAAA,CACA,cAAA,CACA,sBAAA,CACA,kBAAA,CACA,YAAA,CACA,qBAAA,CACA,SAAA,CACA,kDAAA,CACA,mBAAA,CACA,4BAAA,CAGJ,mCACI,eAAA,CACA,gBAAA,CAGJ,4CACI,KACI,SAAA,CAEJ,GACI,SAAA,CAAA","sourcesContent":[".fullScreenLoading {\n    min-width: 100%;\n    min-height: 100%;\n    position: fixed;\n    justify-content: center;\n    align-items: center;\n    display: flex;\n    flex-direction: column;\n    opacity: 0;\n    animation: fadein ease 1s;\n    animation-delay: 0.5s;\n    animation-fill-mode: forwards;\n}\n\n.message {\n    margin-top: 2rem;\n    font-size: 1.2rem;\n}\n\n@keyframes fadein {\n    from {\n        opacity: 0;\n    }\n    to {\n        opacity: 1;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullScreenLoading": "fullScreenLoading__fullScreenLoading--l3fjb",
	"fadein": "fullScreenLoading__fadein--U85Bs",
	"message": "fullScreenLoading__message--FpwJs"
};
export default ___CSS_LOADER_EXPORT___;
