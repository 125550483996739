import classNames from 'classnames'
import Select, {
    components,
    IndicatorsContainerProps,
    Props as SelectProps,
    Theme,
} from 'react-select'
import React, { CSSProperties, FunctionComponent } from 'react'

import { ClearIndicatorProps } from 'react-select'
import { CSSObject } from '@emotion/serialize'
import { Button, ButtonTypes } from '../button/button'

type Option = { label: string | JSX.Element; value: any; disabled?: boolean }

export interface Props<T = Option> {
    name: string
    label?: string | JSX.Element
    options: T[]
    className?: string
    isLoading?: boolean
    onChange: (value: any) => void
    onBlur: (isTouched?: boolean) => void
    onRefresh?: () => void
    helpText?: string | JSX.Element
    isSearchable?: boolean
    isDisabled?: boolean
    isMulti?: boolean
    isClearable?: boolean
    isOptionDisabled?: (option: T) => boolean
    value?: { label: string | JSX.Element; value: any } | ((option: T[]) => T)
    placeholder?: string
}

const themeConfig = (theme: Theme) => {
    return {
        ...theme,
        colors: {
            ...theme.colors,
            primary25: 'rgba(255,109,51,.20)',
            primary: 'rgba(255,109,51,1)',
        },
    }
}

export const ReactSelect = (props: Props): JSX.Element => {
    const {
        name,
        className,
        options,
        label,
        isOptionDisabled,
        onRefresh,
        isLoading,
        onBlur,
        onChange,
        value,
        ...rest
    } = props

    const IndicatorsContainer = (
        props: IndicatorsContainerProps<unknown, false>
    ) => {
        // can we put the refresh button inside the container?
        return (
            <div>
                <components.IndicatorsContainer {...props} />
            </div>
        )
    }

    return (
        <div className={classNames('form-group', className)}>
            {label && <label className="form-label">{label}</label>}
            <div className="input-group">
                <Select
                    className="flex-row flex-grow-1 flex-shrink-1"
                    components={{ IndicatorsContainer }}
                    isLoading={isLoading}
                    name={name}
                    id={name}
                    options={options}
                    isOptionDisabled={isOptionDisabled}
                    onChange={(newValue) => props.onChange(newValue)}
                    onBlur={(_) => props.onBlur(true)}
                    menuPosition="fixed"
                    value={typeof value === 'function' ? value(options) : value}
                    {...rest}
                    theme={(theme) => themeConfig(theme)}
                />
                {onRefresh && (
                    <div className="input-group-append">
                        <Button
                            type={ButtonTypes.transparent}
                            icon={'RefreshCw'}
                            className="btn btn-secondary"
                            onClick={onRefresh}
                        />
                    </div>
                )}
            </div>
            {props.helpText && (
                <small className="form-text text-muted">{props.helpText}</small>
            )}
        </div>
    )
}
