// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".appScreen__appScreen--wmKJn{height:100vh;width:100vw;display:flex;overflow:hidden;position:relative}.appScreen__headerBar--xVtQ8{position:relative;z-index:10;padding:0 1.5rem;background:var(--background)}.appScreen__headerBar--xVtQ8 .appScreen__headerRight--gJIhy{padding:1rem 0;float:right}.appScreen__left--Xd2Xh{display:flex}.appScreen__right--yBpAo{flex-grow:1;display:flex;position:relative;z-index:1;flex-direction:column;overflow-y:auto}.appScreen__content--nbYNV{display:flex;flex-grow:1}", "",{"version":3,"sources":["webpack://./src/app/portal/components/appScreen/appScreen.scss"],"names":[],"mappings":"AAAA,6BACI,YAAA,CACA,WAAA,CACA,YAAA,CACA,eAAA,CACA,iBAAA,CAGJ,6BACI,iBAAA,CACA,UAAA,CACA,gBAAA,CACA,4BAAA,CAEA,4DACI,cAAA,CACA,WAAA,CAIR,wBACI,YAAA,CAGJ,yBACI,WAAA,CACA,YAAA,CACA,iBAAA,CACA,SAAA,CACA,qBAAA,CACA,eAAA,CAGJ,2BACI,YAAA,CACA,WAAA","sourcesContent":[".appScreen {\n    height: 100vh;\n    width: 100vw;\n    display: flex;\n    overflow: hidden;\n    position: relative;\n}\n\n.headerBar {\n    position: relative;\n    z-index: 10;\n    padding: 0 1.5rem;\n    background: var(--background);\n\n    .headerRight {\n        padding: 1rem 0;\n        float: right;\n    }\n}\n\n.left {\n    display: flex;\n}\n\n.right {\n    flex-grow: 1;\n    display: flex;\n    position: relative;\n    z-index: 1;\n    flex-direction: column;\n    overflow-y: auto;\n}\n\n.content {\n    display: flex;\n    flex-grow: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appScreen": "appScreen__appScreen--wmKJn",
	"headerBar": "appScreen__headerBar--xVtQ8",
	"headerRight": "appScreen__headerRight--gJIhy",
	"left": "appScreen__left--Xd2Xh",
	"right": "appScreen__right--yBpAo",
	"content": "appScreen__content--nbYNV"
};
export default ___CSS_LOADER_EXPORT___;
