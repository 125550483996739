import classNames from 'classnames'
import * as React from 'react'
import { UnknownValue } from './unknownValue'

interface Props {
    title?: string
    value: any
    emptyValueAllowed?: boolean
    linebreaks?: boolean
    className?: string
    truncate?: boolean
}

export function DisplayString(props: Props) {
    const { truncate } = {
        truncate: false,
        ...props,
    }

    if (
        typeof props.value === 'undefined' ||
        props.value === null ||
        props.value === ''
    ) {
        if (props.emptyValueAllowed) {
            return (
                <React.Fragment>
                    <span className="text-italic text-muted">none</span>
                </React.Fragment>
            )
        } else {
            return <UnknownValue />
        }
    }

    let value: any = props.value

    if (Array.isArray(value)) {
        value = value.join(', ')
    } else if (typeof value === 'object') {
        value = JSON.stringify(value)
    }

    if (props.linebreaks) {
        const newlineRegex = /(\r\n|\r|\n)/g

        value = value.split(newlineRegex).map((line, index) => {
            if (line.match(newlineRegex)) {
                return <br key={index} />
            }

            return line
        })
    }

    return (
        <React.Fragment>
            <div
                title={props.title}
                className={classNames(props.className, {
                    ['text-wrap']: !truncate,
                    ['text-truncate']: truncate,
                })}
            >
                {value}
            </div>
        </React.Fragment>
    )
}
