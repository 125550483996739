import * as React from 'react'
import { UnknownValue } from './unknownValue'

interface Props {
    value: any
}

export function DisplayBoolean(props: Props) {
    const { value } = props

    if (value === true) {
        return <span>Yes</span>
    }

    if (value === false) {
        return <span>No</span>
    }

    return <UnknownValue />
}
