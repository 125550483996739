import * as React from 'react'
import * as styles from './primaryContent.scss'
import classNames from 'classnames'
import { Loader } from '../loader/loader'
import { CombinedError } from 'urql'

interface Props {
    children: React.ReactNode
    header?: React.ReactNode
    centered?: boolean
    noPadding?: true
    loading?: boolean
    graphQlError?: CombinedError
}

export function PrimaryContent(props: Props) {
    const {
        loading,
        children,
        centered,
        header,
        noPadding,
        graphQlError,
    } = props

    return (
        <div
            className={classNames(styles.container, {
                [styles.centered]: centered,
            })}
        >
            {/* <MobileBreadcrumbs/> */}

            <div className={styles.card}>
                {header && <header className={styles.header}>{header}</header>}

                <main
                    className={classNames(styles.main, {
                        [styles.noPadding]: noPadding,
                    })}
                >
                    {graphQlError && (
                        <React.Fragment>
                            <h2>Could not retrieve data.</h2>
                            <p>
                                Please refresh the page and try again. If this
                                keeps happening, please contact us.
                            </p>
                        </React.Fragment>
                    )}

                    {!graphQlError && children}
                </main>
            </div>

            {loading && (
                <div className={styles.loadingOverlay}>
                    <Loader center large />
                </div>
            )}
        </div>
    )
}
