import * as React from 'react'
import * as Sentry from '@sentry/browser'
import { Integrations as TracingIntegrations } from '@sentry/tracing'
import {
    getRavenEnvironment,
    isDevelopment,
} from '../../helpers/getRavenEnvironment'
import * as styles from './errorReporting.scss'
import { Button, ButtonTypes } from '../button/button'
import { FullScreenCard } from '../fullScreenCard/fullScreenCard'
import { getBackendHost } from '../../../graphQl/helpers/getBackendHost'

interface Props {}

interface State {
    error: any
    eventId: any
}

if (!isDevelopment()) {
    Sentry.init({
        dsn:
            'https://b1d84943d87949b18ee31b746fe152e3@o171695.ingest.sentry.io/5769465',
        release: process.env.CLIENT_VERSION,
        environment: getRavenEnvironment(),
        integrations: [
            new TracingIntegrations.BrowserTracing({
                tracingOrigins: ['localhost', getBackendHost(), /^\//],
            }),
        ],
        tracesSampleRate: 0.2, // To set a uniform sample rate
    })

    Sentry.configureScope((scope) => {
        scope.setExtras({
            client_version: process.env.CLIENT_VERSION,
            last_commit_date: process.env.LAST_COMMIT_DATE,
        })
    })
}

export class ErrorReporting extends React.Component<Props, State> {
    state: State = {
        error: null,
        eventId: null,
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        this.setState({ error })

        if (!isDevelopment()) {
            Sentry.withScope((scope) => {
                scope.setExtras({ component_stack: errorInfo })
                const eventId = Sentry.captureException(error)
                this.setState({ eventId })
            })
        }
    }

    onClick() {
        Sentry.showReportDialog({ eventId: this.state.eventId })
    }

    render() {
        const error = this.state.error
        if (error) {
            return (
                <FullScreenCard>
                    <h2 className={styles.title}>Something went wrong</h2>
                    <p className={styles.explanation}>
                        Please refresh the page and try again. If this keeps
                        happening, please contact us.
                    </p>

                    {!isDevelopment() && (
                        <Button
                            type={ButtonTypes.primary}
                            onClick={this.onClick.bind(this)}
                        >
                            Report feedback
                        </Button>
                    )}

                    <h3 className={styles.debugTitle}>
                        Debugging information:
                    </h3>
                    <div>
                        <b>{error.message}</b>
                    </div>
                    <pre className={styles.error}>
                        {error.stack
                            .replace(/webpack\-internal\:\/\/\//gi, '')
                            .replace(/^.*node_modules.*$/gim, '')}
                    </pre>
                </FullScreenCard>
            )
        } else {
            //when there's not an error, render children untouched
            return this.props.children
        }
    }
}
