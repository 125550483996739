import { Team } from '@graphql-types@'
import { useEffect, useState } from 'react'
import { useTeamInviteToken } from '../../providers/store'
import { useGetTeamInviteMutation } from '../mutations/getTeamInvite.graphql'

interface Result {
    team?: Team
    error?: InvitationError
    loading: boolean
    token?: string
}

export enum InvitationError {
    noToken,
    invalidToken,
}

export function useTeamInviteStatus(): Result {
    const [teamInviteToken] = useTeamInviteToken()

    const [team, setTeam] = useState<Team>()
    const [, getTeamInvite] = useGetTeamInviteMutation()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (!teamInviteToken) return

        getTeamInvite({ token: teamInviteToken })
            .then(({ data }) => {
                const team: Team = data.getTeamInvite

                if (team) {
                    setTeam(team)
                }

                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
            })
    }, [teamInviteToken])

    if (!teamInviteToken) {
        return {
            error: InvitationError.noToken,
            loading: false,
        }
    }

    if (!team) {
        return {
            error: InvitationError.invalidToken,
            loading,
        }
    }

    return {
        team: team,
        token: teamInviteToken,
        loading,
    }
}
