import { useQuery } from 'urql'

const query = `
query facilities {
  facilities {
    edges {
      node {
        name
        teamId
      }
    }
  }
}`

export function useFacilitiesQuery() {
    return useQuery({ query })
}
