import * as React from 'react'
import classNames from 'classnames'
import * as styles from './loadableTable.scss'
import { Loader } from '../loader/loader'

interface Props {
    isLoading: boolean
    children: React.ReactNode
    noResults: boolean
    noResultsMessage?: string
    couldNotLoad: boolean
    noNegativeMargin?: boolean
    className?: string
}

export function LoadableTable(props: Props): JSX.Element {
    if (props.couldNotLoad && !props.isLoading) {
        return (
            <p
                className={classNames(styles.status, {
                    [styles.statusMargin]: props.noNegativeMargin,
                })}
            >
                Something went wrong while trying to load the data.
            </p>
        )
    }

    if (props.noResults && !props.isLoading) {
        return (
            <p
                className={classNames(styles.status, {
                    [styles.statusMargin]: props.noNegativeMargin,
                })}
            >
                {props.noResultsMessage
                    ? props.noResultsMessage
                    : 'No data found.'}
            </p>
        )
    }

    return (
        <div
            className={classNames(styles.container, props.className, {
                [styles.isLoading]: props.isLoading,
                [styles.noNegativeMargin]: props.noNegativeMargin,
            })}
        >
            <table className={styles.table}>{props.children}</table>
        </div>
    )
}
