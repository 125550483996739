import classNames from 'classnames'
import * as React from 'react'
import { useMemo } from 'react'
import { FileError, useDropzone } from 'react-dropzone'
import { AppLinks } from '../../config'
import { AppScreen } from '../appScreen/appScreen'
import { Icon } from '../icon/ficon'
import { PrimaryContent } from '../primaryContent/primaryContent'
import * as styles from './consentryUpload.scss'
import CSVFileValidator from 'csv-file-validator'
import {
    CSVConfig,
    CsvRowError,
    CsvSampleRow,
    isSampleNameValid,
    onlyUnique,
} from './consentryUploadValidation'
import { Button, ButtonTypes } from '../button/button'
import { useValidateTestkitIdQuery } from './mutations/useValidateTestkitId'
import { Loader } from '../loader/loader'
import { SetPageTitle } from '../../helpers/setPageTitle'
import * as Papa from 'papaparse'
import { Form, Formik } from 'formik'
import { SubmitButton } from '../submitButton/submitButton'
import { useUpdateSample } from '../codeTesting/mutations/useUpdateSample'
import { SampleResult, SampleStatus } from '../samplesList/samples'
import { useSnackbar } from 'notistack'
import { useCurrentTeam } from '~/app/providers/user'
import * as hl7v2 from '@redoxengine/redox-hl7-v2'
import moment from 'moment'

export const ConsentryUpload = (): JSX.Element => {
    const parser = new hl7v2.Parser()

    const [rows, setRows] = React.useState([])

    const [acceptedFiles, setAcceptedFiles] = React.useState<any>([])

    const onDropAccepted = React.useCallback(
        (files: any) => {
            setAcceptedFiles([...acceptedFiles, ...files])
        },
        [acceptedFiles]
    )
    //
    // File dropzone
    const result = useDropzone({
        onDrop: onDropAccepted,
        accept: '.csv,.txt',
    })

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = result

    const style = useMemo(
        () =>
            classNames(styles.dropzone, {
                [styles.active]: isDragActive,
                [styles.accepted]: isDragAccept,
                [styles.rejected]: isDragReject,
            }),
        [isDragActive, isDragReject, isDragAccept]
    )

    React.useEffect(() => {
        Promise.all(
            acceptedFiles.map(
                (file) =>
                    new Promise((resolve, reject) => {
                        if (file.name.match(/\.txt$/)) {
                            const reader = new FileReader()
                            reader.onload = function (e) {
                                const contents = e.target.result

                                const allLines = (contents as string).split(
                                    /\r\n|\n/
                                )
                                // Reading line by line
                                const rows = allLines
                                    .flatMap((line) => {
                                        if (line === '') return []

                                        return parser.parse(line)
                                    })
                                    .map((hl7Message) => {
                                        // https://hl7-definition.caristix.com/v2/HL7v2.8/Segments/MSH

                                        const val =
                                            hl7Message?.SPECIMEN?.[0]?.[
                                                'ORDER'
                                            ]?.['0']?.[
                                                'OBXTCDSIDNTE_SUPPGRP'
                                            ]?.['0']?.['OBX']?.['5']?.[0]

                                        let result = 'INCONCLUSIVE'

                                        if (val.match(/^Positive/i)) {
                                            result = 'POSITIVE'
                                        } else if (val.match(/^Negative/i)) {
                                            result = 'NEGATIVE'
                                        }

                                        return {
                                            file: file.name,
                                            testTime: moment
                                                .utc(
                                                    hl7Message?.MSH?.['7']?.[
                                                        '1'
                                                    ],
                                                    'YYYYMMDDHHmmss'
                                                )
                                                .toString(),
                                            sampleName:
                                                hl7Message?.SPECIMEN?.[0]?.[
                                                    'SPM'
                                                ]?.['2']?.['1']?.['1'],
                                            testName:
                                                hl7Message?.SPECIMEN?.[0]?.[
                                                    'ORDER'
                                                ]?.['0']?.['OBR']?.['4']?.['1'],
                                            programName:
                                                hl7Message?.MSH?.['4']?.['1'],
                                            result,
                                        }
                                    })

                                resolve(rows)
                            }

                            reader.readAsText(file)
                        } else if (file.name.match(/\.csv$/)) {
                            Papa.parse(file, {
                                worker: true, // Don't bog down the main thread if its a big file
                                complete: (result) => {
                                    const row = result.data.reduce(
                                        (row, data) => {
                                            if (data[0] === 'Sample Info.') {
                                                row.sampleName = (data[1] as string).trim()
                                            } else if (
                                                data[0] === 'Test Name'
                                            ) {
                                                row.testName = (data[1] as string).trim()
                                            } else if (
                                                data[0] === 'Test Time'
                                            ) {
                                                row.testTime = moment
                                                    .utc(
                                                        (data[1] as string).trim(),
                                                        'YYYY/MM/DD HH:mm:ss'
                                                    )
                                                    .toString()
                                            } else if (
                                                data[0] === 'Program Name'
                                            ) {
                                                row.programName = (data[1] as string).trim()
                                            } else if (data[0] === 'Result') {
                                                const result = (data[1] as string).trim()
                                                if (result.match(/^pos/i)) {
                                                    row.result = 'POSITIVE'
                                                } else if (
                                                    result.match(/^neg/i)
                                                ) {
                                                    row.result = 'NEGATIVE'
                                                }
                                            }

                                            return row
                                        },
                                        {
                                            file: file.name,
                                            result: 'INCONCLUSIVE',
                                        }
                                    )

                                    resolve([row])
                                }, //resolve the promise when complete
                                error: reject, //reject the promise if there is an error
                            })
                        }
                    })
            )
        ).then((results) => {
            setRows(results.flat())
        })
    }, [acceptedFiles])

    const files = acceptedFiles.map((file, i) => (
        <li key={file.name + i} className="text-success">
            {file.name} - {file.size} bytes <Icon name="CheckCircle" small />
        </li>
    ))

    const [currentTeam] = useCurrentTeam()

    const [, updateSample] = useUpdateSample()

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const onSave = React.useCallback(
        (values: any, { setSubmitting }) => {
            Promise.all(
                rows.map((row) =>
                    updateSample({
                        input: {
                            facilityId: currentTeam.teamId,
                            code: row.sampleName,
                            status: SampleStatus.COMPLETED,
                            result: row.result as SampleResult,
                            message: null,
                        },
                    })
                )
            )
                .then((results) => {
                    if (results.some((result) => result.error)) {
                        return
                    }

                    enqueueSnackbar(`All samples are succesfully processed`, {
                        variant: 'info',
                    })

                    setAcceptedFiles([])
                    setRows([])
                })
                .finally(() => {
                    setSubmitting(false)
                })
        },
        [rows, currentTeam]
    )

    return (
        <AppScreen activeAppLink={AppLinks.upload}>
            <SetPageTitle title="Upload" />
            <PrimaryContent>
                <h2>Upload</h2>

                <section className="mt-3 mb-3">
                    <div
                        {...getRootProps({ className: style })}
                        style={{ width: '100%', height: '340px' }}
                    >
                        <input {...getInputProps()} />
                        <div className="position-absolute">
                            <Icon
                                name="UploadCloud"
                                width="100"
                                height="100"
                                style={{
                                    opacity: 0.1,
                                    height: '280px',
                                    width: '280px',
                                }}
                            />
                        </div>
                        {files.length > 0 && <ul>{files}</ul>}
                    </div>
                </section>
                <Formik
                    initialValues={{}}
                    validateOnMount={true}
                    enableReinitialize
                    onSubmit={onSave}
                >
                    {({
                        values,
                        errors,
                        isSubmitting,
                        touched,
                        handleChange,
                        handleBlur,
                        isValid,
                        setFieldValue,
                        setFieldTouched,
                    }) => (
                        <Form>
                            <div className="row">
                                <div className="col-12">
                                    <Button
                                        type={ButtonTypes.superPrimary}
                                        icon="UploadCloud"
                                        disabled={
                                            isSubmitting || rows.length === 0
                                        }
                                        spinning={isSubmitting}
                                        submit
                                    >
                                        Process results
                                    </Button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <section className="mt-3">
                                        <table className="table table-sm table-striped">
                                            <thead className="fw-bold">
                                                <tr>
                                                    <th>Filename</th>
                                                    <th>Time</th>
                                                    <th>Program</th>
                                                    <th>Test</th>
                                                    <th>Sample</th>
                                                    <th>Result</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {rows.map((row, i) => {
                                                    console.log(
                                                        'ROWS',
                                                        rows,
                                                        row
                                                    )
                                                    return (
                                                        <tr key={i}>
                                                            <td>{row.file}</td>
                                                            <td>
                                                                {row.testTime}
                                                            </td>
                                                            <td>
                                                                {
                                                                    row.programName
                                                                }
                                                            </td>
                                                            <td>
                                                                {row.testName}
                                                            </td>
                                                            <td>
                                                                {row.sampleName}
                                                            </td>
                                                            <td>
                                                                {row.result}
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </section>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </PrimaryContent>
        </AppScreen>
    )
}

const GetRowValue = (value: string): string => {
    return !value ? 'N/A' : value
}
