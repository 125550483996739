import * as React from 'react'
import * as styles from './popup.scss'
import { Keys, useKey } from '../../hooks/useKey'
import classNames from 'classnames'
import { usePopup } from '~/app/providers/popup'

interface Props {
    children: React.ReactNode
    className?: string
}

export function Popup(props: Props): JSX.Element {
    const { clearPopup } = usePopup()

    useKey(Keys.escape, () => clearPopup())

    return (
        <div
            className={classNames(styles.wrap, styles.overlay)}
            onClick={() => clearPopup()}
        >
            <div
                className={classNames(styles.popup, props.className)}
                onClick={(event) => event.stopPropagation()}
            >
                {props.children}
            </div>
        </div>
    )
}
