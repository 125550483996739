export enum Paths {
    activity = '/',
    samples = '/samples',
    images = '/images',
    batches = '/batches',
    approvals = '/approvals',
    approvalEdit = '/approvals/:code',
    generate = '/generate',
    upload = '/upload',
    testing = '/testing',

    // Auth
    logIn = '/login',
    ssoPage = '/sso/:slug',

    signUp = '/sign-up',
    forgotPassword = '/forgot-password',
    resetPassword = '/reset-password',
    notAllowed = '/not-allowed',
    notFound = '/',

    // Sources
    sources = '/sources',
    sourceTypeDetail = '/sources/:sourceTypeId',
    sourceDetail = '/sources/source/:sourceId',

    // Flows
    flows = '/flows',
    flowDetail = '/flows/:flowId',

    // Analyzer
    analyzerGroup = '/analyzer/*',
    analyzer = '/analyzer/discover',
    analyzerTable = '/analyzer/discover',
    analyzerDashboard = '/analyzer/dashboard/:dashboard?',
    analyzerGraph = '/analyzer/graph',
    analyzerTimeline = '/analyzer/timeline',
    observables = '/analyzer/observables',
    observableDetail = '/analyzer/observables/:observableType',

    // Queries
    queryList = '/queries',

    // Settings
    settings = '/settings',
    settingsAccount = '/settings/account',
    settingsTeam = '/settings/team',
    settingsTeamUser = '/settings/team/:userId',
    settingsSources = '/settings/sources',
    settingsSourcesDetail = '/settings/sources/:flowId',

    secretList = '/settings/secrets',
    secretDetails = '/settings/secrets/:secretId',

    auditLogs = '/settings/logs',
    systemManagement = '/settings/system',

    indexList = '/settings/indices',
    indexDetail = '/settings/indices/:indexId',

    dataPolicyList = '/settings/data-policies',
    dataPolicyDetail = '/settings/data-policies/:policyId',
}
