import * as React from 'react'
import { getBackendHost } from '../../../graphQl/helpers/getBackendHost'
import { PrimaryContent } from '../../../portal/components/primaryContent/primaryContent'
import { isDevelopment } from '../../../portal/helpers/getRavenEnvironment'
import { SettingsAppScreen } from '../settingsAppScreen'
import { useSystemQuery } from './system.graphql'

export function SystemManagement(): JSX.Element {
	const host = getBackendHost()

	const header = <React.Fragment></React.Fragment>

	const clientVer = process.env.CLIENT_VERSION
	const releaseVer = process.env.RELEASE_VERSION

	const [{ data }] = useSystemQuery();

	return (
		<SettingsAppScreen>
			<PrimaryContent header={header}>
				<h2>System Management</h2>
				<table className="table">
					<tbody>
						<tr>
							<td colSpan={2}>
								<strong>Frontend</strong>
							</td>
						</tr>
						<tr>
							<th>Development mode</th>
							<td>{isDevelopment() ? 'enabled' : 'disabled'}</td>
						</tr>
						<tr>
							<th>Release version</th>
							<td>{releaseVer}</td>
						</tr>
						<tr>
							<th>Client version</th>
							<td>{clientVer}</td>
						</tr>
						<tr>
							<th>Last commit date</th>
							<td>{process.env.LAST_COMMIT_DATE}</td>
						</tr>
						<tr>
							<td colSpan={2}>
								<strong>Backend</strong>
							</td>
						</tr>
						<tr>
							<th>Backend url</th>
							<td>{host}</td>
						</tr>
						<tr>
							<th>Commit id</th>
							<td>{data?.system?.info?.commitId}</td>
						</tr>
						<tr>
							<th>Build date</th>
							<td>{data?.system?.info?.buildDate}</td>
						</tr>
						<tr>
							<th>Go version</th>
							<td>{data?.system?.info?.version}</td>
						</tr>
						<tr>
							<th>Release tag</th>
							<td>{data?.system?.info?.tag}</td>
						</tr>
						<tr>
							<th>Uptime</th>
							<td>{'-'}</td>
						</tr>
					</tbody>
				</table>
			</PrimaryContent>
		</SettingsAppScreen>
	)
}
