import { Role } from '@graphql-enums@'
import { useUser } from '~/app/auth/hooks/useUser'
import { Role as RoleType } from '@graphql-types@'
import React, { useContext, useState } from 'react'
import { usePermissions } from '~/app/providers/user'

interface Permission {
    service: string
    action: string
    resource?: string
}

export const PermissionComponent = (props: {
    children: JSX.Element
    fallback?: JSX.Element
    service?: string
    action?: string
    resource?: string
}): JSX.Element => {
    const {
        children,
        fallback = null,
        service,
        action,
        resource = null,
    } = props

    const [permissions, hasPermission] = usePermissions()

    let allowed = hasPermission({ service, action, resource })
    return allowed ? children : fallback
}
