import { RequestPolicy, useQuery } from 'urql'
import { OperatorInput } from '../../../interfaces/operatorInput'

const query = `
query samplesAggregate($aggs: [SampleAggregateInput!], $where: OperatorInput) {
    samplesAggregate(aggs: $aggs, where: $where) {
        buckets {
            count
            field
            value
        }
    }
}`

interface Variables {
    aggs: SamplesAggInputs[]
    where?: OperatorInput
}

export interface SamplesAggInputs {
    field:
        | 'CREATED_AT'
        | 'RESULT'
        | 'STATUS'
        | 'TESTED_AT'
        | 'ORIGIN'
        | 'FACILITY_ID'
        | 'ORGANISATION_ID'
        | 'LAB_RECEIVED_AT'
    type: 'COUNT'
    interval?: 'MINUTE' | 'HOUR' | 'DAY' | 'MONTH' | 'YEAR' | 'ALL'
}

export function useSamplesAggregateQuery(
    variables: Variables,
    pause: boolean,
    requestPolicy: RequestPolicy = 'network-only'
) {
    return useQuery({
        pause,
        query,
        variables,
        requestPolicy,
    })
}
