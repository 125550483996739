/* e38990870cb1e6697087f3a1cb2d51c14f08d647
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../__types__';

import gql from 'graphql-tag';
import * as React from 'react';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AcceptTeamInviteMutationVariables = Types.Exact<{
  input: Types.AcceptTeamInviteInput;
}>;


export type AcceptTeamInviteMutation = { __typename?: 'Mutation', acceptTeamInvite: { __typename?: 'Team', teamId: string, name: string } };


export const AcceptTeamInviteDocument = gql`
    mutation acceptTeamInvite($input: acceptTeamInviteInput!) {
  acceptTeamInvite(input: $input) {
    teamId
    name
  }
}
    `;

export const AcceptTeamInviteComponent = (props: Omit<Urql.MutationProps<AcceptTeamInviteMutation, AcceptTeamInviteMutationVariables>, 'query'> & { variables?: AcceptTeamInviteMutationVariables }) => (
  <Urql.Mutation {...props} query={AcceptTeamInviteDocument} />
);


export function useAcceptTeamInviteMutation() {
  return Urql.useMutation<AcceptTeamInviteMutation, AcceptTeamInviteMutationVariables>(AcceptTeamInviteDocument);
};