/* eslint-disable react/display-name */
import {
	createStyles,
	createTheme,
	makeStyles,
	MuiThemeProvider,
	TableBody as MuiTableBody,
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import classNames from 'classnames'
import MUIDataTable, {
	MUIDataTableBody,
	MUIDataTableColumn,
	MUIDataTableColumnDef,
	MUIDataTableMeta,
	MUIDataTableOptions,
	TableBody,
	TableBodyCell,
	TableBodyRow,
} from 'mui-datatables'
import * as React from 'react'
import { useCallback, useEffect, useMemo } from 'react'
import { useHashState } from '~/app/hooks/useHashState'
import { usePopup } from '~/app/providers/popup'
import { useApps, useCurrentTeam, usePermissions } from '../../../providers/user'
import { CustomAppFlags } from '../../interfaces/apps'
import { OperatorInput } from '../../interfaces/operatorInput'
import { Button, ButtonTypes } from '../button/button'
import { Button as MBButton } from '@material-ui/core'
import { CertificatePopup } from '../certificate/certificate'
import { Icon } from '../icon/ficon'
import { Loader } from '../loader/loader'
import { useSamplesQuery } from './queries/useSamplesQuery'
import { SampleEdge, SampleResult, SampleStatus, SampleType } from './samples'
import { TimestampPrint } from './samplesHelpers'
import * as styles from './samplesList.scss'
import { SamplesListRowDetail } from './samplesListRowDetail'
import SamplesWarningCell from './samplesWarningCell'

import { useLocalStorage } from '~/app//hooks/useLocalStorage'
import { Role } from '@graphql-enums@'
import { BasicPopup } from '../basicPopup/basicPopup'
import { CSVLink } from 'react-csv'
import { useSnackbar } from 'notistack'
import { slug } from '../portalGenerate/portalGenerate'
import moment from 'moment'
import { SampleRa } from '@graphql-types@'
import { useSamplesExportQuery } from './queries/samplesExport.graphql'
import { useTimezone } from '../../../hooks/useTimezone'
import { createCSVDateTimeString } from './../../helpers/csvDateTimeString'

export function SamplesExport(props): JSX.Element {
	const { where } = props

	const [cursor, setCursor] = React.useState<any>('')
	const [rows, setRows] = React.useState<any[]>([])
	const [ready, setReady] = React.useState<any>(false)

	const { clearPopup, setPopup } = usePopup()

	const [result] = useSamplesExportQuery({
		variables: {
			limit: 500,
			offset: 0,
			after: cursor,
			where,
			sort: {
				order: "DESCENDING",
			}
		},
	})

	const { closeSnackbar, enqueueSnackbar } = useSnackbar()

	const [team] = useCurrentTeam()
	const [tz] = useTimezone()
	//const datetimeformat:string = 'DD-MM-YYYY HH:mm:ss' // The format used for the CSV export. Excel can handle this format, except will not show the seconds, howeven seconds are available in the CSV and other spreadsheet programs like OSX Pages can handle the seconds.

	const csvLink = React.useRef<
		CSVLink & HTMLAnchorElement & { link?: HTMLAnchorElement }
	>()

	React.useEffect(() => {
		const { error, data, fetching } = result
		if (error) {
			console.error(error)
			return
		}

		if (fetching) return
		if (!data) return

		if (data.samples?.edges.length == 0) {
			setReady(true)

			setCSV({
				...csv,
				ready: true,
				filename: `export-sampleslist-${slug(team.name)}-${moment().format(
					'YYYYMMDDTHHmmss'
				)}.csv`,
				data: rows,
			})

			const action = (key) => (
				<>
					<MBButton
						onClick={() => {
							csvLink.current.link.click()
						}}
					>
						Download
					</MBButton>
					<MBButton
						onClick={() => {
							closeSnackbar(key)
						}}
					>
						Close
					</MBButton>
				</>
			)

			clearPopup()

			enqueueSnackbar(
				<span>
					The export file is <b>ready</b>, please download.
					<CSVLink
						className={'invisible'}
						data={rows}
						filename={`export-sampleslist-${slug(team.name)}-${moment().format(
							'YYYYMMDDTHHmmss'
						)}.csv`}
						ref={csvLink}
						target="_blank"
					>
						download {csv.filename}
					</CSVLink>
				</span>,
				{
					persist: true,
					variant: 'success',
					action,
				}
			)
			return
		}

		const cursor = data.samples?.edges.reduce((cursor, edge) => {
			return edge.cursor
		}, "")

		setRows([
			...rows,
			...data.samples?.edges.map((edge) => ({
				code: edge.node.code,
				// The date-time is all converted to the user's timzone setting, and displayed as dd-mm-yyy hh:mm:ss in the csv export.
				submitted: (createCSVDateTimeString(edge.node.submitted, tz)), 
				received: (createCSVDateTimeString(edge.node.received, tz)),
				tested: (createCSVDateTimeString(edge.node.tested, tz)),
				notified: (createCSVDateTimeString(edge.node.notified, tz)),
				status: edge.node.status,
				result: edge.node.result,
				submittedBy: edge.node.submittedBy,
				userAgent: edge.node.userAgent,
				testType: edge.node.testType,
				message: edge.node.message,
				language: edge.node.language,
				guess: (edge.node as SampleRa)?.guess,
				aiResult: (edge.node as SampleRa)?.aiResult,
				score: (edge.node as SampleRa)?.score,
				certainty: (edge.node as SampleRa)?.certainty,
				imageURL: (edge.node as SampleRa)?.image?.imageURL,
				template: (edge.node as SampleRa)?.image?.template,
				apiVersion: (edge.node as SampleRa)?.image?.apiVersion,
				qr: (edge.node as SampleRa)?.image?.qr,
				organisation: edge.node.organisation?.name,
			})),
		])

		setCursor(cursor)
	}, [result])

	const [csv, setCSV] = React.useState({
		ready: false,
		data: [],
		filename: '',
	})

	return (
        <>
            <BasicPopup title="Export" size="s" className={styles.modalHeight}>
                {!ready && (
                    <div>
                        <Loader 
                            large 
                            center 
                            message="Preparing export file... "
                        />     
                    </div>     
                )} 
                { ready && (
                    <>Collected ({rows.length}) rows.</>
                )}
            </BasicPopup>
        </>
	)
}

export function SamplesList(): JSX.Element {
	// Filter states
	const [queryF, setQueryF] = useHashState<any>('q')
	const [statusF, setStatusF] = useHashState<any>('status')
	const [resultF, setResultF] = useHashState<any>('result')
	const [typeF, setTypeF] = useHashState<any>('type')
	const [submittedByF, setsubmittedByF] = useHashState<any>('submitted')

	const [rowsExpanded, setRowsExpanded] = React.useState<any[]>([])
	const [rowsPerPage, setRowsPerPage] = useHashState<any>('rows', 10)
	const [currentPage, setCurrentPage] = useHashState<any>('page', 1)

	const { clearPopup, setPopup } = usePopup()

	// List samples
	const where: OperatorInput = React.useMemo(() => {
		setCurrentPage(1) // Reset pagination if filters change
		const out: OperatorInput = {
			and: [],
		}
		if (queryF) out.and.push({ field: 'code', like: `%${queryF}%` })

		if (statusF) out.and.push({ field: 'status', eq: statusF })

		if (resultF) out.and.push({ field: 'result', eq: resultF })

		if (typeF) out.and.push({ field: 'testType', eq: typeF })

		if (submittedByF)
			out.and.push({ field: 'submittedBy', eq: submittedByF })
		return out
	}, [queryF, statusF, resultF, typeF, submittedByF])

	const [result, refresh] = useSamplesQuery(
		{
			limit: rowsPerPage,
			offset: (currentPage - 1) * rowsPerPage,
			where,
		},
		false
	)

	const samples: SampleEdge[] = result.data ? result.data?.samples?.edges : []
	const samplesCount: number = result.data
		? result.data.samples?.totalCount
		: 0
	const samplesRef = React.useRef<SampleEdge[]>()
	useEffect(() => {
		samplesRef.current = samples
	}, [samples])

	// Refresh results every 10 minutes
	useEffect(() => {
		const interval = setInterval(() => {
			refresh({ requestPolicy: 'network-only' })
		}, 60 * 1000 * 10 )
		return () => clearInterval(interval)
	}, [refresh])

	const [apps, hasApp] = useApps()

	const onCertificateClick = useCallback((testKitId: string) => {
		setPopup(<CertificatePopup testKitId={testKitId} />)
	}, [])

	const allColumns: MUIDataTableColumn[] = [
		{
			name: 'code',
			label: 'Sample ID',
			options: {
				hint: 'Sample ID',
				filter: false,
				display: true,
				sort: true,
				customBodyRenderLite: (dataIndex) => {
					const node = samplesRef.current[dataIndex]?.node
					return (
						<div className={styles.nowrap}>
							<span>{node.code}</span>
							{hasApp(CustomAppFlags.GenerateCertificate) &&
								node.status === SampleStatus.COMPLETED && (
									<Icon
										name="Award"
										className={classNames(
											styles.certBtn,
											'ms-1'
										)}
										small
										onClick={(e) => {
											e.stopPropagation()
											onCertificateClick(node.code)
										}}
									/>
								)}
						</div>
					)
				},
			},
		},
		{
			name: 'submittedBy',
			label: 'Entry',
			options: {
				display: true,
				filter: true,
				filterOptions: {
					names: ['APP', 'WEB', 'OTHER'],
				},
				filterType: 'dropdown',
				sort: false,
				searchable: false,
			},
		},
		{
			name: 'submitted',
			label: 'Submitted',
			options: {
				display: true,
				hint: 'User registered this test kit',
				filter: false,
				sort: true,
				searchable: false,
				customBodyRender: (value) => (
					<span className="badge bg-secondary">
						<TimestampPrint value={value} />
					</span>
				),
			},
		},
		{
			name: 'received',
			label: 'Manual report',
			options: {
				display: true,
				hint: 'Result reported by trained professional',
				filter: false,
				sort: true,
				searchable: false,
				customBodyRender: (value, tableMeta: MUIDataTableMeta) => (
					<SamplesWarningCell
						value={value}
						prevTimestamp={
							tableMeta.rowData[tableMeta.columnIndex - 1]
						}
						firstWarning={72}
						secondWarning={120}
					/>
				),
			},
		},
		{
			name: 'tested',
			label: 'Tested',
			options: {
				display: true,
				hint: 'Lab completed test and sent through Raven',
				filter: false,
				sort: true,
				searchable: false,
				customBodyRender: (value, tableMeta: MUIDataTableMeta) => {
					return (
						<SamplesWarningCell
							value={value}
							prevTimestamp={
								tableMeta.rowData[tableMeta.columnIndex - 1]
							}
							firstWarning={5}
							secondWarning={24}
						/>
					)
				},
			},
		},
		{
			name: 'notified',
			label: 'Notified',
			options: {
				display: true,
				hint: 'User has been notified of the result',
				filter: false,
				sort: true,
				searchable: false,
				customBodyRender: (value) => (
					<span className="badge bg-secondary">
						<TimestampPrint value={value} />
					</span>
				),
			},
		},
		{
			name: 'status',
			label: 'Status',
			options: {
				display: true,
				filter: true,
				filterOptions: {
					names: Object.values(SampleStatus).filter((k) =>
						isNaN(Number(k))
					),
				},
				filterType: 'dropdown',
				sort: false,
				searchable: false,
			},
		},
		{
			name: 'result',
			label: 'Final result',
			options: {
				display: true,
				filter: true,
				filterOptions: {
					names: Object.values(SampleResult).filter((k) =>
						isNaN(Number(k))
					),
				},
				filterType: 'dropdown',
				sort: false,
				searchable: false,
				customBodyRenderLite: (dataIndex) => {
					const val = samplesRef.current[dataIndex]?.node.result
					let setPill:string = "badge"
						switch(val) {
							case SampleResult.POSITIVE:
								setPill += " bg-danger"
								break;
							case SampleResult.NEGATIVE:
								setPill += " bg-success"
								break;
							case SampleResult.INCONCLUSIVE:
								setPill += " bg-warning"
								break;
							case SampleResult.REJECTED:
								setPill += " bg-warning"
								break;
							default:
								setPill += " bg-secondary" 
								break;
						}
									
					return val ? (
						<div className={styles.nowrap}>
							
							<span className={setPill}>{val}</span>
							{samplesRef.current[dataIndex]?.node.hasPII ? (
								<span title="PII uploaded">
									<Icon name="User" small className="ms-1" />
								</span>
							) : (
								''
							)}
						</div>
					) : (
						<span className="badge bg-secondary">N/A</span>
					)
				},
			},
		},
		{
			name: 'userAgent',
			label: 'UserAgent',
			options: {
				display: true,
				filter: false,
				sort: false,
				searchable: false,
				filterType: 'dropdown',
				customBodyRender: (value) =>
					value ? (
						<span className="badge bg-secondary">{value}</span>
					) : (
						''
					),
			},
		},
		{
			name: 'testType',
			label: 'Type',
			options: {
				display: true,
				filter: true,
				filterOptions: {
					names: hasApp(CustomAppFlags.RapidAntigen)
						? Object.values(SampleType).filter((k) =>
							isNaN(Number(k))
						)
						: Object.values(SampleType).filter(
							(k) => isNaN(Number(k)) && k !== SampleType.ra
						),
				},
				filterType: 'dropdown',
				sort: false,
				searchable: false,
				customBodyRender: (value) => (value ? value : 'N/A'),
			},
		},
		{
			name: 'tags',
			label: 'Tags',
			options: {
				display: false,
				filter: false,
				searchable: false,
				sort: false,
			},
		},
		{
			name: 'facility.name',
			label: 'Facility',
			options: {
				display: false,
				filter: false,
				searchable: false,
				sort: false,
			},
		},
		{
			name: 'organisation.name',
			label: 'Organisation',
			options: {
				display: false,
				filter: false,
				searchable: false,
				sort: false,
			},
		},
		{
			name: 'message',
			label: 'Message',
			options: {
				display: false,
				filter: false,
				searchable: false,
				sort: false,
			},
		},
		{
			name: 'guess',
			label: 'User result',
			options: {
				display: false,
				filter: false,
				searchable: false,
				sort: false,
			},
		},
		{
			name: 'aiResult',
			label: 'AI Result',
			options: {
				display: false,
				filter: false,
				searchable: false,
				sort: false,
			},
		},
	]

	const [selectedColumns, setSelectedColumns] = useLocalStorage<string[]>(
		'samplesColumns',
		allColumns.filter((a) => a.options.display).map((a) => a.name)
	)

	// update display state for selected columns
	const columns = allColumns.reduce((cols, col) => {
		const display =
			selectedColumns.findIndex((a: string) => a === col.name) >= 0

		return [
			...cols,
			{
				...col,
				options: {
					...col.options,
					display: display,
				},
			},
		]
	}, [])

	const [, hasPermission] = usePermissions()

	// Set options
	const options: MUIDataTableOptions = {
		enableNestedDataAccess: '.',
		serverSide: true,
		filterType: 'checkbox',
		searchOpen: true,
		searchPlaceholder: 'Search for a code...',
		download: hasPermission({ service: "portal", action: "samples:ActionDownloadData" }),
		rowsPerPageOptions: [10, 25, 50, 100],
		count: samplesCount,
		onChangeRowsPerPage: (numberOfRows) => setRowsPerPage(numberOfRows),
		onChangePage: (currentPage) => {
			setCurrentPage(currentPage + 1)
			setRowsExpanded([])
		},
		// Update column state on change
		onViewColumnsChange: (changedColumn, action) => {
			if (columns.some((n) => n.name === changedColumn)) {
				columns.find((n) => n.name === changedColumn).options.display =
					action === 'add'
				setSelectedColumns(
					columns.filter((a) => a.options.display).map((a) => a.name)
				)
			}
		},
		// Flatten the facility and organisation objects in the downloaded file
		onDownload: (buildHead, buildBody, columns: any[], data) => {
			setPopup(<SamplesExport where={where} />)

			return false
		},
		tableBodyMaxHeight: '70vh',
		print: false,
		selectableRows: 'none',
		sortOrder: { name: 'submitted', direction: 'desc' },
		searchText: queryF,
		textLabels: {
			body: {
				noMatch: result.fetching ? (
					<div>Loading data...</div>
				) : (
					'Sorry, there is no matching data to display'
				),
			},
		},
		onSearchChange: (searchText) => {
			setRowsExpanded([])
			setQueryF(searchText?.trim())
		},
		onFilterChange: (
			changedColumn,
			filterList,
			filterType,
			changedColumnIndex
		) => {
			setRowsExpanded([])
			switch (changedColumn) {
				case 'submittedBy': {
					setsubmittedByF(filterList[changedColumnIndex][0] ?? null)
					break
				}
				case 'testType': {
					setTypeF(
						SampleType[filterList[changedColumnIndex][0]] ?? null
					)
					break
				}
				case 'status': {
					setStatusF(
						SampleStatus[filterList[changedColumnIndex][0]] ?? null
					)
					break
				}
				case 'result': {
					setResultF(
						SampleResult[filterList[changedColumnIndex][0]] ?? null
					)
					break
				}
			}
		},
		// Expandable rows
		expandableRows: true,
		expandableRowsHeader: false,
		expandableRowsOnClick: true,
		rowsExpanded: rowsExpanded,
		isRowExpandable: () => true,
		renderExpandableRow: (rowData, rowMeta) => (
			<SamplesListRowDetail
				sample={samplesRef.current[rowMeta.dataIndex]?.node}
				colSpan={rowData.length + 1}
			/>
		),
		onRowExpansionChange: (_, allExpanded) =>
			setRowsExpanded(allExpanded.map((a) => a.dataIndex)),
	}

	const theme = createTheme({
		overrides: {
			MUIDataTableSelectCell: {
				root: {
					display: 'none',
				},
			},
			MuiTableCell: {
				head: {},
			},
		},
	})

	const useStyles = makeStyles(() =>
		createStyles({
			loading: {
				textAlign: 'center',
			},
		})
	)

	const LoadingTableBody = ({
		loading,
		options,
		columns,
		rowsPerPage,
		...others
	}: { loading: boolean } & MUIDataTableBody) => {
		// @ts-ignore
		const classes = useStyles()
		const rows = [...Array(rowsPerPage)]

		return loading ? (
			<MuiTableBody>
				{rows.map((row, rowIndex) => {
					return (
						<TableBodyRow options={options} key={rowIndex}>
							{columns
								.filter(
									(
										column: any
									): column is MUIDataTableColumn =>
										column?.display === 'true'
								)
								.map(
									(
										column: MUIDataTableColumn,
										columnIndex
									) => (
										<TableBodyCell
											key={column?.name}
											// @ts-ignore
											options={options}
											colIndex={columnIndex}
											rowIndex={rowIndex}
										>
											<Skeleton variant="text" />
										</TableBodyCell>
									)
								)}
						</TableBodyRow>
					)
				})}
			</MuiTableBody>
		) : (
			<TableBody options={options} columns={columns} {...others} />
		)
	}

	const { fetching } = result

	const BodyComponent = useMemo(
		() => (props: MUIDataTableBody) => (
			<LoadingTableBody loading={fetching} {...props} />
		),
		[fetching]
	)

	const dataTable = useMemo(
		() => (
			<MuiThemeProvider theme={theme}>
				<MUIDataTable
					title={''}
					data={samples?.map((edge) => edge.node)}
					columns={columns}
					options={options}
					components={{ TableBody: BodyComponent }}
				/>
			</MuiThemeProvider>
		),
		[samples, fetching]
	)

	return (
		<div>
			{result.fetching && !samples && (
				<div className={styles.stat}>
					<Loader message={'Loading samples'} large />
				</div>
			)}

			{!!samples && (
				<div className={styles.tableWrap}>
					<Button
						className={classNames(
							{ [styles.loading]: result.fetching },
							styles.refreshBtn
						)}
						type={ButtonTypes.nothing}
						title="Refresh"
						onClick={(e) =>
							refresh({ requestPolicy: 'network-only' })
						}
						icon="RefreshCw"
					></Button>
					{dataTable}
				</div>
			)}
		</div>
	)
}
