/* a218eb8a95a45ac32e84bd3ed33e1445e65003b4
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../__types__';

import gql from 'graphql-tag';
import * as React from 'react';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SamplesExportQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int'];
  offset: Types.Scalars['Int'];
  after: Types.Scalars['String'];
  where?: Types.InputMaybe<Types.OperatorInput>;
  sort: Types.SortInput;
}>;


export type SamplesExportQuery = { __typename?: 'Query', samples?: { __typename?: 'SamplesConnection', totalCount: number, edges: Array<{ __typename?: 'SampleEdge', cursor?: string | null, node: { __typename?: 'Sample', hasPII?: boolean | null, code: string, submitted: any, received?: any | null, tested?: any | null, notified?: any | null, status?: Types.StatusEnum | null, result?: Types.ResultEnum | null, submittedBy?: string | null, tags?: Array<string> | null, userAgent?: string | null, testType?: string | null, message?: string | null, language?: string | null, organisation?: { __typename?: 'Team', name: string, teamId: string } | null, facility?: { __typename?: 'Facility', name: string, teamId: string } | null } | { __typename?: 'SampleRA', guess?: Types.GuessEnum | null, aiResult?: Types.ResultEnum | null, score?: number | null, certainty?: number | null, code: string, submitted: any, received?: any | null, tested?: any | null, notified?: any | null, status?: Types.StatusEnum | null, result?: Types.ResultEnum | null, submittedBy?: string | null, tags?: Array<string> | null, userAgent?: string | null, testType?: string | null, message?: string | null, language?: string | null, image?: { __typename?: 'SampleImage', imageURL: string, template: string, qr: string, apiVersion?: string | null, createdAt: any } | null, organisation?: { __typename?: 'Team', name: string, teamId: string } | null, facility?: { __typename?: 'Facility', name: string, teamId: string } | null } } | null>, pageInfo?: { __typename?: 'NextPageInfo', hasNextPage?: boolean | null } | null } | null };


export const SamplesExportDocument = gql`
    query samplesExport($limit: Int!, $offset: Int!, $after: String!, $where: OperatorInput, $sort: SortInput!) {
  samples(
    first: $limit
    offset: $offset
    after: $after
    where: $where
    sort: $sort
  ) {
    totalCount
    edges {
      node {
        code
        submitted
        received
        tested
        notified
        status
        result
        submittedBy
        tags
        userAgent
        testType
        message
        language
        ... on Sample {
          hasPII
        }
        ... on SampleRA {
          guess
          aiResult
          score
          certainty
          image {
            imageURL
            template
            qr
            apiVersion
            createdAt
          }
        }
        organisation {
          name
          teamId
        }
        facility {
          name
          teamId
        }
      }
      cursor
    }
    pageInfo {
      hasNextPage
    }
  }
}
    `;

export const SamplesExportComponent = (props: Omit<Urql.QueryProps<SamplesExportQuery, SamplesExportQueryVariables>, 'query'> & { variables: SamplesExportQueryVariables }) => (
  <Urql.Query {...props} query={SamplesExportDocument} />
);


export function useSamplesExportQuery(options: Omit<Urql.UseQueryArgs<SamplesExportQueryVariables>, 'query'>) {
  return Urql.useQuery<SamplesExportQuery>({ query: SamplesExportDocument, ...options });
};