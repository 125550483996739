import { TeamApp } from '@graphql-types@'
import {
    codeTestingEnabled,
    generateCertificateEnabled,
    rapidAntigenEnabled,
    batchesEnabled,
    sampleApprovalsEnabled,
    uploadLabExportEnabled,
} from '../helpers/featureFlags'

export interface CustomApp {
    appId: string
    slug: string
    description: string
    name: string
    createdAt: string
    updatedAt: string
}

export interface CustomAppEdge {
    app: CustomApp
}

/** CustomApp slug names via the API */
export enum CustomAppFlags {
    Portal = 'portal',
    CodeTesting = 'code-testing',
    SampleApprovals = 'sample-approvals',
    RapidAntigen = 'rapid-antigen',
    Batches = 'batches',
    GenerateCertificate = 'generate-certificate',
    UploadLabExport = 'upload-lab-export',
    NoCard = 'no-card',
    CreateTeam = 'create-team',
}

// App state
export interface CustomApps {
    CodeTesting: boolean
    SampleApprovals: boolean
    RapidAntigen: boolean
    Batches: boolean
    GenerateCertificate: boolean
    UploadLabExport: boolean
}

export const DecideCustomApps = (apps: TeamApp[]): CustomApps => ({
    CodeTesting:
        apps?.some((a) => a.app.slug === CustomAppFlags.CodeTesting) ||
        codeTestingEnabled(),
    SampleApprovals:
        apps?.some((a) => a.app.slug === CustomAppFlags.SampleApprovals) ||
        sampleApprovalsEnabled(),
    RapidAntigen:
        apps?.some((a) => a.app.slug === CustomAppFlags.RapidAntigen) ||
        rapidAntigenEnabled(),
    Batches:
        apps?.some((a) => a.app.slug === CustomAppFlags.Batches) ||
        batchesEnabled(),
    GenerateCertificate:
        apps?.some((a) => a.app.slug === CustomAppFlags.GenerateCertificate) ||
        generateCertificateEnabled(),
    UploadLabExport:
        apps?.some((a) => a.app.slug === CustomAppFlags.UploadLabExport) ||
        uploadLabExportEnabled(),
})
