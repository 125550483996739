import { BasicPopup } from '../../portal/components/basicPopup/basicPopup'
import { useCallback, useEffect, useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useFormatMessage } from '../../portal/helpers/intlContext'
import { TextInput } from '../../portal/components/textInput/textInput'
import { Button, ButtonTypes } from '../../portal/components/button/button'
import { PopupFooter } from '../../portal/components/popupFooter/popupFooter'
import { usePopup } from '~/app/providers/popup'
import { useEnrollOtpMutation } from '../mutations/enrollOTP.graphql'
import { useEnableOtpMutation } from '../mutations/enableOTP.graphql'
import { useSnackbar } from 'notistack'

export function EnableOtpForm(): JSX.Element {
    const [image, setImage] = useState<string>()
    const [string, setString] = useState<string>()
    const [invalidCodes, setInvalidCodes] = useState<string[]>([])

    const t = useFormatMessage()

    const [, enrollOtp] = useEnrollOtpMutation()
    const [, enableOtp] = useEnableOtpMutation()

    useEffect(() => {
        enrollOtp().then((response) => {
            setImage(response.data.enrollOTP.image)
            setString(response.data.enrollOTP.string)
        })
    }, [])

    const validationSchema = Yup.object().shape({
        code: Yup.string()
            .required(t('form.required'))
            .notOneOf(invalidCodes, 'Code is invalid'),
    })

    const { enqueueSnackbar } = useSnackbar()

    const { clearPopup } = usePopup()

    const onSubmit = useCallback((data, { validateForm }) => {
        enableOtp({ code: data.code }).then((response) => {
            if (response.data) {
                enqueueSnackbar(<span>2FA is now enabled.</span>, {
                    variant: 'success',
                })

                clearPopup()
            } else {
                setInvalidCodes([data.code])
                validateForm()
            }
        })
    }, [])

    return (
        <Formik
            initialValues={{ code: '' }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isValid,
            }) => (
                <BasicPopup title="Enable 2FA">
                    <p>
                        Scan the QR-code below with an app like{' '}
                        <a href="https://authy.com/">Authy</a> to setup
                        two-factor authentication.
                    </p>
                    <div className="d-flex flex-column align-items-center">
                        {image && <img src={image} />}

                        {string && (
                            <p>
                                <strong>{string}</strong>
                            </p>
                        )}
                    </div>
                    <form onSubmit={handleSubmit}>
                        <label className="form-label">Token</label>
                        <TextInput
                            title="Token"
                            name="code"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.code}
                            error={errors.code}
                            touched={touched.code}
                            autocomplete="one-time-code"
                            autoFocus
                        />

                        <PopupFooter>
                            <Button
                                disabled={!isValid}
                                type={ButtonTypes.primary}
                                onClick={handleSubmit}
                                submit={true}
                            >
                                Validate
                            </Button>
                        </PopupFooter>
                    </form>
                </BasicPopup>
            )}
        </Formik>
    )
}
