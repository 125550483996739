import { Route, RouteProps, Redirect } from 'react-router-dom'
import { NotAuthorized } from './notAuthorized/notAuthorized'
import useAuth, { useApps, useCurrentTeam, usePermissions } from '../../providers/user'
import { FullScreenLoading } from '../../portal/components/fullScreenLoading/fullScreenLoading'
import { Role } from '@graphql-types@'
import { CustomAppFlags } from '~/app/portal/interfaces/apps'

export interface AuthRouteProps extends RouteProps {
	apps?: CustomAppFlags[]
	service: string
	action: string
}

/** Route that requires user to be authenticated. */
export function AuthRoute(props: AuthRouteProps): JSX.Element {
	const { service, action, apps } = props

	const { me, authenticated, authenticating } = useAuth()

	const [currentTeam] = useCurrentTeam()
	const [, hasPermission] = usePermissions()
	const [, hasApp] = useApps()

	// check if app is authorized
	if (apps && apps?.length > 0) {
		if (
			!apps.reduce((authorized, app) => {
				return authorized || hasApp(app)
			}, false)
		) {
			console.warn(`User not authorized for app ${apps}.`)
			return <NotAuthorized />
		}
	}

	if (!hasPermission({ service, action })) {
		console.warn(
			`User not authorized for service ${service} and action ${action}.`
		)
		return <NotAuthorized />
	}

	return <Route {...props} />
}
