/* c69f3c5030fc6751140df7979cfe6748b33ce35a
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../__types__';

import gql from 'graphql-tag';
import * as React from 'react';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RemoveOtpMutationVariables = Types.Exact<{
  code: Types.Scalars['String'];
}>;


export type RemoveOtpMutation = { __typename?: 'Mutation', removeOTP: { __typename?: 'User', userId: string } };


export const RemoveOtpDocument = gql`
    mutation removeOTP($code: String!) {
  removeOTP(code: $code) {
    userId
  }
}
    `;

export const RemoveOtpComponent = (props: Omit<Urql.MutationProps<RemoveOtpMutation, RemoveOtpMutationVariables>, 'query'> & { variables?: RemoveOtpMutationVariables }) => (
  <Urql.Mutation {...props} query={RemoveOtpDocument} />
);


export function useRemoveOtpMutation() {
  return Urql.useMutation<RemoveOtpMutation, RemoveOtpMutationVariables>(RemoveOtpDocument);
};