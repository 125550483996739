import { devtoolsExchange } from '@urql/devtools'
import { cacheExchange, Entity } from '@urql/exchange-graphcache'
import { IntrospectionData } from '@urql/exchange-graphcache/dist/types/ast/schema'
import { createClient as createWSClient, Sink } from 'graphql-ws'
import moment from 'moment-timezone'
import { useSnackbar } from 'notistack'
import React from 'react'
import {
    createClient,
    dedupExchange,
    fetchExchange,
    makeOperation,
    gql,
    Provider,
    subscriptionExchange,
    makeResult,
} from 'urql'
import { GraphCacheConfig } from '~/graphql.tsx'
import schema from '../../../../graphql.schema.json'
import { useTeamId, useTimezone, useToken } from '../../providers/store'
import { createErrorExchange } from '../helpers/errorExchange'
import { getBackendHost } from '../helpers/getBackendHost'
import { RefreshDocument, useRefreshMutation } from '../../auth/mutations/refresh.graphql'
interface Props {
    children: React.ReactNode
}
import { relayPagination } from './relayPagination'
import { authExchange } from '@urql/exchange-auth'

const ClientContext = React.createContext<any>({
    // this is just to satisfy the TS compiler. If you're using JS you can just omit the default value.
    // resetClient: always(undefined),
}) as any

type CacheFunctions = {
    invalidate: (typeNames: string[]) => void
    resetClient: () => void
}

export function GraphQlProvider(props: Props) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const [tz] = useTimezone()
    const [teamId] = useTeamId()
    // this will convert the timezone to offset and pass to through to the server
    const offset = moment().tz(tz).utcOffset()
    const [token, setToken] = useToken()

    const onError = React.useCallback((error) => {
        // we want to group similar errors, not sending anotification for each individual
        if (error?.networkError) {
            console.error(error?.networkError)

            enqueueSnackbar(`Could not connect to server.`, {
                key: 'network_error',
                variant: 'error',
                preventDuplicate: true,
            })
            return
        }

        error?.graphQLErrors.forEach((e) => {
            if (e.message == 'not_authenticated') {
                enqueueSnackbar('Authentication failed.', {
                    key: e.message,
                    variant: 'error',
                    preventDuplicate: true,
                })
            } else if (e.message === 'user_notfound') {
                enqueueSnackbar('Authentication failed.', {
                    key: e.message,
                    variant: 'error',
                    preventDuplicate: true,
                })
            } else if (e.message === 'password_invalid') {
                enqueueSnackbar('Authentication failed.', {
                    key: e.message,
                    variant: 'error',
                    preventDuplicate: true,
                })
            } else if (e.message === 'otp_invalid_code') {
                //
            } else if (e.message === 'token_notfound') {
                //
            } else if (e.message === 'token_expired') {
                //
            } else if (e.message === 'user_exists') {
                //
            } else {
                console.error(e.message)

                // we need to find a way to group error messages
                enqueueSnackbar(e.message, {
                    key: e.path[0] as string,
                    variant: 'error',
                    preventDuplicate: true,
                })
            }
        })
    }, [])

    const getAuth = async ({ authState, mutate }) => {
        if ( !authState ) {
            if ( token ) {
                return { token }
            }
        }

        // do refresh token?
        const result = await mutate(RefreshDocument, {}, {
            fetchOptions: {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        })

        if ( result.data?.refresh ) {
            return { token: result.date?.refresh }
        }

        setToken(null)
        return null
    }

    const addAuthToOperation = ({ authState, operation }) => {
        if (!authState || !authState.token) {
            return operation
        }

        const fetchOptions =
            typeof operation.context.fetchOptions === 'function'
                ? operation.context.fetchOptions()
                : operation.context.fetchOptions || {}

        return makeOperation(operation.kind, operation, {
            ...operation.context,
            fetchOptions: {
                ...fetchOptions,
                headers: {
                    ...fetchOptions.headers,
                    Authorization: `Bearer ${token}`,
                    'X-Team-Id': teamId ? teamId : '',
                },
            },
        })
    }

    const didAuthError = ({ error }) => {
        /*  console.log('didAuthError', error)  */
        return error.graphQLErrors.some(
            (e) =>
                e.message === 'not_authenticated' ||
                e.extensions?.code === 'FORBIDDEN'
        )
    }

    const willAuthError = ({ operation, authState }) => {
        /*  console.log('WillAuthErr', operation, authState) */

        if (!authState) {
            // Detect our login mutation and let this operation through:
            return !(
                operation.kind === 'mutation' &&
                // Here we find any mutation definition with the "login" field
                operation.query.definitions.some((definition) => {
                    return (
                        definition.kind === 'OperationDefinition' &&
                        definition.selectionSet.selections.some((node) => {
                            // The field name is just an example, since signup may also be an exception
                            return (
                                node.kind === 'Field' &&
                                (node.name.value === 'login' ||
                                    node.name.value === 'signup' ||
                                    node.name.value === 'checkUsername')
                            )
                        })
                    )
                })
            )
        } else if (false /* JWT is expired */) {
            return true
        }
        return false
    }

    const client = React.useMemo(() => {
        const wsUrl = new URL(getBackendHost())
        wsUrl.protocol = {
            'https:': 'wss:',
            'http:': 'ws:',
        }[wsUrl.protocol]

        wsUrl.pathname = '/query'

        const wsClient = createWSClient({
            url: wsUrl.toString(),
            connectionParams: {
                Authorization: token ? `Bearer ${token}` : '',
                'X-Team-Id': teamId ? teamId : '',
                'X-Time-Offset': `${offset}`,
            },
        })

        return createClient({
            url: getBackendHost() + '/query',
            fetchOptions: {
                headers: {
                    'X-Host': window.location.hostname,
                },
            },
            maskTypename: true,
            exchanges: [
                devtoolsExchange,
                dedupExchange,
                cacheExchange<GraphCacheConfig>({
                    schema: schema as IntrospectionData,
                    // https://formidable.com/open-source/urql/docs/graphcache/normalized-caching/#custom-keys-and-non-keyable-entities
                    keys: {
                        Sample: (data) => data.code,
                        SampleRA: (data) => data.code,
                        SampleImage: (data) => data.imageId,
                        SampleComment: (data) => data.commentId,
                        App: (data) => data.appId,
                        Team: (data) => data.teamId,
                        TeamInvite: (data) => data.teamInviteId,
                        Permission: (data) => data.permissionId,
                        User: (data) => data.userId,
                        Facility: (data) => data.teamId,
                        TeamApp: (data) => null,
                        AuditEntry: (data) => null,
                        SampleBucket: () => null,
                    },
                    resolvers: {
                        UsersConnection: {
                            edges: (parent) => {
                                // remove empty nodes (in case of deletion)
                                return parent.edges?.filter((edge) => edge.node)
                            },
                        },
                        Query: {},
                    },
                    updates: {
                        Mutation: {
                            enableOTP(_result, _args, cache) {
                                // we've updated the otp status
                                const me = cache.resolve(
                                    'Query',
                                    'me'
                                ) as Entity
                                const userId = cache.resolve(
                                    me,
                                    'userId'
                                ) as any

                                // we've updated the otp status
                                const fragment = gql`
                                    fragment _ on User {
                                        userId
                                        otpEnabled
                                    }
                                `

                                cache.writeFragment(fragment, {
                                    userId: userId,
                                    otpEnabled: true,
                                })
                            },
                            removeOTP(_result, _args, cache) {
                                const me = cache.resolve(
                                    'Query',
                                    'me'
                                ) as Entity
                                const userId = cache.resolve(
                                    me,
                                    'userId'
                                ) as any

                                // we've updated the otp status
                                const fragment = gql`
                                    fragment _ on User {
                                        userId
                                        otpEnabled
                                    }
                                `

                                cache.writeFragment(fragment, {
                                    userId: userId,
                                    otpEnabled: false,
                                })
                            },
                            // we've confirmed the email address
                            confirmEmail(_result, _args, cache) {
                                const me = cache.resolve(
                                    'Query',
                                    'me'
                                ) as Entity
                                const userId = cache.resolve(
                                    me,
                                    'userId'
                                ) as any

                                // we've updated the otp status
                                const fragment = gql`
                                    fragment _ on User {
                                        userId
                                        emailConfirmed
                                    }
                                `

                                cache.writeFragment(fragment, {
                                    userId: userId,
                                    emailConfirmed: true,
                                })
                            },
                            createTeam(_result, _args, cache) {
                                // we've added a new team to me
                                cache.invalidate('Query', 'me')
                            },
                            changeTeam(_result, _args, cache) {
                                /*
							  console.log('changeTeam', result, args, cache, info);


							  cache.invalidate({
								__typename: 'teamInvites',
							  });
							  */
                                cache.invalidate('Query', 'me')
                            },
                            inviteUser(_result, args, cache, _info) {
                                const fields = cache
                                    .inspectFields('Query')
                                    .filter(
                                        (field) =>
                                            field.fieldName === 'teamInvites'
                                    )
                                    .forEach((field) => {
                                        cache.invalidate(
                                            'Query',
                                            field.fieldKey
                                        )
                                    })
                            },

                            updateUser(_result, args, cache) {
                                const fragment = gql`
                                    fragment _ on User {
                                        fullName
                                        role
                                    }
                                `

                                cache.writeFragment(fragment, {
                                    userId: args.input.userId,
                                    fullName: args.input.fullName,
                                    role: args.input.role,
                                })
                                // cache.invalidate('Query', 'me');
                            },
                            acceptTeamInvite(_result, _args, cache) {
                                // teams is updated now
                                cache.invalidate('Query', 'me')
                            },
                            updateTeam(_result, _args, cache, _info) {
                                const fields = cache
                                    .inspectFields('Team')
                                    .forEach((field) => {
                                        //  console.log('updateTeam', field)
                                        cache.invalidate(
                                            'Query',
                                            field.fieldKey
                                        )
                                    })
                            },
                            cancelTeamInvite(_result, args, cache, _info) {
                                cache.invalidate({
                                    __typename: 'TeamInvite',
                                    teamInviteId: args.teamInviteId,
                                })
                            },
                            removeUserFromTeam(_result, args, cache, _info) {
                                cache.invalidate({
                                    __typename: 'User',
                                    userId: args.input.userId,
                                })
                            },
                        },
                    },
                }),
                createErrorExchange(onError),
                authExchange({
                    addAuthToOperation,
                    getAuth,
                    didAuthError,
                    willAuthError,
                }),
                fetchExchange,
                subscriptionExchange({
                    forwardSubscription: (operation) => {
                        return {
                            subscribe: (sink) => {
                                const response = wsClient.subscribe(
                                    operation,
                                    (sink as unknown) as Sink
                                )
                                return {
                                    unsubscribe: response,
                                }
                            },
                        }
                    },
                }),
            ],
        })
    }, [tz, token, teamId])

    return <Provider value={client}>{props.children}</Provider>
}
