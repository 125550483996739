/* 818513906dd299ed1f6e7657791bd7d7b9f2e9cd
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../__types__';

import gql from 'graphql-tag';
import * as React from 'react';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ConfirmEmailMutationVariables = Types.Exact<{
  token: Types.Scalars['String'];
}>;


export type ConfirmEmailMutation = { __typename?: 'Mutation', confirmEmail: { __typename?: 'User', emailConfirmed: boolean } };


export const ConfirmEmailDocument = gql`
    mutation confirmEmail($token: String!) {
  confirmEmail(token: $token) {
    emailConfirmed
  }
}
    `;

export const ConfirmEmailComponent = (props: Omit<Urql.MutationProps<ConfirmEmailMutation, ConfirmEmailMutationVariables>, 'query'> & { variables?: ConfirmEmailMutationVariables }) => (
  <Urql.Mutation {...props} query={ConfirmEmailDocument} />
);


export function useConfirmEmailMutation() {
  return Urql.useMutation<ConfirmEmailMutation, ConfirmEmailMutationVariables>(ConfirmEmailDocument);
};