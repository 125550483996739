/* 2173da995a9433f9d0ae310c79bf57c01212f27a
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../__types__';

import gql from 'graphql-tag';
import * as React from 'react';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ExchangeMutationVariables = Types.Exact<{
  input: Types.ExchangeInput;
}>;


export type ExchangeMutation = { __typename?: 'Mutation', exchange?: string | null };


export const ExchangeDocument = gql`
    mutation exchange($input: ExchangeInput!) {
  exchange(input: $input)
}
    `;

export const ExchangeComponent = (props: Omit<Urql.MutationProps<ExchangeMutation, ExchangeMutationVariables>, 'query'> & { variables?: ExchangeMutationVariables }) => (
  <Urql.Mutation {...props} query={ExchangeDocument} />
);


export function useExchangeMutation() {
  return Urql.useMutation<ExchangeMutation, ExchangeMutationVariables>(ExchangeDocument);
};