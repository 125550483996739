import { Skeleton, SkeletonProps } from '@material-ui/lab'

export const LoadingSkeleton = (
    props: {
        loading: boolean
        className?: string
        children?: React.ReactNode
    } & SkeletonProps
) => {
    const { loading, className, children, ...rest } = props

    return (
        <>
            {loading ? (
                <Skeleton className={className} {...rest} />
            ) : (
                <>{children}</>
            )}
        </>
    )
}
