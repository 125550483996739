// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".list__list--pMcPK{list-style:none;margin:0;padding:0}.list__container--Kgo0W{position:relative;height:100%}.list__overlay--vQAZf{position:absolute;pointer-events:none;background:rgba(255,255,255,.6);top:0;left:0;width:100%;height:100%;display:flex;justify-content:center;align-items:center}.list__overlay--vQAZf .list__spinner-border--4wzDT{width:80px;height:80px}", "",{"version":3,"sources":["webpack://./src/app/portal/components/list/list.scss"],"names":[],"mappings":"AAAA,mBACI,eAAA,CACA,QAAA,CACA,SAAA,CAGJ,wBACI,iBAAA,CACA,WAAA,CAGJ,sBACI,iBAAA,CACA,mBAAA,CACA,+BAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CACA,WAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CAEA,mDACI,UAAA,CACA,WAAA","sourcesContent":[".list {\n    list-style: none;\n    margin: 0;\n    padding: 0;\n}\n\n.container {\n    position: relative;\n    height: 100%;\n}\n\n.overlay {\n    position: absolute;\n    pointer-events: none;\n    background: rgba(255, 255, 255, 0.6);\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    .spinner-border {\n        width: 80px;\n        height: 80px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "list__list--pMcPK",
	"container": "list__container--Kgo0W",
	"overlay": "list__overlay--vQAZf",
	"spinner-border": "list__spinner-border--4wzDT"
};
export default ___CSS_LOADER_EXPORT___;
