/* 624ed972c38831581e31c11cdf58f2b09c657d02
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../__types__';

import gql from 'graphql-tag';
import * as React from 'react';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type EnableOtpMutationVariables = Types.Exact<{
  code: Types.Scalars['String'];
}>;


export type EnableOtpMutation = { __typename?: 'Mutation', enableOTP: { __typename?: 'User', userId: string } };


export const EnableOtpDocument = gql`
    mutation enableOTP($code: String!) {
  enableOTP(code: $code) {
    userId
  }
}
    `;

export const EnableOtpComponent = (props: Omit<Urql.MutationProps<EnableOtpMutation, EnableOtpMutationVariables>, 'query'> & { variables?: EnableOtpMutationVariables }) => (
  <Urql.Mutation {...props} query={EnableOtpDocument} />
);


export function useEnableOtpMutation() {
  return Urql.useMutation<EnableOtpMutation, EnableOtpMutationVariables>(EnableOtpDocument);
};