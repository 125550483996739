import * as React from 'react'
import * as styles from './button.scss'
import classNames from 'classnames'
import { Icon } from '../icon/ficon'

import { Link } from 'react-router-dom'
import { IconName } from '../../config'

export enum ButtonTypes {
    superPrimary,
    primary,
    secondary,
    danger,
    dangerLight,
    transparent,
    transparentDanger,
    nothing,
    link,
}

export interface Props {
    type: ButtonTypes
    children?: React.ReactNode
    onClick?: (event) => void
    onMouseDown?: (event) => void
    className?: string
    submit?: boolean
    disabled?: boolean
    icon?: IconName
    iconstyle?: React.CSSProperties
    title?: string
    iconOnRight?: boolean
    tabIndex?: number
    small?: boolean
    spinning?: boolean
}

export function Button(props: Props): JSX.Element {
    const iconObj = props.icon ? (
        <Icon name={props.icon} style={props.iconstyle} />
    ) : null
    return (
        <button
            disabled={props.disabled}
            type={props.submit ? 'submit' : 'button'}
            onClick={props.onClick ? props.onClick : null}
            onMouseDown={props.onMouseDown ? props.onMouseDown : null}
            title={props.title}
            tabIndex={props.tabIndex}
            className={classNames(props.className, styles.button, {
                [styles.primary]: props.type === ButtonTypes.primary,
                [styles.superPrimary]: props.type === ButtonTypes.superPrimary,
                [styles.secondary]: props.type === ButtonTypes.secondary,
                [styles.danger]: props.type === ButtonTypes.danger,
                [styles.dangerLight]: props.type === ButtonTypes.dangerLight,
                [styles.transparent]: props.type === ButtonTypes.transparent,
                [styles.transparentDanger]:
                    props.type === ButtonTypes.transparentDanger,
                [styles.nothing]: props.type === ButtonTypes.nothing,
                [styles.link]: props.type === ButtonTypes.link,
                [styles.iconOnRight]: props.iconOnRight,
                [styles.hasIcon]: !!iconObj,
                [styles.small]: props.small,
            })}
        >
            <>
                {props.spinning && !props.iconOnRight && (
                    <span className="spinner-border spinner-border-sm " />
                )}
                {!props.spinning && !props.iconOnRight && iconObj}
                {props.children && (
                    <div className={styles.content}>{props.children}</div>
                )}
                {!props.spinning && props.iconOnRight && iconObj}
                {props.spinning && props.iconOnRight && (
                    <span className="spinner-border spinner-border-sm ms-2" />
                )}
            </>
        </button>
    )
}

interface LinkProps {
    type: ButtonTypes
    children?: React.ReactNode
    className?: string
    icon?: IconName
    iconOnRight?: boolean
    to: string
    small?: boolean
}

export function ButtonLink(props: LinkProps) {
    const iconObj = props.icon ? <Icon name={props.icon} small /> : null

    return (
        <Link
            to={props.to}
            className={classNames(
                props.className,
                styles.button,
                styles.linkButton,
                {
                    [styles.primary]: props.type === ButtonTypes.primary,
                    [styles.superPrimary]:
                        props.type === ButtonTypes.superPrimary,
                    [styles.secondary]: props.type === ButtonTypes.secondary,
                    [styles.danger]: props.type === ButtonTypes.danger,
                    [styles.dangerLight]:
                        props.type === ButtonTypes.dangerLight,
                    [styles.transparent]:
                        props.type === ButtonTypes.transparent,
                    [styles.transparentDanger]:
                        props.type === ButtonTypes.transparentDanger,
                    [styles.nothing]: props.type === ButtonTypes.nothing,
                    [styles.link]: props.type === ButtonTypes.link,
                    [styles.iconOnRight]: props.iconOnRight,
                    [styles.hasIcon]: !!iconObj,
                    [styles.small]: props.small,
                }
            )}
        >
            {iconObj && (
                <>
                    {!props.iconOnRight && iconObj}
                    {props.children && (
                        <div className={styles.content}>{props.children}</div>
                    )}
                    {props.iconOnRight && iconObj}
                </>
            )}
            {!iconObj && props.children}
        </Link>
    )
}
