export const isDevelopment = (): boolean => {
    const { hostname } = window.location

    if (
        hostname.includes('-dev') ||
        hostname === 'localhost' ||
        hostname === '127.0.0.1' ||
        hostname === 'lab.develop.dtact.com'
    ) {
        return true
    }

    return process.env.IS_PRODUCTION === '0'
}

export const isStaging = (): boolean => {
    const { hostname } = window.location

    if (
        hostname.includes('-staging') ||
        hostname === 'staging-raven.dtact.com'
    ) {
        return true
    }
    return false
}

export const getRavenEnvironment = (): RavenEnvironment => {
    if (isDevelopment()) {
        return RavenEnvironment.Development
    } else if (isStaging()) {
        return RavenEnvironment.Staging
    } else {
        return RavenEnvironment.Production
    }
}

export enum RavenEnvironment {
    Development = 'Development',
    Staging = 'Staging',
    Production = 'Production',
}
