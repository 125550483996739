import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { useCallback } from 'react'
import { BasicPopup } from '../../../portal/components/basicPopup/basicPopup'
import { SubmitButton } from '../../../portal/components/submitButton/submitButton'
import { PopupFooter } from '../../../portal/components/popupFooter/popupFooter'
import { useUser } from '../../hooks/useUser'
import { SelectRole } from '../selectRole/selectRole'
import { useUpdateUserMutation } from '../../mutations/updateUser.graphql'
import { User } from '@graphql-types@'
import { usePopup } from '~/app/providers/popup'
import { useCurrentTeam, usePermissions } from '~/app/providers/user'
import { useSnackbar } from 'notistack'
import { PermissionComponent } from '~/app/components/permission/permission'

interface Props {
    user: User
}

export function UserEdit(props: Props): JSX.Element {
    const { user } = props

    const currentUser = useUser()

    const [, hasPermission] = usePermissions()


    const [currentTeam] = useCurrentTeam()

    const [, updateUser] = useUpdateUserMutation()
    const { clearPopup } = usePopup()

    const { enqueueSnackbar } = useSnackbar()

    const onSubmit = useCallback(
        (data) => {
            updateUser({
                input: {
                    userId: user.userId,
                    fullName: user.fullName,
                    role: data.role,
                },
            }).then((response) => {
                if (response?.error != null) {
                    // console.log('Error: ', response?.error.message)
                    enqueueSnackbar(
                        <span>
                            Failed updating <b>{data.fullName}</b>, please try
                            again later.
                        </span>,
                        { variant: 'error' }
                    )
                } else {
                    enqueueSnackbar(
                        <span>
                            Updated user <b>{data.fullName}</b>.
                        </span>,
                        { variant: 'success' }
                    )
                    clearPopup()
                }
            })
        },
        [user]
    )
    
    return (
        <PermissionComponent
            service={'portal'}
            action={'team:UpdateUserRole'}
            >
            <Formik
                initialValues={user}
                onSubmit={onSubmit}
                validationSchema={Yup.object().shape({
                    role: Yup.string().required(),
                })}
            >
                {({
                    values,
                    isSubmitting,
                    isValid,
                    setFieldValue,
                    setFieldTouched,
                }) => (
                    <BasicPopup title={'Edit ' + user.fullName}>
                        <Form>
                            <SelectRole
                                value={values.role}
                                label={<>Role in {currentTeam.name}</>}
                                onChange={(value) => setFieldValue('role', value)}
                                onBlur={(isTouched) =>
                                    setFieldTouched('role', isTouched)
                                }
                            />
    
                            <PopupFooter>
                                <SubmitButton
                                    title="Save"
                                    disabled={!isValid}
                                    saving={isSubmitting}
                                />
                            </PopupFooter>
                        </Form>
                    </BasicPopup>
                )}
            </Formik>
        </PermissionComponent>
    )
}
