import { Facility, Team } from '@graphql-types@'

export interface Sample {
    code: string
    submitted: string
    received?: string
    tested?: string
    notified?: string
    status: SampleStatus
    result?: SampleResult
    submittedBy: 'APP' | 'WEB' | string
    message?: string
    tags?: any[]
    userAgent?: string
    testType?: SampleType
    language: string
    facility?: Facility
    organisation?: Team

    // PCR:
    hasPII?: boolean

    // RA:
    image?: SampleImage
    score?: number
    certainty?: number
    guess?: SampleResult
    images?: SampleImage[] // Errored images
}

export interface SampleImage {
    score: number
    code: string
    imageId: string
    apiVersion: string
    imageURL: string
    template: string
    qr?: string
    createdAt: string
    updatedAt: string

    // Additional info from image API:
    statusCode: number
    operation?: string
    expectedConditions?: boolean
    validationMessage?: string
}

export enum SampleResult {
    POSITIVE = 'POSITIVE',
    NEGATIVE = 'NEGATIVE',
    INCONCLUSIVE = 'INCONCLUSIVE',
    REJECTED = 'REJECTED',
}
export enum SampleStatus {
    PENDING = 'PENDING',
    LAB_RECEIVED = 'LAB_RECEIVED',
    COMPLETED = 'COMPLETED',
    RETESTING = 'RETESTING',
    REQUIRES_APPROVAL = 'REQUIRES_APPROVAL',
}
export enum SampleType {
    swab = 'swab',
    saliva = 'saliva',
    ra = 'ra',
    ra_batch = 'ra_batch',
}

// const resultOptions = Object.values(SampleResult).filter(k => isNaN(Number(k))).map(s => { return { value: s, label: s } })

export interface ImageEdge {
    cursor: string
    node: SampleImage
}

export interface SampleEdge {
    cursor: string
    node: Sample
}

// "buckets"
export interface SampleAgg {
    count: number
    field: string
    value: string
}
