import * as React from 'react'
import {
    Switch,
    Route,
    Redirect,
    withRouter,
    useRouteMatch,
    useHistory,
    useLocation,
    generatePath,
} from 'react-router-dom'
import { Fragment, Suspense, useEffect } from 'react'
import { getRoutes, RouteConfig } from '../helpers/routes'
import { isDevelopment } from '../helpers/getRavenEnvironment'
import { Paths } from '../helpers/routePaths'
import { AuthRoute } from '../../auth/components/authRoute'
import { UnauthRoute } from '../../auth/components/unauthRoute'
import { useSetSentryUser } from '../../auth/hooks/useSetSentryUser'
import { useCheckNewVersion } from '../hooks/useCheckNewVersion'
import { getBackendHost } from '../../graphQl/helpers/getBackendHost'
import {
    useEmailVerifyToken,
    useTeamId,
    useTeamInviteToken,
    useToken,
} from '../../providers/store'
import useAuth, {
    AuthProvider,
    useApps,
    useCurrentTeam,
} from '../../providers/user'
import { useSnackbar } from 'notistack'
import { Button } from '@material-ui/core'
import { gql, useMutation, useSubscription } from 'urql'
import { useDoChangeTeam } from '../../auth/hooks/useDoChangeTeam'
import { useAcceptTeamInviteMutation } from '~/app/auth/mutations/acceptTeamInvite.graphql'
import { Team } from '@graphql-types@'
import { useVerifyEmailMutation } from '~/app/auth/mutations/verifyEmail.graphql'
import { useConfirmEmailMutation } from '~/app/auth/mutations/confirmEmail.graphql'
import { useGetTeamInviteMutation } from '~/app/auth/mutations/getTeamInvite.graphql'
import { useExchangeMutation } from './exchange.graphql'
import { PopupManager } from './popupManager/popupManager'
import { FullScreenLoading } from './fullScreenLoading/fullScreenLoading'
import { RouteNotFound } from './routeNotFound/routeNotFound'
import { useRefreshMutation } from '~/app/auth/mutations/refresh.graphql'
import qs from 'qs'
import { qsOptions } from './qsOptions'

const Connection = () => {
    const newMessages = gql`
        subscription Health {
            health {
                health
            }
        }
    `

    const [res] = useSubscription(
        { query: newMessages },
        (messages = [], response) => {
            return []
        }
    )

    return <></>
}

const SSOLogin = () => {
    const match = useRouteMatch('/sso/:slug')

    const location = useLocation()

    // eslint-disable-next-line no-useless-escape
    const search = qs.parse(location.search.replace(/^\#/, ''), {
        ignoreQueryPrefix: true,
    })

    const [, exchange] = useExchangeMutation()

    const [, setToken] = useToken()

    const history = useHistory<{ path: string; hash: string }>()

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    // should we move this code to main?
    React.useEffect(() => {
        if (!match) return

        if (!search?.code) return

        exchange({
            input: {
                code: search?.code,
                session_state: search?.session_state,
                state: search?.state,
            },
        }).then((data) => {
            const token = data?.data?.exchange ?? null
            if (data?.error) {
                enqueueSnackbar(<span>Could not login.</span>, {
                    persist: false,
                    variant: 'error',
                })

                history.replace(Paths.logIn)
                return
            }

            if (!token) return

            // we also want to set teamId and retrieve me
            setToken(token)

            enqueueSnackbar(<span>Logged in succesfully.</span>, {
                persist: false,
                variant: 'success',
            })

            history.replace(Paths.activity)
        })
    }, [])

    return null
}

const OtpEnableNotification = () => {
    const { me } = useAuth()

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const history = useHistory<{ path: string; hash: string }>()

    const onClick = React.useCallback(() => {
        history.push(generatePath(Paths.settingsAccount))
    }, [])

    useEffect(() => {
        // customized
        const action = (key) => (
            <Fragment>
                <Button onClick={() => onClick()}>Enable</Button>
                <Button
                    onClick={() => {
                        closeSnackbar(key)
                    }}
                >
                    Dismiss
                </Button>
            </Fragment>
        )

        enqueueSnackbar(
            <span>
                Secure your account by enabling 2 factor authentication.
            </span>,
            {
                persist: true,
                variant: 'info',
                action,
            }
        )
    }, [])

    return <></>
}

const ConfirmEmailNotification = () => {
    const [, verifyEmail] = useVerifyEmailMutation()

    const { me } = useAuth()

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const onClick = React.useCallback((key) => {
        closeSnackbar(key)

        verifyEmail().then(() => {
            enqueueSnackbar(
                <span>
                    A verification email has been sent to <b>{me.email}</b>.
                </span>,
                {
                    persist: false,
                    variant: 'info',
                }
            )
        })
    }, [])

    const [emailVerifyToken, setEmailVerifyToken] = useEmailVerifyToken()

    useEffect(() => {
        if (emailVerifyToken) return

        // customized
        const action = (key) => (
            <Fragment>
                <Button onClick={() => onClick(key)}>Resend</Button>
                <Button
                    onClick={() => {
                        closeSnackbar(key)
                    }}
                >
                    Dismiss
                </Button>
            </Fragment>
        )

        enqueueSnackbar(
            <span>
                Please confirm your email address <b>{me.email}</b>.
            </span>,
            {
                persist: true,
                variant: 'info',
                action,
            }
        )
    }, [emailVerifyToken])

    const [, confirmEmail] = useConfirmEmailMutation()

    useEffect(() => {
        if (!emailVerifyToken) return

        confirmEmail({
            token: emailVerifyToken,
        }).then(({ error }) => {
            setEmailVerifyToken(null)

            if (
                error?.graphQLErrors?.some((e) => e.message === 'token_expired')
            ) {
                enqueueSnackbar(
                    `The email verification token has been expired.`,
                    {
                        variant: 'error',
                    }
                )

                return
            }

            enqueueSnackbar(
                <span>
                    Your email address <b>{me.email}</b> has been confirmed now.
                </span>,
                {
                    variant: 'info',
                }
            )
        })
    }, [emailVerifyToken])

    return <></>
}

const TeamJoinNotification = () => {
    const [teamInviteToken, setTeamInviteToken] = useTeamInviteToken()

    const [, getTeamInvite] = useGetTeamInviteMutation()

    const [team, setTeam] = React.useState<Team>()

    const [, setTeamId] = useTeamId()

    useEffect(() => {
        if (!teamInviteToken) return

        getTeamInvite({ token: teamInviteToken }).then(({ data, error }) => {
            // console.log('getTeamInvite', data, error)
            if (
                error?.graphQLErrors?.some((e) => e.message === 'token_expired')
            ) {
                enqueueSnackbar(`The team invitation has been expired.`, {
                    variant: 'error',
                })

                setTeamInviteToken(null)
                return
            }

            if (!data?.getTeamInvite) return

            setTeam(data?.getTeamInvite)
        })
    }, [teamInviteToken])

    const [, acceptTeamInvite] = useAcceptTeamInviteMutation()

    const doChangeTeam = useDoChangeTeam()

    const onOpenTeam = React.useCallback((key, acceptTeamInvite) => {
        closeSnackbar(key)

        const action = (key) => (
            <Fragment>
                <Button
                    onClick={() => {
                        closeSnackbar(key)
                    }}
                >
                    Dismiss
                </Button>
            </Fragment>
        )

        doChangeTeam(acceptTeamInvite.teamId).then(() => {
            enqueueSnackbar(`Changed to team '${acceptTeamInvite.name}'.`, {
                variant: 'info',
                action,
            })
        })
    }, [])

    const onJoinTeam = React.useCallback(
        (key) => {
            closeSnackbar(key)

            acceptTeamInvite({
                input: {
                    token: teamInviteToken,
                },
            }).then(({ data, error }) => {
                if (error) return

                const { acceptTeamInvite } = data

                if (!acceptTeamInvite) {
                    return
                }

                const action = (key) => (
                    <Fragment>
                        <Button
                            onClick={(key) => onOpenTeam(key, acceptTeamInvite)}
                        >
                            Open
                        </Button>
                        <Button
                            onClick={() => {
                                closeSnackbar(key)
                            }}
                        >
                            Dismiss
                        </Button>
                    </Fragment>
                )

                enqueueSnackbar(
                    `You've been added to the '${acceptTeamInvite.name} team.`,
                    {
                        variant: 'info',
                        action,
                    }
                )

                setTeamInviteToken(null)

                return
            })
        },
        [teamInviteToken]
    )

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    useEffect(() => {
        if (!team) return

        // customized
        const action = (key) => (
            <Fragment>
                <Button onClick={() => onJoinTeam(key)}>Join</Button>
                <Button
                    onClick={() => {
                        closeSnackbar(key)
                    }}
                >
                    Dismiss
                </Button>
            </Fragment>
        )

        enqueueSnackbar(`Do you want to join team '${team.name}'?`, {
            persist: true,
            variant: 'info',
            action,
        })
    }, [team])

    return null
}

const NoMatch = ({ location }) => (
    <h3>
        No match for <code>{location.pathname}</code>
    </h3>
)

export function withAuthProvider(Component) {
    return () => (
        <AuthProvider>
            <Component />
        </AuthProvider>
    )
}

const ApplicationComponent = withAuthProvider(
    (props): JSX.Element => {
        const { me, authenticated, authenticating } = useAuth()

        let routes = getRoutes().filter((route) => route.authRequired)

        const history = useHistory()

        if (!authenticated) {
            console.log('Redirect to login ', Paths.logIn)

            history.replace(Paths.logIn)
            return null
        }

        if (!me) {
            return <FullScreenLoading message={'Authenticating...'} />
        }

        return (
            <Suspense
                fallback={
                    <FullScreenLoading message={'Loading components...'} />
                }
            >
                <Switch>
                    {routes.map((route, i) => (
                        <AuthRoute
                            key={i}
                            exact={route.exact}
                            component={route.component}
                            path={route.paths}
                            service={route.service}
                            action={route.action}
                            apps={route.apps}
                        >
                            {route.children}
                        </AuthRoute>
                    ))}
                    <Route path="/" component={RouteNotFound} />
                </Switch>

                <Connection />
                {<TeamJoinNotification />}
                {!me.emailConfirmed && <ConfirmEmailNotification />}
                {!me.otpEnabled && <OtpEnableNotification />}
                <PopupManager />
            </Suspense>
        )
    }
)

export default function Main(): JSX.Element {
    const routes = getRoutes()

    let unauthRoutes = routes.filter((route) => route.notAuthRequired)
    let sharedRoutes = routes.filter(
        (route) => !route.authRequired && !route.notAuthRequired
    )

    // todo(remco): move to own provider? or component?
    useCheckNewVersion()

    useEffect(() => {
        console.log('Backend host', getBackendHost())
        console.log('Development mode enabled', isDevelopment())
        console.log('Release version', process.env.RELEASE_VERSION)
        console.log('Client version', process.env.CLIENT_VERSION)
        console.log('Last commit date', process.env.LAST_COMMIT_DATE)
    }, [])

    const location = useLocation()
    const history = useHistory()

    const [emailVerifyToken, setEmailVerifyToken] = useEmailVerifyToken()

    useEffect(() => {
        const params = qs.parse(location.hash.replace(/^\#/, ''), qsOptions)

        if (params.action === 'email-verify') {
            const token = params.token as string
            setEmailVerifyToken(token)

            history.replace(location.pathname)
        }
    }, [location])

    const [teamInviteToken, setTeamInviteToken] = useTeamInviteToken()

    useEffect(() => {
        const params = qs.parse(location.hash.replace(/^\#/, ''), qsOptions)

        if (params.action === 'invite-team') {
            const teamInviteToken: string = params.token as string
            setTeamInviteToken(teamInviteToken)

            history.replace(location.pathname)
        }
    }, [location])

    return (
        <React.Fragment>
            {/* todo(remco): what is the best place for these providers?? */}
            <>
                <Switch>
                    {unauthRoutes.map((route, i) => (
                        <UnauthRoute
                            key={i}
                            exact={route.exact}
                            component={route.component}
                            path={route.paths}
                            redirectTo={Paths.activity}
                        />
                    ))}
                    {sharedRoutes.map((route, i) => (
                        <Route
                            key={i}
                            exact={route.exact}
                            component={route.component}
                            path={route.paths}
                        />
                    ))}
                    <Route component={ApplicationComponent} />
                    <Route path="/" component={RouteNotFound} />
                </Switch>

                <SSOLogin />
            </>
        </React.Fragment>
    )
}
