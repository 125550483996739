import moment from 'moment'
import { SamplesPieChartData } from '../../consentryDashboard/samplesPieChart'
import { SampleAgg } from '../samples'
import { useSamplesAggregateQuery } from './useSamplesAggregateQuery'

/** Get count of all samples submitted but pending */
export function useSamplesPending(): number {
    const [{ data }] = useSamplesAggregateQuery(
        {
            aggs: [
                {
                    field: 'STATUS',
                    type: 'COUNT',
                },
            ],
            where: {
                and: [{ field: 'status', eq: 'PENDING' }],
            },
        },
        false
    )

    if (data) {
        return (
            data.samplesAggregate?.buckets?.find(
                (b: SampleAgg) => b.value === 'PENDING'
            )?.count || 0
        )
    }

    return null
}

/** Get count of samples received in lab today */
export function useSamplesReceivedLabToday(): number {
    const [{ data }] = useSamplesAggregateQuery(
        {
            aggs: [
                {
                    field: 'LAB_RECEIVED_AT',
                    type: 'COUNT',
                    interval: 'DAY',
                },
            ],
            where: {
                and: [
                    //{ field: "received", eq: moment.utc().toISOString() } // TODO: Not supported by API yet
                ],
            },
        },
        false
    )

    if (data) {
        return (
            data.samplesAggregate?.buckets?.find(
                (b: SampleAgg) => b.value === moment.utc().toISOString()
            )?.count || 0
        ) //
    }

    return null
}

/** Get count of all samples pending testing in lab */
export function useSamplesPendingLab(): number {
    const [{ data }] = useSamplesAggregateQuery(
        {
            aggs: [
                {
                    field: 'STATUS',
                    type: 'COUNT',
                },
            ],
            where: {
                and: [{ field: 'status', eq: 'LAB_RECEIVED' }],
            },
        },
        false
    )

    if (data) {
        return (
            data.samplesAggregate?.buckets?.find(
                (b: SampleAgg) => b.value === 'LAB_RECEIVED'
            )?.count || 0
        )
    }

    return null
}

/** Get count of all samples results */
export function useSamplesResultAgg(): SamplesPieChartData[] {
    const [{ data }] = useSamplesAggregateQuery(
        {
            aggs: [
                {
                    field: 'RESULT',
                    type: 'COUNT',
                },
            ],
        },
        false
    )

    if (data) {
        return data.samplesAggregate?.buckets
            ?.filter((b: SampleAgg) => !!b.value)
            .map((b: SampleAgg) => {
                const val: SamplesPieChartData = {
                    label: b.value,
                    id: b.value.replace(/\w\S*/g, function (txt) {
                        return (
                            txt.charAt(0).toUpperCase() +
                            txt.substr(1).toLowerCase()
                        )
                    }),
                    value: b.count,
                }
                return val
            })
    }

    return null
}

/** Get count of all samples status */
export function useSamplesStatusAgg(): SamplesPieChartData[] {
    const [{ data }] = useSamplesAggregateQuery(
        {
            aggs: [
                {
                    field: 'STATUS',
                    type: 'COUNT',
                },
            ],
        },
        false
    )

    if (data) {
        return data.samplesAggregate?.buckets
            ?.filter((b: SampleAgg) => !!b.value && b.value != 'COMPLETED')
            .map((b: SampleAgg) => {
                const val: SamplesPieChartData = {
                    label: b.value,
                    id: b.value.replace(/\w\S*/g, function (txt) {
                        return (
                            txt.charAt(0).toUpperCase() +
                            txt.substr(1).toLowerCase()
                        )
                    }),
                    value: b.count,
                }
                return val
            })
    }

    return null
}

// Get count of all ra samples results
export function useRaSamplesResultAgg(): SamplesPieChartData[] {
    const [{ data }] = useSamplesAggregateQuery(
        {
            aggs: [
                {
                    field: 'RESULT',
                    type: 'COUNT',
                },
            ],
            where: {
                and: [{ field: 'type', eq: 'ra' }],
            },
        },
        false
    )

    if (data) {
        return data.samplesAggregate?.buckets
            ?.filter((b: SampleAgg) => !!b.value)
            .map((b: SampleAgg) => {
                const val: SamplesPieChartData = {
                    label: b.value,
                    id: b.value.replace(/\w\S*/g, function (txt) {
                        return (
                            txt.charAt(0).toUpperCase() +
                            txt.substr(1).toLowerCase()
                        )
                    }),
                    value: b.count,
                }
                return val
            })
    }

    return null
}

// Get count of all ra samples status
export function useRaSamplesStatusAgg(): SamplesPieChartData[] {
    const [{ data }] = useSamplesAggregateQuery(
        {
            aggs: [
                {
                    field: 'STATUS',
                    type: 'COUNT',
                },
            ],
            where: {
                and: [{ field: 'type', eq: 'ra' }],
            },
        },
        false
    )

    if (data) {
        return data.samplesAggregate?.buckets
            ?.filter((b: SampleAgg) => !!b.value && b.value != 'COMPLETED')
            .map((b: SampleAgg) => {
                const val: SamplesPieChartData = {
                    label: b.value,
                    id: b.value.replace(/\w\S*/g, function (txt) {
                        return (
                            txt.charAt(0).toUpperCase() +
                            txt.substr(1).toLowerCase()
                        )
                    }),
                    value: b.count,
                }
                return val
            })
    }

    return null
}
