import * as React from 'react'
import { Button, ButtonTypes } from '../button/button'
import { ConfirmAction } from '../confirmAction/confirmAction'
import * as styles from './approvalEdit.scss'
import Magnifier from 'react-magnifier'
import { AppLinks } from '../../config'
import { AppScreen } from '../appScreen/appScreen'
import { PrimaryContent } from '../primaryContent/primaryContent'
import { Field, Form, Formik } from 'formik'
import { ReactSelect } from '../reactSelect/reactSelect'
import * as Yup from 'yup'
import { SetPageTitle } from '../../helpers/setPageTitle'
import { useSamplesQuery } from '../samplesList/queries/useSamplesQuery'
import {
    Sample,
    SampleImage,
    SampleResult,
    SampleStatus,
} from '../samplesList/samples'
import { generatePath, RouteComponentProps, useHistory } from 'react-router-dom'
import { useCallback } from 'react'
import { useUpdateSample } from '../codeTesting/mutations/useUpdateSample'
import { LabeledInput } from '../labeledInput/labeledInput'
import { Paths } from '../../helpers/routePaths'
import classNames from 'classnames'
import { TimestampPrint } from '../samplesList/samplesHelpers'
import { useSnackbar } from 'notistack'
import Skeleton from '@material-ui/lab/Skeleton'
import moment from 'moment'
import { Checkbox } from '../checkbox/checkbox'
import { PermissionComponent } from '~/app/components/permission/permission'

interface SkeletonProps {
    loading?: any
    children?: any
}
export const LoadingSkeleton = (props: SkeletonProps) => {
    const { loading, children } = props

    return <>{loading ? <Skeleton /> : <>{children}</>}</>
}

//  const cardExample = require('../approvalSection/example-card.png')

type Props = RouteComponentProps<{ code: string }>

interface UpdateInput {
    outcome: { status: SampleStatus; result: SampleResult }
    message?: string
}

type Option = {
    label: string
    value: { status: SampleStatus; result: SampleResult }
}

const outcomeOptions: Option[] = [
    {
        value: {
            status: SampleStatus.COMPLETED,
            result: SampleResult.POSITIVE,
        },
        label: 'Positive',
    },
    {
        value: {
            status: SampleStatus.COMPLETED,
            result: SampleResult.NEGATIVE,
        },
        label: 'Negative',
    },
    // { value: { status: SampleStatus.COMPLETED, result: SampleResult.INCONCLUSIVE }, label: "Inconclusive" },
    {
        value: {
            status: SampleStatus.COMPLETED,
            result: SampleResult.REJECTED,
        },
        label: 'Reject this sample',
    },
]

export function ApprovalEdit(props: Props): JSX.Element {
    const { code } = props.match.params
    const [, setResultUpdated] = React.useState<boolean>(false)
    //  const [after, setAfter] = React.useState<string>('')
    const [, updateSample] = useUpdateSample()
    const [{ data, fetching }] = useSamplesQuery(
        {
            limit: 1,
            offset: 0,
            where: {
                field: 'code',
                eq: code,
            },
            after: '',
        },
        false
    )

    const history = useHistory<{ hash: string }>()

    const sample: Sample = React.useMemo(
        () => (data ? data?.samples?.edges[0]?.node : null),
        [data, fetching]
    )

    const cursor: string = React.useMemo(
        () => (data ? data?.samples?.edges[0]?.cursor : null),
        [data, fetching]
    )

    const [{ data: nextData }] = useSamplesQuery(
        {
            limit: 1,
            offset: 0,
            where: {
                // field: "code",
                // eq: code,
                and: [
                    {
                        field: 'status',
                        eq: SampleStatus.REQUIRES_APPROVAL,
                    },
                ],
            },
            after: cursor,
        },
        cursor == null
    )

    const onClickNext = useCallback(
        (cursor) => {
            if (!nextData?.samples?.edges[0]?.node.code) return

            const code = nextData?.samples?.edges[0]?.node.code
            history.push(
                generatePath(Paths.approvalEdit, {
                    code: code,
                })
            )
        },
        [nextData]
    )

    const images: SampleImage[] =
        sample?.images?.sort((a, b) =>
            moment(b.createdAt).diff(moment(a.createdAt))
        ) ?? []

    const [currentImage, setCurrentImage] = React.useState(0)

    const { enqueueSnackbar } = useSnackbar()

    // Update the sample to new chosen result/status
    const onUpdateSample = useCallback(
        (input: UpdateInput, { setSubmitting }) => {
            setSubmitting(true)
            updateSample({
                input: {
                    code: sample.code,
                    status: input.outcome.status,
                    result: input.outcome.result,
                    facilityId: sample.facility?.teamId,
                    message: input.message ?? null,
                },
            }).then((response) => {
                if (response.error || !response.data) {
                    enqueueSnackbar(<span>Could not update result.</span>, {
                        variant: 'error',
                    })
                } else {
                    enqueueSnackbar(
                        <span>
                            Result <b>{sample.code}</b> updated and approved.
                        </span>,
                        {
                            variant: 'success',
                        }
                    )

                    setResultUpdated(true)

                    if (!nextData?.samples?.edges[0]?.node.code) return

                    const code = nextData?.samples?.edges[0]?.node.code
                    history.push(
                        generatePath(Paths.approvalEdit, {
                            code: code,
                        })
                    )
                }
                setSubmitting(false)
            })
        },
        [updateSample, sample, nextData]
    )

    React.useEffect(() => {
        setCurrentImage(0)
    }, [code])

    // The image filter toggle
    const [checked, setChecked] = React.useState(false)
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked)
    }

    const filter = checked ? styles.raFilters : null

    return (
        <AppScreen activeAppLink={AppLinks.approvals}>
            <SetPageTitle title="Approvals Review" />
            <PrimaryContent>
                {!sample && !fetching ? (
                    <p>No data was found matching this code.</p>
                ) : (
                    <div className={styles.container}>
                        <PermissionComponent
                            service={'sample-approvals'}
                            action={'approvals:ViewReviewImage'}
                            >
                            <div className={styles.imgCol}>
                                {images?.[currentImage] && (
                                    <Magnifier
                                        src={images?.[currentImage]?.imageURL}
                                        width={'100%'}
                                        mgWidth={250}
                                        mgHeight={250}
                                        mgShape="square"
                                        zoomFactor={3.0}
                                        className={classNames(
                                            filter,
                                            styles.magnifierWrap
                                        )}
                                    />
                                )}
                            </div>
                        </PermissionComponent>

                        <div
                            className={classNames(
                                'container',
                                styles.boxContainer
                            )}
                        >
                            <h2 className="row">Review Rapid Antigen test</h2>

                            <div className="row">
                                <div
                                    className={classNames(
                                        'col col-md',
                                        styles.box
                                    )}
                                >
                                    <h3>Code used</h3>
                                    <span
                                        className={classNames(
                                            styles.boxContent
                                        )}
                                    >
                                        <LoadingSkeleton loading={fetching}>
                                            <code>{sample?.code}</code>
                                        </LoadingSkeleton>
                                    </span>
                                </div>

                                <div
                                    className={classNames(
                                        'col col-md',
                                        styles.box
                                    )}
                                >
                                    <h3>Submitted</h3>
                                    <span
                                        className={classNames(
                                            styles.boxContent
                                        )}
                                    >
                                        <LoadingSkeleton loading={fetching}>
                                            <TimestampPrint
                                                value={sample?.submitted}
                                            />
                                        </LoadingSkeleton>
                                    </span>
                                </div>

                                <div
                                    className={classNames(
                                        'col col-md',
                                        styles.box
                                    )}
                                >
                                    <h3>Language</h3>
                                    <span
                                        className={classNames(
                                            styles.boxContent
                                        )}
                                    >
                                        <LoadingSkeleton loading={fetching}>
                                            {sample?.language || 'EN'}
                                        </LoadingSkeleton>
                                    </span>
                                </div>
                            </div>

                            <div className="row">
                                <div
                                    className={classNames(
                                        'col col-4',
                                        styles.box
                                    )}
                                >
                                    <h3>Result determined</h3>
                                    <span
                                        className={classNames(
                                            styles.boxContent
                                        )}
                                    >
                                        <LoadingSkeleton loading={fetching}>
                                            {sample?.result}
                                        </LoadingSkeleton>
                                    </span>
                                </div>

                                {/* , {sample.certainty}% certain */}
                                <div
                                    className={classNames(
                                        'col col-4',
                                        styles.box
                                    )}
                                >
                                    <h3>User input</h3>
                                    <span
                                        className={classNames(
                                            styles.boxContent
                                        )}
                                    >
                                        <LoadingSkeleton loading={fetching}>
                                            {sample?.guess
                                                ? `${sample.guess}`
                                                : 'No user input recorded'}
                                        </LoadingSkeleton>
                                    </span>
                                </div>

                                <div
                                    className={classNames(
                                        'col col-4',
                                        styles.box
                                    )}
                                >
                                    <h3>QR</h3>
                                    <span
                                        className={classNames(
                                            styles.boxContent
                                        )}
                                    >
                                        <LoadingSkeleton loading={fetching}>
                                            <code>
                                                {sample?.image?.qr
                                                    ? sample.image.qr
                                                    : 'N/A'}
                                            </code>
                                        </LoadingSkeleton>
                                    </span>
                                </div>
                            </div>

                            <div className="row">
                                <div
                                    className={classNames(
                                        'col col-4',
                                        styles.box
                                    )}
                                >
                                    <h3>Facility</h3>
                                    <span
                                        className={classNames(
                                            styles.boxContent
                                        )}
                                    >
                                        <LoadingSkeleton loading={fetching}>
                                            {sample?.facility
                                                ? sample.facility.name
                                                : 'N/A'}
                                        </LoadingSkeleton>
                                    </span>
                                </div>
                                <div
                                    className={classNames(
                                        'col col-4',
                                        styles.box
                                    )}
                                >
                                    <h3>Organisation</h3>
                                    <span
                                        className={classNames(
                                            styles.boxContent
                                        )}
                                    >
                                        <LoadingSkeleton loading={fetching}>
                                            {sample?.organisation
                                                ? sample.organisation.name
                                                : 'N/A'}
                                        </LoadingSkeleton>
                                    </span>
                                </div>
                                <div
                                    className={classNames(
                                        'col col-4',
                                        styles.box
                                    )}
                                >
                                    <h3>Provider</h3>
                                    <span
                                        className={classNames(
                                            styles.boxContent
                                        )}
                                    >
                                        <LoadingSkeleton loading={fetching}>
                                            {sample?.image?.template
                                                ? sample.image.template
                                                : 'N/A'}
                                        </LoadingSkeleton>
                                    </span>
                                </div>
                            </div>
                            <PermissionComponent
                            service={'sample-approvals'}
                            action={'approvals:ViewReviewImage'}
                            >
                                <div
                                className={classNames(
                                    styles.checkbox,
                                    'mb-4 mt-4 g-0'
                                )}
                            >
                                <Checkbox
                                    label={'Apply image filter'}
                                    name={'Toggle filter'}
                                    checked={checked}
                                    onChange={handleChange}
                                    toggle
                                />
                            </div>

                            </PermissionComponent>


                            <PermissionComponent
                                service={'sample-approvals'}
                                action={'approvals:ViewReviewHistory'}
                                >
                                    <section className="mt-4">
                                    <h5 className="row">Picture history</h5>
                                    <LoadingSkeleton loading={fetching}>
                                        <>
                                            {images.map((image, i) => (
                                                <div
                                                    className={classNames('row', {
                                                        [styles.selected]:
                                                            currentImage === i,
                                                    })}
                                                    key={image.imageId}
                                                >
                                                    <div
                                                        className={classNames(
                                                            'col col-1 mb-1',
                                                            styles.box
                                                        )}
                                                    >
                                                        <span
                                                            className={
                                                                styles.boxContent
                                                            }
                                                        >
                                                            <Button
                                                                onClick={() =>
                                                                    setCurrentImage(
                                                                        i
                                                                    )
                                                                }
                                                                type={
                                                                    ButtonTypes.link
                                                                }
                                                            >
                                                                <img
                                                                    className={
                                                                        styles.thumbnail
                                                                    }
                                                                    src={
                                                                        image.imageURL
                                                                    }
                                                                />
                                                            </Button>
                                                        </span>
                                                    </div>
                                                    <div
                                                        className={classNames(
                                                            'col col-3 mt-1 mb-1 text-center text-wrap',
                                                            styles.box
                                                        )}
                                                    >
                                                        <span
                                                            className={classNames(
                                                                styles.boxContent
                                                            )}
                                                        >
                                                            <TimestampPrint
                                                                value={
                                                                    image.createdAt
                                                                }
                                                            />
                                                        </span>
                                                    </div>
                                                    <div
                                                        className={classNames(
                                                            'col col-2 mt-1 mb-1',
                                                            styles.box
                                                        )}
                                                    >
                                                        <span
                                                            className={
                                                                styles.boxContent
                                                            }
                                                        >
                                                            {image.operation}
                                                        </span>
                                                    </div>
                                                    <div
                                                        className={classNames(
                                                            'col col-1 mt-1 mb-1',
                                                            styles.box
                                                        )}
                                                    >
                                                        <span
                                                            className={
                                                                styles.boxContent
                                                            }
                                                        >
                                                            {image.score != null
                                                                ? image.score
                                                                : 'N/A'}
                                                        </span>
                                                    </div>
                                                    <div
                                                        className={classNames(
                                                            'col col-3 mt-1 mb-1 text-center text-wrap',
                                                            styles.box
                                                        )}
                                                    >
                                                        <span
                                                            className={
                                                                styles.boxContent
                                                            }
                                                        >
                                                            {
                                                                image.validationMessage
                                                            }
                                                        </span>
                                                    </div>
                                                    <div
                                                        className={classNames(
                                                            'col col-2 mt-1 mb-1 text-center',
                                                            styles.box
                                                        )}
                                                    >
                                                        <span
                                                            className={
                                                                styles.boxContent
                                                            }
                                                        >
                                                            {image.template}
                                                        </span>
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    </LoadingSkeleton>
                                </section>
                            </PermissionComponent>

                            <div className="row">
                                {sample?.status !=
                                    SampleStatus.REQUIRES_APPROVAL && (
                                    <>
                                        <h5 className="mt-4">
                                            No approval required.
                                        </h5>

                                        <Button
                                            onClick={() => onClickNext(cursor)}
                                            disabled={
                                                !nextData?.samples?.edges[0]
                                                    ?.node.code
                                            }
                                            type={ButtonTypes.secondary}
                                        >
                                            Next
                                        </Button>
                                    </>
                                )}
                                <PermissionComponent
                                    service={'sample-approvals'}
                                    action={'approvals:ActionApprovalForm'}
                                    >
                                    {sample?.status === SampleStatus.REQUIRES_APPROVAL && (
                                        <section className="mt-4">
                                            <h5 className="row">
                                                Choose result to send the user
                                            </h5>
                                            <Formik
                                                initialValues={{
                                                    outcome: null,
                                                    message: '',
                                                }}
                                                validateOnMount={true}
                                                enableReinitialize
                                                onSubmit={onUpdateSample}
                                                validationSchema={Yup.object().shape(
                                                    {
                                                        outcome: Yup.object().required(),
                                                    }
                                                )}
                                                >
                                                {({
                                                    values,
                                                    errors,
                                                    touched,
                                                    handleChange,
                                                    handleBlur,
                                                    isValid,
                                                    setFieldValue,
                                                    setFieldTouched,
                                                    isSubmitting,
                                                    submitForm,
                                                }) => (
                                                    <Form>
                                                        <p>
                                                            Your selection will be
                                                            sent to the user on
                                                            confirmation.
                                                        </p>
                                                        <Field
                                                            name="outcome"
                                                            component={({
                                                                field,
                                                                form,
                                                            }) => (
                                                                <ReactSelect
                                                                    name="outcome"
                                                                    label="Choose outcome"
                                                                    isMulti={false}
                                                                    options={
                                                                        outcomeOptions
                                                                    }
                                                                    value={outcomeOptions.find(
                                                                        (option) =>
                                                                            option.value ===
                                                                            field.value
                                                                    )}
                                                                    onChange={(
                                                                        option: Option
                                                                    ) =>
                                                                        form.setFieldValue(
                                                                            field.name,
                                                                            option.value
                                                                        )
                                                                    }
                                                                    onBlur={
                                                                        field.onBlur
                                                                    }
                                                                    className={
                                                                        styles.selectWrap
                                                                    }
                                                                />
                                                            )}
                                                        />

                                                        <LabeledInput
                                                            name="message"
                                                            title="Custom message (optional)"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.message}
                                                            error={errors.message}
                                                            touched={
                                                                touched.message
                                                            }
                                                            className={
                                                                styles.selectWrap
                                                            }
                                                        />

                                                        <Button
                                                            className={'me-2'}
                                                            onClick={() =>
                                                                onClickNext(cursor)
                                                            }
                                                            disabled={
                                                                !nextData?.samples
                                                                    ?.edges[0]?.node
                                                                    .code
                                                            }
                                                            type={
                                                                ButtonTypes.secondary
                                                            }
                                                        >
                                                            Next
                                                        </Button>

                                                        <ConfirmAction
                                                            disabled={
                                                                !isValid ||
                                                                isSubmitting
                                                            }
                                                            buttonType={
                                                                ButtonTypes.primary
                                                            }
                                                            icon="Save"
                                                            iconOnRight
                                                            dialogueTitle={
                                                                <>
                                                                    Do you wish to
                                                                    set the result
                                                                    as{' '}
                                                                    <strong>
                                                                        {
                                                                            values
                                                                                .outcome
                                                                                ?.result
                                                                        }
                                                                    </strong>
                                                                    ?
                                                                </>
                                                            }
                                                            dialogueText="Confirm"
                                                            dialogueIcon="Save"
                                                            dialogueType={
                                                                ButtonTypes.primary
                                                            }
                                                            onAction={submitForm}
                                                        >
                                                            <>
                                                                {isSubmitting && (
                                                                    <span className="spinner-border spinner-border-sm me-2" />
                                                                )}
                                                                Confirm{' '}
                                                                {/* &amp; review next */}
                                                            </>
                                                        </ConfirmAction>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </section>
                                    )}
                                </PermissionComponent>
                            </div>
                        </div>
                    </div>
                )}
            </PrimaryContent>
        </AppScreen>
    )
}
