import { useSnackbar } from 'notistack'
import { useEffect } from 'react'

export function useCheckNewVersion() {
    const version = process.env.CLIENT_VERSION

    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        const interval = setInterval(() => {
            fetch('/version.json?' + Date.now())
                .then((response) => response.json())
                .then((response) => {
                    if (version !== response.version) {
                        enqueueSnackbar(
                            <span>
                                A new portal version is available, please
                                refresh to update..
                            </span>,
                            {
                                persist: false,
                                variant: 'info',
                            }
                        )
                    }
                })
                .catch(() => {
                    console.error('Failed to fetch version information')
                })
        }, 120 * 1000)

        return () => clearInterval(interval)
    }, [])
}
