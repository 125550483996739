import { useCallback } from 'react'
import { useToken } from '../../providers/store'
import { setTeamInvitationToken } from '../authActions'

export function useLogout(redirect: string = null): () => void {
    const [, setToken] = useToken()

    return useCallback(() => {
        setToken(null)
        // window.location.replace(redirect || Paths.logIn);

        // Unset the team invite token just to make sure this thing is removed somewhere
        // if the system left it hanging in session storage
        setTeamInvitationToken(null)
    }, [redirect])
}
