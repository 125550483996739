import * as React from 'react'
import { useEffect, useState } from 'react'
import * as styles from './companyLogo.scss'
import { Logo } from '../logo/logo'
import { SmallLogo } from '../logo/small'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { Paths } from '../../helpers/routePaths'

export interface Props {
    small: boolean
}

export function CompanyLogo(props: Props): JSX.Element {
    const { small } = props
    const [logoComponent, setLogoComponent] = useState<JSX.Element>()

    useEffect(() => {
        setLogoComponent(
            small ? (
                <SmallLogo className={styles.logo} />
            ) : (
                <Logo className={styles.logo} />
            )
        )
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'))
        }, 500)
    }, [small])

    return (
        <div className={styles.logoWrap}>
            <Link
                to={Paths.activity}
                className={classNames(styles.container, {
                    [styles.expanded]: !small,
                })}
            >
                {logoComponent}
            </Link>
        </div>
    )
}
