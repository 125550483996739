import { useCallback, useState } from 'react'
import { validateForm } from '../../../portal/helpers/validateForm'
import { SubmitButton } from '../../../portal/components/submitButton/submitButton'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useFormatMessage } from '../../../portal/helpers/intlContext'
import { useUser } from '../../hooks/useUser'
import { LabeledInput } from '../../../portal/components/labeledInput/labeledInput'
import { passwordValidate } from '../../helpers/passwordValidate'
import { useUpdateUserMutation } from '../../mutations/updateUser.graphql'
import { useSnackbar } from 'notistack'

/**
UpdatePassword shows a Formik form with 3 fields:
- original password
- new password
- repeat new password (to make sure there are no typo's)

After user fills in the 3 fields correct the `save` button becomes active.
When user clicks `save` button the new password wil be set and a message will show
the action passed, or a error is something went wrong in the update process

Checks if password is complex enough, a password must contain
- a small character
- a capital character
- a digit
- a minimum of 12 characters
*/

export function UpdatePassword() {
    const t = useFormatMessage()
    const user = useUser()
    const [incorrectPasswords, setIncorrectPasswords] = useState<string[]>([])

    const { enqueueSnackbar } = useSnackbar()

    const [, updateUser] = useUpdateUserMutation()

    const getValidationSchema = useCallback((values) => {
        const isSettingPassword =
            !!values.currentPassword ||
            !!values.newPassword ||
            !!values.repeatPassword
        const passwordType = isSettingPassword
            ? Yup.string().required(t('form.required'))
            : Yup.string()

        return Yup.object().shape({
            currentPassword: passwordType.notOneOf(
                incorrectPasswords,
                'Incorrect password'
            ),
            newPassword: passwordType && passwordValidate,
            repeatPassword: passwordType.oneOf(
                [values.newPassword],
                t('form.passwordsMustMatch')
            ),
        })
    }, [])

    const onSubmit = useCallback(
        (data, { setSubmitting, setFieldError, resetForm }) => {
            const input = {
                fullName: data.fullName,
                userId: user.userId,
                currentPassword: data.currentPassword,
                newPassword: data.newPassword,
                role: user.role,
            }

            updateUser({ input }).then((response) => {
                setSubmitting(false)
                if (response.error) {
                    if (
                        response.error.graphQLErrors &&
                        response.error.graphQLErrors[0].message ===
                            'password_invalid'
                    ) {
                        setIncorrectPasswords(data.currentPassword)
                        setFieldError('currentPassword', 'Incorrect password')
                    } else {
                        enqueueSnackbar(
                            <span>
                                Something went wrong, please try again later.
                            </span>,
                            {
                                variant: 'error',
                            }
                        )
                    }
                } else {
                    resetForm()
                    enqueueSnackbar(
                        <span>Your password has been updated.</span>,
                        {
                            variant: 'success',
                        }
                    )
                }
            })
        },
        [user]
    )

    return (
        <Formik
            initialValues={{
                fullName: user.fullName,
                email: user.email,
                currentPassword: '',
                newPassword: '',
                repeatPassword: '',
            }}
            enableReinitialize={true}
            validate={(values) => validateForm(getValidationSchema, values)}
            onSubmit={onSubmit}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                isValid,
                dirty,
            }) => (
                <form onSubmit={handleSubmit}>
                    <LabeledInput
                        title="Current password"
                        name="currentPassword"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.currentPassword}
                        error={errors.currentPassword}
                        touched={touched.currentPassword}
                        type="password"
                    />
                    <LabeledInput
                        title="New password"
                        name="newPassword"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.newPassword}
                        error={errors.newPassword}
                        touched={touched.newPassword}
                        type="password"
                    />
                    <LabeledInput
                        title="Repeat new password"
                        name="repeatPassword"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.repeatPassword}
                        error={errors.repeatPassword}
                        touched={touched.repeatPassword}
                        type="password"
                    />
                    <SubmitButton
                        title="Save"
                        saving={isSubmitting}
                        disabled={!isValid || !dirty}
                    />
                </form>
            )}
        </Formik>
    )
}
