import { BasicPopup } from '../../../portal/components/basicPopup/basicPopup'
import { Button, ButtonTypes } from '../../../portal/components/button/button'
import { useDoChangeTeam } from '../../hooks/useDoChangeTeam'
import { useCallback } from 'react'
import * as styles from './switchTeams.scss'
import { useCurrentTeam, useTeams } from '../../../providers/user'
import { usePopup } from '~/app/providers/popup'
import { useSnackbar } from 'notistack'
import { Team } from '@graphql-types@'

export function SwitchTeams(): JSX.Element {
    const [currentTeam] = useCurrentTeam()

    const [teams] = useTeams()

    const otherTeams = teams?.filter(
        (team) => team.teamId !== currentTeam?.teamId
    )

    const doChangeTeam = useDoChangeTeam()

    const { clearPopup } = usePopup()

    const { enqueueSnackbar } = useSnackbar()

    const onSwitch = useCallback(
        (team: Team) => {
            clearPopup()

            doChangeTeam(team.teamId).then(() => {
                enqueueSnackbar(
                    <span>
                        Switched to team <b>{team.name}</b>.
                    </span>,
                    { variant: 'success' }
                )
            })
        },
        [doChangeTeam]
    )

    return (
        <BasicPopup title="Switch teams" size="s">
            {otherTeams?.length === 0 && (
                <p>
                    You are not a member of any teams besides{' '}
                    {currentTeam?.name}.
                </p>
            )}

            {teams?.length > 0 && (
                <ul className={styles.list}>
                    {teams
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((team) => (
                            <li key={team.teamId} className={styles.item}>
                                <Button
                                    disabled={
                                        team.teamId === currentTeam?.teamId
                                    }
                                    type={ButtonTypes.transparent}
                                    onClick={() => onSwitch(team)}
                                >
                                    {team.name}
                                </Button>
                                {team.teamId === currentTeam?.teamId && (
                                    <span className={styles.current}>
                                        CURRENT
                                    </span>
                                )}
                            </li>
                        ))}
                </ul>
            )}
        </BasicPopup>
    )
}
