import classNames from 'classnames'
import * as React from 'react'
import * as styles from './avatar.scss'

export type AvatarProps = {
    url: string
    small?: boolean
    className?: string
}

export function Avatar({
    url,
    small,
    className,
    ...rest
}: AvatarProps): JSX.Element {
    const aClass = classNames(className, styles.avatar, small ? styles.sm : '')
    return <img src={url} alt="Avatar" className={aClass} />
}
