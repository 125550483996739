// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".switchTeams__list--J4ZsD{margin:-1rem}.switchTeams__list--J4ZsD ul{margin-left:0rem}.switchTeams__item--v0vc2{border-bottom:1px solid var(--border-color);display:flex;align-items:center;padding:1rem}.switchTeams__item--v0vc2:last-child{border-bottom:none}.switchTeams__current--XQCD8{background:var(--background);border-radius:var(--border-radius);padding:0 .5rem;font-size:.9rem;text-transform:uppercase;display:inline-block;font-weight:bold;margin-left:auto}", "",{"version":3,"sources":["webpack://./src/app/auth/components/switchTeams/switchTeams.scss"],"names":[],"mappings":"AAAA,0BACI,YAAA,CAEA,6BACI,gBAAA,CAIR,0BACI,2CAAA,CACA,YAAA,CACA,kBAAA,CACA,YAAA,CAEA,qCACI,kBAAA,CAIR,6BACI,4BAAA,CACA,kCAAA,CACA,eAAA,CACA,eAAA,CACA,wBAAA,CACA,oBAAA,CACA,gBAAA,CACA,gBAAA","sourcesContent":[".list {\n    margin: -1rem;\n\n    ul {\n        margin-left: 0rem;\n    }\n}\n\n.item {\n    border-bottom: 1px solid var(--border-color);\n    display: flex;\n    align-items: center;\n    padding: 1rem;\n\n    &:last-child {\n        border-bottom: none;\n    }\n}\n\n.current {\n    background: var(--background);\n    border-radius: var(--border-radius);\n    padding: 0 0.5rem;\n    font-size: 0.9rem;\n    text-transform: uppercase;\n    display: inline-block;\n    font-weight: bold;\n    margin-left: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "switchTeams__list--J4ZsD",
	"item": "switchTeams__item--v0vc2",
	"current": "switchTeams__current--XQCD8"
};
export default ___CSS_LOADER_EXPORT___;
