import { RequestPolicy, useQuery } from 'urql'
import { OperatorInput } from '../../../interfaces/operatorInput'

const query = `
query samples($limit: Int!, $offset: Int!, $after: String!, $where: OperatorInput) {
    samples(first: $limit, offset: $offset, after: $after, where: $where) {
        totalCount
        edges {
            node {
              code
              submitted
              received
              tested
              notified
              status
              result
              submittedBy
              tags
              userAgent
              testType
              message
              language
              ... on Sample {
                hasPII
              }
              ... on SampleRA {
                guess
                aiResult
                score
                certainty
                image {
                  imageURL
                  template
                  qr
                  apiVersion
                  createdAt
                }
                images {
                  imageId
                  imageURL
                  statusCode
                  operation
                  validationMessage
                  expectedConditions
                  apiVersion
                  template
                  createdAt
                  updatedAt
                }
              }
              organisation {
                  name
                  teamId
              }
              facility {
                  name
                  teamId
              }

            }
            cursor
          }
        pageInfo {
        hasNextPage
        }
    }
}`

interface Variables {
    limit: number
    after?: string
    offset?: number
    where?: OperatorInput
}

export function useSamplesQuery(
    variables: Variables,
    pause: boolean = false,
    requestPolicy: RequestPolicy = 'network-only'
) {
    return useQuery({
        pause,
        query,
        variables: {
            after: '',
            ...variables,
        },
        requestPolicy,
    })
}
