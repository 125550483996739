import moment from 'moment-timezone'

export const time = {
    today: moment.utc().startOf('day'),
    thisWeek: moment.utc().startOf('isoWeek'),
    lastWeekMin: moment
        .utc()
        .startOf('isoWeek')
        .subtract(1, 'week')
        .startOf('isoWeek'),
    lastWeekMax: moment
        .utc()
        .endOf('isoWeek')
        .subtract(1, 'week')
        .endOf('isoWeek'),
    last30Days: moment.utc().startOf('day').subtract(30, 'days').startOf('day'),
    thisMonth: moment.utc().startOf('month'),
    lastMonthMin: moment.utc().subtract(1, 'month').startOf('month'),
    lastMonthMax: moment.utc().subtract(1, 'month').endOf('month'),
    lastQuarter: moment.utc().startOf('quarter'),
}
