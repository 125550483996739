import * as React from 'react'
import * as styles from './column.scss'
import classNames from 'classnames'
import { MobileBreadcrumbs } from '../mobileBreadcrumbs/mobileBreadcrumbs'

interface Props {
    children: React.ReactNode
    className?: string
    empty?: boolean
    sidebarWidth?: boolean
    listWidth?: boolean
    footer?: React.ReactNode
    disableScrollable?: boolean
}

export function Column(props: Props) {
    const hasFooter = !!props.footer

    const myClassNames = classNames(styles.column, props.className, {
        [styles.sidebarWidth]: props.sidebarWidth,
        [styles.listWidth]: props.listWidth,
        [styles.scrollable]: !props.disableScrollable,
        [styles.hasFooter]: hasFooter,
    })

    const inside = (
        <React.Fragment>
            <MobileBreadcrumbs />

            {props.children}

            {hasFooter && <div className={styles.footer}>{props.footer}</div>}
        </React.Fragment>
    )

    if (props.empty) {
        return <article className={myClassNames}>{inside}</article>
    }

    return <div className={myClassNames}>{inside}</div>
}
