import * as React from 'react'
import { useMemo, useState } from 'react'
import * as styles from './tabs.scss'
import { Button, ButtonTypes } from '../button/button'
import classNames from 'classnames'

export interface Props {
    children: React.ReactNode
    className?: string
    centerNav?: boolean
}

/** Returns a collection of `<Tab>`-elements, defined as children, with automatic navigation built for same. */
export function Tabs(props: Props): JSX.Element {
    const { children, className, centerNav } = props

    const tabs = useMemo(() => {
        const tabs: {
            title: string
            displayActive?: boolean
            element: React.ReactNode
            disabled?: boolean
        }[] = []

        React.Children.forEach(children, (element) => {
            if (
                React.isValidElement(element) &&
                element.props &&
                element.props.title
            ) {
                tabs.push({
                    title: element.props.title,
                    displayActive: element.props.displayActive,
                    element: element,
                    disabled: element.props.disabled,
                })
            }
        })
        return tabs
    }, [children])

    const [active, setActive] = useState<string>(tabs[0].title) // Set first item as active

    const activeTab = useMemo(() => tabs.find((tab) => tab.title === active), [
        tabs,
        active,
    ])

    return (
        <React.Fragment>
            <nav
                className={classNames(className, styles.nav, {
                    [styles.centeredNav]: centerNav,
                })}
            >
                {tabs.map((tab) => (
                    <Button
                        key={tab.title}
                        onClick={() => setActive(tab.title)}
                        type={ButtonTypes.nothing}
                        disabled={tab.disabled}
                        className={classNames(styles.tabLink, {
                            [styles.active]: tab.title === active,
                        })}
                    >
                        {tab.title}
                        <div
                            className={tab.displayActive ? styles.dot : null}
                        ></div>
                    </Button>
                ))}
            </nav>
            {!!activeTab && activeTab.element}
        </React.Fragment>
    )
}
