import { TableCell, TableRow } from '@material-ui/core'
import moment from 'moment-timezone'
import { useCallback } from 'react'
import { usePopup } from '~/app/providers/popup'
import { Button, ButtonTypes } from '../button/button'
import { Icon } from '../icon/ficon'
import { Sample, SampleImage, SampleType } from './samples'
import { SamplesImagePopup } from './samplesImagePopup'
import { TimestampPrint } from '../samplesList/samplesHelpers'

interface Props {
    sample: Sample
    colSpan: number
}

export function SamplesListRowDetail(props: Props): JSX.Element {
    const { sample, colSpan } = props

    const { setPopup } = usePopup()

    const onOpenImage = useCallback((code: string, image: SampleImage) => {
        setPopup(<SamplesImagePopup code={code} image={image} />)
    }, [])

    const imgErrors = sample.images?.sort((a, b) => {
        return moment(b.createdAt).unix() - moment(a.createdAt).unix() // Sort by createdAt
    })

    return (
        <TableRow>
            <TableCell colSpan={colSpan} className="text-center">
                {sample.testType === SampleType.ra && (
                    <>
                        <ul className="list-inline mb-0">
                            <li
                                className="list-inline-item"
                                data-customtooltip="Submitted"
                            >
                                <Icon name="Calendar" small className="me-2" />
                                <TimestampPrint value={sample.submitted} />
                            </li>
                            <li
                                className="list-inline-item"
                                data-customtooltip="Result determined"
                            >
                                <Icon name="Cpu" small className="me-2" />
                                {sample.result}
                            </li>
                            <li
                                className="list-inline-item"
                                data-customtooltip="Language"
                            >
                                <Icon name="Flag" small className="me-2" />
                                {sample.language || 'EN'}
                            </li>
                            <li
                                className="list-inline-item"
                                data-customtooltip="User input"
                            >
                                <Icon name="User" small className="me-2" />
                                {sample.guess
                                    ? `${sample.guess}`
                                    : 'No user input recorded'}
                            </li>
                            {sample.facility && (
                                <li
                                    className="list-inline-item"
                                    data-customtooltip="Facility"
                                >
                                    <Icon name="Truck" small className="me-2" />
                                    {sample.facility.name}
                                </li>
                            )}
                            {sample.organisation && (
                                <li
                                    className="list-inline-item"
                                    data-customtooltip="Organisation"
                                >
                                    <Icon name="Home" small className="me-2" />
                                    {sample.organisation.name}
                                </li>
                            )}
                            {sample.image?.template && (
                                <li
                                    className="list-inline-item"
                                    data-customtooltip="Provider"
                                >
                                    <Icon
                                        name="Package"
                                        small
                                        className="me-2"
                                    />
                                    {sample.image.template}
                                </li>
                            )}
                            {sample.image?.qr && (
                                <li
                                    className="list-inline-item"
                                    data-customtooltip="QR"
                                >
                                    <Icon
                                        name="Camera"
                                        small
                                        className="me-2"
                                    />
                                    {sample.image.qr}
                                </li>
                            )}
                        </ul>
                        {sample?.image && (
                            <Button
                                onClick={() =>
                                    onOpenImage(sample.code, sample?.image)
                                }
                                type={ButtonTypes.primary}
                                icon="Image"
                                iconOnRight
                                className="mt-3"
                            >
                                View final image
                            </Button>
                        )}
                        {!!imgErrors && imgErrors.length > 0 && (
                            <div className="mt-2">
                                <p className="mb-0">
                                    <strong>Images</strong>
                                </p>
                                <ul className="mt-0">
                                    {imgErrors.map((s, i) => (
                                        <li key={i}>
                                            #{i + 1}. [{s.operation}-
                                            {s.statusCode}]{' '}
                                            <TimestampPrint
                                                value={s.createdAt}
                                            />
                                            <Button
                                                onClick={() =>
                                                    onOpenImage(sample.code, s)
                                                }
                                                type={ButtonTypes.link}
                                                className="ms-1"
                                            >
                                                (image)
                                            </Button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </>
                )}
                {sample.testType != SampleType.ra && (
                    <ul className="list-inline mb-0">
                        <li
                            className="list-inline-item"
                            data-customtooltip="Submitted"
                        >
                            <Icon name="Calendar" small className="me-2" />
                            <TimestampPrint value={sample.submitted} />
                        </li>
                        <li
                            className="list-inline-item"
                            data-customtooltip="Language"
                        >
                            <Icon name="Flag" small className="me-2" />
                            {sample.language || 'EN'}
                        </li>
                        {sample.facility && (
                            <li
                                className="list-inline-item"
                                data-customtooltip="Facility"
                            >
                                <Icon name="Truck" small className="me-2" />
                                {sample.facility.name}
                            </li>
                        )}
                        {sample.organisation && (
                            <li
                                className="list-inline-item"
                                data-customtooltip="Organisation"
                            >
                                <Icon name="Home" small className="me-2" />
                                {sample.organisation.name}
                            </li>
                        )}
                    </ul>
                )}
                {sample.message && (
                    <p className="mb-0 mt-2">
                        <strong>Message:</strong> {sample.message}
                    </p>
                )}
            </TableCell>
        </TableRow>
    )
}
