/* f00b41421a2c20e254658290eae8b8b07a3a8feb
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../__types__';

import gql from 'graphql-tag';
import * as React from 'react';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type MeQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me: { __typename?: 'User', userId: string, userName: string, email: string, fullName: string, role: string, lastLoginDate: any, emailConfirmed: boolean, avatarUrl: string, otpEnabled: boolean, createdAt: any, updatedAt: any, currentTeam?: { __typename?: 'Team', teamId: string, name: string, createdAt: any, updatedAt: any, apps?: Array<{ __typename?: 'TeamApp', app?: { __typename?: 'App', appId: string, slug: string, name: string, description: string, createdAt: any, updatedAt: any } | null }> | null } | null, teams?: { __typename?: 'TeamsConnection', edges?: Array<{ __typename?: 'TeamEdge', node: { __typename?: 'Team', teamId: string, name: string, createdAt: any, updatedAt: any } } | null> | null } | null }, permissions: Array<{ __typename?: 'Permission', service: string, action: string, resource: string }> };


export const MeDocument = gql`
    query me {
  me {
    userId
    userName
    email
    fullName
    role
    lastLoginDate
    emailConfirmed
    avatarUrl
    otpEnabled
    createdAt
    updatedAt
    currentTeam {
      teamId
      name
      createdAt
      updatedAt
      apps {
        app {
          appId
          slug
          name
          description
          createdAt
          updatedAt
        }
      }
    }
    teams(first: 9999, sort: {order: ASCENDING}) {
      edges {
        node {
          teamId
          name
          createdAt
          updatedAt
        }
      }
    }
  }
  permissions {
    service
    action
    resource
  }
}
    `;

export const MeComponent = (props: Omit<Urql.QueryProps<MeQuery, MeQueryVariables>, 'query'> & { variables?: MeQueryVariables }) => (
  <Urql.Query {...props} query={MeDocument} />
);


export function useMeQuery(options?: Omit<Urql.UseQueryArgs<MeQueryVariables>, 'query'>) {
  return Urql.useQuery<MeQuery>({ query: MeDocument, ...options });
};