import Tooltip from '@mui/material/Tooltip'
import Zoom from '@mui/material/Zoom'

/**
 * @param info :string
 * @returns a little * that on hover shows a tooltip with the info.
 */
export function InfoTooltip(info: string): JSX.Element {
    return (
        <Tooltip TransitionComponent={Zoom} title={info} placement="top" arrow>
            <small>*</small>
        </Tooltip>
    )
}
