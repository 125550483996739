import * as icons from 'react-feather'
export type IconName = keyof typeof icons
export { Camera, AlertTriangle, CheckSquare, Square, X } from 'react-feather'
export type { Icon as fIcon } from 'react-feather'
import classNames from 'classnames'
import * as styles from './ficon.scss'

export type IconProps = {
    icon?: icons.Icon

    /** Name of the icon, type IconName */
    name?: IconName

    /** Set small to make icon size 1 x 1 rem */
    small?: boolean
    /** Set xtra large to make icon size 8 x 8 rem  */
    xl?:boolean
    /** Set active to make icon show gold-ish yellow color, representing an "active" icon */
    active?: boolean
    /** Add a custom class to the icon */
    className?: string
} & icons.IconProps

/**
Create a icon using the Feather icon pack. For more information about the Feather icon pack see https://feathericons.com/

- Use predefined optional parameters `small` or `active` *prefered over className
- Use custom classname for applying custom styling to the icon.
**/

export const Icon = ({
    icon,
    name,
    small,
    xl,
    active,
    className,
    ...rest
}: IconProps): JSX.Element => {
    const IconComponent = icon ?? icons[name]

    const IconClass = classNames(
        className,
        small ? styles.featherS : styles.feather,
        xl ? styles.featherXL : styles.feather,
        active ? styles.active : ''
    )

    if (!IconComponent) {
        console.warn(`Could not find icon "${name}".`)
        return null
    }
    return <IconComponent className={IconClass} {...rest} />
}
