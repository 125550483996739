// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".avatar__avatar--Xj9Uw{width:2.5rem;height:2.5rem;border-radius:50%}.avatar__sm--_jh0c{width:1.75rem;height:1.75rem}", "",{"version":3,"sources":["webpack://./src/app/auth/components/avatar/avatar.scss"],"names":[],"mappings":"AAAA,uBACI,YAAA,CACA,aAAA,CACA,iBAAA,CAEJ,mBACI,aAAA,CACA,cAAA","sourcesContent":[".avatar {\n    width: 2.5rem;\n    height: 2.5rem;\n    border-radius: 50%;\n}\n.sm {\n    width: 1.75rem;\n    height: 1.75rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatar": "avatar__avatar--Xj9Uw",
	"sm": "avatar__sm--_jh0c"
};
export default ___CSS_LOADER_EXPORT___;
