// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".consentryUpload__dropzone--qhSCe{flex:1;display:flex;flex-direction:column;align-items:center;padding:3rem;border:2px dashed var(--border-color);border-radius:var(--border-radius);background-color:var(--background-lighter);color:var(--text);outline:none;transition:border .24s ease-in-out}.consentryUpload__dropzone--qhSCe:hover,.consentryUpload__dropzone--qhSCe.consentryUpload__active--tsb2A{border-color:var(--primary-color)}.consentryUpload__dropzone--qhSCe.consentryUpload__accepted--ghiDz{border-color:#00e676}.consentryUpload__dropzone--qhSCe.consentryUpload__rejected--ytWbY{border-color:#ff1744}", "",{"version":3,"sources":["webpack://./src/app/portal/components/consentryUpload/consentryUpload.scss"],"names":[],"mappings":"AAAA,kCACI,MAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,YAAA,CACA,qCAAA,CACA,kCAAA,CACA,0CAAA,CACA,iBAAA,CACA,YAAA,CACA,kCAAA,CAEA,yGAEI,iCAAA,CAEJ,mEACI,oBAAA,CAEJ,mEACI,oBAAA","sourcesContent":[".dropzone {\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 3rem;\n    border: 2px dashed var(--border-color);\n    border-radius: var(--border-radius);\n    background-color: var(--background-lighter);\n    color: var(--text);\n    outline: none;\n    transition: border 0.24s ease-in-out;\n\n    &:hover,\n    &.active {\n        border-color: var(--primary-color);\n    }\n    &.accepted {\n        border-color: #00e676;\n    }\n    &.rejected {\n        border-color: #ff1744;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropzone": "consentryUpload__dropzone--qhSCe",
	"active": "consentryUpload__active--tsb2A",
	"accepted": "consentryUpload__accepted--ghiDz",
	"rejected": "consentryUpload__rejected--ytWbY"
};
export default ___CSS_LOADER_EXPORT___;
