import * as React from 'react'
import { AppScreen } from '../../../portal/components/appScreen/appScreen'
import { AppLinks } from '../../../portal/config'
import { PrimaryContent } from '../../../portal/components/primaryContent/primaryContent'
import { useUser } from '../../hooks/useUser'
import { DisplayRole } from '../../helpers/displayRole'
import { Icon } from '../../../portal/components/icon/ficon'

export function NotAuthorized(): JSX.Element {
    const user = useUser()
    return (
        <AppScreen title={'Not authorized'} activeAppLink={AppLinks.activity}>
            <PrimaryContent>
                <div className="d-flex justify-content-center align-items-center flex-grow-1">
                    <div className="text-center">
                        <Icon name="Lock" className="mb-3" />
                        <h3>You are not authorized to view this page</h3>
                        <p>
                            You currently have the role of{' '}
                            <strong>
                                <DisplayRole roleId={user.role} />
                            </strong>{' '}
                            in <strong>{user.currentTeam.name}</strong>.
                        </p>
                        <p>Contact your admin to get access to this page.</p>
                    </div>
                </div>
            </PrimaryContent>
        </AppScreen>
    )
}
