/* c9b7cb51a4e0601d8e4df5d92d13d2e023aaae31
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../__types__';

import gql from 'graphql-tag';
import * as React from 'react';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SignupMutationVariables = Types.Exact<{
  input: Types.SignupInput;
}>;


export type SignupMutation = { __typename?: 'Mutation', signup?: string | null };


export const SignupDocument = gql`
    mutation signup($input: signupInput!) {
  signup(input: $input)
}
    `;

export const SignupComponent = (props: Omit<Urql.MutationProps<SignupMutation, SignupMutationVariables>, 'query'> & { variables?: SignupMutationVariables }) => (
  <Urql.Mutation {...props} query={SignupDocument} />
);


export function useSignupMutation() {
  return Urql.useMutation<SignupMutation, SignupMutationVariables>(SignupDocument);
};