import { RequestPolicy, useQuery } from 'urql'
import { OperatorInput }  from '../../../interfaces/operatorInput'

const query = `
query batches($limit: Int!, $offset: Int!, $after: String!, $where: OperatorInput) {
    batches(first: $limit, offset: $offset, after: $after, where: $where) {
        totalCount
        edges {
            node {
                batchId
                team {
                    teamId
                    name
                }
                active
                approvalThreshold
                mode
                templates
                noCard
            }
            cursor
        }
        pageInfo {
            hasNextPage
        }
    }
}
`

interface Variables {
    limit: number
    after?: string
    offset?: number
    where?: OperatorInput
}

export function useBatchesQuery(
    variables: Variables,
    pause: boolean = false,
    requestPolicy: RequestPolicy = 'network-only'
) {
    return useQuery({
        pause,
        query,
        variables: {
            after: '',
            ...variables,
        },
        requestPolicy,
    })
}