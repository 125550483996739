import * as React from 'react'
import { Link } from 'react-router-dom'
import { Paths } from '../../helpers/routePaths'

export function RouteNotFound(): JSX.Element {
    return (
        <div
            className="d-flex justify-content-center align-items-center flex-grow-1"
            style={{ minHeight: '100vh' }}
        >
            <div className="text-center">
                <h1 className="display-3 mb-2">404</h1>
                <h4>Oops! Page not found.</h4>
                <p className="text-muted">
                    Go back to the <Link to={Paths.activity}>Dashboard</Link>{' '}
                    page.
                </p>
            </div>
        </div>
    )
}
