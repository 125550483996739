/* 1ba36ced1147b0ca31d70ea0679c5ce1506f8305
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../__types__';

import gql from 'graphql-tag';
import * as React from 'react';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ResetPasswordMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword: boolean };


export const ResetPasswordDocument = gql`
    mutation resetPassword($email: String!) {
  resetPassword(email: $email)
}
    `;

export const ResetPasswordComponent = (props: Omit<Urql.MutationProps<ResetPasswordMutation, ResetPasswordMutationVariables>, 'query'> & { variables?: ResetPasswordMutationVariables }) => (
  <Urql.Mutation {...props} query={ResetPasswordDocument} />
);


export function useResetPasswordMutation() {
  return Urql.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument);
};