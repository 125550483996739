import qs, { IParseOptions, IStringifyOptions } from 'qs'
export const qsOptions: IParseOptions = {
	ignoreQueryPrefix: true,
	arrayLimit: 100,
	allowDots: false,
	allowPrototypes: false,
	charset: 'utf-8',
	charsetSentinel: false,
	comma: false,
	delimiter: '&',
	depth: 5,
	parameterLimit: 1000,
	parseArrays: true,
	plainObjects: true,
	strictNullHandling: false,
}
