/* eslint-disable react/display-name */
import * as React from 'react'
import { useCallback, useEffect, useMemo } from 'react'
import MUIDataTable, {
	MUIDataTableColumn,
	MUIDataTableOptions,
} from 'mui-datatables'
import { TimestampPrint } from '../samplesList/samplesHelpers'
import * as styles from './imagesList.scss'
import { Button, ButtonTypes } from '../button/button'
import { CertificatePopup } from '../certificate/certificate'
import classNames from 'classnames'
import { useImagesQuery } from './queries/useImagesQuery'
import { ImageEdge, SampleImage } from '../samplesList/samples'
import { OperatorInput } from '../../interfaces/operatorInput'
import { createTheme, MuiThemeProvider } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import { SamplesImagePopup } from '../samplesList/samplesImagePopup'
import { useApps, useCurrentTeam, usePermissions } from '../../../providers/user'
import { Button as MBButton } from '@material-ui/core'

import {
	MUIDataTableBody,
	TableBody,
	TableBodyCell,
	TableBodyRow,
} from 'mui-datatables'
import {
	createStyles,
	makeStyles,
	TableBody as MuiTableBody,
} from '@material-ui/core'
import { usePopup } from '~/app/providers/popup'
import { useHashState } from '~/app/hooks/useHashState'
import { useLocalStorage } from '~/app/hooks/useLocalStorage'
import { Role } from '@graphql-enums@'
import { useSnackbar } from 'notistack'
import { CSVLink } from 'react-csv'
import { slug } from '../portalGenerate/portalGenerate'
import moment from 'moment'
import { BasicPopup } from '../basicPopup/basicPopup'
import { Icon } from '../icon/ficon'
import { Loader } from '../loader/loader'
import { useTimezone } from '../../../hooks/useTimezone'
import { createCSVDateTimeString } from '../../helpers/csvDateTimeString'

export function ImagesExport(props): JSX.Element {
    const { where } = props

    const [cursor, setCursor] = React.useState<any>('')
    const [rows, setRows] = React.useState<any[]>([])
    const [ready, setReady] = React.useState<any>(false)

    const { clearPopup } = usePopup()

    const [result] = useImagesQuery(
        {
            limit: 500,
            offset: 0,
            after: cursor,
            where,
        },
        false
    )

    const { closeSnackbar, enqueueSnackbar } = useSnackbar()

    const [team] = useCurrentTeam()
	const [ tz ] = useTimezone()

    const csvLink = React.useRef<
        CSVLink & HTMLAnchorElement & { link?: HTMLAnchorElement }
    >()

    React.useEffect(() => {
        const { error, data, fetching } = result
        if (error) {
            console.error(error)
            return
        }

        if (fetching) return
        if (!data) return

        if (data.images?.edges.length == 0) {
            setReady(true)

            setCSV({
                ...csv,
                ready: true,
                filename: `export-imageslist-${slug(team.name)}-${moment().format(
                    'YYYYMMDDTHHmmss'
                )}.csv`,
                data: rows,
            })

            const action = (key) => (
                <>
                    <MBButton
                        onClick={() => {
                            csvLink.current.link.click()
                        }}
                    >
                        Download
                    </MBButton>
                    <MBButton
                        onClick={() => {
                            closeSnackbar(key)
                        }}
                    >
                        Close
                    </MBButton>
                </>
            )

            clearPopup()

            enqueueSnackbar(
                <span>
                    The export file is <b>ready</b>, please download.
                    <CSVLink
                        className={'invisible'}
                        data={rows}
                        filename={`export-imageslist-${slug(team.name)}-${moment().format(
                            'YYYYMMDDTHHmmss'
                        )}.csv`}
                        ref={csvLink}
                        target="_blank"
                    >
                        download {csv.filename}
                    </CSVLink>
                </span>,
                {
                    persist: true,
                    variant: 'success',
                    action,
                }
            )
            return
        }

        const cursor = data.images?.edges.reduce((cursor, edge) => {
            return edge.cursor
        })

        setRows([
			...rows, 
			// "code","imageURL","userAgent","template","statusCode","operation","score","qr","apiVersion","validationMessage","expectedConditions","createdAt","updatedAt"
			...data.images?.edges.map((edge) => ({//edge.node)])
				code: edge.node.code,
				imageURL: edge.node.imageURL,
				userAgent: edge.node.userAgent,
				template: edge.node.template,
				statusCode: edge.node.statuscode,
				operation: edge.node.operation,
				score: edge.node.score,
				qr: edge.node.qr,
				apiVersion: edge.node.apiVersion,
				validationMessage: edge.node.validationMessage,
				expectedConditions: edge.node.expectedConditions,
				createdAt: (createCSVDateTimeString(edge.node.createdAt, tz)),
				updatedAt: (createCSVDateTimeString(edge.node.updatedAt, tz)),
			})),
		])
        setCursor(cursor)
    }, [result])

    const [csv, setCSV] = React.useState({
        ready: false,
        data: [],
        filename: '',
    })

    return (
        <>
            <BasicPopup title="Export" size="s" className={styles.modalHeight}>
                {!ready && (
                    <div>
                        <Loader 
                            large 
                            center 
                            message="Preparing export file... "
                        />     
                    </div>     
                )} 
                { ready && (
                    <>Collected ({rows.length}) rows.</>
                )}
            </BasicPopup>
        </>
    )
}

export function ImagesList(): JSX.Element {
	// Filter states
	const [queryF, setQueryF] = useHashState('q')
	const [operationF, setOperationF] = React.useState<string>()

	const [rowsExpanded, setRowsExpanded] = React.useState<any[]>([])
	const [rowsPerPage, setRowsPerPage] = React.useState<number>(10)
	const [currentPage, setCurrentPage] = React.useState<number>(1)

	const onOpenImage = useCallback((code: string, image: SampleImage) => {
		setPopup(<SamplesImagePopup code={code} image={image} />)
	}, [])

	// List samples
	const where: OperatorInput = React.useMemo(() => {
		setCurrentPage(1) // Reset pagination if filters change
		const out: OperatorInput = {
			and: [],
		}
		if (queryF) out.and.push({ field: 'code', like: `%${queryF}%` })
		return out
	}, [queryF])

	const [result, refresh] = useImagesQuery(
		{
			limit: rowsPerPage,
			offset: (currentPage - 1) * rowsPerPage,
			after: '',
			where,
		},
		false
	)

	const images: ImageEdge[] = result.data ? result.data?.images?.edges : []
	const imagesCount: number = result.data ? result.data.images?.totalCount : 0
	const imagesRef = React.useRef<ImageEdge[]>()
	useEffect(() => {
		imagesRef.current = images
	}, [images])

	// Refresh results every 10 minutes
	useEffect(() => {
		const interval = setInterval(() => {
			refresh({ requestPolicy: 'network-only' })
		}, 60 * 1000 * 10 )

		return () => clearInterval(interval)
	}, [refresh])

	const [apps] = useApps()

	const { setPopup } = usePopup()

	const onCertificateClick = useCallback((testKitId: string) => {
		setPopup(<CertificatePopup testKitId={testKitId} />)
	}, [])

	const allColumns = [
		{
			name: 'code',
			label: 'Sample ID',
			options: {
				display: true,
				hint: 'Sample ID',
				filter: false,
				sort: true,
				customBodyRenderLite: (dataIndex) => {
					const node = imagesRef.current[dataIndex]?.node
					return (
						<div className={styles.nowrap}>
							<span>{node.code}</span>
						</div>
					)
				},
			},
		},
		{
			name: 'createdAt',
			label: 'Created',
			options: {
				display: true,
				hint: 'Time of upload',
				filter: false,
				sort: true,
				searchable: false,
				customBodyRender: (value) => (
					<span className="badge bg-secondary">
						<TimestampPrint value={value} />
					</span>
				),
			},
		},
		{
			name: 'operation',
			label: 'Operation',
			options: {
				display: true,
				filter: true,
				searchable: false,
				sort: false,
			},
		},
		{
			name: 'template',
			label: 'Template',
			options: {
				display: true,
				filter: true,
				searchable: false,
				sort: false,
			},
		},
		{
			name: 'userAgent',
			label: 'UserAgent',
			options: {
				display: true,
				filter: false,
				searchable: false,
				sort: false,
			},
		},
		{
			name: 'score',
			label: 'Score',
			options: {
				display: true,
				filter: false,
				searchable: false,
				sort: false,
			},
		},
		{
			name: 'statusCode',
			label: 'StatusCode',
			options: {
				display: true,
				filter: false,
				searchable: false,
				sort: false,
			},
		},
		{
			name: 'validationMessage',
			label: 'ValidationMessage',
			options: {
				display: true,
				filter: false,
				searchable: false,
				sort: false,
			},
		},
		{
			name: 'qr',
			label: 'QR',
			options: {
				display: true,
				filter: false,
				searchable: false,
				sort: false,
			},
		},
		{
			name: 'imageURL',
			label: 'Image',
			options: {
				display: true,
				filter: false,
				sort: true,
				searchable: false,
				customBodyRenderLite: (dataIndex) => {
					const node = imagesRef.current[dataIndex]?.node
					return (
						<Button
							onClick={() => onOpenImage(node.code, node)}
							type={ButtonTypes.link}
							className="ms-1"
						>
							<img
								className={styles.thumbnail}
								src={node.imageURL}
							/>
						</Button>
					)
				},
			},
		},
		{
			name: 'Download',
			label: 'Download',
			options: {
				display: false,
				filter: false,
				sort: false,
				searchable: false,
				customBodyRenderLite: (dataIndex) => {
					const node = imagesRef.current[dataIndex]?.node
					return (
						<a href={node?.imageURL}>
							<Icon name={'Download'} small />
						</a>
					)
				},
			},
		},

		/*{
  name: "organisation.name",
  label: "Organisation",
  options: {
	display: false,
	filter: false,
	searchable: false,
	sort: false,
  },
},*/
	]

	const [selectedColumns, setSelectedColumns] = useLocalStorage<string[]>(
		'imageColumns',
		allColumns.filter((a) => a.options.display).map((a) => a.name)
	)

	// update display state for selected columns
	const columns = allColumns.reduce((cols, col) => {
		const display =
			selectedColumns.findIndex((a: string) => a === col.name) >= 0

		return [
			...cols,
			{
				...col,
				options: {
					...col.options,
					display: display,
				},
			},
		]
	}, [])

	const [, hasPermission] = usePermissions()

	// Set options
	const options: MUIDataTableOptions = {
		enableNestedDataAccess: '.',
		serverSide: true,
		filterType: 'checkbox',
		searchOpen: true,
		searchPlaceholder: 'Search for a code...',
		download: hasPermission({ service: 'sample-approvals', action: 'images:ActionDownloadData' }),
		rowsPerPageOptions: [10, 25, 50, 100],
		count: imagesCount,
		onChangeRowsPerPage: (numberOfRows) => setRowsPerPage(numberOfRows),
		onChangePage: (currentPage) => {
			setCurrentPage(currentPage + 1)
			setRowsExpanded([])
		},
		// Update column state on change
		onViewColumnsChange: (changedColumn, action) => {
			if (columns.some((n) => n.name === changedColumn)) {
				columns.find((n) => n.name === changedColumn).options.display =
					action === 'add'
			}
			setSelectedColumns(
				columns.filter((a) => a.options.display).map((a) => a.name)
			)
		},
		// Flatten the facility and organisation objects in the downloaded file
		onDownload: (buildHead, buildBody, columns: any[], data) => {
			setPopup(<ImagesExport where={where} />)

			return false
		},
		print: false,
		selectableRows: 'none',
		sortOrder: { name: 'submitted', direction: 'desc' },
		textLabels: {
			body: {
				noMatch: result.fetching ? (
					<div>Loading data...</div>
				) : (
					'Sorry, there is no matching data to display'
				),
			},
		},
		onSearchChange: (searchText) => {
			setRowsExpanded([])
			setQueryF(searchText?.trim())
		},
		onFilterChange: (
			changedColumn,
			filterList,
			filterType,
			changedColumnIndex
		) => {
			setRowsExpanded([])
			switch (changedColumn) {
				case 'operation': {
					setOperationF(filterList[changedColumnIndex][0] ?? null)
					break
				}
			}
		},
		// Expandable rows
		expandableRows: false,
		expandableRowsHeader: false,
		expandableRowsOnClick: false,
		rowsExpanded: rowsExpanded,
		isRowExpandable: () => true,
		onRowExpansionChange: (_, allExpanded) =>
			setRowsExpanded(allExpanded.map((a) => a.dataIndex)),
	}

	const theme = createTheme({
		overrides: {
			MUIDataTableSelectCell: {
				root: {
					display: 'none',
				},
			},
			MuiTableCell: {
				head: {},
			},
		},
	})

	const useStyles = makeStyles(() =>
		createStyles({
			loading: {
				textAlign: 'center',
			},
		})
	)

	const LoadingTableBody = ({
		loading,
		options,
		columns,
		rowsPerPage,
		...others
	}: { loading: boolean } & MUIDataTableBody) => {
		// @ts-ignore
		const classes = useStyles()
		const rows = [...Array(rowsPerPage)]

		return loading ? (
			<MuiTableBody>
				{rows.map((row, rowIndex) => {
					return (
						<TableBodyRow options={options} key={rowIndex}>
							{columns
								.filter(
									(column: any) => column?.display === 'true'
								)
								.map(
									(
										column: MUIDataTableColumn,
										columnIndex
									) => (
										<TableBodyCell
											key={column?.name}
											// @ts-ignore
											options={options}
											colIndex={columnIndex}
											rowIndex={rowIndex}
										>
											<Skeleton variant="text" />
										</TableBodyCell>
									)
								)}
						</TableBodyRow>
					)
				})}
			</MuiTableBody>
		) : (
			<TableBody options={options} columns={columns} {...others} />
		)
	}

	const { fetching } = result

	const BodyComponent = useMemo(
		() => (props: MUIDataTableBody) => (
			<LoadingTableBody loading={fetching} {...props} />
		),
		[fetching]
	)

	const dataTable = useMemo(
		() => (
			<MuiThemeProvider theme={theme}>
				<MUIDataTable
					title={''}
					data={images?.map((edge) => edge.node)}
					columns={columns}
					options={options}
					components={{ TableBody: BodyComponent }}
				/>
			</MuiThemeProvider>
		),
		[fetching, images]
	)

	return (
		<div>
			<div className={styles.tableWrap}>
				<Button
					className={classNames(
						{ [styles.loading]: fetching },
						styles.refreshBtn
					)}
					type={ButtonTypes.nothing}
					title="Refresh"
					onClick={(e) => refresh({ requestPolicy: 'network-only' })}
					icon="RefreshCw"
				></Button>
				{dataTable}
			</div>
		</div>
	)
}
