import * as React from 'react'
import { ChangeEvent } from 'react'
import { FormikErrors, FormikTouched } from 'formik'
import * as styles from './textInput.scss'
import classNames from 'classnames'

import PasswordStrengthBar from 'react-password-strength-bar'

export interface TextInputProps {
    title?: string
    name: string
    onChange: (e: ChangeEvent) => void
    onBlur?: (e: ChangeEvent) => void
    value: string | number
    error?: string | FormikErrors<any> | string[] | FormikErrors<any>[]
    touched?: boolean | FormikTouched<any> | FormikTouched<any>[]
    onFocus?: any
    placeholder?: string
    readOnly?: boolean
    disabled?: boolean
    autocomplete?:
        | 'off'
        | 'new-password'
        | 'on'
        | 'one-time-code'
        | 'email'
        | 'current-password'
    passwordStrength?: boolean
    autoFocus?: boolean
    className?: string
    type?: 'text' | 'number' | 'password' | 'url' | 'tel' | 'file'
    accept?: string
    appendText?: string | JSX.Element
}

export function TextInput(props: TextInputProps): JSX.Element {
    const showErrors = !!props.error && props.touched
    const showValid =
        !props.error && !props.readOnly && (props.value || props.touched)

    return (
        <React.Fragment>
            {props.appendText ? (
                <div className="input-group">
                    <input
                        name={props.name}
                        type={props.type ? props.type : 'text'}
                        className={classNames(
                            'form-control',
                            {
                                ['is-invalid']: showErrors,
                                ['is-valid']: showValid,
                            },
                            props.className
                        )}
                        value={props.value}
                        onChange={props.onChange}
                        disabled={props.disabled}
                        onBlur={props.onBlur}
                        placeholder={props.placeholder}
                        onFocus={props.onFocus ? props.onFocus : () => {}}
                        readOnly={!!props.readOnly}
                        autoComplete={
                            props.autocomplete ? props.autocomplete : 'on'
                        }
                        accept={props.accept}
                        autoFocus={props.autoFocus}
                    />
                    <span className="input-group-text">{props.appendText}</span>
                </div>
            ) : (
                <input
                    name={props.name}
                    type={props.type ? props.type : 'text'}
                    className={classNames(
                        'form-control',
                        {
                            ['is-invalid']: showErrors,
                            ['is-valid']: showValid,
                        },
                        props.className
                    )}
                    accept={props.accept}
                    value={props.value}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    placeholder={props.placeholder}
                    onFocus={props.onFocus ? props.onFocus : () => {}}
                    readOnly={!!props.readOnly}
                    autoComplete={
                        props.autocomplete ? props.autocomplete : 'on'
                    }
                    autoFocus={props.autoFocus}
                />
            )}
            {props.passwordStrength && (
                <PasswordStrengthBar
                    password={props.value as string}
                    className="w-100"
                />
            )}
            <div
                className={classNames(
                    styles.error,
                    showErrors ? 'd-block' : 'd-none'
                )}
            >
                {showErrors ? props.error : null}
            </div>
        </React.Fragment>
    )
}
