import * as React from 'react'
import * as styles from './portalSamples.scss'
import { AppScreen } from '../appScreen/appScreen'
import { AppLinks } from '../../config'
import { SamplesList } from '../samplesList/samplesList'
import { SetPageTitle } from '../../helpers/setPageTitle'
import { PermissionComponent } from '~/app/components/permission/permission'

export function PortalSamples(): JSX.Element {
    return (
        <AppScreen activeAppLink={AppLinks.testkits}>
            <PermissionComponent
                service={'portal'}
                action={'samples:ViewData'}
            >
                <>
                    <SetPageTitle title="Samples" />
                    <div className={styles.container}>
                        <SamplesList />
                    </div>
                </>
            </PermissionComponent>
        </AppScreen>
    )
}
