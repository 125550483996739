import * as React from 'react'
import * as styles from './fullScreenLoading.scss'
import { Loader } from '../loader/loader'

export interface Props {
    message: string
}

export function FullScreenLoading(props: Props): JSX.Element {
    return (
        <div className={styles.fullScreenLoading}>
            <Loader large />
            <p className={styles.message}>{props.message}</p>
        </div>
    )
}
