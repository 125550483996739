import { Paths } from './helpers/routePaths'
import * as icons from 'react-feather'
export type IconName = keyof typeof icons
import { Keys } from './hooks/useKey'
import { isDevelopment } from './helpers/getRavenEnvironment'
import { CustomAppFlags, CustomApps } from './interfaces/apps'
import { User, Role as RoleType } from '@graphql-types@'
import { Role } from '@graphql-enums@'

export enum AppLinks {
    activity,
    images,
    batches,
    testkits,
    generate,
    approvals,
    upload,
    testing,
    //alerts,
    events,
    settings,
}

export interface Config {
    appLink: AppLinks
    path: Paths
    label: string
    icon: IconName
    keyboardShortcut: Keys
    hideInProduction: boolean
    app: CustomAppFlags
    service?: string
    action?: string
    component?: React.FC<{}>
}

const config = (): Config[] => [
    {
        appLink: AppLinks.activity,
        path: Paths.activity,
        label: 'Dashboard',
        icon: 'BarChart2',
        keyboardShortcut: Keys.d,
        hideInProduction: false,
        app: CustomAppFlags.Portal,
    },
    {
        appLink: AppLinks.testkits,
        path: Paths.samples,
        label: 'Samples',
        icon: 'Activity',
        keyboardShortcut: Keys.s,
        hideInProduction: false,
        service: 'portal',
        action: 'samples:ViewData',
        app: CustomAppFlags.Portal,
    },
    // Images UI
    {
        appLink: AppLinks.images,
        path: Paths.images,
        label: 'Images',
        icon: 'Image',
        keyboardShortcut: Keys.i,
        hideInProduction: false,
        service: 'sample-approvals',
        action: 'images:ViewData',
        app: CustomAppFlags.SampleApprovals,
    },
    {
        appLink: AppLinks.batches,
        path: Paths.batches,
        label: 'Batches',
        icon: 'Grid',
        keyboardShortcut : Keys.c,
        hideInProduction: true,
        service: 'batches',
        action: 'batches:ViewData',
        app: CustomAppFlags.Batches,
    },
    {
        appLink: AppLinks.approvals,
        path: Paths.approvals,
        label: 'Approvals',
        icon: 'CheckCircle',
        keyboardShortcut: Keys.a,
        hideInProduction: false,
        service: 'sample-approvals',
        action: 'approvals:ViewEntry',
        app: CustomAppFlags.SampleApprovals,
    },
    {
        appLink: AppLinks.generate,
        path: Paths.generate,
        label: 'Generator',
        icon: 'Anchor',
        keyboardShortcut: Keys.g,
        hideInProduction: false,
        service: 'portal',
        action: 'generator:ViewGenerator',
        app: CustomAppFlags.Portal,
    },
    // Upload Lab Export file(s)
    {
        appLink: AppLinks.upload,
        path: Paths.upload,
        label: 'Upload',
        icon: 'UploadCloud',
        keyboardShortcut: Keys.u,
        hideInProduction: false,
        service: 'upload-lab-export',
        action: 'labupload:View',
        app: CustomAppFlags.UploadLabExport,
    },
    // Testing UI
    {
        appLink: AppLinks.testing,
        path: Paths.testing,
        label: 'Testing',
        icon: 'Terminal',
        keyboardShortcut: Keys.b,
        hideInProduction: false,
        service: 'code-testing',
        action: 'testing:View',
        app: CustomAppFlags.CodeTesting,
    },
    {
        appLink: AppLinks.settings,
        path: Paths.settingsAccount,
        label: 'Settings',
        icon: 'Settings',
        keyboardShortcut: Keys.e,
        hideInProduction: false,
        app: CustomAppFlags.Portal,
    },
]

export const getConfig = (): Config[] => {
    const isDev = isDevelopment()
    return config()
}
