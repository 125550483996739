import '../scss/style.scss'
import { render } from 'react-dom'
import Main from './portal/components/main'
import { CustomBrowserRouter } from './portal/components/customBrowserRouter'
import { Translate } from './portal/components/translate'
import { ErrorReporting } from './portal/components/errorReporting/errorReporting'
import { GraphQlProvider } from './graphQl/components/graphQlProvider'
import { StoreProvider } from './providers/store'
import { AuthProvider } from './providers/user'
import * as styles from './index.scss'
import { SnackbarProvider } from 'notistack'
import { PopupProvider } from './providers/popup'

render(
    <ErrorReporting>
        <SnackbarProvider
            preventDuplicate={true}
            maxSnack={3}
            classes={{
                variantInfo: styles.info,
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
        >
            <CustomBrowserRouter>
                <StoreProvider>
                    <GraphQlProvider>
                        <Translate>
                            <PopupProvider>
                                <Main />
                            </PopupProvider>
                        </Translate>
                    </GraphQlProvider>
                </StoreProvider>
            </CustomBrowserRouter>
        </SnackbarProvider>
    </ErrorReporting>,
    document.querySelector('#root')
)
