import { PrimaryContent } from '../../../portal/components/primaryContent/primaryContent'
import { LoadableTable } from '../../../portal/components/loadableTable/loadableTable'
import { SettingsAppScreen } from '../settingsAppScreen'
import { DisplayDate } from '../../../portal/components/displayDate/displayDate'
import { Avatar } from '../../../auth/components/avatar/avatar'
import { DisplayString } from '../../../portal/components/displayString'
import { useAuditLogQuery } from '../../queries/AuditLog.graphql'
import { TeamAuditEntryEdge } from '@graphql-types@'
import { Loader } from '~/app/portal/components/loader/loader'

export function AuditLogList(): JSX.Element {
    // List audit logs
    const [{ data, stale, fetching, error, ...other }] = useAuditLogQuery({
        variables: {
            limit: 50,

            // where: {
            // 	field: "action",
            // 	eq: "eventsSearch" // TODO: Replace with state-backed input
            // }
        },
    })

    const auditLogs: TeamAuditEntryEdge[] = data
        ? data?.me.currentTeam?.auditLog?.edges
        : []

    return (
        <SettingsAppScreen title={'Audit logs'}>
            <PrimaryContent>
                <h2>Audit logs</h2>
                <p>This is the log of events having occured in the system.</p>
                {(fetching || stale) && <Loader large />}
                {data?.me.currentTeam?.auditLog && (
                    <LoadableTable
                        isLoading={fetching || stale}
                        noResults={!auditLogs || !auditLogs.length}
                        noResultsMessage="No logs found"
                        couldNotLoad={!!error}
                        className="mb-0"
                    >
                        <thead>
                            <tr>
                                <th>-?SERVICE?-</th>
                                <th>Action</th>
                                <th>User</th>
                                <th>IP</th>
                                <th>Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {auditLogs?.map((log, i) => (
                                <tr key={i} style={{ cursor: 'auto' }}>
                                    <td>
                                        System{' '}
                                        {/* TODO: Needs the real service name here, then it can be used to insert an icon too, e.g. "FLOW" */}
                                    </td>
                                    <td>{log.node.action}</td>
                                    <td>
                                        <Avatar
                                            url={
                                                log.node.user.avatarUrl.slice(
                                                    0,
                                                    -2
                                                ) + 'identicon'
                                            }
                                            small
                                            className="me-2"
                                        />
                                        <DisplayString
                                            value={log.node.user.fullName}
                                            className="d-inline"
                                        />
                                    </td>
                                    <td>{log.node.ip}</td>
                                    <td>
                                        <DisplayDate
                                            value={log.node.createdAt}
                                            small
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </LoadableTable>
                )}
            </PrimaryContent>
        </SettingsAppScreen>
    )
}
