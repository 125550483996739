import * as React from 'react'
import { ReactSelect } from '../../../portal/components/reactSelect/reactSelect'
import { RolesComponent } from './roles.graphql'
import { useUser } from '../../hooks/useUser'

interface Props {
	value: string
	label?: string | JSX.Element
	onChange: (value: any, shouldValidate?: boolean) => void
	onBlur: (isTouched?: boolean, shouldValidate?: boolean) => void
}

/**
  SelectRole presents a select-element containing the roles the editorUser can
  change a user into. Will only add the superAdmin role when editorUser is a
  superAdmin.
*/

export function SelectRole(props: Props): JSX.Element {
	return (
		<RolesComponent>
			{({ data, fetching }) => (
				<SelectRoleInner {...props} fetching={fetching} data={data} />
			)}
		</RolesComponent>
	)
}

/**
 * setNotAllowedRoles returns a array with role names that are disallowed for specific user.
 * This is a helper-function for the SelectRoleInner and UserDetail components in Lab-UI.
 * @param role : UUID string for the role
 * @returns : [] filled with the roles that are not allowed to pick for specific user.
 */
export function setNotAllowedRoles(role:string)  {
	const currentUserRole = role
	switch(currentUserRole) {
		case "Developer":
			return [ 'Super Admin' , 'Team Admin' , 'Admin' ]
			break;
		case "Admin":
			return [ 'Developer', 'Super Admin' ]
			 break;

		case "Team Admin":
			return [ 'Super Admin' , 'Developer' , 'Admin' ]
			break;
		default:
			return []
	}

}

export function SelectRoleInner(
	props: Props & { data: any; fetching: boolean }
): JSX.Element {
	const { value, label, onChange, onBlur, fetching, data } = props

	const currentUserRole:string = data?.roles.find(
		(role) => role.roleId === useUser().role
	)?.name ?? 'unknown role'

	const notAllowedList = setNotAllowedRoles(currentUserRole)

	const options = data?.roles	
		.filter((role) => {
			const allow:boolean = notAllowedList.indexOf(role.name) > -1
			if ( !allow ) {
				return role
			}
		})
		.sort((i1, i2) => i1.name.localeCompare(i2.name))
		.map((role) => ({
			label: role.name,
			value: role.roleId,
		}))
	const selectedRole = options?.find((op) => {
		return op.value === value
	})

	return (
		<ReactSelect
			name="role"
			label={label}
			isLoading={fetching}
			options={options}
			onChange={(item) => onChange(item.value)}
			onBlur={onBlur}
			isOptionDisabled={(option) => {
				return option.disabled
			}}
			value={selectedRole}
		/>
	)
}
