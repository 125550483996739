/* d5638759081a80c07aedf0de0b314511dabefc33
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../__types__';

import gql from 'graphql-tag';
import * as React from 'react';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ConfirmResetPasswordMutationVariables = Types.Exact<{
  token: Types.Scalars['String'];
  password: Types.Scalars['String'];
}>;


export type ConfirmResetPasswordMutation = { __typename?: 'Mutation', confirmResetPassword: { __typename?: 'User', userId: string } };


export const ConfirmResetPasswordDocument = gql`
    mutation confirmResetPassword($token: String!, $password: String!) {
  confirmResetPassword(token: $token, password: $password) {
    userId
  }
}
    `;

export const ConfirmResetPasswordComponent = (props: Omit<Urql.MutationProps<ConfirmResetPasswordMutation, ConfirmResetPasswordMutationVariables>, 'query'> & { variables?: ConfirmResetPasswordMutationVariables }) => (
  <Urql.Mutation {...props} query={ConfirmResetPasswordDocument} />
);


export function useConfirmResetPasswordMutation() {
  return Urql.useMutation<ConfirmResetPasswordMutation, ConfirmResetPasswordMutationVariables>(ConfirmResetPasswordDocument);
};