/* a6734bbef705ed26edff3697d0786e052453b5da
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../__types__';

import gql from 'graphql-tag';
import * as React from 'react';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateTeamMutationVariables = Types.Exact<{
  input: Types.UpdateTeamInput;
}>;


export type UpdateTeamMutation = { __typename?: 'Mutation', updateTeam: { __typename?: 'Team', teamId: string, name: string, createdAt: any, updatedAt: any, apps?: Array<{ __typename?: 'TeamApp', app?: { __typename?: 'App', appId: string, slug: string, name: string, description: string, createdAt: any, updatedAt: any } | null }> | null } };


export const UpdateTeamDocument = gql`
    mutation updateTeam($input: updateTeamInput!) {
  updateTeam(input: $input) {
    teamId
    name
    createdAt
    updatedAt
    apps {
      app {
        appId
        slug
        name
        description
        createdAt
        updatedAt
      }
    }
  }
}
    `;

export const UpdateTeamComponent = (props: Omit<Urql.MutationProps<UpdateTeamMutation, UpdateTeamMutationVariables>, 'query'> & { variables?: UpdateTeamMutationVariables }) => (
  <Urql.Mutation {...props} query={UpdateTeamDocument} />
);


export function useUpdateTeamMutation() {
  return Urql.useMutation<UpdateTeamMutation, UpdateTeamMutationVariables>(UpdateTeamDocument);
};