import { App, Permission, Team, TeamApp, User } from '@graphql-types@'
import {
	createContext,
	ReactNode,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react'

import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { useRefreshMutation } from '../auth/mutations/refresh.graphql'
import { useMeQuery } from '../auth/queries/useMe.graphql'
import { CustomAppFlags } from '../portal/interfaces/apps'
import {
	useEmailVerifyToken,
	useTeamId,
	useTeamInviteToken,
	useToken,
} from './store'

// https://dev.to/finiam/predictable-react-authentication-with-the-context-api-g10
interface AuthContextType {
	me?: User
	currentTeam?: Team
	apps?: App[]
	permissions: Permission[]

	error?: any

	authenticating: boolean
	authenticated: boolean
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType)
import * as Sentry from '@sentry/browser'

// Export the provider as we need to wrap the entire app with it
export function AuthProvider({
	children,
}: {
	children: ReactNode
}): JSX.Element {
	const [token, setToken] = useToken()

	const [teamId, setTeamId] = useTeamId()

	const [{ fetching, data }] = useMeQuery({})

	const { me, teams, apps, currentTeam, permissions } = useMemo(() => {
		if (!data) return {}

		const apps: TeamApp[] = data.me.currentTeam.apps

		const teams = data.me.teams?.edges.map((edge) => edge.node)

		const currentTeam = data.me.currentTeam

		return {
			me: data?.me,
			apps: apps.map((ta) => ta.app),
			currentTeam: currentTeam,
			teams: teams,
			permissions: data.permissions,
		}
	}, [fetching, data, token, teamId])

	useEffect(() => {
		if (!me) return

		Sentry.configureScope((scope) => {
			scope.setUser({
				email: me.email,
			})
		})
	}, [me])

	// if no team selected, if team doesn't exist, pick a team
	useEffect(() => {
		if (!teams || teams?.length === 0) return

		if (!teamId || !currentTeam) {
			console.warn(`Could not find team with id ${teamId}`)

			// todo(remco): check if this is ok
			setTeamId(teams[0].teamId)
		}
	}, [teams, teamId])

	// We only want to render the underlying app after we
	// assert for the presence of a current user.
	return (
		<AuthContext.Provider
			value={{
				authenticating: false,
				authenticated: !!token,
				me,
				apps,
				currentTeam,
				permissions,
			}}
		>
			{children}
		</AuthContext.Provider>
	)
}

// Let's only export the `useAuth` hook instead of the context.
// We only want to use the hook directly and never the context component.
export default function useAuth(): AuthContextType {
	return useContext(AuthContext)
}

export function useAuthenticated(): readonly [boolean] {
	const { authenticated } = useAuth()
	return [authenticated] as const
}

export function useMe(): readonly [User] {
	const { me } = useAuth()
	return [me] as const
}

export function useApps(): readonly [
	App[],
	(hasApp: CustomAppFlags) => boolean
] {
	const { apps } = useAuth()

	const hasApp = (hasApp: CustomAppFlags) => {
		if (hasApp === CustomAppFlags.Portal) return true

		return apps.findIndex((app) => app.slug === hasApp) >= 0
	}

	return [apps, hasApp] as const
}

export function usePermissions(): [
	Permission[],
	({
		resource,
		service,
		action,
	}: {
		resource?: string
		service?: string
		action?: string
	}) => boolean
] {
	const { permissions } = useAuth()

	const hasPermission = ({
		resource = '*',
		service = '*',
		action = '*',
	}: {
		resource?: string
		service?: string
		action?: string
	}) => {
		if (!!!service && !!!action) return true

		if (action === '*' && service === '*') return true

		return permissions.reduce((allowed, v) => {
			if (
				(v.action === action || action == '*') &&
				(v.service === service || service == '*')
			) {
				return true
			}

			return allowed
		}, false)
	}

	return [permissions, hasPermission]
}

export function useTeams(): readonly [Team[]] {
	const { me } = useAuth()
	return [me?.teams?.edges.map((edge) => edge.node)] as const
}

export function useCurrentTeam(): Team[] {
	const { currentTeam } = useAuth()
	return [currentTeam]
}
