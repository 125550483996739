/* a079a24b23aaa6791b3fff9fa10c258351fac4bf
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../__types__';

import gql from 'graphql-tag';
import * as React from 'react';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetTeamInviteMutationVariables = Types.Exact<{
  token: Types.Scalars['String'];
}>;


export type GetTeamInviteMutation = { __typename?: 'Mutation', getTeamInvite: { __typename?: 'Team', teamId: string, name: string, createdAt: any, updatedAt: any } };


export const GetTeamInviteDocument = gql`
    mutation getTeamInvite($token: String!) {
  getTeamInvite(input: {token: $token}) {
    teamId
    name
    createdAt
    updatedAt
  }
}
    `;

export const GetTeamInviteComponent = (props: Omit<Urql.MutationProps<GetTeamInviteMutation, GetTeamInviteMutationVariables>, 'query'> & { variables?: GetTeamInviteMutationVariables }) => (
  <Urql.Mutation {...props} query={GetTeamInviteDocument} />
);


export function useGetTeamInviteMutation() {
  return Urql.useMutation<GetTeamInviteMutation, GetTeamInviteMutationVariables>(GetTeamInviteDocument);
};