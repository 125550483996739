/* afb8486f51211d04075b2d909362f4970798497f
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../__types__';

import gql from 'graphql-tag';
import * as React from 'react';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CheckUsernameMutationVariables = Types.Exact<{
  username: Types.Scalars['String'];
  token?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CheckUsernameMutation = { __typename?: 'Mutation', checkUsername?: { __typename?: 'Check', action: string, redirectTo: string } | null };


export const CheckUsernameDocument = gql`
    mutation checkUsername($username: String!, $token: String) {
  checkUsername(username: $username, token: $token) {
    action
    redirectTo
  }
}
    `;

export const CheckUsernameComponent = (props: Omit<Urql.MutationProps<CheckUsernameMutation, CheckUsernameMutationVariables>, 'query'> & { variables?: CheckUsernameMutationVariables }) => (
  <Urql.Mutation {...props} query={CheckUsernameDocument} />
);


export function useCheckUsernameMutation() {
  return Urql.useMutation<CheckUsernameMutation, CheckUsernameMutationVariables>(CheckUsernameDocument);
};