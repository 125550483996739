// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tab__content--M4vcx{flex-grow:1;display:flex;flex-direction:column;overflow-y:auto}", "",{"version":3,"sources":["webpack://./src/app/portal/components/tab/tab.scss"],"names":[],"mappings":"AAAA,qBACI,WAAA,CACA,YAAA,CACA,qBAAA,CACA,eAAA","sourcesContent":[".content {\n    flex-grow: 1;\n    display: flex;\n    flex-direction: column;\n    overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "tab__content--M4vcx"
};
export default ___CSS_LOADER_EXPORT___;
