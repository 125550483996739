// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ficon__feather--hhklp,.ficon__featherS--aBfox .ficon__featherXL--RQMVm{-webkit-transition:all .5s;-moz-transition:all .5s;transition:all .5s;stroke-width:1.5px;stroke-linecap:round;stroke-linejoin:round;fill:none;stroke:currentColor}.ficon__feather--hhklp{width:28px;height:28px}.ficon__featherS--aBfox{width:1rem;height:1rem}.ficon__featherXL--RQMVm{width:8rem;height:8rem}.ficon__active--xr2DP{color:var(--primary-color)}", "",{"version":3,"sources":["webpack://./src/app/portal/components/icon/ficon.scss"],"names":[],"mappings":"AAAA,wEAEI,0BAAA,CACA,uBAAA,CACA,kBAAA,CACA,kBAAA,CACA,oBAAA,CACA,qBAAA,CACA,SAAA,CACA,mBAAA,CAEJ,uBACI,UAAA,CACA,WAAA,CAEJ,wBACI,UAAA,CACA,WAAA,CAGJ,yBACI,UAAA,CACA,WAAA,CAGJ,sBACI,0BAAA","sourcesContent":[".feather,\n.featherS .featherXL {\n    -webkit-transition: all 0.5s;\n    -moz-transition: all 0.5s;\n    transition: all 0.5s;\n    stroke-width: 1.5px;\n    stroke-linecap: round;\n    stroke-linejoin: round;\n    fill: none;\n    stroke: currentColor;\n}\n.feather {\n    width: 28px;\n    height: 28px;\n}\n.featherS {\n    width: 1rem;\n    height: 1rem;\n}\n\n.featherXL {\n    width: 8rem;\n    height: 8rem;\n}\n\n.active {\n    color: var(--primary-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"feather": "ficon__feather--hhklp",
	"featherS": "ficon__featherS--aBfox",
	"featherXL": "ficon__featherXL--RQMVm",
	"active": "ficon__active--xr2DP"
};
export default ___CSS_LOADER_EXPORT___;
