import * as React from 'react'
import * as styles from './accountHeader.scss'
import { Link } from 'react-router-dom'
import { LogOutButton } from '../../../auth/components/logOutButton'
import { ExpandableNav } from '../expandableNav/expandableNav'
import { Avatar } from '../../../auth/components/avatar/avatar'
import { Icon } from '../icon/ficon'
import { Paths } from '../../helpers/routePaths'
import { useCallback } from 'react'
import { SwitchTeams } from '../../../auth/components/switchTeams/switchTeams'
import { useCurrentTeam, useMe, useTeams } from '../../../providers/user'
import { usePopup } from '~/app/providers/popup'

export function AccountHeader(): JSX.Element {
    const [me] = useMe()
    const [teams] = useTeams()
    const [currentTeam] = useCurrentTeam()

    const { setPopup } = usePopup()

    const openSwitchTeam = useCallback(() => {
        setPopup(<SwitchTeams />)
    }, [])

    const hasMultipleTeams =
        teams?.filter((team) => team.teamId != currentTeam?.teamId).length > 0

    const multipleUsers = false

    if (!me) {
        return null
    }

    const navButton = (
        <React.Fragment>
            {me.avatarUrl && <Avatar url={me.avatarUrl} />}
        </React.Fragment>
    )

    return (
        <div className={styles.accountHeader}>
            <p className={styles.currentTeam}>
                <span>Team</span>
                {currentTeam?.name}
            </p>
            <ExpandableNav
                button={navButton}
                className={styles.inner}
                buttonClassName={styles.mainButton}
                navClassName={styles.nav}
            >
                <div className={styles.info}>
                    <h4 className={styles.title}>{me.fullName}</h4>
                    {(hasMultipleTeams || multipleUsers) && (
                        <p className="m-0">
                            Team:{' '}
                            <Link to={Paths.settingsTeam}>
                                {currentTeam?.name}
                            </Link>
                        </p>
                    )}
                </div>
                {hasMultipleTeams && (
                    <button className={styles.button} onClick={openSwitchTeam}>
                        <Icon name="Repeat" small className={styles.icon} />
                        <span className={styles.buttonText}>Switch teams</span>
                    </button>
                )}
                <Link to="/settings/account" className={styles.button}>
                    <Icon name="Settings" small className={styles.icon} />
                    <span className={styles.buttonText}>Account settings</span>
                </Link>
                <LogOutButton className={styles.button}>
                    <Icon name="LogOut" small className={styles.icon} />
                    <span className={styles.buttonText}>Log out</span>
                </LogOutButton>
            </ExpandableNav>
        </div>
    )
}
