import * as React from 'react'
import { AppLinks } from '../../portal/config'
import { List } from '../../portal/components/list/list'
import { AppScreen } from '../../portal/components/appScreen/appScreen'
import { ListLink } from '../../portal/components/listLink/listLink'
import { matchPath } from 'react-router-dom'
import { useRouter } from '../../portal/hooks/useRouter'
import { Paths } from '../../portal/helpers/routePaths'
import { Column } from '../../portal/components/column/column'
import { Icon } from '../../portal/components/icon/ficon'
import { SetPageTitle } from '../../portal/helpers/setPageTitle'
import { Role } from '@graphql-enums@'
import { PermissionComponent } from '~/app/components/permission/permission'

interface Props {
	title?: string
	children?: React.ReactNode
}

export function SettingsAppScreen(props: Props): JSX.Element {
	const { title, children } = props

	const { location } = useRouter()

	return (
		<AppScreen title={title} activeAppLink={AppLinks.settings}>
			<Column sidebarWidth>
				<List>
					<ListLink
						active={
							!!matchPath(
								location.pathname,
								Paths.settingsAccount
							)
						}
						to={Paths.settingsAccount}
					>
						<Icon name="User" small /> Account
					</ListLink>
					<ListLink
						active={
							!!matchPath(location.pathname, Paths.settingsTeam)
						}
						to={Paths.settingsTeam}
					>
						<Icon name="Users" small /> Team
					</ListLink>
					<PermissionComponent
						service={'portal'}
						action="auditlog:list"
					>
						<ListLink
							active={
								!!matchPath(location.pathname, Paths.auditLogs)
							}
							to={Paths.auditLogs}
						>
							<Icon name="Eye" small /> Audit logs
						</ListLink>
					</PermissionComponent>
					<PermissionComponent service={'portal'} action="system:get">
						<ListLink
							active={
								!!matchPath(
									location.pathname,
									Paths.systemManagement
								)
							}
							to={Paths.systemManagement}
						>
							<Icon name="Server" small /> System Management
						</ListLink>
					</PermissionComponent>
				</List>
			</Column>
			{children}
		</AppScreen>
	)
}
