import * as React from 'react'
import * as styles from './checkbox.scss'
import { ChangeEvent } from 'react'
import { FormikErrors, FormikTouched } from 'formik'
import classNames from 'classnames'

export interface CheckboxProps {
    name: string
    label?: string | JSX.Element
    onChange: (e: ChangeEvent) => void
    onBlur?: (e: ChangeEvent) => void
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error?: string | FormikErrors<any>
    touched?: boolean | FormikTouched<any>
    checked: boolean
    readonly?: boolean
    className?: string
    toggle?: boolean
    helpText?: string
}

/**
	Checkbox creates a checkbox input element.

	- Normal checkbox is hidden and replaced by circle with dot look
	- Normal checkbox is brandOrange on checked and not readonly
	- Readonly enables checkbox to be visible but not changeble, dot will turn into grey on readonly
	- Toggle-checkbox is a sliding checkbox element
	- Use checkbox as a toggle-look element by adding toggle to Checkbox.
**/
export function Checkbox(props: CheckboxProps): JSX.Element {
    const {
        name,
        label,
        readonly,
        toggle,
        checked,
        onChange,
        className,
    } = props
    return (
        <div className={classNames('container', className)}>
            <label className={toggle ? styles.switch : styles.label}>
                <input
                    type="checkbox"
                    id={name}
                    name={name}
                    disabled={readonly}
                    defaultChecked={checked}
                    onChange={onChange}
                />
                <div className={toggle ? styles.slider : styles.box}></div>
            </label>
            <label htmlFor={name}>{label}</label>
            {props.helpText && (
                <small className="form-text text-muted">{props.helpText}</small>
            )}
        </div>
    )
}
